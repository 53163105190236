import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { boolean, number, object, string } from 'yup';
import { Validation, regex } from '../../helper/Messages';
import { Path } from '../../helper/path';
import axios from 'axios';
import { showErrorMessage, showSuccessMessage } from '../../helper/showMessage';
import { Icon } from '@iconify/react';
import { useLocation } from 'react-router-dom';

export default function AddNewContact({ openModal, event, session, id, setShowUploadCsv, setShowUpload, GetContactList, navigate, listId, status, GetAllContact, GetAllList }) {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedType, setSelectedType] = useState('');
    const [isLoader, setIsLoader] = useState(false);
    const location = useLocation();

    const toggleDropdown = (index) => {
        setIsOpen((prevIndex) => (prevIndex === index ? false : index));
    };
    const generateValidationSchema = (type) => {
        switch (type) {
            case 'String':
                return string().trim().optional();
            case 'Number':
                return number().optional();
            case 'Boolean':
                return boolean().optional();
            default:
                return string().trim().optional();
        }
    };

    const validationSchema = object().shape({
        firstName: string().trim().required(Validation?.firstNameRequired),
        lastName: string().trim().required(Validation?.lastNameRequired),
        email: string().trim().required(Validation?.emailRequired).matches(regex?.emailRegex, Validation?.emailInvalid),
        jobTitle: string().trim().optional(),
        industry: string().trim().optional(),
        companyName: string().trim().optional(),
        phone: string().trim().optional(),
        domain: string().trim().optional(),
        interest: string().trim().optional(),


        additionalNotes: string().trim().optional(),
        linkedinURL: string().trim().optional(),
        location: string().trim().optional(),
        contactStage: string().trim().optional(),

        name: string().trim().optional(),
        type: string().trim().required(Validation?.typeRequired),
        value: generateValidationSchema(selectedType), // Generate validation schema based on selected type
    });

    const { register: contactCredential, reset, handleSubmit, formState: { errors } } = useForm({ resolver: yupResolver(validationSchema) });

    const onSubmit = async (data, e) => {
        console.log(data, "data")
        e.preventDefault();
        const camelCaseFieldName = data.name.replace(/\s+/g, '');
        setIsLoader(true)
        let requestBody = {
            userInfo: {
                firstName: data.firstName,
                lastName: data.lastName,
                jobTitle: data.jobTitle,
                industry:data.industry,
                companyName: data.companyName,
                phone: data.phone,
                domain: data.domain,
                interest: data.interest,
                additionalNotes: data.additionalNotes,
                linkedinURL: data.linkedinURL,
                location: data.location,
                contactStage: data.contactStage,
                [camelCaseFieldName]: data.value,
                type: data.type,
            },
            email: data.email,
            listId: listId,
        };

        for (const key in requestBody.userInfo) {
            if (requestBody.userInfo.hasOwnProperty(key) && requestBody.userInfo[key] === "") {
                delete requestBody.userInfo[key];
            }
        }

        // Check if the pathname includes "/administration/contact"
        if (!location.pathname.includes('/administration/contact')) {
            requestBody.campaignId = id;
        }
        const path = location.pathname.includes('/administration/contact') ? Path.UploadSingleContact : Path.CampaignSingleContact;
        await axios.post(path,
            requestBody,
            {
                headers: {
                    Authorization: "Bearer " + session?.data?.token,
                }
            }).then((success) => {
                setIsLoader(false)
                showSuccessMessage(success?.data?.message)
                event(!openModal)
                reset();
                if (!location.pathname.includes('/administration/contact')) {
                    setShowUpload(false)
                    setShowUploadCsv(false)
                    GetContactList();
                } else if (status == true) {
                    GetAllContact();
                    GetAllList();
                }
                else {
                    navigate("/administration/contact")
                }
            }).catch((error) => {
                setIsLoader(false)
                console.log(error)
                showErrorMessage(error?.response?.data?.error || 'Something Went Wrong.')
            })
    }

    const handleTypeChange = (event) => {
        setSelectedType(event.target.value);
    };

    return (
        <div className={`${openModal ? 'block' : 'hidden'} bg-black/40 absolute z-20 inset-0 flex items-center overflow-y-hidden`}>
            <div className="bg-white border border-gary-3 mx-auto max-w-[764px] w-full p-6">
                {/* Popup Header */}
                <h4 className="font-bold text-center text-dark text-xl  h-10 md:h-16">Add New Contact</h4>

                <form onSubmit={handleSubmit(onSubmit)} >
                    <div className="flex flex-col gap-3 dropdown__content max-h-[80dvh] overflow-y-auto">

                        <div className="overflow-hidden">
                            <button type="button" onClick={() => toggleDropdown('index1')} className={`p-5 w-full flex items-center justify-between h-16 bg-violet-100 rounded-lg border border-neutral-300 hover:border-purple group transition-all duration-300 text-zinc-800 ${isOpen === 'index1' ? 'border-purple !text-purple' : ''}`}>
                                <span className={`text-sm font-medium group-hover:text-purple transition-all duration-300`}>
                                    Basic Information
                                </span>
                                <span className={` ${isOpen === 'index1' ? '' : 'rotate-180'}`}>
                                    <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path id="Stroke 1" d="M1 7.77637L8 0.776367L15 7.77637" stroke="#2D2D2D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </span>
                            </button>
                            <div className={`grid grid-cols-2 gap-3 overflow-hidden transition-[padding-top] duration-150 dropdown__content ${isOpen === 'index1' ? 'pt-3' : ''}`}
                                style={{ maxHeight: isOpen === 'index1' ? '500px' : '0px' }}>
                                <div>
                                    <label className="custom-label ">First Name</label>
                                    <input {...contactCredential("firstName")} type="text" placeholder="Type the person’s first name" className="custom-input !py-2 border-zinc-400 focus:border-purple !rounded !h-10" />
                                    {
                                        errors.firstName && <div className="error-css">{errors?.firstName?.message}</div>
                                    }
                                </div>
                                <div>
                                    <label className="custom-label ">Last Name</label>
                                    <input  {...contactCredential("lastName")} type="text" placeholder="Type the person’s last name" className="custom-input !py-2 border-zinc-400 focus:border-purple !rounded h-10" />
                                    {
                                        errors.lastName && <div className="error-css">{errors?.lastName?.message}</div>
                                    }
                                </div>
                                <div className="col-span-2">
                                    <label className="custom-label ">Email Address</label>
                                    <input {...contactCredential("email")} type="text" placeholder="Type the person’s email address" className="custom-input !py-2 border-zinc-400 focus:border-purple !rounded h-10" />
                                    {
                                        errors.email && <div className="error-css">{errors?.email?.message}</div>
                                    }
                                </div>
                                <div >
                                    <label className="custom-label ">Job Title</label>
                                    <input type="text" {...contactCredential("jobTitle")} placeholder="“Director of Sales”, “VP of Marketing”, etc." className="custom-input !py-2 border-zinc-400 focus:border-purple !rounded h-10" />
                                </div>
                                <div>
                                    <label className="custom-label ">Industry</label>
                                    <input type="text" {...contactCredential("industry")} placeholder="Industry" className="custom-input !py-2 border-zinc-400 focus:border-purple !rounded h-10" />
                                </div>
                                {/* <div>
                                    <label className="custom-label ">Account</label>
                                    <select className="custom-input !py-2 border-zinc-400 focus:border-purple !rounded !h-10 bg-[url('/src/assets/images/dashboard/select-down-icon.svg')] bg-no-repeat bg-[97.5%]
                                bg-[length:1.3rem] bg-white appearance-none">
                                        <option selected>Choose/ type account name</option>
                                    </select>
                                </div> */}
                                <div>
                                    <label className="custom-label ">Company Name</label>
                                    <input type="text" {...contactCredential("companyName")} placeholder="Company name" className="custom-input !py-2 border-zinc-400 focus:border-purple !rounded h-10" />
                                </div>
                                <div>
                                    <label className="custom-label ">Phone</label>
                                    <input type="number" {...contactCredential("phone")} placeholder="Type phone number" className="custom-input !py-2 border-zinc-400 focus:border-purple !rounded !h-10" />
                                </div>
                                <div>
                                    <label className="custom-label ">Domain URL</label>
                                    <input type="text" {...contactCredential("domain")} placeholder="Domain name" className="custom-input !py-2 border-zinc-400 focus:border-purple !rounded h-10" />
                                </div>
                                <div>
                                    <label className="custom-label ">Interest</label>
                                    <input type="text" {...contactCredential("interest")} placeholder="Interest" className="custom-input !py-2 border-zinc-400 focus:border-purple !rounded h-10" />
                                </div>
                            </div>
                        </div>

                        <div className="overflow-hidden">
                            <button type="button" onClick={() => toggleDropdown('index2')} className={`p-5 w-full flex items-center justify-between h-16 bg-violet-100 rounded-lg border border-neutral-300 hover:border-purple group transition-all duration-300 text-zinc-800 ${isOpen === 'index2' ? 'border-purple !text-purple' : ''}`}>
                                <span className={`text-sm font-medium group-hover:text-purple transition-all duration-300`}>
                                    Additional Information
                                </span>
                                <span className={` ${isOpen === 'index2' ? '' : 'rotate-180'}`}>
                                    <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path id="Stroke 1" d="M1 7.77637L8 0.776367L15 7.77637" stroke="#2D2D2D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </span>
                            </button>
                            <div className={`grid grid-cols-2 gap-3 overflow-hidden transition-[padding-top] duration-150 dropdown__content ${isOpen === 'index2' ? 'pt-3' : ''}`}
                                style={{ maxHeight: isOpen === 'index2' ? '500px' : '0px' }}>

                                {/* <div>
                                    <label className="custom-label ">Account Phone</label>
                                    <input type="number" placeholder="Type phone number" className="custom-input !py-2 border-zinc-400 focus:border-purple !rounded !h-10" />
                                </div>
                                <div className="flex gap-2 items-end">
                                    <div>
                                        <label className="custom-label ">Status</label>
                                        <select className="custom-input !py-2 bg-white border-zinc-400 focus:border-purple !rounded !h-10 bg-[url('/src/assets/images/dashboard/select-down-icon.svg')]
                                        bg-no-repeat bg-[95%] bg-[length:1.3rem] appearance-none">
                                            <option selected="true" disabled="disabled" value="">Status</option>
                                        </select>
                                    </div>
                                    <button type='submit' className={`bg-green text-white rounded-full py-1 text-base h-10 font-semibold px-4 whitespace-nowrap`}>
                                        + Add Phone Number
                                    </button>
                                </div> */}


                                <div className="col-span-2">
                                    <label className="custom-label ">Additional Notes</label>
                                    <textarea  {...contactCredential("additionalNotes")} placeholder="Type the Additional Notes" className="custom-input !py-2 border-zinc-400 focus:border-purple !rounded min-h-20">
                                    </textarea>
                                </div>
                                <div className="col-span-2">
                                    <label className="custom-label ">Linkedin Profile URL</label>
                                    <input type="url" {...contactCredential("linkedinURL")} placeholder="Type the Linkedin Profile URL" className="custom-input !py-2 border-zinc-400 focus:border-purple !rounded h-10" />
                                    {
                                        errors.linkedinURL && <div className="error-css">{errors?.linkedinURL?.message}</div>
                                    }
                                </div>
                                <div className="col-span-2">
                                    <label className="custom-label ">Location</label>
                                    <input type="text" {...contactCredential("location")} placeholder="Location" className="custom-input !py-2 border-zinc-400 focus:border-purple !rounded h-10" />
                                </div>
                                {/* <div>
                                    <label className="custom-label ">Time Zone</label>
                                    <input type="text" readOnly placeholder="Will be decided on location" className="!bg-[#C4C4C4] !text-[#AEAEAE] custom-input !py-2 border-zinc-400 !rounded h-10" />
                                </div> */}
                                <div className="col-span-2">
                                    <label className="custom-label ">Contact Stage</label>
                                    <input type="text" {...contactCredential("contactStage")} placeholder="Type the Contact Stage" className="custom-input !py-2 border-zinc-400 focus:border-purple !rounded h-10" />
                                </div>
                            </div>
                        </div>

                        <div className="overflow-hidden">
                            <button type="button" onClick={() => toggleDropdown('index3')} className={`p-5 w-full flex items-center justify-between h-16 bg-violet-100 rounded-lg border border-neutral-300 hover:border-purple group transition-all duration-300 text-zinc-800 ${isOpen === 'index3' ? 'border-purple !text-purple' : ''}`}>
                                <span className={`text-sm font-medium group-hover:text-purple transition-all duration-300`}>
                                    Custom Fields
                                </span>
                                <span className={` ${isOpen === 'index3' ? '' : 'rotate-180'}`}>
                                    <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path id="Stroke 1" d="M1 7.77637L8 0.776367L15 7.77637" stroke="#2D2D2D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </span>
                            </button>
                            <div className={`grid grid-cols-2 gap-3 overflow-hidden transition-[padding-top] duration-150 dropdown__content ${isOpen === 'index3' ? 'pt-3' : ''}`}
                                style={{ maxHeight: isOpen === 'index3' ? '500px' : '0px' }}>
                                <div className="col-span-2">
                                    <label className="custom-label ">Field Name</label>
                                    <input  {...contactCredential("name")} type="text" placeholder="Enter Name" className="custom-input !py-2 border-zinc-400 focus:border-purple !rounded !h-10" />
                                    {errors.name && <div className="error-css">{errors.name.message}</div>}
                                </div>
                                <div className="col-span-2">
                                    <label className="custom-label ">Type <span className="italic text-zinc-800 text-sm font-extralight">(Type cannot be changed after a custom filed is created)</span></label>
                                    <select  {...contactCredential("type")} value={selectedType} onChange={handleTypeChange} className="custom-input !py-2 border-zinc-400 focus:border-purple !rounded !h-10 bg-[url('/src/assets/images/dashboard/select-down-icon.svg')] bg-no-repeat bg-[98.5%] bg-[length:1.3rem] bg-white appearance-none">
                                        <option disabled selected>Select</option>
                                        <option value="String">String</option>
                                        <option value="Number">Number</option>
                                        <option value="Boolean">Boolean</option>
                                    </select>
                                    {errors.type && <div className="error-css">{errors.type.message}</div>}

                                </div>
                                <div className="col-span-2">
                                    <label className="custom-label ">Value</label>
                                    <input type="text" {...contactCredential("value")} placeholder="value" className="custom-input !py-2 border-zinc-400 focus:border-purple !rounded h-10" />
                                    {errors.value && <div className="error-css">{errors.value.message}</div>}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mt-8 flex justify-end items-center gap-6">
                        <button onClick={() => { event(!openModal); reset(); }} type="button" className="px-4 flex gap-1 items-center border-r border-[#7B7B7B] text-neutral-500 ">
                            <span className="text-sm font-bold">Cancel</span>
                        </button>
                        <button type='submit' className={`flex items-center gap-2 bg-green text-white rounded-full py-1 text-sm h-10 px-16 font-[600]`}>
                            {
                                <span>
                                    {`Save`}
                                </span>
                            }
                            {
                                isLoader && <Icon icon={'svg-spinners:tadpole'} />
                            }
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}
