export const ISO_COUNTRIES = {
    AFG: 'AF',
    ALA: 'AX',
    ALB: 'AL',
    DZA: 'DZ',
    ASM: 'AS',
    AND: 'AD',
    AGO: 'AO',
    AIA: 'AI',
    ATA: 'AQ',
    ATG: 'AG',
    ARG: 'AR',
    ARM: 'AM',
    ABW: 'AW',
    AUS: 'AU',
    AUT: 'AT',
    AZE: 'AZ',
    BHS: 'BS',
    BHR: 'BH',
    BGD: 'BD',
    BRB: 'BB',
    BLR: 'BY',
    BEL: 'BE',
    BLZ: 'BZ',
    BEN: 'BJ',
    BMU: 'BM',
    BTN: 'BT',
    BOL: 'BO',
    BIH: 'BA',
    BWA: 'BW',
    BVT: 'BV',
    BRA: 'BR',
    VGB: 'VG',
    IOT: 'IO',
    BRN: 'BN',
    BGR: 'BG',
    BFA: 'BF',
    BDI: 'BI',
    KHM: 'KH',
    CMR: 'CM',
    CAN: 'CA',
    CPV: 'CV',
    CYM: 'KY',
    CAF: 'CF',
    TCD: 'TD',
    CHL: 'CL',
    CHN: 'CN',
    HKG: 'HK',
    MAC: 'MO',
    CXR: 'CX',
    CCK: 'CC',
    COL: 'CO',
    COM: 'KM',
    COG: 'CG',
    COD: 'CD',
    COK: 'CK',
    CRI: 'CR',
    CIV: 'CI',
    HRV: 'HR',
    CUB: 'CU',
    CYP: 'CY',
    CZE: 'CZ',
    DNK: 'DK',
    DJI: 'DJ',
    DMA: 'DM',
    DOM: 'DO',
    ECU: 'EC',
    EGY: 'EG',
    SLV: 'SV',
    GNQ: 'GQ',
    ERI: 'ER',
    EST: 'EE',
    ETH: 'ET',
    FLK: 'FK',
    FRO: 'FO',
    FJI: 'FJ',
    FIN: 'FI',
    FRA: 'FR',
    GUF: 'GF',
    PYF: 'PF',
    ATF: 'TF',
    GAB: 'GA',
    GMB: 'GM',
    GEO: 'GE',
    DEU: 'DE',
    GHA: 'GH',
    GIB: 'GI',
    GRC: 'GR',
    GRL: 'GL',
    GRD: 'GD',
    GLP: 'GP',
    GUM: 'GU',
    GTM: 'GT',
    GGY: 'GG',
    GIN: 'GN',
    GNB: 'GW',
    GUY: 'GY',
    HTI: 'HT',
    HMD: 'HM',
    VAT: 'VA',
    HND: 'HN',
    HUN: 'HU',
    ISL: 'IS',
    IND: 'IN',
    IDN: 'ID',
    IRN: 'IR',
    IRQ: 'IQ',
    IRL: 'IE',
    IMN: 'IM',
    ISR: 'IL',
    ITA: 'IT',
    JAM: 'JM',
    JPN: 'JP',
    JEY: 'JE',
    JOR: 'JO',
    KAZ: 'KZ',
    KEN: 'KE',
    KIR: 'KI',
    PRK: 'KP',
    KOR: 'KR',
    KWT: 'KW',
    KGZ: 'KG',
    LAO: 'LA',
    LVA: 'LV',
    LBN: 'LB',
    LSO: 'LS',
    LBR: 'LR',
    LBY: 'LY',
    LIE: 'LI',
    LTU: 'LT',
    LUX: 'LU',
    MKD: 'MK',
    MDG: 'MG',
    MWI: 'MW',
    MYS: 'MY',
    MDV: 'MV',
    MLI: 'ML',
    MLT: 'MT',
    MHL: 'MH',
    MTQ: 'MQ',
    MRT: 'MR',
    MUS: 'MU',
    MYT: 'YT',
    MEX: 'MX',
    FSM: 'FM',
    MDA: 'MD',
    MCO: 'MC',
    MNG: 'MN',
    MNE: 'ME',
    MSR: 'MS',
    MAR: 'MA',
    MOZ: 'MZ',
    MMR: 'MM',
    NAM: 'NA',
    NRU: 'NR',
    NPL: 'NP',
    NLD: 'NL',
    ANT: 'AN',
    NCL: 'NC',
    NZL: 'NZ',
    NIC: 'NI',
    NER: 'NE',
    NGA: 'NG',
    NIU: 'NU',
    NFK: 'NF',
    MNP: 'MP',
    NOR: 'NO',
    OMN: 'OM',
    PAK: 'PK',
    PLW: 'PW',
    PSE: 'PS',
    PAN: 'PA',
    PNG: 'PG',
    PRY: 'PY',
    PER: 'PE',
    PHL: 'PH',
    PCN: 'PN',
    POL: 'PL',
    PRT: 'PT',
    PRI: 'PR',
    QAT: 'QA',
    REU: 'RE',
    ROU: 'RO',
    RUS: 'RU',
    RWA: 'RW',
    BLM: 'BL',
    SHN: 'SH',
    KNA: 'KN',
    LCA: 'LC',
    MAF: 'MF',
    SPM: 'PM',
    VCT: 'VC',
    WSM: 'WS',
    SMR: 'SM',
    STP: 'ST',
    SAU: 'SA',
    SEN: 'SN',
    SRB: 'RS',
    SYC: 'SC',
    SLE: 'SL',
    SGP: 'SG',
    SVK: 'SK',
    SVN: 'SI',
    SLB: 'SB',
    SOM: 'SO',
    ZAF: 'ZA',
    SGS: 'GS',
    SSD: 'SS',
    ESP: 'ES',
    LKA: 'LK',
    SDN: 'SD',
    SUR: 'SR',
    SJM: 'SJ',
    SWZ: 'SZ',
    SWE: 'SE',
    CHE: 'CH',
    SYR: 'SY',
    TWN: 'TW',
    TJK: 'TJ',
    TZA: 'TZ',
    THA: 'TH',
    TLS: 'TL',
    TGO: 'TG',
    TKL: 'TK',
    TON: 'TO',
    TTO: 'TT',
    TUN: 'TN',
    TUR: 'TR',
    TKM: 'TM',
    TCA: 'TC',
    TUV: 'TV',
    UGA: 'UG',
    UKR: 'UA',
    ARE: 'AE',
    GBR: 'GB',
    USA: 'US',
    UMI: 'UM',
    URY: 'UY',
    UZB: 'UZ',
    VUT: 'VU',
    VEN: 'VE',
    VNM: 'VN',
    VIR: 'VI',
    WLF: 'WF',
    ESH: 'EH',
    YEM: 'YE',
    ZMB: 'ZM',
    ZWE: 'ZW',
    XKX: 'XK',
};

export const CodeToCountry = {
    "AF": "Afghanistan",
    "AX": "Åland Islands",
    "AL": "Albania",
    "DZ": "Algeria",
    "AS": "American Samoa",
    "AD": "Andorra",
    "AO": "Angola",
    "AI": "Anguilla",
    "AQ": "Antarctica",
    "AG": "Antigua & Barbuda",
    "AR": "Argentina",
    "AM": "Armenia",
    "AW": "Aruba",
    "AU": "Australia",
    "AT": "Austria",
    "AZ": "Azerbaijan",
    "BS": "Bahamas",
    "BH": "Bahrain",
    "BD": "Bangladesh",
    "BB": "Barbados",
    "BY": "Belarus",
    "BE": "Belgium",
    "BZ": "Belize",
    "BJ": "Benin",
    "BM": "Bermuda",
    "BT": "Bhutan",
    "BO": "Bolivia",
    "BA": "Bosnia & Herzegovina",
    "BW": "Botswana",
    "BV": "Bouvet Island",
    "BR": "Brazil",
    "IO": "British Indian Ocean Territory",
    "VG": "British Virgin Islands",
    "BN": "Brunei",
    "BG": "Bulgaria",
    "BF": "Burkina Faso",
    "BI": "Burundi",
    "KH": "Cambodia",
    "CM": "Cameroon",
    "CA": "Canada",
    "CV": "Cape Verde",
    "BQ": "Caribbean Netherlands",
    "KY": "Cayman Islands",
    "CF": "Central African Republic",
    "TD": "Chad",
    "CL": "Chile",
    "CN": "China",
    "CX": "Christmas Island",
    "CC": "Cocos (Keeling) Islands",
    "CO": "Colombia",
    "KM": "Comoros",
    "CG": "Congo - Brazzaville",
    "CD": "Congo - Kinshasa",
    "CK": "Cook Islands",
    "CR": "Costa Rica",
    "CI": "Côte d’Ivoire",
    "HR": "Croatia",
    "CU": "Cuba",
    "CW": "Curaçao",
    "CY": "Cyprus",
    "CZ": "Czechia",
    "DK": "Denmark",
    "DJ": "Djibouti",
    "DM": "Dominica",
    "DO": "Dominican Republic",
    "EC": "Ecuador",
    "EG": "Egypt",
    "SV": "El Salvador",
    "GQ": "Equatorial Guinea",
    "ER": "Eritrea",
    "EE": "Estonia",
    "SZ": "Eswatini",
    "ET": "Ethiopia",
    "FK": "Falkland Islands",
    "FO": "Faroe Islands",
    "FJ": "Fiji",
    "FI": "Finland",
    "FR": "France",
    "GF": "French Guiana",
    "PF": "French Polynesia",
    "TF": "French Southern Territories",
    "GA": "Gabon",
    "GM": "Gambia",
    "GE": "Georgia",
    "DE": "Germany",
    "GH": "Ghana",
    "GI": "Gibraltar",
    "GR": "Greece",
    "GL": "Greenland",
    "GD": "Grenada",
    "GP": "Guadeloupe",
    "GU": "Guam",
    "GT": "Guatemala",
    "GG": "Guernsey",
    "GN": "Guinea",
    "GW": "Guinea-Bissau",
    "GY": "Guyana",
    "HT": "Haiti",
    "HM": "Heard & McDonald Islands",
    "HN": "Honduras",
    "HK": "Hong Kong SAR China",
    "HU": "Hungary",
    "IS": "Iceland",
    "IN": "India",
    "ID": "Indonesia",
    "IR": "Iran",
    "IQ": "Iraq",
    "IE": "Ireland",
    "IM": "Isle of Man",
    "IL": "Israel",
    "IT": "Italy",
    "JM": "Jamaica",
    "JP": "Japan",
    "JE": "Jersey",
    "JO": "Jordan",
    "KZ": "Kazakhstan",
    "KE": "Kenya",
    "KI": "Kiribati",
    "KW": "Kuwait",
    "KG": "Kyrgyzstan",
    "LA": "Laos",
    "LV": "Latvia",
    "LB": "Lebanon",
    "LS": "Lesotho",
    "LR": "Liberia",
    "LY": "Libya",
    "LI": "Liechtenstein",
    "LT": "Lithuania",
    "LU": "Luxembourg",
    "MO": "Macao SAR China",
    "MG": "Madagascar",
    "MW": "Malawi",
    "MY": "Malaysia",
    "MV": "Maldives",
    "ML": "Mali",
    "MT": "Malta",
    "MH": "Marshall Islands",
    "MQ": "Martinique",
    "MR": "Mauritania",
    "MU": "Mauritius",
    "YT": "Mayotte",
    "MX": "Mexico",
    "FM": "Micronesia",
    "MD": "Moldova",
    "MC": "Monaco",
    "MN": "Mongolia",
    "ME": "Montenegro",
    "MS": "Montserrat",
    "MA": "Morocco",
    "MZ": "Mozambique",
    "MM": "Myanmar (Burma)",
    "NA": "Namibia",
    "NR": "Nauru",
    "NP": "Nepal",
    "NL": "Netherlands",
    "NC": "New Caledonia",
    "NZ": "New Zealand",
    "NI": "Nicaragua",
    "NE": "Niger",
    "NG": "Nigeria",
    "NU": "Niue",
    "NF": "Norfolk Island",
    "KP": "North Korea",
    "MK": "North Macedonia",
    "MP": "Northern Mariana Islands",
    "NO": "Norway",
    "OM": "Oman",
    "PK": "Pakistan",
    "PW": "Palau",
    "PS": "Palestinian Territories",
    "PA": "Panama",
    "PG": "Papua New Guinea",
    "PY": "Paraguay",
    "PE": "Peru",
    "PH": "Philippines",
    "PN": "Pitcairn Islands",
    "PL": "Poland",
    "PT": "Portugal",
    "PR": "Puerto Rico",
    "QA": "Qatar",
    "RE": "Réunion",
    "RO": "Romania",
    "RU": "Russia",
    "RW": "Rwanda",
    "WS": "Samoa",
    "SM": "San Marino",
    "ST": "São Tomé & Príncipe",
    "SA": "Saudi Arabia",
    "SN": "Senegal",
    "RS": "Serbia",
    "SC": "Seychelles",
    "SL": "Sierra Leone",
    "SG": "Singapore",
    "SX": "Sint Maarten",
    "SK": "Slovakia",
    "SI": "Slovenia",
    "SB": "Solomon Islands",
    "SO": "Somalia",
    "ZA": "South Africa",
    "GS": "South Georgia & South Sandwich Islands",
    "KR": "South Korea",
    "SS": "South Sudan",
    "ES": "Spain",
    "LK": "Sri Lanka",
    "BL": "St. Barthélemy",
    "SH": "St. Helena",
    "KN": "St. Kitts & Nevis",
    "LC": "St. Lucia",
    "MF": "St. Martin",
    "PM": "St. Pierre & Miquelon",
    "VC": "St. Vincent & Grenadines",
    "SD": "Sudan",
    "SR": "Suriname",
    "SJ": "Svalbard & Jan Mayen",
    "SE": "Sweden",
    "CH": "Switzerland",
    "SY": "Syria",
    "TW": "Taiwan",
    "TJ": "Tajikistan",
    "TZ": "Tanzania",
    "TH": "Thailand",
    "TL": "Timor-Leste",
    "TG": "Togo",
    "TK": "Tokelau",
    "TO": "Tonga",
    "TT": "Trinidad & Tobago",
    "TN": "Tunisia",
    "TR": "Turkey",
    "TM": "Turkmenistan",
    "TC": "Turks & Caicos Islands",
    "TV": "Tuvalu",
    "UM": "U.S. Outlying Islands",
    "VI": "U.S. Virgin Islands",
    "UG": "Uganda",
    "UA": "Ukraine",
    "AE": "United Arab Emirates",
    "GB": "United Kingdom",
    "US": "United States",
    "UY": "Uruguay",
    "UZ": "Uzbekistan",
    "VU": "Vanuatu",
    "VA": "Vatican City",
    "VE": "Venezuela",
    "VN": "Vietnam",
    "WF": "Wallis & Futuna",
    "EH": "Western Sahara",
    "YE": "Yemen",
    "ZM": "Zambia",
    "ZW": "Zimbabwe"
}