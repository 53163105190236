import React, { useEffect, useState } from 'react';
import BackIcon from '../../assets/images/campaigns/backbutton.svg'
import axios from 'axios';
import { Path } from '../../helper/path';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { object, string } from 'yup';
import { UserManagementModalValidation, regex } from '../../helper/Messages';
import { showErrorMessage, showSuccessMessage } from '../../helper/showMessage';
import { Icon } from '@iconify/react';


function UserManagementModel({ hide, userId, sessionData, mode, refresh, setRefresh }) {
    const [userData, setUserData] = useState()
    const [roles, setRoles] = useState([])
    const [brandList, setBrandList] = useState([])
    const [modetype, setModeType] = useState(mode)
    const [isLoader, setIsLoader] = useState(false)
    const validationSchema = object().shape({
        firstName: string().trim().required(UserManagementModalValidation?.firstNameRequired).matches(regex?.nameRegex, UserManagementModalValidation?.firtsNameInvalid),
        lastName: string().trim().required(UserManagementModalValidation?.lastNameRequired).matches(regex?.nameRegex, UserManagementModalValidation?.lastNameInvalid),
        email: string().trim().required(UserManagementModalValidation?.emailRequired).email(UserManagementModalValidation?.emailInvalid),
        role: string().trim().required(UserManagementModalValidation?.roleRequired),
        associatedBrand: string().trim().required(UserManagementModalValidation?.associatedBrandRequired),

    });
    const { register: userModalCredential, handleSubmit, setValue, formState: { errors } } = useForm({ resolver: yupResolver(validationSchema) });

    async function getRoles() {
        await axios.get(Path.roles,
            {
                headers: {
                    Authorization: "Bearer " + sessionData?.token,
                },
            }).then((success) => {
                setRoles(success?.data)
            }).catch((error) => {
                console.log(error)
            })
    }

    async function GetListData() {
        await axios.get(Path.BrandVoiceActive,
            {
                headers: {
                    Authorization: "Bearer " + sessionData?.token,
                }
            }
        ).then((success) => {
            setBrandList(success?.data)
        }).catch((error) => {
            console.log(error)
        })
    }



    useEffect(() => {
        async function ViewUserData() {

            await axios.get(`${Path.UserManagementViewUser}${userId}`,
                {
                    headers: {
                        Authorization: "Bearer " + sessionData?.token,
                    },
                }).then((success) => {
                    setUserData(success?.data)
                    setValue("firstName", success?.data?.firstName || "");
                    setValue("lastName", success?.data?.lastName || "");
                    setValue("email", success?.data?.email || "");
                    setValue("role", success?.data?.roles[0]._id || "");
                    if (success?.data?.brandId) {
                        setValue("associatedBrand", success?.data?.brandId || "");
                    }
                }).catch((error) => {
                    console.log(error)
                })
        }
        ViewUserData();
        getRoles();
        GetListData();
    }, [userId, modetype])


    const onSubmit = async (data, event) => {
        event.preventDefault();
        setIsLoader(true)
        await axios.put(`${Path.UserManagementViewUser}${userId}`,
            {
                firstName: data.firstName,
                lastName: data.lastName,
                emailId: data.email,
                // roleId: data.role,
                brandId: data.associatedBrand
            },
            {
                headers: {
                    Authorization: "Bearer " + sessionData?.token,
                },
            }
        ).then((success) => {
            setIsLoader(false)
            hide()
            showSuccessMessage(success?.data?.message)
            setRefresh(!refresh)
        }).catch((error) => {
            console.log(error)
            showErrorMessage(error?.response?.data?.error || 'Something Went Wrong.')
            setIsLoader(false)

        })
    };
    return (
        <div className={`bg-black/40 absolute z-20 inset-0 flex items-center`}>
            <div className="bg-white border border-gary-3 mx-auto max-w-[800px] w-full p-6">
                {/* Popup Header */}
                <h4 className="font-bold text-center text-dark text-xl md:text-[26px] h-10 md:h-16">{modetype === "view" ? "View" : "Edit"} User</h4>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="grid grid-cols-2 gap-7">

                        <div>
                            <label className="custom-label">
                                {`First Name`}
                            </label>
                            <input type="firstName" name="firstName" readOnly={modetype === 'view'} placeholder="Enter First Name"
                                {...userModalCredential("firstName")}
                                className={errors?.firstName ? `${modetype === 'view' ? "!text-grey-3" : ""} custom-input !border !border-[#FF1D48]` : `custom-input ${modetype === 'view' ? "!text-grey-3" : ""}`}
                            />
                            {
                                errors.firstName && <div className="error-css">{errors?.firstName?.message}</div>
                            }
                        </div>
                        <div>
                            <label className="custom-label">
                                {`Last Name`}
                            </label>
                            <input type="lastName" name="lastName" readOnly={modetype === 'view'} placeholder="Last Name"
                                {...userModalCredential("lastName")}
                                className={errors?.lastName ? `${modetype === 'view' ? "!text-grey-3" : ""} custom-input !border !border-[#FF1D48]` : `custom-input ${modetype === 'view' ? "!text-grey-3" : ""}`}
                            />
                            {
                                errors.lastName && <div className="error-css">{errors?.lastName?.message}</div>
                            }
                        </div>
                        <div className="col-span-2">
                            <label className="custom-label">
                                {`Email`}
                            </label>
                            <input readOnly className={errors?.email ? `${modetype === 'view' ? "!text-grey-3" : ""} custom-input !border !border-[#FF1D48]` : `custom-input ${modetype === 'view' ? "!text-grey-3" : ""}`} type="email" name="email" placeholder="Enter Email" {...userModalCredential("email")}
                            />
                            {
                                errors.email && <div className="error-css">{errors?.email?.message}</div>
                            }
                        </div>
                        <div className="col-span-2">
                            <label className="custom-label">
                                {`Role`}
                            </label>
                            <select disabled {...userModalCredential("role")} className={errors?.role ? `custom-input ${modetype !== 'view' ? "!text-grey-3 bg-[url('/src/assets/images/dashboard/select-down-icon.svg')]" : ""}   bg-no-repeat bg-[92.5%] bg-[length:1.7rem] bg-transparent appearance-none !border !border-[#FF1D48]`
                                :
                                `custom-input ${modetype !== 'view' ? "!text-grey-3 bg-[url('/src/assets/images/dashboard/select-down-icon.svg')]" : ""}  bg-no-repeat bg-[97.5%] bg-[length:1.7rem] bg-transparent appearance-none`} >
                                <option value="" disabled selected>Select user role</option>
                                {roles && roles.map((item) => (
                                    <option key={item._id} value={item._id}>{item.name}</option>
                                ))}
                            </select>
                            {
                                errors.role && <div className="error-css">{errors?.role?.message}</div>
                            }
                        </div>

                        <div className="col-span-2">
                            <label className="custom-label">
                                {`Associated Brand`}
                            </label>
                            <select disabled={modetype === 'view'} {...userModalCredential("associatedBrand")} className={errors?.associatedBrand ? "custom-input  bg-no-repeat bg-[97%] bg-[length:1.5rem] bg-[url('assets/images/common/Arrow-Down.svg')] appearance-none !border !border-[#FF1D48]" : "custom-input bg-[url('/src/assets/images/dashboard/select-down-icon.svg')] bg-no-repeat bg-[97.5%] bg-[length:1.7rem] bg-transparent appearance-none"} >
                                <option selected >Select A Option</option>
                                {
                                    brandList?.length !== 0 && brandList?.map((data) => {
                                        return <option key={data?._id} value={data?._id}>{data?.name}</option>
                                    })
                                }
                            </select>
                            {
                                errors.associatedBrand && <div className="error-css">{errors?.associatedBrand?.message}</div>
                            }
                        </div>

                    </div>

                    {/* Popup Footer */}
                    <div className="mt-10 flex justify-end items-center gap-6">
                        <button
                            onClick={hide}
                            type="button" className="px-4 flex gap-1 items-center border-r border-[#7B7B7B] text-neutral-500 ">
                            <img width={20} height={20} src={BackIcon} alt="" />
                            <span className="text-base font-bold">Back</span>
                        </button>
                        {
                            modetype === 'view'
                                ?
                                <div type='button' onClick={() => setModeType("edit")} className="btn py-3 !bg-green">Edit</div>
                                :
                                <button type="submit" className="flex gap-2 btn py-3 !bg-green">
                                    <span>
                                        Save
                                    </span>
                                    {isLoader && <Icon icon={'svg-spinners:tadpole'} />}
                                </button>
                        }
                    </div>
                </form>
            </div>
        </div>
    )
}

export default UserManagementModel