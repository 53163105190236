export const baseURL = process.env.REACT_APP_BACKEND_URL || 'https://api.ayecampaigns.com';

export const Path = {
  SignUp: `${baseURL}/auth/user`,
  Login: `${baseURL}/auth/login`,
  ForgotPassword: `${baseURL}/auth/forgot-password`,
  ChangePassword: `${baseURL}/auth/change-password`,
  roles: `${baseURL}/v1/role`,
  inviteUser: `${baseURL}/v1/user/invite`,
  updateUser: `${baseURL}/v1/user/`,
  uploadImage: `${baseURL}/v1/upload/profile-picture`,
  resendInviteUser: `${baseURL}/v1/user/invite/`,
  UserManagementListing: `${baseURL}/v1/user`,
  UserManagementListingNew: `${baseURL}/v1/new/user`,
  UserManagementViewUser: `${baseURL}/v1/user/`,
  BrandKnowledgeTextBase: `${baseURL}/v1/knowledgeBase`,
  GetBrandKnowledgeDataByID: `${baseURL}/v1/knowledgeBase/`,
  UpdateBrandKnowledgeByID: `${baseURL}/v1/knowledgeBase/`,
  DeleteBrandKnowledgeByID: `${baseURL}/v1/knowledgeBase/status/`,
  BrandVoiceActive: `${baseURL}/v1/brandVoice/active`,
  BrandVoiceTextBase: `${baseURL}/v1/brandVoice`,
  BrandVoiceTextBaseNew: `${baseURL}/v1/new/brandVoice`,
  GetBrandVoiceDataByID: `${baseURL}/v1/brandVoice/`,
  UpdateBrandVoiceByID: `${baseURL}/v1/brandVoice/`,
  RetryBrandVoiceByID: `${baseURL}/v1/brandVoice/retry/`,

  DeleteBrandVoiceByID: `${baseURL}/v1/brandVoice/status/`,
  DeleteContact: `${baseURL}/v1/dynamic/delete/`,

  CreateORGetCampaign: `${baseURL}/v1/campaign`,
  GetCampaignByID: `${baseURL}/v1/campaign/`,
  CampaignActions: `${baseURL}/v1/campaign/status/`,
  addStepCampaign: `${baseURL}/v1/step`,
  EditStepName: `${baseURL}/v1/step/`,
  campaignOverviewById: `${baseURL}/v1/campaign/step/`,
  DeleteStepCampaignByID: `${baseURL}/v1/step/status/`,
  DeleteTemplateCampaignByID: `${baseURL}/v1/template/status/`,
  EmailGenerate: `${baseURL}/v1/content`,
  EmailSave: `${baseURL}/v1/template`,
  EmailEdit: `${baseURL}/v1/template/version`,
  EmailVariation: `${baseURL}/v1/template/variation`,
  RestoreVersion: `${baseURL}/v1/template/version/restore`,
  FileUpload: `${baseURL}/v1/upload`,
  DeleteUserManagementById: `${baseURL}/v1/user/status/`,
  GetCampaignActivity: `${baseURL}/v1/campaign/activity/`,
  GetDynamicVarList: `${baseURL}/v1/dynamic/keys`,
  GetPersonaList: `${baseURL}/v1/personas`,
  createNewList: `${baseURL}/v1/list/create`,
  getByIdNewList: `${baseURL}/v1/list/get-by-id/`,
  GetCampaignsByBrandId: `${baseURL}/v1/campaign/byBrand/`,
  uploadMultiple: `${baseURL}/v1/contact/create-multiple`,
  CampaignSingleContact: `${baseURL}/v1/dynamic/campaign/create`,
  UploadSingleContact: `${baseURL}/v1/dynamic/create`,
  GetAllList: `${baseURL}/v1/list/get-all`,
  GetContactListByCampaignId: `${baseURL}/v1/dynamic/get-all/`,
  GetContactListByCampaignIdNew: `${baseURL}/v1/new/dynamic/get-all`,

  GetPredefinedTemplates: `${baseURL}/v1/starterTemplates`,
  GetDashboardAnalytics: `${baseURL}/v1/dashboard/analytics`,
  GetDashboardLineChartAnalytics: `${baseURL}/v1/dashboard/lineChart`,
  getByIdContact: `${baseURL}/v1/dynamic/get-by-id/`,
  GetByListContact: `${baseURL}/v1/dynamic/by-list/`,
  AddListToCampaign: `${baseURL}/v1/dynamic/map-records/by-list`,
  SendMail: `${baseURL}/v1/sendmail`,
  campaignAnalytics: `${baseURL}/v1/campaign-analytics/`,
  generateContent: `${baseURL}/v1/generate-content`,
  GenerateManyContent: `${baseURL}/v1/many/generate-content`,
  EmailHistory: `${baseURL}/v1/dynamic/email-history/`,
  EmailHistoryByContactId: `${baseURL}/v1/dynamic/email-history/all/`,
  GetAllContacts: `${baseURL}/v1/dynamic/get-all`,
  GetAllContactsNew: `${baseURL}/v1/new/dynamic/get-all`,

  GetAllIntegration: `${baseURL}/v1/integration`,
  DeleteIntegration: `${baseURL}/v1/integration/status/`,
  MapRecords: `${baseURL}/v1/dynamic/map-records`,
  SendMailVersion: `${baseURL}/v1/sendmail/version`,
  GetPageViews: `${baseURL}/v1/dynamic/page-view-history/`,
  GetPageViewsDetail: `${baseURL}/v1/visitors/pageviews`,
  CreateExperiment: `${baseURL}/v1/experiment/create`,
  GetExperiment: `${baseURL}/v1/experiment/`,
  UpdateExperiment: `${baseURL}/v1/experiment`,
  GetVisitorList: `${baseURL}/v1/visitors`,
  GetVisitorCampaign: `${baseURL}/v1/visitors/campaign/`,
  GetExternalVisitors: `${baseURL}/v1/visitors/external`,
  GetDomain: `${baseURL}/v1/visitors/domain/`,
  GetRule: `${baseURL}/v1/rule`,

  GetVisitorCount: `${baseURL}/v1/visitors/count/`,
  GetCountryCount: `${baseURL}/v1/visitors/country/`,
  GetPageViewCount: `${baseURL}/v1/visitors/pageView/`,
  GetBrowserCount: `${baseURL}/v1/visitors/browserreport/`,
  GetPageReport: `${baseURL}/v1/visitors/pagereport/`,
  GetWeeklyPageReport: `${baseURL}/v1/visitors/weeklyPageReport/`,
  GetWebsites: `${baseURL}/v1/websites`,
  GetCountryReport: `${baseURL}/v1/visitors/countryReport/`,
  BulkUploadByCampaign: `${baseURL}/v1/dynamic/bulk/campaign/create`,
  BulkUploadByDirectory: `${baseURL}/v1/dynamic/bulk/create`,
  generateSave: `${baseURL}/v1/many/generate-save`,
  GetDraftList: `${baseURL}/v1/many/get-saved-drafts`,
  ComposeSavedDraft: `${baseURL}/v1/many/compose-saved-draft`,
  DraftWithExperiment: `${baseURL}/v1/many/draft-with-experiment`,
  ComposeWithExperiment: `${baseURL}/v1/many/compose-with-experiment`,
  DraftDetails: `${baseURL}/v1/many/get-draft-details`,
  UpdateDraftDetails: `${baseURL}/v1/many/update-draft-details`,
  rulesEngine: `${baseURL}/v1/ruleEngin`,
  rulesEngineNew: `${baseURL}/v1/new/ruleEngin`,
  peopleSearch: `${baseURL}/v1/search-peopels`,
  emailAccess: `${baseURL}/v1/search-peopels`,
  updateDraft: `${baseURL}/v1/many/update-drafts-status`,
  reviewAndSend: `${baseURL}/v1/many/review-send-draft`,
  resendEmail: `${baseURL}/v1/many/send-failed-draft`,
  UpdateStatus: `${baseURL}/v1/ruleEngin/status`,
};
