import React from 'react'
import { RiLoader4Line } from "react-icons/ri";


export function FullScreenLoader() {
    return (
        <div className={`fixed top-0 left-0 !z-[99999] w-screen h-screen flex justify-center items-center bg-[rgba(0,0,0,0.4)]`}>
            <RiLoader4Line className="animate-spin" size={64} scale={10} color="white" />
        </div>
    )
}