import React, { useEffect, useState } from 'react';
import textIcon from "../../assets/images/brand-management/text.svg";
import fileIcon from "../../assets/images/brand-management/file.svg";
import linkIcon from "../../assets/images/brand-management/link.svg";
import BrandVoiceTextModel from './BrandVoiceTextModel';
import BrandVoiceLinkModel from './BrandVoiceLinkModel';
import crossIcon from '../../assets/images/common/crossicons.svg';
import BrandVoiceFileModel from './BrandVoiceFileModel';
import { useModal } from 'react-modal-hook';

export default function AddBrandVoiceModel({ refresh, setRefresh, hide, session }) {
    const [toggle, setToggle] = useState(false)
    const [openTextPopUp, hideTextPopUp] = useModal(() => (
        <BrandVoiceTextModel AddBrandVoiceModelHide={hide} hide={() => { hideTextPopUp(); setToggle(false); }} id={''} refresh={refresh} setRefresh={setRefresh} setToggle={setToggle} session={session} />
    ));
    const [openFilePopUp, hideFilePopUp] = useModal(() => (
        <BrandVoiceFileModel AddBrandVoiceModelHide={hide} hide={() => { hideFilePopUp(); setToggle(false); }} id={''} refresh={refresh} setRefresh={setRefresh} setToggle={setToggle} session={session}/>
    ));
    const [openLinkPopUp, hideLinkPopUp] = useModal(() => (
        <BrandVoiceLinkModel AddBrandVoiceModelHide={hide} hide={() => { hideLinkPopUp(); setToggle(false); }} id={''} refresh={refresh} setRefresh={setRefresh} setToggle={setToggle} session={session}/>
    ));


    const handleTextPopUpToggle = () => {
        openTextPopUp()
        setToggle(true)
    }
    const handleFilePopUpToggle = () => {
        openFilePopUp()
        setToggle(true)
    }
    const handleLinkPopUpToggle = () => {
        openLinkPopUp()
        setToggle(true)
    }


    return (
        <>
            <div className={`${toggle ? "hidden " : ""}bg-black/40 absolute z-20 inset-0 flex items-center`}>
                <div className="bg-white border border-gary-3 mx-auto max-w-[900px] w-full p-8">
                    {/* Popup Header */}
                    <div className="ml-auto w-fit cursor-pointer" onClick={() => hide()}>
                        <img width={25} height={25} src={crossIcon} alt="" />
                    </div>
                    <div className="text-center h-16 ">
                        <h4 className="large-title mb-2">Add brand</h4>
                        <p className="text-neutral-500 text-sm ">Give Aye Assistant facts to more accurately write about any topic.</p>
                    </div>

                    <p className="text-center text-dark text-sm">Please select one that applies as per the type of content you want to give to Aye Assistant.</p>
                    <div className="flex justify-between max-w-[700px] items-center mx-auto py-8">
                        <button onClick={() => handleTextPopUpToggle()} type="button" className="text-center flex flex-col justify-center items-center gap-8 rounded-xl border border-[#ECECEC] hover:border-purple focus-within:border-purple w-[218px] h-[225px]">
                            <img width={50} height={50} src={textIcon} alt="" />
                            <div className="font-medium">
                                <h5 className="text-dark text-sm">From Text</h5>
                                <p className="w-40 text-neutral-500 text-xs">Write or copy and <br /> paste text</p>
                            </div>
                        </button>
                        <button onClick={() => handleFilePopUpToggle()} type="button" className="text-center flex flex-col justify-center items-center gap-8 rounded-xl border border-[#ECECEC] hover:border-purple focus-within:border-purple w-[218px] h-[225px]">
                            <img width={50} height={50} src={fileIcon} alt="" />
                            <div className="font-medium">
                                <h5 className="text-dark text-sm">Upload File</h5>
                                <p className="w-40 text-neutral-500 text-xs">Upload a pdf/doc/docx <br/>file</p>
                            </div>
                        </button>

                        <button onClick={() => handleLinkPopUpToggle()} type="button" className="text-center flex flex-col justify-center items-center gap-8 rounded-xl border border-[#ECECEC] hover:border-purple focus-within:border-purple w-[218px] h-[225px]">
                            <img width={50} height={50} src={linkIcon} alt="" />
                            <div className="font-medium">
                                <h5 className="text-dark text-sm">Enter URL</h5>
                                <p className="w-40 text-neutral-500 text-xs">Upload a supported URL for the brand</p>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}
