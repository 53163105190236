import React, { useEffect, useState } from "react"
import backIcon from "../../assets/images/campaigns/backbutton.svg"
import { showErrorMessage, showSuccessMessage } from "../../helper/showMessage";
import axios from "axios";
import { Path } from "../../helper/path";
import { Icon } from "@iconify/react";

export default function CreateExperimentModel({ setExperimentModel, experimentModel, experimentData, session }) {

    const [inputValues, setInputValues] = useState([]);
    const [isLoader, setIsLoader] = useState(false)
    const [data, setData] = useState({})

    async function GetExperiment() {
        await axios.get(`${Path.GetExperiment}${experimentData?.id}?stepId=${experimentData?.item?.step?._id}`, {
            headers: {
                Authorization: "Bearer " + session?.data?.token,
            },
        }).then((success) => {
            if (Object.keys(success?.data).length !== 0) {
                setData(success?.data)
                const newInputValues = success?.data && success?.data?.experimentInfo.map(item => item.percentage);
                setInputValues(newInputValues);
            } else {
                setData({});
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    async function SubmitHandler(e) {
        e.preventDefault();
        const totalPercentage = inputValues.reduce((acc, val) => acc + parseInt(val), 0);

        if (totalPercentage !== 100) {
            showErrorMessage("The total percentage should be equal to 100.");
            return;
        }

        const templateInfo = experimentData.item.templates.map((template, index) => ({
            templateId: template._id,
            percentage: parseInt(inputValues[index])
        }));

        const requestBody = {
            stepId: experimentData?.item?.step?._id,
            campaignId: experimentData?.id,
            templateInfo: templateInfo
        };

        const updateRequestBody = {
            experimentId: data._id,
            templateInfo: templateInfo
        };

        setIsLoader(true)
        if (Object.keys(data).length !== 0) {
            await axios.put(Path.UpdateExperiment,
                updateRequestBody,
                {
                    headers: {
                        Authorization: "Bearer " + session?.data?.token,
                    }
                }).then((success) => {
                    setIsLoader(false)
                    showSuccessMessage(success?.data?.message)
                    setExperimentModel(!experimentModel);

                }).catch((error) => {
                    setIsLoader(false)
                    console.log(error)
                    showErrorMessage(error?.response?.data?.error || 'Something Went Wrong.')
                })
        } else {
            await axios.post(Path.CreateExperiment,
                requestBody,
                {
                    headers: {
                        Authorization: "Bearer " + session?.data?.token,
                    }
                }).then((success) => {
                    setIsLoader(false)
                    showSuccessMessage(success?.data?.message)
                    setExperimentModel(!experimentModel);

                }).catch((error) => {
                    setIsLoader(false)
                    console.log(error)
                    showErrorMessage(error?.response?.data?.error || 'Something Went Wrong.')
                })
        }
    }

    const handleInputChange = (index, value) => {
        const newInputValues = [...inputValues];
        newInputValues[index] = value;
        setInputValues(newInputValues);
    };

    useEffect(() => {
        if (Object.keys(data).length !== 0) {
            setInputValues(Array(experimentData?.item?.templates.length).fill(0));
        }
        if(experimentModel) GetExperiment();
    }, [experimentModel]);

    return (
        <div className={`${experimentModel ? 'block' : 'hidden'} bg-black/40 absolute z-20 inset-0 flex items-center overflow-y-hidden`}>
            <div className="bg-white border border-gary-3 mx-auto max-w-[750px] w-full p-6">
                {/* Popup Header */}
                <div className="text-center">
                    <h4 className="font-bold text-dark text-xl ">Create Experiment</h4>
                    <p className="text-neutral-500 text-sm">Enter traffic load value for respective variations</p>
                </div>

                <form onSubmit={(e) => SubmitHandler(e)} className="text-left mt-[44px]">
                    {/* Popup Main Section */}
                    <div className="flex max-w-[700px] gap-3 mt-5 overflow-auto">
                        {experimentData && experimentData?.item?.templates.map((data, index) => {
                            const parser = new DOMParser();
                            const htmlDoc = parser.parseFromString(data?.htmlBody, 'text/html');
                            const textContent = htmlDoc.body.textContent.trim();
                            const textContentWithoutWhitespace = textContent.replace(/\s{2,}/g, ' ');
                            const truncatedContent = textContentWithoutWhitespace.length > 50 ? textContentWithoutWhitespace.slice(0, 50) + '...' : textContentWithoutWhitespace;
                            return (
                                <div key={index} className=" bg-[#F9FAFC] rounded-[19px] p-5 w-[224px] mx-auto flex-shrink-0">
                                    <h6 className="font-medium mb-2 text-sm">
                                        {`Default Variation ${index + 1}`}
                                    </h6>
                                    <div className="text-xs" title={data?.emailSubject.replace(/(<([^>]+)>)/gi, '')}
                                        dangerouslySetInnerHTML={{ __html: data?.emailSubject.length > 50 ? data?.emailSubject.slice(0, 50) + '...' : data?.emailSubject }}
                                    ></div>
                                    <div className="inline-block text-xs text-gray-6">{truncatedContent}</div>
                                    <label className="bg-white mt-4 text-dark flex items-center border border-purple rounded-lg p-2 w-[80%] text-[22px]">
                                        <input value={inputValues[index] || ''} onChange={(e) => handleInputChange(index, e.target.value)} type="text" className="w-8 outline-none" placeholder="50" />
                                        <span>%</span>
                                    </label>
                                </div>
                            )
                        })}
                    </div>

                    {/* Popup Footer */}
                    <div className="mt-10 flex justify-end items-center gap-6">
                        <button onClick={() => { setExperimentModel(!experimentModel) }} type="button" className="px-4 flex gap-1 items-center border-r border-[#7B7B7B] text-neutral-500 ">
                            <img width={20} height={20} src={backIcon} alt="" />
                            <span className="text-sm font-bold">Back</span>
                        </button>
                        <button type="submit" className="btn py-3 !bg-green gap-2">
                            Save Experiment
                            {
                                isLoader && <Icon icon={'svg-spinners:tadpole'} />
                            }
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}