import React, { useEffect, useState } from 'react'
import backIcon from "../../assets/images/campaigns/backbutton.svg"
import { addNewCampaignModelValidation } from '../../helper/Messages'
import { object, string } from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import axios from 'axios';
import { showSuccessMessage, showErrorMessage } from '../../helper/showMessage';
import { Path } from '../../helper/path';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';


export default function AddNewCampaignModel({ hide, setHide, refresh, setRefresh, session }) {
    const [list, setList] = useState([])
    const [isLoader, setIsLoader] = useState(false)

    const navigate = useNavigate();
    const validationSchema = object().shape({
        campaignName: string().trim().required(addNewCampaignModelValidation?.campaignNameRequired),
        brandName: string().trim().required(addNewCampaignModelValidation?.brandRequired),
    });
    const { register: addNewCampaignModelCredential, handleSubmit, setValue, formState: { errors } } = useForm({ resolver: yupResolver(validationSchema) });

    const onSubmit = async (data, event) => {
        event.preventDefault();
        if (hide.id !== '') {
            setIsLoader(true)
            await axios.put(`${Path.GetCampaignByID}${hide.id}`,
                {
                    name: data.campaignName,
                    brandId: data.brandName
                },
                {
                    headers: {
                        Authorization: "Bearer " + session,
                    }
                }
            ).then((success) => {
                showSuccessMessage(success?.data?.message)
                setHide({ status: true, id: '' })
                setRefresh(!refresh)
                setIsLoader(false)
            }).catch((error) => {
                showErrorMessage(error?.response?.data?.error || 'Something Went Wrong.')
                setIsLoader(false)
            })
        } else {
            setIsLoader(true)
            await axios.post(Path.CreateORGetCampaign,
                {
                    name: data.campaignName,
                    brandId: data.brandName
                },
                {
                    headers: {
                        Authorization: "Bearer " + session,
                    }
                }
            ).then((success) => {
                showSuccessMessage(success?.data?.message)
                setHide({ status: true, id: '' })
                setRefresh(!refresh)
                navigate("/campaigns")
                setIsLoader(false)

            }).catch((error) => {
                showErrorMessage(error?.response?.data?.error || 'Something Went Wrong.')
                setIsLoader(false)

            })
        }
    };

    useEffect(() => {
        async function GetData() {
            await axios.get(`${Path.GetCampaignByID}${hide.id}`,
                {
                    headers: {
                        Authorization: "Bearer " + session,
                    }
                }
            ).then((success) => {
                setValue("campaignName", success?.data?.name)
                setValue("brandName", success?.data?.brandId?._id)
            }).catch((error) => {
                console.log(error)
            })
        }

        async function GetListData() {
            await axios.get(Path.BrandVoiceActive,
                {
                    headers: {
                        Authorization: "Bearer " + session,
                    }
                }
            ).then((success) => {
                setList(success?.data)
            }).catch((error) => {
                console.log(error?.response?.data?.error)
            })
        }
        hide.status === false && GetData();
        hide.status === false && GetListData();
    }, [hide, session])
    return (
        <div className={` ${hide.status === true ? 'hidden' : 'block'} bg-black/40 absolute z-20 inset-0 flex items-center`}>
            <div className="bg-white border border-gary-3 mx-auto max-w-[750px] w-full p-6">
                {/* Popup Header */}
                <h4 className="font-bold text-center text-dark text-xl h-10 md:h-16">Add New Campaign</h4>
                <form onSubmit={handleSubmit(onSubmit)} className="text-left">
                    {/* Popup Main Section */}
                    <div className="mt-5  relative">
                        <label className="custom-label">Start with giving a unique name to your campaign</label>
                        <textarea
                            className={errors.campaignName ? "focus:border-purple custom-input rounded-lg !pr-[8rem] !border !border-[#FF1D48]" : "focus:border-purple custom-input rounded-lg !pr-[8rem]"}
                            rows={4}
                            cols={4}
                            name="cname"
                            placeholder="Campaign Name"
                            {...addNewCampaignModelCredential("campaignName")}
                        >
                        </textarea>
                        {
                            errors.campaignName && <div className="error-css">
                                {errors?.campaignName?.message}
                            </div>
                        }
                        <span className="absolute right-[1rem] top-[3rem] text-zinc-400 text-sm italic">
                            256 characters
                        </span>
                    </div>
                    <div className="col-span-2 mt-4">
                        <label className="custom-label">
                            {`Select Unique Brand for your campaign`}
                        </label>
                        <select  {...addNewCampaignModelCredential("brandName")} defaultValue={""}
                            className={errors.campaignName ? "custom-input bg-white !rounded-lg bg-[url('/src/assets/images/dashboard/select-down-icon.svg')] bg-no-repeat bg-[97.5%] bg-[length:1.7rem] bg-transparent appearance-none text-gray-6 !border  !border-[#FF1D48]" : " custom-input bg-white !rounded-lg bg-[url('/src/assets/images/dashboard/select-down-icon.svg')] bg-no-repeat bg-[97.5%] bg-[length:1.7rem] bg-transparent appearance-none text-gray-6"}
                        >
                            <option value="" disabled>Choose a brand which aye assistant will write in</option>
                            {
                                list?.length !== 0 && list?.map((data, index) => {
                                    return (
                                        <option key={index} value={data?._id}>
                                            {data?.name}
                                        </option>
                                    );
                                })
                            }

                        </select>

                        {
                            errors.brandName && <div className="error-css">{errors?.brandName?.message}</div>
                        }
                    </div>
                    {/* Popup Footer */}
                    <div className="mt-10 flex justify-end items-center gap-6">
                        <button onClick={() => setHide({ status: true, id: '' })} type="button" className="px-4 flex gap-1 items-center border-r border-[#7B7B7B] text-neutral-500 ">
                            <img src={backIcon} alt="" />
                            <span className="text-sm font-bold">Back</span>
                        </button>
                        <button type='submit' className={`flex items-center gap-2 bg-purple text-white rounded-full py-1 text-sm h-10 px-10 font-[600] `}>
                            {
                                <span>
                                    {`Continue`}
                                </span> 
                            }
                            {
                                isLoader && <Icon icon={'svg-spinners:tadpole'} />
                            }
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};