import React, { useContext, useEffect, useRef, useState } from 'react'
import actionIcon from "../../assets/images/brand-management/action-Icon.svg"
import envelopIcon from '../../assets/images/campaigns/envelop.svg'
import { Icon } from '@iconify/react'
import ReactSwitch from 'react-switch'
import { useModal } from "react-modal-hook";
import CreateExperimentModel from '../model/CreateExperimentModel'
import OnboardingCampaignModel from '../model/OnboardingCampaignModel';
import axios from 'axios'
import { Path } from '../../helper/path'
import { AuthContext } from '../../_providers/AuthProvider'
import { showErrorMessage, showSuccessMessage } from '../../helper/showMessage'
import addStepIcon from '../../assets/images/campaigns/add-step.svg'
import { useLocation } from 'react-router-dom'
import { FullScreenLoader } from '../common/FullScreenLoader'
import { EditorEdit, Winner } from '../../helper/SvgIcons'
import Swal from 'sweetalert2'
export default function CampaignOverview({ navigate, filterType, id }) {
    const [overviewStep, setOverviewStep] = useState()
    const { session } = useContext(AuthContext)
    const [refresh, setRefresh] = useState(false);
    const [analyticsData, setAnalyticsData] = useState("")
    const [isLoader, setIsLoader] = useState(false);
    const [experimentModel, setExperimentModel] = useState(false);
    const [experimentData, setExperimentData] = useState("");




    async function GetOverviewSetp() {
        setIsLoader(true)
        await axios.get(`${Path.campaignOverviewById}${id}`, {
            headers: {
                Authorization: "Bearer " + session?.data?.token,
            },
        }).then((success) => {
            setOverviewStep(success?.data)
            setIsLoader(false)

        }).catch((error) => {
            console.log(error)
            setIsLoader(false)

        })
    }
    useEffect(() => {

        GetOverviewSetp()
    }, [refresh, session?.data?.token])

    const [openTextPopUp, hideTextPopUp] = useModal(() => (
        <OnboardingCampaignModel hide={hideTextPopUp} id={id} session={session} navigate={navigate} />
    ));

    useEffect(() => {
        async function getCampaignAnalytics() {
            await axios.get(`${Path.campaignAnalytics}${id}`,
                {
                    headers: {
                        Authorization: "Bearer " + session?.data?.token,
                    }
                }
            ).then((success) => {
                setAnalyticsData(success?.data)
            }).catch((error) => {
                console.log(error?.response?.data)
            })
        }
        session?.data?.token && getCampaignAnalytics();
    }, [session])



    return (
        <>
            {isLoader ? <FullScreenLoader /> : " "}
            <CreateExperimentModel experimentModel={experimentModel} setExperimentModel={setExperimentModel} experimentData={experimentData} session={session} />
            {overviewStep?.length !== 0
                ?
                <div>
                    <div className="bg-white p-5 mt-2 flex justify-between items-center gap-6 md:gap-8 lg:gap-12">
                        <h4 className="text-black-2 text-[15px] font-bold mr-auto">Statistics</h4>
                        <div>
                            <h3 className="text-dark font-bold text-sm">{analyticsData?.sent}</h3>
                            <p className="text-gray-6 text-sm">Sent</p>
                        </div>
                        <div>
                            <h3 className="text-dark font-bold text-sm">{analyticsData?.click}</h3>
                            <p className="text-gray-6 text-sm">Clicked</p>
                        </div>
                        <div>
                            <h3 className="text-dark font-bold text-sm">{analyticsData?.openCount}</h3>
                            <p className="text-gray-6 text-sm">Opened</p>
                        </div>
                        <div>
                            <h3 className="text-dark font-bold text-sm">{analyticsData?.interested}</h3>
                            <p className="text-gray-6 text-sm">Interested</p>
                        </div>
                        <div>
                            <h3 className="text-dark font-bold text-sm">{analyticsData?.bounced}</h3>
                            <p className="text-gray-6 text-sm">Bounced</p>
                        </div>
                        <div>
                            <h3 className="text-dark font-bold text-sm">{analyticsData?.reply}</h3>
                            <p className="text-gray-6 text-sm">Reply</p>
                        </div>
                        <div>
                            <h3 className="text-dark font-bold text-sm">{analyticsData?.delivered}</h3>
                            <p className="text-gray-6 text-sm">Delivered</p>
                        </div>

                    </div>
                    <div className="mb-4">
                        <Table GetOverviewSetp={GetOverviewSetp} overviewStep={overviewStep} session={session} refresh={refresh} setRefresh={setRefresh} navigate={navigate} id={id} filterType={filterType} setExperimentModel={setExperimentModel} experimentModel={experimentModel} setExperimentData={setExperimentData} />
                    </div>
                    <button onClick={openTextPopUp} className="btn font-bold bg-green py-2 mb-4 ml-4 text-sm">Add a Step</button>
                </div>
                :
                <div className="min-h-[80dvh] flex flex-col gap-4 text-center justify-center items-center">
                    <div className="h-[400px] bg-violet-200 flex justify-center w-[650px] md:w-[921px]">
                        <img className="!max-w-[80%]" src={addStepIcon} alt="" />
                    </div>
                    <div>
                        <h4 className="text-dark text-xl font-bold">Supercharge your Workflow with AI driven campaigns</h4>
                        <p className="text-neutral-500 text-xs font-normal mt-1"> Add multiple steps to your campaign and enjoy the hassel free approaches</p>
                    </div>
                    <button onClick={openTextPopUp} className="btn !bg-green">Add Step</button>
                </div>
            }
        </>
    )
}

function Table({ overviewStep, session, setRefresh, refresh, navigate, id, filterType, GetOverviewSetp, experimentModel, setExperimentModel, setExperimentData }) {
    const [toggleA, setToggleA] = useState(true)
    const [toggle, setToggle] = useState(true)
    const [isActive, setIsActive] = useState(false);
    const [editedStepName, setEditedStepName] = useState('');
    const [editIndex, setEditIndex] = useState(null);
    const [isLoader, setIsLoader] = useState(false)
    const dropdownRef = useRef(null);
    const dropdownRefA = useRef(null);


    const StatusHandler = () => {
        setIsActive(!isActive);
    };


    async function Archive(id, type) {
        await axios.get(`${Path.DeleteStepCampaignByID}${id}?status=${type}`,
            {
                headers: {
                    Authorization: "Bearer " + session?.data?.token,
                }
            }
        ).then((success) => {
            showSuccessMessage(success?.data?.message)
            setRefresh(!refresh)
            setToggle(null)
        }).catch((error) => {
            console.log(error?.response?.data)
            setToggle(null)
        })
    }

    async function TemplateArchive(id) {
        await axios.get(`${Path.DeleteTemplateCampaignByID}${id}?status=ARCHIVE`,
            {
                headers: {
                    Authorization: "Bearer " + session?.data?.token,
                }
            }
        ).then((success) => {
            showSuccessMessage(success?.data?.message)
            setRefresh(!refresh)
            setToggle(null)
        }).catch((error) => {
            console.log(error?.response?.data)
            setToggle(null)
        })
    }

    async function Delete(id) {
        await axios.get(`${Path.DeleteStepCampaignByID}${id}?status=DELETED`,
            {
                headers: {
                    Authorization: "Bearer " + session?.data?.token,
                }
            }
        ).then((success) => {
            showSuccessMessage(success?.data?.message)
            setRefresh(!refresh)
            setToggle(null)
        }).catch((error) => {
            console.log(error?.response?.data)
            setToggle(null)
        })
    }

    async function TemplateDelete(id) {
        await axios.get(`${Path.DeleteTemplateCampaignByID}${id}?status=DELETED`,
            {
                headers: {
                    Authorization: "Bearer " + session?.data?.token,
                }
            }
        ).then((success) => {
            showSuccessMessage(success?.data?.message)
            setRefresh(!refresh)
            setToggle(null)
        }).catch((error) => {
            console.log(error?.response?.data)
            setToggle(null)
        })
    }


    const handleEditIconClick = (stepName, index) => {
        setEditedStepName(stepName);
        setEditIndex(index);
    };

    const handleInputChange = (event) => {
        setEditedStepName(event.target.value);
    };

    const handleSave = async (stepId) => {
        setIsLoader(true)
        await axios.put(`${Path.EditStepName}${stepId}`,
            {
                name: editedStepName,
            },
            {
                headers: {
                    Authorization: "Bearer " + session?.data?.token,
                }
            }).then((success) => {
                showSuccessMessage(success?.data?.message)
                GetOverviewSetp();
                setIsLoader(false)
                setEditIndex(null);
            }).catch((error) => {
                console.log(error)
                setIsLoader(false)
                setEditIndex(null);
                showErrorMessage(error?.response?.data?.error || 'Something Went Wrong.')
            })
    };

    useEffect(() => {
        // Handle outside clicks
        const handleClickOutside = (event) => {
            if (dropdownRefA.current && !dropdownRefA.current.contains(event.target)) {
                setToggleA(null);
            }
        };

        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    const DropdownTemplate = ({ templateId, Archive, Delete, navigate }) => {
        const [openDropdown, setOpenDropdown] = useState(false);
        const dropdownRef = useRef(null);

        useEffect(() => {
            const handleClickOutside = (event) => {
                if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                    setOpenDropdown(false);
                }
            };
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, []);

        return (
            <div className='relative' ref={dropdownRef}>
                <button
                    type="button"
                    onClick={() => setOpenDropdown(!openDropdown)}
                    className="flex items-center justify-center w-8 h-8 rounded-full hover:bg-gray-200 transition-colors"
                >
                    <img width={30} height={30} src={actionIcon} alt='' />
                </button>
                <ul className={`text-sm w-48 bg-white absolute right-0 mt-2 shadow-lg z-50 rounded-lg ${openDropdown ? 'block' : 'hidden'}`}>
                    <li className="cursor-pointer font-medium hover:font-bold hover:text-dark hover:bg-purple-100 text-gray-600 transition-all py-2 px-4"
                        onClick={() => navigate(`/campaigns/onboarding-campaign/email-step?templateId=${templateId}`)}>
                        Edit
                    </li>
                    <li className="cursor-pointer font-medium hover:font-bold hover:text-dark hover:bg-purple-100 text-gray-600 transition-all py-2 px-4"
                        onClick={() => Archive(templateId)}>
                        Archive
                    </li>
                    <li className="cursor-pointer font-medium hover:font-bold hover:text-dark hover:bg-purple-100 text-gray-600 transition-all py-2 px-4"
                        onClick={() => {
                            Swal.fire({
                                title: `Are you sure you want to delete template?`,
                                text: "You won't be able to revert this!",
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonColor: "#7F5FEE",
                                cancelButtonColor: "#F86E6E",
                                confirmButtonText: "Yes"
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    Delete(templateId)
                                }
                            })
                        }}>
                        Delete
                    </li>
                </ul>
            </div>
        );
    };


    const Dropdown = ({ item, i, actionIcon, Archive, Delete }) => {
        const [openDropdown, setOpenDropdown] = useState(null);
        const dropdownRefs = useRef([]);

        const handleClickOutside = (event) => {
            if (dropdownRefs.current) {
                dropdownRefs.current.forEach((ref, index) => {
                    if (ref && !ref.contains(event.target)) {
                        setOpenDropdown(null);
                    }
                });
            }
        };

        useEffect(() => {
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, []);

        return (
            <th className="w-24" key={i} onClick={(e) => {
                e.stopPropagation();
            }}>
                <div className="flex justify-end">
                    <div className='relative' ref={(el) => (dropdownRefs.current[i] = el)}>
                        <button
                            type="button"
                            onClick={() => {
                                if (openDropdown === i) {
                                    setOpenDropdown(null);
                                } else {
                                    setOpenDropdown(i);
                                }
                            }}
                            className="flex items-center justify-center w-8 h-8 rounded-full hover:bg-gray-200 transition-colors"
                        >
                            <img width={30} height={30} src={actionIcon} alt='' />
                        </button>

                        <ul className={`text-sm w-48 bg-white absolute right-0 mt-2 shadow-lg z-50 rounded-lg ${openDropdown === i ? 'block' : 'hidden'}`}>
                            {item?.step?.status === "ACTIVE" ? (
                                <li className="cursor-pointer font-medium hover:font-bold hover:text-dark hover:bg-purple-100 text-gray-600 transition-all py-2 px-4" onClick={() => Archive(item?.step?._id, "ARCHIVE")}>
                                    Archive
                                </li>
                            ) : (
                                <li className="cursor-pointer font-medium hover:font-bold hover:text-dark hover:bg-purple-100 text-gray-600 transition-all py-2 px-4" onClick={() => {
                                    setOpenDropdown(null)
                                    Archive(item?.step?._id, "ACTIVE")
                                }}>
                                    Active
                                </li>
                            )}
                            <li className="w-full cursor-pointer font-medium hover:font-bold hover:text-dark hover:bg-purple-100 text-gray-600 transition-all py-2 px-4" onClick={() => {
                                setOpenDropdown(null)
                                Swal.fire({
                                    title: `Are you sure you want to delete step?`,
                                    text: "You won't be able to revert this!",
                                    icon: "warning",
                                    showCancelButton: true,
                                    confirmButtonColor: "#7F5FEE",
                                    cancelButtonColor: "#F86E6E",
                                    confirmButtonText: "Yes"
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        Delete(item?.step?._id)
                                    }
                                })
                            }}>
                                Delete
                            </li>
                        </ul>
                    </div>
                </div>
            </th>
        );
    };

    return (
        <>
            {
                overviewStep && overviewStep.map((item, i) => {
                    // Function to calculate the maximum sum of click and openCount across all templates in all steps
                    const getMaxSumClickOpen = (overviewSteps) => {
                        let maxSum = 0;
                        let winningTemplateId = null;  // Identifier for the winning template

                        overviewSteps.forEach(step => {
                            step.templates.forEach(template => {
                                const currentSum = template.emailHistory.click + template.emailHistory.openCount;
                                if (currentSum > maxSum) {
                                    maxSum = currentSum;
                                    winningTemplateId = template._id;  // Assuming each template has a unique ID
                                }
                            });
                        });
                        return { maxSum, winningTemplateId };
                    };

                    // Calculate the maximum sum and find the winning template ID
                    const { maxSum: maxSumClickOpen, winningTemplateId } = getMaxSumClickOpen(overviewStep);

                    let Condition = item?.step?.status !== 'ARCHIVE'
                    if (filterType === true) Condition = item?.step?.status === 'ARCHIVE'
                    if (Condition)
                        return (
                            <table key={item._id} className="w-full bg-white [&_th]:text-dark text-left text-xs md:text-sm ">
                                {/* Table Header */}
                                <thead
                                    className="bg-[#ECECEC] font-bold [&_th]:px-2 md:[&_th]:px-3 [&_th]:py-4 md:[&_th]:text-sm"
                                    onClick={() => navigate(`/campaigns/onboarding-campaign/email-step?stepId=${item?.step?._id}&id=${id}`)}
                                >
                                    <tr className='cursor-pointer'>
                                        <th>
                                            <div key={item._id} className="flex gap-1 items-center">
                                                {editIndex === i ? (
                                                    <div className="relative">
                                                        <input
                                                            type="text"
                                                            value={editedStepName}
                                                            onChange={handleInputChange}
                                                            autoFocus
                                                            className="text-sm w-[80%] font-bold text-dark outline-none border-b border-gray-400 focus:border-blue-500 pr-8"
                                                        />
                                                        <button
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleSave(item?.step?._id);
                                                            }}
                                                            className="absolute text-purple text-sm right-3 top-0 bottom-0 pr-2"
                                                        >
                                                            {isLoader ? (
                                                                <Icon icon={'svg-spinners:tadpole'} />
                                                            ) : (
                                                                <Icon icon="tabler:check" width={20} height={20} />
                                                            )}
                                                        </button>
                                                    </div>
                                                ) : (
                                                    <div className="flex gap-1 items-center">
                                                        <img
                                                            className="hidden xl:block"
                                                            width={15}
                                                            height={15}
                                                            src={envelopIcon}
                                                            alt=""
                                                        />
                                                        <span
                                                            title={item?.step?.name}
                                                            className="text-sm font-bold text-dark cursor-pointer"
                                                            onClick={() =>
                                                                navigate(
                                                                    `/campaigns/onboarding-campaign/email-step?stepId=${item?.step?._id}&id=${id}`
                                                                )
                                                            }
                                                        >
                                                            {item?.step?.name.length > 25
                                                                ? item?.step?.name.slice(0, 20) + '...'
                                                                : item?.step?.name}
                                                        </span>
                                                        <span
                                                            className="cursor-pointer pl-1"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleEditIconClick(item?.step?.name, i);
                                                            }}
                                                        >
                                                            <EditorEdit />
                                                        </span>
                                                    </div>
                                                )}
                                            </div>
                                        </th>
                                        <th ></th>
                                        <th ></th>
                                        <th ></th>
                                        <th ></th>
                                        <th ></th>
                                        <th ></th>
                                        <th ></th>
                                        <Dropdown
                                            item={item}
                                            i={i}
                                            actionIcon={actionIcon}
                                            Archive={Archive}
                                            Delete={Delete}

                                        />
                                    </tr>
                                </thead>

                                {/* Table Head */}
                                <tbody className="[&_tr]:border-b [&_tr]:border-[#ECECEC] [&_td]:px-2 md:[&_td]:px-3 [&_td]:py-4">
                                    {
                                        item?.templates?.map((data, index) => {
                                            let winnerTag = null;

                                            if (data.emailHistory.click + data.emailHistory.openCount === maxSumClickOpen &&
                                                data._id === winningTemplateId) {
                                                winnerTag = <Winner />;
                                            }

                                            if (data?.status === "ACTIVE") {
                                                const parser = new DOMParser();
                                                const htmlDoc = parser.parseFromString(data?.htmlBody, 'text/html');
                                                const textContent = htmlDoc.body.textContent.trim();
                                                const textContentWithoutWhitespace = textContent.replace(/\s{2,}/g, ' ');
                                                const truncatedContent = textContentWithoutWhitespace.length > 65 ? textContentWithoutWhitespace.slice(0, 65) + '...' : textContentWithoutWhitespace;
                                                return (
                                                    <tr key={data?._id}>
                                                        <td className="text-center w-[250px]">
                                                            <span className="text-dark font-normal">
                                                                {`Default Variation ${index + 1}`}
                                                            </span>
                                                        </td>
                                                        <td onClick={() => navigate(`/campaigns/onboarding-campaign/email-step?templateId=${data?._id}&id=${id}&stepId=${item?.step?._id}`)} className="font-bold text-black-1 w-[600px] xl:w-[700px] cursor-pointer select-none">
                                                            <div title={data?.emailSubject.replace(/(<([^>]+)>)/gi, '')}
                                                                dangerouslySetInnerHTML={{ __html: data?.emailSubject.length > 70 ? data?.emailSubject.slice(0, 70) + '...' : data?.emailSubject }}
                                                            ></div>
                                                            <div className="text-gray-2 font-normal text-xs">{truncatedContent}</div>
                                                        </td>
                                                        <td className='w-[110px]'>
                                                            <span className="text-gray-2 font-normal">
                                                                <strong className="block text-dark">{data?.emailHistory?.sent}</strong>
                                                                <small className='text-sm'>Sent</small>
                                                            </span>
                                                        </td>
                                                        <td className='w-[110px]'>
                                                            <span className="text-gray-2 font-normal">
                                                                <strong className="block text-dark">{data?.emailHistory?.delivered}</strong>
                                                                <small className='text-sm'>Delivered</small>
                                                            </span>
                                                        </td>
                                                        <td className='w-[110px]'>
                                                            <span className="text-gray-2 font-normal ">
                                                                <div className='flex gap-1'>
                                                                    <strong className="block text-dark">{data?.emailHistory?.click}</strong>
                                                                </div>
                                                                <small className='text-sm'>Click</small>
                                                            </span>
                                                        </td>
                                                        <td className='w-[110px]'>
                                                            <span className="text-gray-2 font-normal ">
                                                                <div className='flex gap-1'>
                                                                    <strong className="block text-dark">{data?.emailHistory?.openCount}</strong>
                                                                </div>
                                                                <small className='text-sm'>Open</small>
                                                            </span>
                                                        </td>
                                                        <td className='w-[110px]'>
                                                            <span className="text-gray-2 font-normal">
                                                                <strong className="block text-dark">{data?.emailHistory?.bounced}</strong>
                                                                <small className='text-sm'>Bounced</small>
                                                            </span>

                                                        </td>

                                                        <td className='w-[50px]'>
                                                            {winnerTag}
                                                        </td>
                                                        <td >
                                                            <div className='flex justify-end'>
                                                                <DropdownTemplate templateId={data?._id} Archive={TemplateArchive} Delete={TemplateDelete} navigate={navigate} />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            } else {
                                                return null;
                                            }
                                        })
                                    }
                                    {/* {
                                        showExperiment === i &&
                                        (
                                            <tr>
                                                <td className="text-center w-[250px]">
                                                    <span className="text-dark font-normal">
                                                    </span>
                                                </td>
                                                <td
                                                    onClick={() => navigate(`/campaigns/onboarding-campaign/email-step?id=${id}&stepId=${item?.step?._id}`)}

                                                    className="font-bold text-black-1 lg:w-[300px] xl:w-[600px] cursor-pointer select-none">
                                                    <div>No subject</div>
                                                    <div className="text-gray-2 font-normal text-xs">{"-"}</div>
                                                </td>
                                            </tr>
                                        )
                                    }
                                    <tfoot className="[&_td]:p-3">
                                        <tr className='!border-0'>
                                            <td >
                                                <button onClick={() => setShowExperiment(showExperiment === i ? null : i)} type="button" className="block bg-transparent outline-none text-zinc-800 text-sm font-semibold border border-[#D6D6D6] p-2 rounded-md">
                                                    Start Experiment
                                                </button>
                                            </td>
                                        </tr>
                                    </tfoot> */}

                                    <tfoot className="[&_td]:p-3">
                                        {item.templates.length >= 2 &&
                                            <tr className='!border-0'>
                                                <td >
                                                    <button type="button" onClick={() => {
                                                        setExperimentModel(!experimentModel);
                                                        setExperimentData({ item, id })
                                                    }
                                                    }
                                                        className="block bg-transparent outline-none text-zinc-800 text-sm font-semibold border border-[#D6D6D6] p-2 rounded-md">
                                                        Start Experiment
                                                    </button>
                                                </td>
                                            </tr>
                                        }

                                    </tfoot>
                                </tbody>
                                {/* Table Footer */}

                            </table>
                        )
                })
            }
        </>
    )

}