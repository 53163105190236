/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../_providers/AuthProvider";
import axios from "axios";
import { Path } from "../../helper/path";
import EditorLoader from "../common/EditorLoader";
import { mjmlConvert } from "../../helper/Utility";
import { showErrorMessage, showSuccessMessage } from "../../helper/showMessage";

function FullScreenEditor() {
  const navigate = useNavigate();
  const location = useLocation();
  const iframeRef = useRef(null);
  const { session } = useContext(AuthContext);
  const searchParams = new URLSearchParams(location.search);

  const campaignId = searchParams.get("id");
  const stepId = searchParams.get("stepId");
  const templateId = searchParams.get("templateId");
  const variationId = searchParams.get("variationId");

  const [saveMjml, setSavedMjml] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState({ mjml: "" });
  const [subject, setSubject] = useState("");

  // const iframeSrc = "http://localhost:3000";
  const iframeSrc = "https://aye-campaign-editor.fair.dental";
  const style = {
    width: "100%",
    height: "100dvh",
    border: "none",
  };

    async function SaveAPI(mjml, html) {
        setIsLoading(true);
        await axios.post(Path.EmailSave,
            {
                subject: subject,
                body: mjml,
                campaignId: campaignId,
                stepId: stepId,
                htmlBody: html,
            },
            {
                headers: {
                    Authorization: "Bearer " + session?.data?.token,
                },
            }
        ).then((success) => {
            showSuccessMessage(success?.data?.message);
            navigate(-1);
        }).catch((error) => {
            showErrorMessage(error?.response?.data?.error);
        }).finally(() => {
            setIsLoading(false);
        })
    }

    async function EditAPI(mjml, html) {
        setIsLoading(true);
        // console.log(sub)
        await axios.post(Path.EmailEdit,
            {
                subject: subject,
                body: mjml,
                templateId: templateId,
                htmlBody: html,
            },
            {
                headers: {
                    Authorization: "Bearer " + session?.data?.token,
                },
            }
        ).then((success) => {
            showSuccessMessage(success?.data?.message);
            // navigate(-1);
        }).catch((error) => {
            showErrorMessage(error?.response?.data?.error);
        }).finally(() => {
            setIsLoading(false);
        })
    }

    async function VariationAPI(mjml, html) {
        setIsLoading(true);
        await axios.post(Path.EmailVariation,
            {
                subject: subject,
                body: mjml,
                campaignId: campaignId,
                stepId: stepId,
                variationFrom: variationId,
                htmlBody: html,
            },
            {
                headers: {
                    Authorization: "Bearer " + session?.data?.token,
                },
            }
        ).then((success) => {
            showSuccessMessage(success?.data?.message);
            navigate(-1);
        }).catch((error) => {
            showErrorMessage(error?.response?.data?.error);
        }).finally(() => {
            setIsLoading(false);
        })
    }


    useEffect(() => {
        async function GetInitialData(id) {
            await axios.get(`${Path.EmailSave}/${id}`, {
                headers: {
                    Authorization: "Bearer " + session?.data?.token,
                },
            }).then((success) => {
                setMessage({ mjml: success?.data?.emailBody })
                setSubject(success?.data?.emailSubject)
            }).catch((error) => {
                console.log(error?.response?.data?.error);
            }).finally(() => {
                setIsLoading(false);
            })
        }
        templateId != null && session.data.token && GetInitialData(templateId);
        variationId != null && session.data.token && GetInitialData(variationId);
    }, [templateId, variationId, session])

  useEffect(() => {
    const iframe = iframeRef.current;
    iframe.addEventListener("load", () => {
      const win = iframe.contentWindow;
      win.postMessage(message, iframeSrc);
    });
  }, [iframeRef, message]);

  // TO DO
  // If Save is clicked update this MJML to DB
  useEffect(() => {
    setIsLoading(true);

        const handler = (ev) => {
            if (typeof ev.data !== "object") return;
            if (!ev.data.type) return;

      if (ev.data.type === "cancel-click") {
        navigate(-1);
        return;
      }
      if (ev.data.type !== "save-click") return;
      if (!ev.data.message) return;

            if (ev.data.type === "save-click") {
                if (subject !== '') {
                    if (variationId == null) {
                        if (templateId == null) SaveAPI(ev.data.message, mjmlConvert(ev.data.message)?.html)
                        else EditAPI(ev.data.message, mjmlConvert(ev.data.message)?.html)
                    } else VariationAPI(ev.data.message, mjmlConvert(ev.data.message)?.html);
                } else {
                    console.log(subject)
                }
            }
        };

    window.addEventListener("message", handler);

    return () => window.removeEventListener("message", handler);
  }, []);

    useEffect(()=>{
        console.log(subject)
    },[subject])
    return (
        <div>
            {isLoading && <EditorLoader />}
            <iframe
                ref={iframeRef}
                src={iframeSrc}
                title="Iframe Page"
                style={style}
            />
        </div>
    );
}

export default FullScreenEditor;
