import React, { useContext, useEffect, useState } from 'react';
import ResendIcon from "../../assets/images/common/resend-icon.svg"
import Clearbit from "../../assets/images/common/clearbit.svg"
import Apollo from "../../assets/images/common/apollo.svg"
import AddApolloCredentialModel from '../model/AddApolloCredentialModel';
import AddClearbitCredentialModel from '../model/AddClearBitCredentialModel';
import AddResendCredentialModel from '../model/AddResendCredentialModel';
import { AuthContext } from '../../_providers/AuthProvider';
import { useModal } from 'react-modal-hook';
import axios from 'axios';
import { Path } from '../../helper/path';
import Loader from '../common/Loader';
import Swal from 'sweetalert2';
import ReactSwitch from 'react-switch';
import { showErrorMessage } from '../../helper/showMessage';

function IntegrationSettings() {
    const [isLoader, setIsLoader] = useState(false);
    const [selectedIntegration, setSelectedIntegration] = useState(null);
    const { session } = useContext(AuthContext);
    const [openSuccesApolloCredentialPopUp, hideSuccesApolloCredentialPopUp] = useModal(() => (
        <AddApolloCredentialModel selectedIntegration={selectedIntegration} sessionData={session} hide={(status) => {
            if (status) {
                getAllIntegration()
            }
            hideSuccesApolloCredentialPopUp()
            setSelectedIntegration(null)
        }} />
    ), [session, selectedIntegration]);
    const [openSuccesClearbitCredentialPopUp, hideSuccesClearbitCredentialPopUp] = useModal(() => (
        <AddClearbitCredentialModel selectedIntegration={selectedIntegration} sessionData={session} hide={(status) => {
            if (status) {
                getAllIntegration()
            }
            hideSuccesClearbitCredentialPopUp()
            setSelectedIntegration(null)
        }} />
    ), [session, selectedIntegration]);
    const [openSuccesResendCredentialPopUp, hideSuccesResendCredentialPopUp] = useModal(() => (
        <AddResendCredentialModel selectedIntegration={selectedIntegration} sessionData={session} hide={(status) => {
            if (status) {
                getAllIntegration()
            }
            hideSuccesResendCredentialPopUp()
            setSelectedIntegration(null)
        }} />
    ), [session, selectedIntegration]);
    async function getAllIntegration() {
        setIsLoader(true);
        await axios.get(Path.GetAllIntegration,
            {
                headers: {
                    Authorization: "Bearer " + session?.data?.token,
                },
            })
            .then((integrations) => {
                let currentIntergationList = [...integrationList]
                integrations.data?.map((integration) => {
                    if (integration?.applicationName === 'Resend') {
                        let index = currentIntergationList.findIndex((item) => item?.heading === 'Resend')
                        if (index !== -1) {
                            currentIntergationList[index].btnText = 'Disconnect'
                            currentIntergationList[index]._id = integration?._id
                        }
                    }
                    if (integration?.applicationName === 'Clearbit') {
                        let index = currentIntergationList.findIndex((item) => item?.heading === 'Clearbit')
                        if (index !== -1) {
                            currentIntergationList[index].btnText = 'Disconnect'
                            currentIntergationList[index]._id = integration?._id
                        }
                    }
                    if (integration?.applicationName === 'Apollo') {
                        let index = currentIntergationList.findIndex((item) => item?.heading === 'Apollo')
                        if (index !== -1) {
                            currentIntergationList[index].btnText = 'Disconnect'
                            currentIntergationList[index]._id = integration?._id
                        }
                    }
                })
                setIntegration(currentIntergationList)
                setIsLoader(false);
            })
            .catch((error) => {
                setIsLoader(false);
                console.log(error);
            });
    }
    const handleRowClick = (item) => {
        if (item?.btnText === 'Connect') {
            item?.onClick();
        } else {
            setSelectedIntegration(item)
            item?.onClick();
        }
    }
    const toggleClick = (item) => {
        if (item?.btnText === 'Connect') {
            item?.onClick();
        } else {
            Swal.fire({
                title: `Are you sure you want to disconnect?`,
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#7F5FEE",
                cancelButtonColor: "#F86E6E",
                confirmButtonText: "Yes"
            }).then((result) => {
                if (result.isConfirmed) {
                    setIsLoader(true);
                    axios.get(`${Path.DeleteIntegration}${item?._id}?status=DELETED`, {
                        headers: {
                            Authorization: "Bearer " + session?.data?.token,
                        }
                    })
                        .then(() => {
                            setIsLoader(false);
                            window.location.reload();
                        });
                }
            });
        }
    };

    useEffect(() => {
        session?.data?.token && getAllIntegration();
    }, [session?.data?.token]);

    const [integrationList, setIntegration] = useState([
        {
            _id: '1',
            img: ResendIcon,
            heading: 'Resend',
            content: 'Your Resend account is connected with Aye Campaigns.',
            link: '',
            btnText: 'Connect',
            documentationUrl: 'https://resend.com/api-keys',
            onClick: openSuccesResendCredentialPopUp
        },
        {
            _id: '2',
            img: Clearbit,
            heading: 'Clearbit',
            content: 'Your Hubspot account is connected with Aye Campaigns.',
            link: '',
            btnText: 'Connect',
            documentationUrl: 'https://dashboard.clearbit.com/keys',
            onClick: openSuccesClearbitCredentialPopUp
        },
        {
            _id: '3',
            img: Apollo,
            heading: 'Apollo',
            content: 'Your Apollo account is connected with Aye Campaigns.',
            link: '',
            btnText: 'Connect',
            documentationUrl: 'https://app.apollo.io/#/settings/integrations/api',
            onClick: openSuccesApolloCredentialPopUp
        },
    ])
    return (
        <div className='w-full min-h-full flex flex-col gap-10 select-none font-sans'>
            <div className='w-full flex flex-col'>
                <span className='text-gray-2 text-sm '>
                    {`Administration > Integration Settings`}
                </span>
                <div className='large-title'>
                    {`Integration Settings`}
                </div>
            </div>
            <div className='w-full flex flex-col gap-4 bg-white p-4 pb-8'>
                <div className='font-semibold text-black-2 text-base'>
                    {`Connected Integration`}
                </div>
                <hr className='border-gray-2' />
                {isLoader ? <div className='w-full h-[400px] flex items-center justify-center'><Loader /></div> : <div className='w-full flex flex-col gap-3'>
                    {
                        integrationList?.length !== 0 && integrationList?.map((items) => {
                            return (
                                <div key={items?._id} className='w-full flex justify-between items-center rounded-lg bg-grey-4 p-4 cursor-pointer' >
                                    <div className='flex items-center gap-3 flex-1' onClick={() => {
                                        if (items?.btnText === 'Disconnect') {
                                            handleRowClick(items)
                                        }
                                    }}>
                                        <div className='size-10 rounded-full'>
                                            <img src={items?.img} alt="Icon" />
                                        </div>
                                        <div className='flex flex-col gap-1'>
                                            <span className='font-semibold text-black-1 text-[15px]'>
                                                {items?.heading}
                                            </span>
                                            <span className='text-gray-2 text-sm'>
                                                {items?.content}
                                            </span>
                                        </div>
                                    </div>
                                    <div>
                                        <ReactSwitch
                                            checked={items?.btnText === 'Disconnect'}
                                            className={` cursor-pointer transition-all duration-300 ease-in-out transform`}
                                            uncheckedIcon={false}
                                            onChange={() => toggleClick(items)}
                                            checkedIcon={false}
                                            boxShadow="0 0 2px 2px #ccc"
                                            onColor="#53D0A4"
                                            offColor="#FF0000"
                                            width={39}
                                            height={20}
                                            handleDiameter={12}
                                        />
                                    </div>
                                </div>
                            )
                        })
                    }

                </div>}
            </div>
        </div>
    );
};

export default IntegrationSettings;