import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import microsoftIcon from "../../assets/images/campaigns/microsoft-excel.png";
import listViewIcon from "../../assets/images/campaigns/list-view.svg";
import AddContactListModel from "../model/AddContactSelectListModel";
import { useModal } from "react-modal-hook";
import importContactIcon from "../../assets/images/campaigns/importContactIcon.svg";
import UploadCSVModel from "../model/UploadCSVModel";
import { useLocation, useNavigate } from "react-router-dom";
import {
    ArchiveIcon,
    CallIcon,
    DownloadIcon,
    EmailIcon,
    LinkedInIcon,
    Personas,
    UserContactIcon,
} from "../../helper/SvgIcons";
import axios from "axios";
import { Path } from "../../helper/path";
import { AuthContext } from "../../_providers/AuthProvider";
import CSVReader from "react-csv-reader";
import { showErrorMessage, showSuccessMessage } from "../../helper/showMessage";
import { FullScreenLoader } from "../common/FullScreenLoader";
import AddNewContact from "../model/AddNewContact";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { object, string } from "yup";
import { Validation } from "../../helper/Messages";
import NewSelectListModal from "../model/NewSelectListModal";
import { Icon } from "@iconify/react";
import Swal from "sweetalert2";
import { Dropdown } from "../administration/ContactDirectory";
import ReactPaginate from "react-paginate";

export default function CampaignContact({ }) {
    const [isLoader, setIsLoader] = useState(false);
    const { session } = useContext(AuthContext);
    const [toggle, setToggle] = useState(true);
    const [activeTab, setActiveTab] = useState(0);
    const [openModal, setOpenModal] = useState(false);
    const [basicFieldError, setBasicFieldError] = useState([]);
    const [contactData, setContactData] = useState([]);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get("id");
    const [contactList, setContactList] = useState([]);
    const [showUpload, setShowUpload] = useState(false);
    const [showUploadCsv, setShowUploadCsv] = useState(false);
    const [stepModal, setStepModal] = useState(false);
    const [selectedId, setSelectedId] = useState([]);
    const [selectListModal, setSelectListModal] = useState(false);
    const [selectCSVListModal, setSelectCSVListModal] = useState(false);
    const [listId, setListId] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");
    const [openImportModal, setOpenImportModal] = useState(false);
    const [fileKey, setFileKey] = useState(0);
    const [fileName, setFileName] = useState(0);
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
    const [selectedDraftCheckboxes, setSelectedDraftCheckboxes] = useState([]);
    const [dynamicVarList, setDynamicVarList] = useState([]);
    const [mapOptions, setMapOptions] = useState([]);
    // draft list state
    const [drafts, setDrafts] = useState([]);
    const [reload, setReload] = useState(false);
    const navigate = useNavigate();


    const [showSetting, setShowSetting] = useState(false);
    const [filter, setFilter] = useState({ name: '' })
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
    const [pageCount, setPageCount] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);

    const [openSelectListPopUp, hideSelectListPopUp] = useModal(() => (
        <AddContactListModel
            campaignId={id}
            hide={(status) => {
                hideSelectListPopUp()
                if (status) {
                    setShowUpload(false);
                    setShowUploadCsv(false);
                    GetContactList()
                }
            }} session={session} />
    ), [session]);
    const [openUploadCSVtPopUp, hideUploadCSVtPopUp] = useModal(() => (
        <UploadCSVModel hide={hideUploadCSVtPopUp} />
    ));

    const BasicFields = [
        // { key: "contactStage", type: "string", required: true, regex: null },
        // {
        //   key: "firstName",
        //   type: "string",
        //   required: true,
        //   regex: /^[a-zA-Z\s']+$/,
        // },
        // {
        //   key: "lastName",
        //   type: "string",
        //   required: true,
        //   regex: /^[a-zA-Z\s']+$/,
        // },
        // {
        //   key: "dateOfBirth",
        //   type: "string",
        //   required: true,
        //   regex: /^\d{4}-\d{2}-\d{2}$/,
        //   format: "Date format YYYY-MM-DD",
        // }, // Assuming date format YYYY-MM-DD
        {
            key: "email",
            type: "string",
            required: true,
            regex: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        },
        // {
        //   key: "phone",
        //   type: "string",
        //   required: true,
        //   regex: /^\+?[0-9]{7,15}$/,
        //   format: "International phone format with optional '+' (7 to 15 digits)",
        // },
        // { key: "companyName", type: "string", required: false, regex: null },
        // {
        //   key: "domainName",
        //   type: "string",
        //   required: false,
        //   regex:
        //     /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/,
        // },
        // { key: "status", type: "string", required: true, regex: null },
        // {
        //   key: "additionalNotes",
        //   type: "string",
        //   required: false,
        //   regex: null,
        // },
        // {
        //   key: "linkedinUrl",
        //   type: "string",
        //   required: false,
        //   regex: /^(https?:\/\/)?(www\.)?linkedin\.com\/.*$/,
        // },
        // { key: "personas", type: "string", required: false, regex: null },
        // { key: "jobTitle", type: "string", required: false, regex: null },
    ];

    function CheckBasicFields(array, fields) {
        let errors = [];

        array.forEach((obj, index) => {
            fields.forEach(({ key, type, required, regex, format }) => {
                const value = obj[key];
                const isValueNull = value === null;

                if (required && !obj.hasOwnProperty(key)) {
                    errors.push(`Row ${index + 1} is missing required key '${key}'`);
                } else if (required && obj.hasOwnProperty(key) && isValueNull) {
                    errors.push(
                        `Row ${index + 1
                        } has required key '${key}' but the value is missing`
                    );
                } else if (required && !isValueNull && typeof value !== type) {
                    errors.push(
                        `Row ${index + 1
                        } has key '${key}' with incorrect type. Expected ${type}, found ${typeof value}`
                    );
                } else if (regex && !isValueNull && value && !regex.test(value)) {
                    let formatError = format
                        ? ` Expected format: ${format}`
                        : " Expected value to match provided pattern";
                    errors.push(
                        `Row ${index + 1
                        } has key '${key}' with value not matching the required format.${formatError}`
                    );
                }
            });
        });

        if (errors.length > 0) {
            console.log(errors);
            setBasicFieldError(errors);
            return false;
        }

        return true;
    }

    function AppendNewFields(array, newFields) {
        array.forEach((obj) => {
            for (const [key, value] of Object.entries(newFields)) {
                obj[key] = value;
            }
        });
        return array;
    }

    const handleForce = (data, fileInfo) => {
        // Reset the states
        setContactData([]);
        setErrorMessage("");
        setBasicFieldError([]);

        if (data.length === 0) {
            setErrorMessage("No data found in the file. Please upload a valid CSV.");
            return;
        }

        // Validate the basic fields
        if (CheckBasicFields(data, BasicFields)) {
            const newListFields = {
                // campaignId: id, // Get List Id from previous page || Change this to campaignId when using this component in campaign
                // addedBy: session?.data?.userInfo?._id,
            };
            const _data = AppendNewFields(data, newListFields);
            setContactData(_data);
            setOpenImportModal(!openImportModal)
            setFileKey((prevKey) => prevKey + 1);
            setFileName(fileInfo.name)
        } else {
            // Show Error Model
            showErrorMessage(basicFieldError);
        }
    };

    async function GetContactList(pageNumber) {
        setIsLoader(true);
        await axios
            .get(`${Path.GetContactListByCampaignIdNew}/${id}`, {
                params: {
                    page: pageNumber,
                    size: 10,
                    name: filter.name,
                    sortKey: sortConfig.key,
                    sortOrder: sortConfig.direction,
                },
                headers: {
                    Authorization: "Bearer " + session?.data?.token,
                },
            })
            .then((success) => {
                setIsLoader(false);
                setPageCount(success?.data?.totalPages)
                setContactList(success?.data?.contacts);
            })
            .catch((error) => {
                setIsLoader(false);
                console.log(error);
            });
    }



    const handlePageClick = (data) => {
        const selectedPage = data.selected + 1;
        setCurrentPage(selectedPage);
        GetContactList(selectedPage);
    };

    const requestSort = key => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    const sortedContactListing = useMemo(() => {
        let sortableItems = [...contactList];
        if (sortConfig !== null) {
            sortableItems.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'asc' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'asc' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableItems;
    }, [contactList, sortConfig]);


    function convertArray(inputArray, mapOptions) {
        let destructuredField = ["email"];
        let outputArray = [];

        // Filter out the values and then map them back to an object with new indices
        const filteredValues = Object.values(mapOptions).filter(
            (value) => !destructuredField.includes(value)
        );

        // Reconstruct the object with new sequential indices
        const filteredMapOptions = Object.fromEntries(
            filteredValues.map((value, index) => [index.toString(), value])
        );

        inputArray.forEach((obj) => {
            const { email, ...rest } = obj;
            const userInfo = {};
            Object.entries(rest).forEach(([key, value], index) => {
                const selectedKey = filteredMapOptions[index] || key;
                const finalKey = selectedKey.startsWith("data.")
                    ? selectedKey.slice(5)
                    : selectedKey;
                userInfo[finalKey] = value;
            });
            outputArray.push({ userInfo, email });
        });
        return outputArray;
    }

    async function UploadHandleClick() {
        if (contactData == null) {
            showErrorMessage("Please check the contact the data");
        } else {
            const updatedData = convertArray(contactData, mapOptions);

            // Ensure updatedData is an array and append campaignId and listId to each object
            if (Array.isArray(updatedData)) {
                updatedData.forEach((obj) => {
                    obj.campaignId = id;  // Replace id with your actual campaign ID
                    obj.listId = listId;  // Replace listId with your actual list ID
                });
            }
            await axios
                .post(`${Path.BulkUploadByCampaign}`, updatedData, {
                    headers: {
                        Authorization: "Bearer " + session?.data?.token,
                    },
                })
                .then((success) => {
                    showSuccessMessage(success?.data?.message);
                    setShowUpload(false);
                    setShowUploadCsv(false);
                    GetContactList();
                    setBasicFieldError([]);
                })
                .catch((error) => {
                    setErrorMessage(error?.response?.data?.error);
                });
        }
    }

    const papaparseOptions = {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
        transformHeader: (header) => header.replace(/\W/g, "_"),
    };

    const handleDownload = () => {
        const fileUrl = "/ContactImportTemplate.csv";
        const link = document.createElement("a");
        link.href = fileUrl;
        link.setAttribute("download", "ContactImportTemplate.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    const hiddenFields = ["_id", "__v", "createdAt", "updatedAt"];

    async function GetDynamicVarList() {
        await axios
            .get(Path.GetDynamicVarList, {
                headers: {
                    Authorization: "Bearer " + session?.data?.token,
                },
            })
            .then(async (success) => {
                setDynamicVarList(success?.data);
            })
            .catch((error) => {
                console.log(error?.response?.data?.error);
            });
    }

    useEffect(() => {
        session?.data?.token && GetContactList();
        GetDynamicVarList();
        // getDraftList();
    }, [session?.data?.token, reload]);

    useEffect(() => {
        setContactData([]);
        setBasicFieldError([]);
    }, []);

    return (
        <>
            {isLoader ? <FullScreenLoader /> : " "}
            <StepModal
                openModal={stepModal}
                event={setStepModal}
                id={id}
                session={session}
                selectedId={selectedId}
                setSelectedCheckboxes={setSelectedCheckboxes}
            />
            <AddNewContact
                openModal={openModal}
                event={setOpenModal}
                id={id}
                session={session}
                setShowUpload={setShowUpload}
                setShowUploadCsv={setShowUploadCsv}
                GetContactList={GetContactList}
                listId={listId}
            />
            <SelectListModal
                openModal={openModal}
                event={setOpenModal}
                setListId={setListId}
                selectListModal={selectListModal}
                setSelectListModal={setSelectListModal}
                session={session}
            />

            <CSVSelectListModal
                setShowUploadCsv={setShowUploadCsv}
                showUploadCsv={showUploadCsv}
                setListId={setListId}
                selectCSVListModal={selectCSVListModal}
                setSelectCSVListModal={setSelectCSVListModal}
                session={session}
            />

            {/* {openList === true && activeTab === 1 ? */}
            {/* <ul className="mt-3 flex items-center gap-10 bg-white shadow-[0px_2px_6px_rgba(0,0,0,0.06)] pl-3">
				<li className=" hover:text-purple">
					<button
						onClick={() => {
							setShowUpload(false)
							setShowUploadCsv(false)
							setContactData([]);
							setBasicFieldError([]);
							setActiveTab(0)
						}}
						type="button"
						className={`${activeTab === 0 ? "active" : ""
							} flex gap-3 items-center tab w-max`}
					>
						<span className="">
							<ContatcList
								active={activeTab === 0}
								color={activeTab === 0 ? "#7F5FEE" : "#888888"}
							/>
						</span>
						Contact List
					</button>
				</li>
				<li className="flex-auto hover:text-purple">
					<button
						type="button"
						onClick={() => {
							setActiveTab(1)
						}}
						className={`${activeTab === 1 ? "active" : ""
							} flex gap-3 items-center tab w-max`}
					>
						<span className="">
							<Personas
								active={activeTab === 0}
								color={activeTab === 0 ? "#7F5FEE" : "#888888"}
							/>
						</span>
						Personas
					</button>
				</li>
				{!showUpload &&
					<li className="pr-2">
						<div onClick={() => setShowUpload(true)} className='outline-none rounded-md bg-purple text-white appearance-none text-sm cursor-pointer lg:text-base py-1 px-2'>
							Add Contact
						</div>
					</li>
				}


			</ul> */}
            <div className="flex items-center border-b border-[#E9E9E9] mt-5">
                <ul className="flex items-center">
                    <li className=" hover:text-purple">
                        <button
                            onClick={() => setActiveTab(0)}
                            type="button"
                            className={`${activeTab === 0 ? "active" : ""
                                } flex gap-3 items-center tab !min-w-[130px] !border-0`}>
                            <span>
                                <Personas color={activeTab === 0 ? "#7F5FEE" : "#888888"} />
                            </span>
                            Contacts
                        </button>
                    </li>
                </ul>
            </div>
            {showUpload ? (
                showUploadCsv ? (
                    <UploadCSV
                        listId={listId}
                        openImportModal={openImportModal}
                        setOpenImportModal={setOpenImportModal}
                        contactData={contactData}
                        setShowUploadCsv={setShowUploadCsv}
                        handleDownload={handleDownload}
                        handleForce={handleForce}
                        papaparseOptions={papaparseOptions}
                        UploadHandleClick={UploadHandleClick}
                        basicFieldError={basicFieldError}
                        errorMessage={errorMessage}
                        fileKey={fileKey}
                        fileName={fileName}
                        session={session}
                        dynamicVarList={dynamicVarList}
                        setDynamicVarList={setDynamicVarList}
                        setMapOptions={setMapOptions}
                    />
                ) : (
                    <UploadOptions
                        setShowUploadCsv={setShowUploadCsv}
                        setOpenModal={setOpenModal}
                        openModal={openModal}
                        openSelectListPopUp={openSelectListPopUp}
                        selectListModal={selectListModal}
                        setSelectListModal={setSelectListModal}
                        setSelectCSVListModal={setSelectCSVListModal}
                        selectCSVListModal={selectCSVListModal}
                    />
                )
            ) : activeTab === 0 ? (
                <ContactListTable
                    showUpload={showUpload} setSelectedId={setSelectedId} setStepModal={setStepModal} stepModal={stepModal} contactList={contactList} hiddenFields={hiddenFields} setShowUpload={setShowUpload} toggle={toggle} setToggle={setToggle} navigate={navigate} selectedCheckboxes={selectedCheckboxes} setSelectedCheckboxes={setSelectedCheckboxes} id={id} session={session} showSetting={showSetting} setShowSetting={setShowSetting} setFilter={setFilter} setCurrentPage={setCurrentPage} setReload={setReload} GetContactList={GetContactList} pageCount={pageCount} handlePageClick={handlePageClick} currentPage={currentPage} sortedContactListing={sortedContactListing} requestSort={requestSort} sortConfig={sortConfig}
                />
            ) : (
                <DraftListTable
                    session={session}
                    drafts={drafts}
                    selectedDraftCheckboxes={selectedDraftCheckboxes}
                    setSelectedDraftCheckboxes={setSelectedDraftCheckboxes}
                    setReload={setReload}
                />
            )}
        </>
    );
}

const UploadOptions = ({
    setShowUploadCsv,
    selectListModal,
    setSelectListModal,
    selectCSVListModal,
    setSelectCSVListModal,
    openSelectListPopUp,
}) => {
    return (
        <div className="flex flex-col justify-center items-center gap-3  ">
            <div className="bg-[#DAD6FF] max-w-[1280px]  flex gap-8 justify-center items-center py-20 px-16 w-full flex-col md:flex-row h-max">
                <div className="bg-white rounded-[23px] border border-stone-300 flex flex-col gap-5 justify-center items-center min-w-[300px] w-4/12 min-h-full self-auto md:self-stretch p-8">
                    <span className="w-[72px] h-[72px] flex justify-center items-center bg-green rounded-full">
                        <img width={40} height={40} src={listViewIcon} alt="" />
                    </span>
                    <p className="w-[80%] mx-auto text-center text-black-1 text-sm">
                        Add a single new contact into the current list
                    </p>
                    <button
                        // disabled
                        onClick={() => setSelectListModal(!selectListModal)}
                        type="button"
                        className="flex gap-3 justify-center items-center bg-purple w-[173px] h-[34px] rounded-[9px] font-bold text-sm text-white">
                        Add New Contact
                    </button>
                </div>
                <div className="bg-white rounded-[23px] border border-stone-300 flex flex-col gap-5 justify-between items-center min-w-[300px] w-4/12 min-h-full self-auto md:self-stretch p-8">
                    <span className="w-[72px] h-[72px] flex justify-center items-center bg-green rounded-full">
                        <img width={40} height={40} src={microsoftIcon} alt="" />
                    </span>
                    <p className="w-[80%] mx-auto text-center text-black-1 text-sm">
                        Upload a CSV file of contacts.
                        {/* <span className="italic"> comming soon</span> */}
                    </p>
                    <button
                        // disabled
                        onClick={() => { setSelectCSVListModal(!selectCSVListModal) }}
                        type="button"
                        className="flex gap-3 justify-center items-center bg-purple w-[173px] h-[34px] rounded-[9px] font-bold text-sm text-white">
                        Upload CSV
                        <Icon className="text-2xl" icon="ph:upload-simple" />
                    </button>
                </div>

                <div className="bg-white rounded-[23px] border border-stone-300 flex flex-col gap-5 justify-center items-center min-w-[300px] w-4/12 min-h-full self-auto md:self-stretch p-8">
                    <span className="w-[72px] h-[72px] flex justify-center items-center bg-green rounded-full">
                        <img width={40} height={40} src={listViewIcon} alt="" />
                    </span>
                    <p className="w-[80%] mx-auto text-center text-black-1 text-sm">
                        Upload Contacts from one of your current lists
                    </p>
                    <button
                        onClick={openSelectListPopUp}
                        type="button"
                        className="flex gap-3 justify-center items-center bg-purple w-[173px] h-[34px] rounded-[9px] font-bold text-sm text-white">
                        Add New
                    </button>
                </div>
            </div>
        </div>
    );
};

const UploadCSV = ({
    setShowUploadCsv,
    handleDownload,
    handleForce,
    papaparseOptions,
    UploadHandleClick,
    basicFieldError,
    contactData,
    errorMessage,
    setOpenImportModal,
    openImportModal,
    fileKey,
    fileName,
    session,
    dynamicVarList,
    setDynamicVarList,
    setMapOptions,
    listId
}) => {
    return (
        <div className="flex flex-col justify-center items-center gap-5 min-h-[65dvh] mt-5 py-10 bg-white">
            <div className="flex flex-col gap-6 w-full md:max-w-3xl p-12 md:p-0 items-start">
                <img width={53} height={38} src={importContactIcon} alt="" />
                <div className="flex flex-col w-full">
                    <div className="justify-between flex gap-2 w-full">
                        <h6 className="text-2xl font-bold">Upload Contact List</h6>
                        <a
                            href="https://docs.google.com/spreadsheets/d/1B5BHwqME0sRP05xE7iP-pgVFI6vrUkcvmOPhlPbZKFU/edit#gid=1622148469"
                            target="_blank"
                            rel="noreferrer"
                            className="flex justify-center items-center gap-2 rounded-full  hover:bg-grey-8 hover:border-transparent border border-purple bg-white text-zinc-500 p-2 px-5 duration-300">
                            <DownloadIcon />
                            <span className="text-base">{`View Template`}</span>
                        </a>
                    </div>
                    <span className="text-base font-normal text-neutral-500">
                        You can import up to 100,000 rows at a
                        time. Supported file format : CSV
                    </span>
                </div>
                <p className="text-neutral-500 text-base font-normal mt-3">
                    For accurate mapping, please include at least one of these fields:
                    Company Name, Company Website, LinkedIn URL, and/or Contact Email. For
                    more information, please visit our help center
                </p>
                <div className="w-full flex gap-4 justify-between items-center flex-col md:flex-row">
                    <CSVReader
                        cssClass="flex gap-4 border-[2px] border-green justify-center items-center py-3 px-8 rounded-md w-8/12 md:w-7/12"
                        // label="Upload CSV File"
                        key={fileKey}
                        onFileLoaded={handleForce}
                        parserOptions={papaparseOptions}

                    />
                    <div className="flex gap-2">
                        {/* <button
							onClick={UploadHandleClick}
							type="button"
							className="relative btn !bg-green px-10"
						>
							Upload Users List
						</button> */}
                        <button
                            onClick={() => setShowUploadCsv(false)}
                            type="button"
                            className="relative btn text-base font-bold px-4">
                            Cancel
                        </button>
                    </div>
                </div>
                {basicFieldError.length !== 0 && (
                    <div className="flex flex-col overflow-y-auto max-h-[300px] w-full">
                        <h3 className="my-4">File Upload Errors </h3>
                        {basicFieldError.map((e, i) => {
                            return <p key={i} className="text-red-color py-1">{e}</p>;
                        })}
                    </div>
                )}
                {contactData.length !== 0 && (
                    // <ContactTable contactData={contactData} errorMessage={errorMessage} />
                    <NewSelectListModal
                        errorMessage={errorMessage}
                        contactData={contactData}
                        setOpenImportModal={setOpenImportModal}
                        openImportModal={openImportModal}
                        fileName={fileName}
                        session={session}
                        dynamicVarList={dynamicVarList}
                        setDynamicVarList={setDynamicVarList}
                        UploadHandleClick={UploadHandleClick}
                        setMapOptions={setMapOptions}
                    />
                )}
            </div>
        </div>
    );
};
const ContactListTable = ({ contactList, hiddenFields = [], setShowUpload, showUpload, toggle, setToggle, navigate, setStepModal, stepModal, setSelectedId, setSelectedCheckboxes, selectedCheckboxes, id, session, showSetting, setShowSetting, setFilter, setCurrentPage, setReload, GetContactList, pageCount, handlePageClick, currentPage, sortedContactListing, requestSort,
    sortConfig }) => {

    const dropdownRef = useRef(null);
    const [isLoader, setIsLoader] = useState(false);

    const handleCheckboxChange = (index) => {
        if (index === -1) {
            // If the checkbox is the select-all checkbox
            // Check if all checkboxes are already selected
            const allSelected = selectedCheckboxes.length === contactList.length;

            // If all checkboxes are already selected, deselect them all
            if (allSelected) {
                setSelectedCheckboxes([]);
            } else {
                // If not all checkboxes are selected, select them all
                setSelectedCheckboxes(contactList.map((_, i) => i));
            }
        } else {
            // If a checkbox other than the select-all checkbox is clicked
            if (selectedCheckboxes.includes(index)) {
                setSelectedCheckboxes(
                    selectedCheckboxes.filter((item) => item !== index)
                );
            } else {
                setSelectedCheckboxes([...selectedCheckboxes, index]);
            }
        }
    };

    const handleGenerateButtonClick = () => {
        // Logic to handle generate button click
        const selectedIds = selectedCheckboxes.map((index) => contactList[index]?._id).filter(Boolean);
        setSelectedId(selectedIds);
        // console.log(selectedIds)
        setStepModal(!stepModal);
    };

    const handleSendButtonClick = async () => {
        // Logic to handle generate button click
        const selectedIds = selectedCheckboxes
            .map((index) => contactList[index]?._id)
            .filter(Boolean);
        setIsLoader(true);
        await axios
            .post(
                Path.SendMailVersion,
                {
                    campaignId: id,
                    contactId: selectedIds,
                },
                {
                    headers: {
                        Authorization: "Bearer " + session?.data?.token,
                    },
                }
            )
            .then((success) => {
                setIsLoader(false);
                showSuccessMessage(success?.data?.message);
                setSelectedCheckboxes([]);
            })
            .catch((error) => {
                setIsLoader(false);
                console.log(error);
                showErrorMessage(
                    error?.response?.data?.error || "Something Went Wrong."
                );
            });
    };

    const dummyList = [
        {
            count: 10,
            name: "Total",
        },
        {
            count: 10,
            name: "Replied",
        },
        {
            count: 3,
            name: "Open",
        },
        {
            count: 0,
            name: "Converted",
        },
        {
            count: 0,
            name: "Interested",
        },
        {
            count: 0,
            name: "Not Interested",
        },
        {
            count: 10,
            name: "Approaching",
        },
        {
            count: 10,
            name: "All In progress",
        },
    ];

    let headers = [];
    if (contactList.length) {
        headers = Object.keys(contactList[0]);
    }
    const dropdownOptions = [
        { label: 'Delete', value: 'delete' }
    ];

    const handleDropdownSelect = (option, data) => {
        if (option.value === 'delete') {
            Swal.fire({
                title: `Are you sure you want to delete the contact?`,
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#7F5FEE",
                cancelButtonColor: "#F86E6E",
                confirmButtonText: "Yes"
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.delete(`${Path.DeleteContact}${data?._id}`, {
                        headers: {
                            Authorization: "Bearer " + session?.data?.token,
                        }
                    })
                        .then(() => {
                            window.location.reload();
                        });
                }
            });
        }
    };
    const handleEmailButtonClick = (data) => {
        if (data?.email) {
            const mailtoLink = `mailto:${data.email}`;
            window.location.href = mailtoLink;
        }
    };

    useEffect(() => {
        //   Function to handle outside click
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setToggle(null);
            }
        }
        //   Bind the event listener
        document.addEventListener("click", handleClickOutside);
        //   Cleanup the event listener on unmount
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);


    return (
        <>
            <div className="flex gap-4 items-end flex-wrap px-4 pt-6 justify-between">
                <h2 className="large-title">{`Contact List`}</h2>
                <div className="flex gap-4">
                    {/* <button
							type="button"
							className="outline-none flex justify-center gap-2 items-center rounded-md bg-purple text-white appearance-none text-sm cursor-pointer lg:text-base py-1.5 px-8"
							onClick={handleSendButtonClick}
						>
							<span>Send</span>
							{
								isLoader && <Icon icon={'svg-spinners:tadpole'} />
							}
						</button> */}

                    {/* {selectedCheckboxes.length > 0 && ( */}
                    <button
                        // disabled={!selectedCheckboxes.length > 0}
                        type="button"
                        className={`outline-none rounded-md bg-purple text-white appearance-none text-sm ${!selectedCheckboxes.length > 0 ? "cursor-default" : "cursor-pointer"} lg:text-base py-1.5 px-5`}
                        onClick={() => { !selectedCheckboxes.length > 0 ? showErrorMessage("Select at least one contact") : handleGenerateButtonClick(); }}>
                        Compose
                    </button>
                    {/* )} */}

                    {!showUpload && (
                        <button className="pr-2">
                            <div
                                onClick={() => setShowUpload(true)}
                                className="outline-none rounded-md bg-purple text-white appearance-none text-sm cursor-pointer lg:text-base py-1.5 px-3">
                                Add Contact
                            </div>
                        </button>
                    )}
                </div>
            </div>
            <div className="flex bg-white mt-3 flex-col overflow-y-auto min-h-[500px] w-full">
                {/* <button onClick={() => setShowUpload(true)}>Upload</button> */}

                {/* {contactList.length > 0 ? (
					<table className="text-left mt-2 text-xs md:text-sm lg:!text-[15px] ">
						<thead className="[&_th]:!border-t-0 [&_th]:!font-medium [&_th]:!p-3 [&_th]:text-sm [&_th]:!text-[#7B7B7B] [&_th]:lg:!text-base">
							<tr>
								<th key={0}>
									#
								</th>
								{headers.map((header) => {
									if (hiddenFields.includes(header))
										return "";
									return (
										<th className="" key={header}>
											{header}
										</th>
									);
								})}
							</tr>
						</thead>
						<tbody className="[&_td]:py-4 [&_td]:border-b [&_td]:border-white [&_td]:font-medium [&_td]:px-2 md:[&_td]:px-3">
							{contactList.map((data, index) => {
								return (
									<tr key={index} className="bg-grey-4">
										<td className="p-2 px-4 border" key={0}>
											{index + 1}
										</td>
										{headers.map((header) => {
											// Skip rendering the cell if the header is 'addedBy'
											if (hiddenFields.includes(header))
												return null;
											return (
												<td
													className="p-2 px-4 border"
													key={header}
												>
													{String(data[header])}
												</td>
											);
										})}
									</tr>
								);
							})}
						</tbody>
					</table>
				) : (
					<div className="text-purple bg-grey-4 text-center h-52 flex mt-2 justify-center items-center bg">
						<div>Data not found</div>
					</div>
				)} */}
                <div className="bg-white my-3">
                    <div className="flex items-center gap-3 border-b border-[#ECECEC] p-3">
                        {/* Checkbox Button */}
                        <button
                            type="button"
                            className="flex justify-between items-center pl-2 text-base font-medium border rounded-md border-gray-7 -ml-2">
                            <input
                                type="checkbox"
                                className="h-4 w-4 accent-purple rounded-none bg-light-gray-3 align-middle"
                                onClick={() => handleCheckboxChange(-1)}
                                checked={
                                    contactList.length > 0 &&
                                    selectedCheckboxes.length === contactList.length
                                }
                                readOnly // Make the checkbox read-only to avoid React warnings
                            />
                            <span className="text-4xl text-gray-6">
                                <Icon icon="ri:arrow-drop-down-line" />
                            </span>
                        </button>
                        <div className='flex gap-2'>
                            <button
                                onClick={() => {
                                    if (showSetting) {
                                        setShowSetting(!showSetting)
                                        setFilter({ name: '' })
                                        setCurrentPage(1)
                                        GetContactList(1)
                                        setReload((e) => !e)
                                    } else setShowSetting(true)
                                }}
                                type="button" className="min-w-[127px] text-sm font-medium rounded-lg flex items-center gap-2 border text-gray-6 border-gray-8 hover:text-dark hover:border-dark  h-[33px] px-2"
                            >
                                <ArchiveIcon color={showSetting ? "#2D2D2D" : "#AEAEAE"} />
                                <span style={{ color: showSetting ? "#2D2D2D" : "#AEAEAE" }}>
                                    {!showSetting ? `Show Filters` : `Hide Filters`}
                                </span>
                            </button>
                            {
                                showSetting &&
                                <div className="flex items-center gap-2">
                                    <input
                                        onChange={(event) => setFilter({ name: event.target.value })}
                                        className={'w-60 h-[30px] text-dark text-xs border border-dark rounded-lg px-2'} type={'name'} placeholder={'Enter User Name'}
                                    />

                                    <button
                                        onClick={() => {
                                            setCurrentPage(1)
                                            GetContactList(1)
                                        }}
                                        type={'button'} className={'w-24 h-[30px] text-dark text-sm font-medium rounded-lg border border-dark hover:bg-dark hover:text-white'}
                                    >
                                        {`Apply`}
                                    </button>
                                </div>
                            }
                        </div>
                        {/* Show Filter Button */}
                        {/* <div className="relative">
							<button type="button"
								className="text-base font-medium py-1 px-4 rounded-lg  flex items-center gap-2 border text-gray-7 border-gray-7/50 hover:text-purple hover:border-current focus:border-purple focus:text-purple">
								<FilterIcons />
								Show Filter
							</button>
						</div> */}
                        {/* <div className="flex justify-between space-x-2">
							{dummyList && dummyList.map((data, index) => {
								return (
									<div key={index} className="text-center text-sm">
										<div className="font-bold">{data.count}</div>
										<div className="text-gray-6">{data.name}</div>
									</div>
								)
							})}
						</div> */}
                        {/* Latest Edited Button */}
                        {/* <button
							type="button"
							className="text-sm lg:text-base text-gray-6 font-medium ml-auto h-[33px] p-1 	
							rounded-lg flex justify-center items-center gap-2 border border-gray-7
							hover:text-purple hover:border-current focus:border-purple focus:text-purple"
						>
							<img
								className="w-[25px] h-[25px] hidden md:block"
								width={18}
								height={18}
								src={sortIcon}
								alt=""
							/>
							{`Contact Last Activity`}
						</button> */}
                    </div>
                    {/* Campaign List */}
                    <div className="overflow-auto pb-8">
                        {sortedContactListing.length > 0 ? (
                            <div className="flex bg-white flex-col overflow-y-auto min-h-[500px] w-full">
                                <table className="w-full table-auto text-left text-sm">
                                    <thead className="[&_th]:!border-t-0 [&_th]:!font-medium [&_th]:!px-2 [&_th]:!py-3 [&_th]:text-[15px] [&_th]:!text-[#7B7B7B] ">
                                        <tr>
                                            <th className='w-8 text-center'></th>
                                            <th onClick={() => requestSort('firstName')} className='w-[250px] cursor-pointer'>
                                                User Name  {sortConfig.key === 'firstName' && (sortConfig.direction === 'asc' ? <span className='text-[12px]'>▲</span> : <span className='text-[12px]'>▼</span>)}
                                            </th>
                                            <th className='space-x-2 w-[200px] ml-auto'>Step Name</th>
                                            <th onClick={() => requestSort('domain')} className='space-x-2 w-[200px] ml-auto cursor-pointer'>
                                                Domain {sortConfig.key === 'domain' && (sortConfig.direction === 'asc' ? <span className='text-[12px]'>▲</span> : <span className='text-[12px]'>▼</span>)}
                                            </th>
                                            <th className='space-x-2 w-[250px] ml-auto'>Company Name</th>
                                            <th className='space-x-2 w-[200px] ml-auto'>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody className="[&_td]:px-2 [&_td]:py-4 [&_td]:bg-grey-4">
                                        {sortedContactListing &&
                                            sortedContactListing.map((data, index) => {
                                                return (
                                                    <tr key={index} className="border-t border-[#fff]">
                                                        <td className="w-8 text-center">
                                                            <input
                                                                type="checkbox"
                                                                className="h-4 w-4 accent-purple align-middle"
                                                                checked={selectedCheckboxes.includes(index)}
                                                                onChange={() => handleCheckboxChange(index)}
                                                            />
                                                        </td>
                                                        {/* <td className="w-8">
                                                        <span className="size-10 flex justify-center items-center rounded-full bg-gray-200">
                                                            <span className="text-purple uppercase font-semibold text-lg">
                                                                {`${data?.firstName[0]}${data?.lastName[0]}`}
                                                            </span>
                                                        </span>
                                                    </td> */}
                                                        <td
                                                            className="cursor-pointer  w-[250px]"
                                                            onClick={() =>
                                                                navigate(
                                                                    `/campaigns/contact-details?id=${id}&contactId=${data._id}`
                                                                )
                                                            }>
                                                            <div className="text-purple flex gap-3 items-center">
                                                                <span className="size-10 flex justify-center items-center rounded-full bg-gray-200">
                                                                    <span className="text-purple uppercase font-semibold text-lg">
                                                                        {`${data?.firstName[0]}${data?.lastName[0]}`}
                                                                    </span>
                                                                </span>
                                                                <span>{`${data?.firstName} ${data?.lastName}`}</span>
                                                            </div>
                                                        </td>

                                                        <td className="space-x-2 w-[200px] ml-auto">
                                                            {data?.stepName ? (
                                                                <span className="bg-[#AEAEAE] text-white text-xs p-1 rounded-md px-2 inline-block text-center">
                                                                    {data?.stepName}
                                                                </span>
                                                            ) : (
                                                                "-"
                                                            )}
                                                        </td>

                                                        <td className="space-x-2 w-[200px] ml-auto">
                                                            {data?.domain ? (
                                                                <span className="bg-[#F9FAFC] text-gray-6 text-xs p-1 rounded-md px-2 inline-block text-center">
                                                                    {data?.domain}
                                                                </span>
                                                            ) : (
                                                                "-"
                                                            )}
                                                        </td>
                                                        <td className="text-gray-6 space-x-2">
                                                            <span>{data?.jobTitle ? data?.jobTitle : "-"}</span>
                                                            <span className="text-purple">
                                                                {data?.companyName ? data?.companyName : "-"}
                                                            </span>
                                                        </td>
                                                        <td className="z-10 w-[200px]">
                                                            <div className="w-fit border border-gray-6 flex bg-white rounded-[5px] ">
                                                                {
                                                                    // data?.email &&
                                                                    <button
                                                                        onClick={() => handleEmailButtonClick(data)}
                                                                        title={data?.email}
                                                                        className={`text-center p-1 px-2 border-r border-gray-6 ${data?.email
                                                                            ? "cursor-pointer"
                                                                            : "cursor-default"
                                                                            }`}>
                                                                        <EmailIcon
                                                                            color={data?.email ? "#7B7B7B" : "#d3d3d3"}
                                                                        />
                                                                    </button>
                                                                }
                                                                {
                                                                    // data?.phone &&
                                                                    <button
                                                                        title={data?.phone}
                                                                        className={`text-center p-1 px-2 border-r border-gray-6 ${data?.phone
                                                                            ? "cursor-pointer"
                                                                            : "cursor-default"
                                                                            }`}>
                                                                        <CallIcon
                                                                            color={data?.phone ? "#7B7B7B" : "#d3d3d3"}
                                                                        />
                                                                    </button>
                                                                }
                                                                {(data?.linkedinURL || data?.linkedinUrl) && (
                                                                    <button
                                                                        onClick={() => {
                                                                            try {
                                                                                const url = data?.linkedinURL || data?.linkedinUrl; // Define url here
                                                                                if (!url) {
                                                                                    return;
                                                                                }
                                                                                const normalizedUrl = new URL(url.startsWith('http') ? url : `https://${url}`);
                                                                                window.open(normalizedUrl.href, '_blank');
                                                                            } catch (e) {
                                                                                console.error('Invalid URL:', data?.linkedinURL || data?.linkedinUrl);
                                                                            }
                                                                        }}
                                                                        title={data?.linkedinURL || data?.linkedinUrl}
                                                                        className={`text-center p-1 px-2 border-r border-gray-6 ${data?.linkedinURL || data?.linkedinUrl ? "cursor-pointer" : "cursor-default"
                                                                            }`}>
                                                                        <LinkedInIcon
                                                                            color={data?.linkedinURL || data?.linkedinUrl ? "#7B7B7B" : "#d3d3d3"}
                                                                        />
                                                                    </button>
                                                                )}



                                                                {/* <button className="flex items-center space-x-1 text-center text-gray-4 p-1 px-2 border-r border-gray-6">
                                                                    <UserContactIcon />
                                                                    <span>+1</span>
                                                                </button> */}
                                                                <div className="relative">
                                                                    <Dropdown
                                                                        options={dropdownOptions}
                                                                        onSelect={(option) => handleDropdownSelect(option, data)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                    </tbody>
                                </table>
                                <div className='flex justify-end'>
                                    <ReactPaginate
                                        previousLabel={"<"}
                                        nextLabel={">"}
                                        breakLabel={"..."}
                                        breakClassName={"break-me"}
                                        pageCount={pageCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={4}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination"}
                                        subContainerClassName={"pages pagination"}
                                        activeClassName={"active"}
                                        forcePage={currentPage - 1}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="text-purple bg-grey-4 text-center h-52 flex mt-2 justify-center items-center">
                                <div>Data not found</div>
                            </div>
                        )}
                    </div>
                </div >
            </div >
        </>
    );
};

const StepModal = ({
    openModal,
    event,
    id,
    session,
    selectedId,
    setSelectedCheckboxes,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [overviewStep, setOverviewStep] = useState();
    const [selectedStepId, setSelectedStepId] = useState(null);
    const [selectedTemplateId, setSelectedTemplateId] = useState(null);
    const [selectedCheckboxIndex, setSelectedCheckboxIndex] = useState(null);
    const [isLoader, setIsLoader] = useState(false);
    const [isDraftLoader, setIsDraftLoader] = useState(false);

    const toggleDropdown = (stepId, index) => {
        setIsOpen((prevIndex) => (prevIndex === index ? null : index));
        handleStepSelection(stepId);
    };

    async function GetOverviewSetp() {
        await axios
            .get(`${Path.campaignOverviewById}${id}`, {
                headers: {
                    Authorization: "Bearer " + session?.data?.token,
                },
            })
            .then((success) => {
                setOverviewStep(success?.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handleStepSelection = (stepId) => {
        setSelectedStepId(stepId);
        setSelectedTemplateId(null); // Reset the selected template ID
    };

    const handleTemplateSelection = (templateId) => {
        setSelectedTemplateId(templateId);
    };

    useEffect(() => {
        GetOverviewSetp();
    }, [openModal, session?.data?.token]);

    const submitHandler = async () => {
        setIsLoader(true);
        await axios
            .post(
                Path.GenerateManyContent,
                {
                    templateId: selectedTemplateId,
                    personaIds: selectedId,
                    campaignId: id,
                    stepId: selectedStepId,
                },
                {
                    headers: {
                        Authorization: "Bearer " + session?.data?.token,
                    },
                }
            )
            .then((success) => {
                setIsLoader(false);
                showSuccessMessage(success?.data?.message);
                event(!openModal);
                setSelectedCheckboxes([]);
            })
            .catch((error) => {
                setIsLoader(false);
                console.log(error);
                showErrorMessage(
                    error?.response?.data?.error || "Something Went Wrong."
                );
            });
    };

    const DraftHandler = async () => {
        setIsDraftLoader(true);
        await axios
            .post(
                Path.generateSave,
                {
                    templateId: selectedTemplateId,
                    personaIds: selectedId,
                    campaignId: id,
                    stepId: selectedStepId,
                },
                {
                    headers: {
                        Authorization: "Bearer " + session?.data?.token,
                    },
                }
            )
            .then((success) => {
                setIsDraftLoader(false);
                showSuccessMessage(success?.data?.message);
                event(!openModal);
                setSelectedCheckboxes([]);
            })
            .catch((error) => {
                setIsDraftLoader(false);
                console.log(error);
                showErrorMessage(
                    error?.response?.data?.error || "Something Went Wrong."
                );
            });
    };

    const submitHandlerExperiment = async () => {
        setIsLoader(true);
        await axios
            .post(
                Path.ComposeWithExperiment,
                {
                    experiment: true,
                    personaIds: selectedId,
                    campaignId: id,
                    stepId: selectedStepId,
                },
                {
                    headers: {
                        Authorization: "Bearer " + session?.data?.token,
                    },
                }
            )
            .then((success) => {
                setIsLoader(false);
                showSuccessMessage(success?.data?.message);
                event(!openModal);
                setSelectedCheckboxes([]);
            })
            .catch((error) => {
                setIsLoader(false);
                console.log(error);
                showErrorMessage(
                    error?.response?.data?.error || "Something Went Wrong."
                );
            });
    };

    const DraftHandlerExperiment = async () => {
        setIsDraftLoader(true);
        await axios
            .post(
                Path.DraftWithExperiment,
                {
                    experiment: true,
                    personaIds: selectedId,
                    campaignId: id,
                    stepId: selectedStepId,
                },
                {
                    headers: {
                        Authorization: "Bearer " + session?.data?.token,
                    },
                }
            )
            .then((success) => {
                setIsDraftLoader(false);
                showSuccessMessage(success?.data?.message);
                event(!openModal);
                setSelectedCheckboxes([]);
            })
            .catch((error) => {
                setIsDraftLoader(false);
                console.log(error);
                showErrorMessage(
                    error?.response?.data?.error || "Something Went Wrong."
                );
            });
    };

    //experiment checkbox
    const handleCheckboxChange = (index) => {
        setSelectedCheckboxIndex((prevIndex) => prevIndex === index ? null : index);
    };

    return (
        <div
            className={`${openModal ? "block" : "hidden"
                } bg-black/40 absolute z-20 inset-0 flex items-center overflow-y-hidden`}>
            <div className="bg-white border border-gary-3 mx-auto max-w-[764px] w-full p-6">
                <h4 className="font-bold text-center text-dark text-xl  h-10 md:h-16">
                    Select Template
                </h4>

                <div className="flex flex-col gap-3 dropdown__content max-h-[60dvh] overflow-y-auto">
                    <div className="overflow-auto space-y-2">
                        {overviewStep &&
                            overviewStep.map((item, index) => {
                                return (
                                    <div key={index}>
                                        <button
                                            type="button"
                                            onClick={(e) => {
                                                toggleDropdown(item?.step?._id, index)
                                            }}
                                            className={`p-5 w-full flex items-center justify-between h-16 bg-violet-100 rounded-lg border border-neutral-300 hover:border-purple group transition-all duration-300 ${isOpen === index
                                                ? "border-purple text-purple"
                                                : "text-zinc-800"
                                                }`}>
                                            <span
                                                className={`text-sm font-medium group-hover:text-purple transition-all duration-300 w-100 contents`}>
                                                <input

                                                    type="checkbox"
                                                    title="Send/Draft as experiment"
                                                    className="h-4 w-4 mr-2 accent-purple align-middle"
                                                    checked={selectedCheckboxIndex === index} // Check if this checkbox should be checked
                                                    onChange={() => handleCheckboxChange(index)} // Handle checkbox change
                                                    disabled={!item?.templates.some((template) => template.percentage)}
                                                // disabled={item?.templates?.length === 0}
                                                />
                                                {item?.step?.name}
                                                <span className="ml-auto mr-2">
                                                    {selectedCheckboxIndex !== null &&
                                                        selectedCheckboxIndex === index
                                                        ? " (Save/Draft as experiment)"
                                                        : ""}
                                                </span>
                                            </span>
                                            <span
                                                className={` ${isOpen === index ? "" : "rotate-180"}`}>
                                                <svg
                                                    width="16"
                                                    height="9"
                                                    viewBox="0 0 16 9"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        id="Stroke 1"
                                                        d="M1 7.77637L8 0.776367L15 7.77637"
                                                        stroke="#2D2D2D"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                </svg>
                                            </span>
                                        </button>
                                        <div
                                            className={` overflow-hidden transition-[padding-top] duration-150 dropdown__content ${isOpen === index ? "pt-3" : ""
                                                }`}
                                            style={{ maxHeight: isOpen === index ? "500px" : "0px" }}>
                                            {item?.templates.length ? (
                                                item?.templates.map((data, index) => {
                                                    const parser = new DOMParser();
                                                    const htmlDoc = parser.parseFromString(
                                                        data?.htmlBody,
                                                        "text/html"
                                                    );
                                                    const textContent = htmlDoc.body.textContent.trim();
                                                    const textContentWithoutWhitespace =
                                                        textContent.replace(/\s{2,}/g, " ");
                                                    const truncatedContent =
                                                        textContentWithoutWhitespace.length > 50
                                                            ? textContentWithoutWhitespace.slice(0, 50) +
                                                            "..."
                                                            : textContentWithoutWhitespace;

                                                    return (
                                                        <div
                                                            key={data?._id}
                                                            onClick={() => selectedCheckboxIndex === null && handleTemplateSelection(data._id)}
                                                            className={`flex text-sm items-center justify-between px-2 gap-2 cursor-pointer 
                                                                ${data._id === selectedTemplateId && !selectedCheckboxIndex ?
                                                                    "bg-gray-200 border-purple text-purple rounded-lg p-2"
                                                                    : "border-neutral-300"} h-16 overflow-y-auto`}
                                                            disabled={selectedCheckboxIndex !== null ? true : false}
                                                        >
                                                            <div className="text-center">
                                                                <span
                                                                    className={`text-dark font-normal ${data._id === selectedTemplateId
                                                                        ? "text-purple"
                                                                        : ""
                                                                        }`}>
                                                                    {`Default Variation ${index + 1}`}
                                                                </span>
                                                            </div>
                                                            {data.percentage && <div>{`${data?.percentage}%`}</div>}
                                                            <div className="font-bold text-black-1 max-w-[300px] lg:max-w-[500px] select-none">
                                                                <div
                                                                    title={data?.emailSubject.replace(
                                                                        /(<([^>]+)>)/gi,
                                                                        ""
                                                                    )}
                                                                    dangerouslySetInnerHTML={{
                                                                        __html:
                                                                            data?.emailSubject.length > 55
                                                                                ? data?.emailSubject.slice(0, 55) +
                                                                                "..."
                                                                                : data?.emailSubject,
                                                                    }}></div>
                                                                <div className="text-gray-2 font-normal text-xs">
                                                                    {truncatedContent}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                            ) : (
                                                <div> Template not added</div>
                                            )}
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                </div>

                <div className="mt-8 flex justify-end items-center gap-2">
                    <button
                        onClick={() => {
                            event(!openModal);
                            setSelectedCheckboxes([]);
                        }}
                        type="button"
                        className="px-4 flex gap-1 items-center  text-neutral-500 ">
                        <span className="font-bold text-sm">Cancel</span>
                    </button>
                    {/* <button
            type="button"
            onClick={
              selectedCheckboxIndex == null
                ? DraftHandler
                : DraftHandlerExperiment
            }
            className={`flex  gap-2 items-center bg-green text-white rounded-full py-1 h-10 px-10 text-sm font-[600] `}>
            <span>{selectedCheckboxIndex == null ? `Draft` : `Draft`}</span>
            {isDraftLoader && <Icon icon={"svg-spinners:tadpole"} />}
          </button> */}
                    <button
                        type="submit"
                        onClick={
                            selectedCheckboxIndex == null
                                ? submitHandler
                                : submitHandlerExperiment
                        }
                        className={`flex  gap-2 items-center bg-green text-white rounded-full py-1 h-10 px-10 text-sm font-[600] `}>
                        <span>{selectedCheckboxIndex == null ? `Send` : `Send`}</span>
                        {isLoader && <Icon icon={"svg-spinners:tadpole"} />}
                    </button>
                </div>
            </div>
        </div>
    );
};

export function SelectListModal({
    setSelectListModal,
    event,
    openModal,
    selectListModal,
    session,
    setListId,
}) {
    const [listData, setListData] = useState(null);
    const [isLoader, setIsLoader] = useState(false);

    const validationSchema = object().shape({
        list: string().trim().required(Validation?.campaignRequired),
    });

    const {
        register: userModalCredential,
        setValue,
        handleSubmit,
        formState: { errors },
    } = useForm({ resolver: yupResolver(validationSchema) });

    async function GetAllList() {
        await axios
            .get(`${Path.GetAllList}`, {
                headers: {
                    Authorization: "Bearer " + session?.data?.token,
                },
            })
            .then((success) => {
                setListData(success?.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    useEffect(() => {
        GetAllList();
    }, [selectListModal, session?.data?.token]);

    const onSubmit = async (data, e) => {
        e.preventDefault();
        setSelectListModal(!selectListModal);
        setListId(data.list);
        event(!openModal);
        setValue("list", null);
    };

    return (
        <div
            className={`${selectListModal ? "block" : "hidden"
                } bg-black/40 absolute z-20 inset-0 flex items-center overflow-y-hidden`}>
            <div className="bg-white border border-gary-3 mx-auto max-w-[764px] w-full p-6">
                <h4 className="font-bold text-center text-dark text-xl h-10 md:h-16">
                    Select List
                </h4>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="col-span-2">
                        <label className="custom-label">{`Select the List`}</label>
                        <select
                            {...userModalCredential("list")}
                            className={
                                errors?.list
                                    ? `custom-input !text-grey-3 bg-[url('/src/assets/images/dashboard/select-down-icon.svg')] bg-no-repeat bg-[92.5%] bg-[length:1.7rem] bg-transparent appearance-none !border !border-[#FF1D48]`
                                    : `custom-input !text-grey-3 bg-[url('/src/assets/images/dashboard/select-down-icon.svg')] bg-no-repeat bg-[97.5%] bg-[length:1.7rem] bg-transparent appearance-none`
                            }>
                            <option value="" disabled selected>
                                Select List
                            </option>
                            {listData &&
                                listData.map((item) => (
                                    <option key={item._id} value={item._id}>
                                        {item.title}
                                    </option>
                                ))}
                        </select>
                        {errors.list && (
                            <div className="error-css">{errors?.list?.message}</div>
                        )}
                    </div>
                    <div className="mt-8 flex justify-end items-center gap-6">
                        <button
                            onClick={() => {
                                setSelectListModal(!selectListModal);
                                setValue("list", null);
                            }}
                            type="button"
                            className="px-4 flex gap-1 items-center border-r border-[#7B7B7B] text-neutral-500 ">
                            <span className="text-sm font-bold">Cancel</span>
                        </button>
                        <button
                            type="submit"
                            className={`flex  gap-2 items-center bg-purple text-white rounded-full py-1 text-sm h-10 px-16 font-[600] font-base`}>
                            <span>{`Continue`}</span>
                            {isLoader && <Icon icon={"svg-spinners:tadpole"} />}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}


export function CSVSelectListModal({
    setSelectCSVListModal,
    setShowUploadCsv,
    showUploadCsv,
    selectCSVListModal,
    session,
    setListId,
}) {
    const [listData, setListData] = useState(null);
    const [isLoader, setIsLoader] = useState(false);

    const validationSchema = object().shape({
        list: string().trim().required(Validation?.campaignRequired),
    });

    const {
        register: userModalCredential,
        setValue,
        handleSubmit,
        formState: { errors },
    } = useForm({ resolver: yupResolver(validationSchema) });

    async function GetAllList() {
        await axios
            .get(`${Path.GetAllList}`, {
                headers: {
                    Authorization: "Bearer " + session?.data?.token,
                },
            })
            .then((success) => {
                setListData(success?.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    useEffect(() => {
        GetAllList();
    }, [selectCSVListModal, session?.data?.token]);

    const onSubmit = async (data, e) => {
        e.preventDefault();
        setSelectCSVListModal(!selectCSVListModal);
        setListId(data.list);
        setShowUploadCsv(!showUploadCsv);
        setValue("list", null);
    };

    return (
        <div
            className={`${selectCSVListModal ? "block" : "hidden"
                } bg-black/40 absolute z-20 inset-0 flex items-center overflow-y-hidden`}>
            <div className="bg-white border border-gary-3 mx-auto max-w-[764px] w-full p-6">
                <h4 className="font-bold text-center text-dark text-xl h-10 md:h-16">
                    Select List
                </h4>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="col-span-2">
                        <label className="custom-label">{`Select the List`}</label>
                        <select
                            {...userModalCredential("list")}
                            className={
                                errors?.list
                                    ? `custom-input !text-grey-3 bg-[url('/src/assets/images/dashboard/select-down-icon.svg')] bg-no-repeat bg-[92.5%] bg-[length:1.7rem] bg-transparent appearance-none !border !border-[#FF1D48]`
                                    : `custom-input !text-grey-3 bg-[url('/src/assets/images/dashboard/select-down-icon.svg')] bg-no-repeat bg-[97.5%] bg-[length:1.7rem] bg-transparent appearance-none`
                            }>
                            <option value="" disabled selected>
                                Select List
                            </option>
                            {listData &&
                                listData.map((item) => (
                                    <option key={item._id} value={item._id}>
                                        {item.title}
                                    </option>
                                ))}
                        </select>
                        {errors.list && (
                            <div className="error-css">{errors?.list?.message}</div>
                        )}
                    </div>
                    <div className="mt-8 flex justify-end items-center gap-6">
                        <button
                            onClick={() => {
                                setSelectCSVListModal(!selectCSVListModal);
                                setValue("list", null);
                            }}
                            type="button"
                            className="px-4 flex gap-1 items-center border-r border-[#7B7B7B] text-neutral-500 ">
                            <span className="text-sm font-bold">Cancel</span>
                        </button>
                        <button
                            type="submit"
                            className={`flex  gap-2 items-center bg-green text-white rounded-full py-1 text-sm h-10 px-16 font-[600] font-base`}>
                            <span>{`Continue`}</span>
                            {isLoader && <Icon icon={"svg-spinners:tadpole"} />}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

// preview after selection the file contact table
const ContactTable = ({ contactData, errorMessage }) => {
    if (contactData.length === 0) return null;
    // Extracting the keys from the first object in your array
    const headers = Object.keys(contactData[0]);

    return (
        <div className="flex flex-col overflow-y-auto max-h-[500px] w-full">
            <h3 className="my-4">Contact Data</h3>
            <div className="text-sm text-red-color py-4">{errorMessage}</div>
            <table className="w-full">
                <thead>
                    <tr className="border-grey-1 border">
                        <th className="p-2 px-4 text-left border" key={0}>
                            #
                        </th>
                        {headers.map((header) => {
                            if (
                                header === "listId" ||
                                header === "addedBy" ||
                                header === "campaignId"
                            )
                                return "";
                            return (
                                <th className="p-2 px-4 text-left border" key={header}>
                                    {header}
                                </th>
                            );
                        })}
                    </tr>
                </thead>
                <tbody>
                    {contactData.map((data, index) => {
                        return (
                            <tr key={index} className="border-grey-1 border">
                                <td className="p-2 px-4 border" key={0}>
                                    {index + 1}
                                </td>
                                {headers.map((header) => {
                                    // Skip rendering the cell if the header is 'addedBy'
                                    if (
                                        header === "listId" ||
                                        header === "addedBy" ||
                                        header === "campaignId"
                                    )
                                        return null;
                                    return (
                                        <td className="p-2 px-4 border" key={header}>
                                            {String(data[header])}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

// Draft Listing
const DraftListTable = ({
    drafts,
    selectedDraftCheckboxes,
    setSelectedDraftCheckboxes,
    session,
    setReload,
}) => {
    const navigate = useNavigate();
    const [draftIsLoader, setDraftIsLoader] = useState(false);
    const [allChecked, setAllChecked] = useState(false);

    const SelectAllCheckBoxes = async () => {
        if (allChecked) {
            const updatedData = await Promise.all(
                selectedDraftCheckboxes?.map((items, index) => {
                    if (drafts[index]?.status === "REVIEWED") return false;
                    else return false;
                })
            );
            console.log(updatedData);
            setSelectedDraftCheckboxes(updatedData);
        } else {
            const updatedData = await Promise.all(
                selectedDraftCheckboxes?.map((items, index) => {
                    if (drafts[index]?.status === "REVIEWED") return true;
                    else return false;
                })
            );
            setSelectedDraftCheckboxes(updatedData);
        }
    };

    const handleCheckboxChange = async (index) => {
        let data = await Promise.all(
            selectedDraftCheckboxes?.map((items, i) => {
                if (index === i) return !items;
                else return items;
            })
        );
        setSelectedDraftCheckboxes(data);
    };

    const handleGenerateButtonClick = async () => {
        const data = await Promise.all(
            drafts?.map((items, index) => {
                if (selectedDraftCheckboxes[index]) return items?.contactId;
            })
        );
        const selectedIds = await Promise.all(
            data?.filter((items) => items !== undefined)
        );
        setDraftIsLoader(true);
        await axios
            .post(
                Path.ComposeSavedDraft,
                {
                    contactIds: selectedIds,
                },
                {
                    headers: {
                        Authorization: "Bearer " + session?.data?.token,
                    },
                }
            )
            .then((success) => {
                showSuccessMessage(success?.data?.message);
                setReload((e) => !e);
            })
            .catch((error) => {
                showErrorMessage(
                    error?.response?.data?.error || "Something Went Wrong."
                );
            })
            .finally(() => {
                setDraftIsLoader(false);
            });
    };

    const handleResend = async (draft_id) => {
        setDraftIsLoader(true);
        await axios
            .post(
                Path.resendEmail,
                {
                    draftIds: [draft_id],
                },
                {
                    headers: {
                        Authorization: "Bearer " + session?.data?.token,
                    },
                }
            )
            .then((success) => {
                showSuccessMessage(success?.data?.message);
                setReload((e) => !e);
            })
            .catch((error) => {
                showErrorMessage(
                    error?.response?.data?.error || "Something Went Wrong."
                );
            })
            .finally(() => {
                setDraftIsLoader(false);
            });
    };
    useEffect(() => {
        async function UpdateCheckboxes() {
            const checkBox = await Promise.all(
                drafts?.map((items) => {
                    return false;
                })
            );
            setSelectedDraftCheckboxes(checkBox);
        }
        drafts?.length !== 0 && UpdateCheckboxes();
    }, [drafts]);
    useEffect(() => {
        async function UpdateSelectAll() {
            let condition = true;
            await Promise.all(
                drafts?.filter((items, index) => {
                    if (
                        items?.status === "REVIEWED" &&
                        selectedDraftCheckboxes[index] === false
                    )
                        condition = false;
                })
            );
            setAllChecked(condition);
        }
        UpdateSelectAll();
    }, [selectedDraftCheckboxes]);

    return (
        <div className="flex gap-4 items-end flex-wrap px-4 pt-6 justify-between">
            <h2 className="large-title">Draft List</h2>
            <div className="flex gap-4">
                {selectedDraftCheckboxes?.includes(true) && (
                    <button
                        type="button"
                        className="outline-none flex items-center gap-2 rounded-md bg-purple text-white appearance-none text-sm cursor-pointer lg:text-base py-1.5 px-5"
                        onClick={handleGenerateButtonClick}>
                        <span>Send Draft</span>
                        {draftIsLoader && <Icon icon={"svg-spinners:tadpole"} />}
                    </button>
                )}
            </div>
            <div className="flex bg-white mt-3 flex-col overflow-y-auto min-h-[500px] w-full">
                <div className="bg-white my-3">
                    <div className="flex items-center gap-3 border-b border-[#ECECEC] p-3">
                        <button
                            type="button"
                            className="flex justify-between items-center pl-2 text-base font-medium border rounded-md border-gray-7 -ml-2">
                            <input
                                type="checkbox"
                                className="h-4 w-4 accent-purple rounded-none bg-light-gray-3 align-middle"
                                onClick={() => SelectAllCheckBoxes()}
                                checked={allChecked}
                            />
                            <span className="text-4xl text-gray-6">
                                <Icon icon="ri:arrow-drop-down-line" />
                            </span>
                        </button>
                    </div>
                    <div className="overflow-auto pb-8">
                        {drafts?.length > 0 ? (
                            <table className="w-full table-auto text-left text-sm">
                                <tbody className="[&_td]:px-2 [&_td]:py-4 [&_td]:bg-grey-4">
                                    {drafts?.length !== 0 &&
                                        drafts?.map((draft, index) => {
                                            return (
                                                <tr key={index} className="border-t border-[#fff]">
                                                    <td className=" text-center">
                                                        <input
                                                            disabled={draft?.status !== "REVIEWED"}
                                                            type="checkbox"
                                                            className="h-4 w-4 accent-purple align-middle"
                                                            checked={selectedDraftCheckboxes[index]}
                                                            onChange={() => handleCheckboxChange(index)}
                                                        />
                                                    </td>
                                                    <td className="">
                                                        <span className="size-12 flex justify-center items-center rounded-full bg-gray-200">
                                                            <span className="text-purple uppercase font-semibold">
                                                                {`${draft?.firstName[0]}${draft?.lastName[0]}`}
                                                            </span>
                                                        </span>
                                                    </td>
                                                    <td
                                                        className="cursor-pointer"
                                                        onClick={() => {
                                                            if (draft?.status !== "FAILED") {
                                                                navigate(
                                                                    `/campaigns/onboarding-campaign/email-step?draftId=${draft?._id}&id=${draft?.campaignId}&stepId=${draft?.stepId}`
                                                                );
                                                            }
                                                        }}>
                                                        <span className="text-purple">
                                                            {`${draft?.firstName} ${draft?.lastName}`}
                                                        </span>
                                                        <br />
                                                        <span className="text-purple">
                                                            {draft.emailSubject
                                                                .split(" ")
                                                                .slice(0, 15)
                                                                .join(" ") + `...`}
                                                        </span>
                                                        <p className="text-gray-400 ">
                                                            {draft.emailContent
                                                                .split(" ")
                                                                .slice(0, 15)
                                                                .join(" ") + `...`}
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <div className="flex items-center gap-2">
                                                            <span>Status:</span>
                                                            <button
                                                                className={`min-w-[100px] ${draft?.status !== "DRAFT"
                                                                    ? draft?.status === "FAILED"
                                                                        ? "bg-light-grey text-grey"
                                                                        : "bg-light-green text-green"
                                                                    : "bg-light-red text-red"
                                                                    } text-xs lg:text-sm px-3 py-1 font-semibold rounded-lg`}>
                                                                {draft?.status}
                                                            </button>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="flex items-center gap-1">
                                                            {draft?.status === "FAILED" && (
                                                                <button
                                                                    onClick={() => {
                                                                        handleResend(draft?._id);
                                                                    }}
                                                                    className="flex items-center gap-1 text-white bg-green border-2 rounded-md p-2 py-1"
                                                                    title="Resend Email">
                                                                    <span className="">Resend</span>
                                                                    <Icon icon={`mingcute:mail-send-line`} />
                                                                </button>
                                                            )}
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </table>
                        ) : (
                            <div className="text-purple bg-grey-4 text-center h-52 flex mt-2 justify-center items-center">
                                <div>Data not found</div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
