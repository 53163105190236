import React, { memo, useState } from "react";
import datamaps from "../../helper/datamaps.world.json"
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';
import { ISO_COUNTRIES, CodeToCountry } from "../../helper/Countries.js";
import { colord } from 'colord';

import ReactTooltip from "react-tooltip";

const GeoMap = ({ data }) => {
    const [tooltip, setTooltip] = useState();

    function getOpacity(code) {
        return code === 'AQ' ? 0 : 1;
    }

    const colors = {
        baseColor: "#ADADAD",
        fillColor: "#858C98",
        strokeColor: "#100829",
        hoverColor: "#7F5FEE"
    }
    
    function handleHover(code) {
        if (code === 'AQ') return;
        const country = data?.find((d) => d.country === code);
        setTooltip(`${CodeToCountry[code] || "Unknown"} : ${country?.visitorCount || "0"} visitors`);
    }

    function getFillColor(code) {
        if (code === 'AQ') return;
        const country = data?.find((d) => d.country === code);
        if (!country) {
            return colors.fillColor;
        }
        return colord(colors.hoverColor).darken(0.1 * (1.0 - country.visitorCount / 100)).toHex();
    }

    return (
        <div data-tip="" className="w-full overflow-hidden font-[DM-Sans]" data-for="world-map-tooltip" >
            <ComposableMap width={1200} height={900} projection="geoMercator">
                <Geographies geography={datamaps}>
                    {({ geographies }) => {
                        return geographies.map(geo => {
                            const code = ISO_COUNTRIES[geo.id];

                            return (
                                <Geography
                                    key={geo.rsmKey}
                                    geography={geo}
                                    fill={getFillColor(code)}
                                    stroke={colors.strokeColor}
                                    opacity={getOpacity(code)}
                                    style={{
                                        default: { outline: 'none' },
                                        hover: { outline: 'none', fill: colors.hoverColor },
                                        pressed: { outline: 'none' },
                                    }}
                                    onMouseOver={() => handleHover(code)}
                                    onMouseOut={() => setTooltip(null)}
                                />
                            );
                        });
                    }}
                </Geographies>
            </ComposableMap>
            <ReactTooltip id="world-map-tooltip">{tooltip}</ReactTooltip>
        </div>
    );
};

export default memo(GeoMap);
