import { React, useEffect, useState } from 'react';
import backIcon from "../../assets/images/campaigns/backbutton.svg"
import axios from 'axios';
import { Path } from '../../helper/path';
import { showErrorMessage, showSuccessMessage } from '../../helper/showMessage';
import { Icon } from '@iconify/react';
import { object, string } from 'yup';
import { BrandVoiceModelValidation, Validation, regex } from '../../helper/Messages';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

export default function BrandVoiceTextModel({ id, AddBrandVoiceModelHide, refresh, setRefresh, hide, setToggle, session, EditGetData }) {
    const [data, setData] = useState(null)
    const [isLoader, setIsLoader] = useState(false)

    const validationSchema = object().shape({
        name: string().trim().required(BrandVoiceModelValidation?.nameRequired),
        brand: string().trim().required(BrandVoiceModelValidation?.brandRequired),
    });
    const { register: brandVoiceCredential, handleSubmit, setValue, formState: { errors } } = useForm({ resolver: yupResolver(validationSchema) });
    const handleCloseTextPopUp = () => {
        hide()
        setToggle(false)
    }
    const onSubmit = async (data, event) => {
        event.preventDefault();
        if (id !== '') {
            setIsLoader(true)
            await axios.put(`${Path.UpdateBrandVoiceByID}${id}`,
                {
                    name: data.name,
                    content: data.brand,
                    type: "TEXT",
                },
                {
                    headers: {
                        Authorization: "Bearer " + session?.data?.token,
                    },
                }
            ).then((success) => {
                setIsLoader(false)
                setRefresh(!refresh)
                hide();
                showSuccessMessage(success?.data?.message)
                EditGetData && EditGetData();
            }).catch((error) => {
                console.log(error)
                setIsLoader(false)
            })

        } else {
            setIsLoader(true)
            await axios.post(Path.BrandVoiceTextBase,
                {
                    name: data.name,
                    content: data.brand,
                    type: "TEXT",
                },

                {
                    headers: {
                        Authorization: "Bearer " + session?.data?.token,
                    },
                }
            ).then((success) => {
                setIsLoader(false)
                showSuccessMessage(success?.data?.message)
                setRefresh(!refresh)
                hide();
                AddBrandVoiceModelHide()
            }).catch((error) => {
                console.log(error)
                setIsLoader(false)
                showErrorMessage(error?.response?.data?.error || 'Something Went Wrong.')
            })
        }

    };
    useEffect(() => {
        async function GetData() {
            await axios.get(`${Path.GetBrandVoiceDataByID}${id}`,
                {
                    headers: {
                        Authorization: "Bearer " + session?.data?.token,
                    }
                }
            ).then((success) => {
                setData(success?.data)
                setValue('name', success?.data?.name)
                setValue('brand', success?.data?.content)
            }).catch((error) => {
                console.log(error)
            })
        }
        id !== '' && GetData()
    }, [id, session?.data?.token])

    return (
        <div className={`bg-black/40 absolute z-20 inset-0 flex items-center`}>
            <div className="bg-white border border-gary-3 mx-auto max-w-[850px] w-full p-8">
                {/* Popup Header */}
                <div className="text-center h-16">
                    <h4 className="font-bold text-dark text-xl">
                        {
                            id !== '' ? 'Edit brand' : 'Add brand'
                        }
                    </h4>
                    <p className="text-neutral-500 text-sm">
                        Give Aye Assistant facts to more accurately write about any topic.
                    </p>
                </div>
                <form className="mx-auto w-[95%]" onSubmit={handleSubmit(onSubmit)}>
                    <div className="mt-4">
                        <label className="custom-label">
                            {`Name`}
                        </label>
                        <input
                            type="name" name="name" placeholder="Enter a Brand Name"
                            className={`custom-input !rounded-lg ${errors?.name ? 'border border-red-color' : ''}`}
                            defaultValue={data?.name}
                            {...brandVoiceCredential("name")}
                        />
                        {
                            errors.name && <div className="error-css">{errors?.name?.message}</div>
                        }

                    </div>
                    <div className="mt-4">
                        <label className="custom-label">
                            {`Brand Text`}
                        </label>
                        <textarea
                            cols={8}
                            rows={8}
                            name='textarea'
                            className={`custom-input !rounded-lg ${errors?.brand ? 'border border-red-color' : ''}`}
                            defaultValue={data?.content}
                            {...brandVoiceCredential("brand")}
                            placeholder="Write brand text"></textarea>
                        {
                            errors.brand && <div className="error-css">{errors?.brand?.message}</div>
                        }
                    </div>
                    {/* Popup Footer */}
                    <div className="mt-10 flex justify-end items-center gap-6">
                        <button type="button" onClick={handleCloseTextPopUp} className="px-4 flex gap-1 items-center border-r border-[#7B7B7B] text-neutral-500 ">
                            <img src={backIcon} alt='' />
                            <span className="text-sm font-bold">Back</span>
                        </button>
                        <button type="submit"
                            className={`flex items-center gap-2 bg-purple text-white rounded-full py-1 text-sm h-10 px-10  font-[600] `}>
                            {
                                id !== ''
                                    ?
                                    <>
                                        {<span> {`Save`}</span>}
                                        {
                                            isLoader && <Icon icon={'svg-spinners:tadpole'} />
                                        }
                                    </>
                                    :
                                    <>
                                        {<span>{`Create`}</span>}
                                        {
                                            isLoader && <Icon icon={'svg-spinners:tadpole'} />
                                        }
                                    </>
                            }
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}