import React, { useEffect, useState } from 'react'
import backIcon from "../../assets/images/campaigns/backbutton.svg"
import { showErrorMessage, showSuccessMessage } from '../../helper/showMessage';
import axios from 'axios';
import { Path } from '../../helper/path';
import { Icon } from '@iconify/react';
import { AddTagsIcon } from '../../helper/SvgIcons';
import { BrandKnowledgeBaseModelValidation } from '../../helper/Messages';


export default function AddKnowledgeFileModel({ id, session, hide, setToggle, refresh, setRefresh }) {
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState("");
    const [tags, setTags] = useState([]);
    const [data, setData] = useState(null)
    const [isLoader, setIsLoader] = useState(false)
    const [tagLoader, setTagLoader] = useState(false)
    const [isDragging, setIsDragging] = useState(false);
    const [isFileLoader, setIsFileLoader] = useState(false)
    const [inputError, setInputError] = useState({
        name: { error: false, message: '' },
        file: { error: false, message: '' },
        tags: { error: false, message: '' },
    });

    const TagsCreater = () => {
        const inputElement = document.getElementById('tagsInput');
        const tagValue = inputElement.value.trim();
        if (tagValue !== "") {
            setTagLoader(true)
            let update = [...tags];
            if (!update.includes(tagValue)) {
                update.push(tagValue);
                setTags(update);
                inputElement.value = '';
                setTagLoader(false)
            } else {
                setTagLoader(false)
                showErrorMessage("Value already exists");
            }
        }

    };

    const TagsDelete = (index) => {
        let update = [...tags]
        update.splice(index, 1)
        setTags(update)
    }
    const onSubmit = async (event, id) => {
        event.preventDefault();
        const name = event?.target?.name?.value.trim();
        setInputError({
            name: { error: !name, message: !name ? 'Name is required' : '' },
            file: { error: !file, message: !file ? 'File is required' : '' },
            tags: { error: tags.length === 0, message: tags.length === 0 ? 'Tags are required' : '' },
        });

        if (!name || !file || tags.length === 0) {
            return;
        }
        if (id !== '') {
            setIsLoader(true)
            await axios.put(`${Path.UpdateBrandKnowledgeByID}${id}`,
                {
                    name: event?.target?.name?.value,
                    content: file,
                    type: "FILE",
                    tags: tags

                },
                {
                    headers: {
                        Authorization: "Bearer " + session?.data?.token,
                    }
                }
            ).then((success) => {
                setIsLoader(false)
                showSuccessMessage(success?.data?.message)
                setRefresh(!refresh)
                hide()
            }).catch((error) => {
                console.log(error)
                setIsLoader(false)
            })
        } else {
            setIsLoader(true)
            await axios.post(Path.BrandKnowledgeTextBase,
                {
                    name: event?.target?.name?.value,
                    content: file,
                    type: "FILE",
                    tags: tags
                },
                {
                    headers: {
                        Authorization: "Bearer " + session?.data?.token,
                    },
                }
            ).then((success) => {
                setIsLoader(false)
                showSuccessMessage(success?.data?.message)
                setRefresh(!refresh)
                hide()
            }).catch((error) => {
                console.log(error)
                setIsLoader(false)
            })
        }
    };

    const handleDragEnter = (e) => {
        e.preventDefault();
        setIsDragging(true);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDragLeave = () => {
        setIsDragging(false);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setIsDragging(false);

        const selectedFile = e.dataTransfer.files[0];
        if (selectedFile.type === "application/pdf") {
            setFileName(selectedFile.name || "");
            FileUpload(selectedFile);
            setInputError((prevInputError) => ({
                ...prevInputError,
                file: { error: false, message: '' },
            }));
        } else {
            showErrorMessage("Please select a valid PDF file.");
        }
    };

  


    const handleInputChange = (field, value) => {
        setInputError((prevInputError) => ({
            ...prevInputError,
            [field]: { error: !value.trim(), message: !value.trim() ? BrandKnowledgeBaseModelValidation[field + 'Required'] : '' },
        }));
    }

    const FileUpload = async (selectedFile) => {
        const formData = new FormData();
        formData.append("file", selectedFile);
        setIsFileLoader(true)
        await axios.post(Path.FileUpload,
            formData,
            {
                headers: {
                    Authorization: "Bearer " + session?.data?.token,
                }
            }
        ).then((success) => {
            setFile(success?.data?.publicUrl)
            setIsFileLoader(false)
        }).catch((error) => {
            setIsFileLoader(false)
            showErrorMessage(error?.data?.message || 'Something Went Wrong.')
        })
    }



    useEffect(() => {
        async function GetData() {
            await axios.get(`${Path.GetBrandKnowledgeDataByID}${id}`,
                {
                    headers: {
                        Authorization: "Bearer " + session?.data?.token,
                    }
                }
            ).then((success) => {
                const fileUrl = success?.data?.content; // Assuming content holds the file URL
                if (fileUrl) {
                    const urlArray = fileUrl.split('/');
                    const extractedFileName = urlArray[urlArray.length - 1];
                    const decodedFileName = decodeURIComponent(extractedFileName);
                    setFileName(decodedFileName);
                }
                setFile(fileUrl)
                setData(success?.data)
                setTags(success?.data?.tags)
            }).catch((error) => {
                console.log(error)
            })
        }
        id !== '' && GetData()
    }, [id, session?.data?.token])



    const handleFormKeyDown = (e) => {
        if (e.key === 'Enter') e.preventDefault();
    };

    const handleCloseTextPopUp = () => {
        hide()
        setToggle(false)
    }

    return (
        <div className={`bg-black/40 absolute z-20 inset-0 flex items-center`}>
            <div className="bg-white border border-gary-3 mx-auto max-w-[850px] w-full p-8">
                {/* Popup Header */}
                <div className="text-center">
                    <h4 className="font-bold text-dark text-xl md:text-[26px]">Add to Knowledge Base</h4>
                    <p className="text-neutral-500 text-base/9 ">Give Aye Assistant facts to more accurately write about any topic.</p>
                </div>
                <form onSubmit={(e) => onSubmit(e, id)} onKeyDown={(e) => handleFormKeyDown(e)} className="mx-auto w-[95%] mt-[44px]">
                    <div className="mt-6">
                        <label className="custom-label">
                            {`Name`}
                        </label>
                        <input onChange={(e) => handleInputChange('name', e.target.value)} defaultValue={data?.name} type="name" name="name" placeholder="Enter a Name"
                            className={`custom-input !h-[46px] !rounded-lg ${inputError.name.error ? 'border border-red-color' : ''}`}
                        />
                        {inputError.name.error && <div className="error-css">{inputError.name.message}</div>}

                    </div>
                    <div className="mt-6">
                        <label className="custom-label flex items-center gap-2">
                            <span>{`Upload File`}</span>
                            {isFileLoader && <div className='text-purple '> <Icon icon={'svg-spinners:tadpole'} /></div>}
                        </label>
                        <div
                            onDragEnter={handleDragEnter}
                            onDragOver={handleDragOver}
                            onDragLeave={handleDragLeave}
                            onDrop={handleDrop}
                            onClick={() => document.getElementById('pdfUpload').click()} className={`cursor-pointer ${inputError.file.error ? 'border border-red-color' : ''} relative flex justify-center items-center flex-col h-[269px] border-dashed border-2 ${isDragging ? 'border-blue-400' : 'border-[#EAEAEA]'} !rounded-lg  `}>
                            {
                                file !== null
                                    ?
                                    <>
                                        <p className="text-dark text-base text-center">
                                            {fileName}
                                        </p>
                                    </>
                                    : fileName ?
                                        <p className="text-dark text-base text-center">
                                            {fileName}
                                        </p>
                                        :
                                        <p className="text-[#AEAEAE] text-base text-center">
                                            Upload or drag here as it's accepting only pdf <br /> File size up to 25 MB
                                        </p>
                            }
                            <input
                                id="pdfUpload"
                                type="file"
                                className="hidden"
                                accept="application/pdf"
                                onChange={async (e) => {
                                    const selectedFile = e.target.files[0];
                                    if (selectedFile) {
                                        if (selectedFile.type === "application/pdf") {
                                            setFileName(selectedFile.name || "");
                                            FileUpload(selectedFile);
                                            setInputError((prevInputError) => ({
                                                ...prevInputError,
                                                file: { error: false, message: '' },
                                            }));
                                        } else {
                                            showErrorMessage("Please select a valid PDF file.");
                                            e.target.value = null;
                                        }
                                    }
                                }}
                            />
                        </div>
                        {inputError.file.error && <div className="error-css">{inputError.file.message}</div>}
                    </div>

                    <div className="mt-6">
                        <label className="custom-label">
                            {`Tags`}
                        </label>
                        <div className={`w-full flex flex-wrap justify-start items-center gap-1 border border-light-grey-2 rounded-lg p-1 ${inputError.tags.error ? 'border border-red-color' : ''}`}>
                            {

                                <>
                                    <div className=' flex flex-wrap items-center gap-3 p-1'>
                                        {
                                            tags?.length !== 0 && tags?.map((data, index) => {
                                                return (
                                                    <div key={index} className='flex items-center gap-2  rounded-md p-1 px-2 text-green font-[600] bg-light-green'>
                                                        <span>
                                                            {data}
                                                        </span>
                                                        <Icon onClick={() => TagsDelete(index)} className='text-black text-md cursor-pointer' icon={'radix-icons:cross-2'} />
                                                    </div>
                                                );
                                            })
                                        }
                                        <div className='flex items-center w-[150px] !h-[36px] gap-2  px-2 rounded-md'>
                                            <input id="tagsInput" onChange={(e) => handleInputChange('tags', e.target.value)} className="w-full h-[full] outline-none" type="text" placeholder="Type here..." />
                                            <span className="cursor-pointer" onClick={TagsCreater}>
                                                {
                                                    tagLoader ? <Icon className="text-green" icon={'svg-spinners:tadpole'} /> : <AddTagsIcon />
                                                }
                                            </span>
                                        </div>
                                    </div>
                                </>

                            }
                        </div>
                        {inputError.tags.error && <div className="error-css">{inputError.tags.message}</div>}
                    </div>

                    {/* Popup Footer */}
                    <div className="mt-10 flex justify-end items-center gap-6">
                        <button onClick={handleCloseTextPopUp} type="button" className="px-4 flex gap-1 items-center border-r border-[#7B7B7B] text-neutral-500 ">
                            <img width={20} height={20} src={backIcon} alt="" />
                            <span className="text-base font-bold">Back</span>
                        </button>
                        <button type='submit' className={`flex items-center gap-2 bg-green text-white rounded-full py-1 text-base h-10 px-10  font-[600] font-base`}>
                            {
                                <span>
                                    {
                                        id !== '' ? 'Save' : 'Add to Knowledge Base'
                                    }
                                </span>
                            }
                            {
                                isLoader && <Icon icon={'svg-spinners:tadpole'} />
                            }
                        </button>

                    </div>
                </form>
            </div>
        </div>
    )
}