import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import creatIcon from '../../assets/images/campaigns/create.svg'
import emailIcon from '../../assets/images/campaigns/email.svg'
import phoneIcon from '../../assets/images/campaigns/phone.svg'
import linkedInIcon from '../../assets/images/campaigns/linkedIn.svg'
import Arrow from "../../assets/images/common/Arrow-Down.svg"
import backIcon from "../../assets/images/campaigns/backbutton.svg"
import ReactSwitch from 'react-switch'
import axios from 'axios'
import { AuthContext } from '../../_providers/AuthProvider'
import { Path } from '../../helper/path'
import { ArrowRight, DownArrow, SideBarArrow } from '../../helper/SvgIcons'
import { Icon } from '@iconify/react'
import { FullScreenLoader } from '../common/FullScreenLoader'
import { MultiSelect } from 'react-multi-select-component'
import { showErrorMessage, showSuccessMessage } from '../../helper/showMessage'

export default function CampaignContactDetails() {
    const { session } = useContext(AuthContext)
    const [isActive, setIsActive] = useState(false);
    const [data, setData] = useState([])
    const [isLoader, setIsLoader] = useState(false);
    const [historyData, setHistoryData] = useState([])
    const [pageViewsData, setPageViewsData] = useState([])
    const [ActionModel, setActionModel] = useState(false);


    const location = useLocation();
    const [showPreview, setShowPreview] = useState(false)
    const [subject, setSubject] = useState(null)
    const [body, setBody] = useState(null)

    const searchParams = new URLSearchParams(location.search);

    const contactId = searchParams.get('contactId');
    const id = searchParams.get('id');
    const status = searchParams.get('status');
    const action = searchParams.get('action');




    const StatusHandler = () => {
        setIsActive(!isActive);
    };

    async function GetContactDetail() {
        setIsLoader(true)
        await axios.get(`${Path.getByIdContact}${contactId}`,
            {
                headers: {
                    Authorization: "Bearer " + session?.data?.token,
                },
            }
        ).then((success) => {
            setData(success?.data)
            setIsLoader(false)
        }).catch((error) => {
            console.log(error)
            setIsLoader(false)

        })
    }

    async function GetPageViews() {
        setIsLoader(true)
        await axios.get(`${Path.GetPageViews}${contactId}`,
            {
                headers: {
                    Authorization: "Bearer " + session?.data?.token,
                },
            }
        ).then((success) => {
            setPageViewsData(success?.data)
            setIsLoader(false)
        }).catch((error) => {
            console.log(error)
            setIsLoader(false)

        })
    }

    async function EmailHistory() {
        setIsLoader(true)
        await axios.get(`${Path.EmailHistory}${id}?contactId=${contactId}`, {
            headers: {
                Authorization: "Bearer " + session?.data?.token,
            },
        }).then((success) => {
            setHistoryData(success?.data)
            setIsLoader(false)
        }).catch((error) => {
            console.log(error)
            setIsLoader(false)
        })
    }

    async function EmailHistoryByContactId() {
        setIsLoader(true)
        await axios.get(`${Path.EmailHistoryByContactId}${contactId}`, {
            headers: {
                Authorization: "Bearer " + session?.data?.token,
            },
        }).then((success) => {
            setHistoryData(success?.data)
            setIsLoader(false)
        }).catch((error) => {
            console.log(error)
            setIsLoader(false)
        })
    }

    useEffect(() => {
        if (status === "true") {
            EmailHistoryByContactId();
        } else {
            EmailHistory();
        }
        GetContactDetail();
        GetPageViews();

    }, [session?.data?.token])

    function convertDateFormat(dateString) {
        const originalDate = new Date(dateString);

        const day = originalDate.getDate();
        const month = originalDate.getMonth() + 1; // Months are zero-based
        const year = originalDate.getFullYear();
        const hours = originalDate.getHours();
        const minutes = originalDate.getMinutes();
        const period = hours >= 12 ? 'pm' : 'am';
        const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
        const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;

        // Format the date as "MM/DD/YYYY hh:mm tt"
        const formattedDate =
            <>
                {formattedHours}:{formattedMinutes} {period}
                <span className="w-[6px] h-[6px] bg-zinc-400 rounded-full inline-block mx-1"></span>
                {day}/{month}/{year}
            </>
        return formattedDate;
    }

    return (
        isLoader ? (
            <FullScreenLoader />
        ) : (
            <>
                <PreviewSection body={body} subject={subject} showPreview={showPreview} setShowPreview={setShowPreview} />
                <div className='py-2 text-purple flex items-center gap-1 cursor-pointer' onClick={() => window.history.back()}>
                    <Icon
                        icon="la:arrow-left"
                        className="icon-color"
                        width={16}
                        height={16}
                    />
                    <span className='text-sm '>{"Back"}</span>
                </div>
                <div className="bg-white p-3 border border-gray-200 flex gap-8">
                    <div className="flex flex-col items-start gap-2 basis-[45%]">
                        <div className="flex gap-2 items-baseline">
                            <h2 className="text-dark text-sm font-bold">{`${data?.data?.firstName} ${data?.data?.lastName}`}</h2>
                        </div>
                        <h5 className="space-x-2 text-xs font-medium">
                            <span className="text-zinc-500">{data?.data?.jobTitle}</span>
                            <span className="text-purple">{data?.data?.companyName}</span>
                        </h5>

                        <div>
                            <h4 className="text-dark text-sm font-bold">Personal Info</h4>
                            <ul className="mt-4 text-[#888888] flex flex-col gap-2 font-medium text-xs">
                                {data?.email && (
                                    <li className="flex gap-2 items-center">
                                        <span>
                                            <img className="w-5 h-5" width={20} height={20} src={emailIcon} alt="" />
                                        </span>
                                        <span
                                            className={`${data?.data?.domain ? 'cursor-pointer text-purple' : 'text-purple'}`}
                                            onClick={() => {
                                                if (data?.email) {
                                                    const mailtoLink = `mailto:${data.email}`;
                                                    window.location.href = mailtoLink;
                                                }
                                            }}>
                                            {data?.email ? data?.email : "-"}
                                        </span>
                                    </li>
                                )}
                                {data?.data?.phone && (
                                    <li className="flex gap-2 items-center">
                                        <span>
                                            <img className="w-5 h-5" width={20} height={20} src={phoneIcon} alt="" />
                                        </span>
                                        <span className="text-purple">{data?.data?.phone ? data?.data?.phone : "-"}</span>
                                    </li>
                                )}
                                {(data?.data?.linkedinURL || data?.data?.linkedinUrl) && (
                                    <li className="flex gap-2 items-center">
                                        <span>
                                            <img className="w-5 h-5" width={20} height={20} src={linkedInIcon} alt="" />
                                        </span>
                                        <span
                                            className="cursor-pointer text-purple"
                                            onClick={() => {
                                                try {
                                                    const url = data?.data?.linkedinURL || data?.data?.linkedinUrl;
                                                    if (!url) return;
                                                    const normalizedUrl = new URL(url.startsWith('http') ? url : `https://${url}`);
                                                    window.open(normalizedUrl.href, '_blank');
                                                } catch (e) {
                                                    console.error('Invalid URL:', data?.data?.linkedinURL || data?.data?.linkedinUrl);
                                                }
                                            }}>
                                            {data?.data?.linkedinURL || data?.data?.linkedinUrl ? data?.data?.linkedinURL || data?.data?.linkedinUrl : "-"}
                                        </span>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>

                    <div>
                        <h4 className="text-dark text-sm font-bold">Other Info</h4>
                        <ul className="mt-4 text-[#888888] flex flex-col gap-2 font-medium text-xs">
                            {data?.data &&
                                Object.entries(data?.data)
                                    .filter(([key]) => !['firstName', 'lastName', 'jobTitle', 'companyName', 'email', 'phone', 'linkedinURL', 'linkedinUrl'].includes(key))
                                    .map(([key, value]) => (
                                        <li key={key} className="flex gap-2 items-center">
                                            <span className="w-[130px] capitalize">{key.replace(/([A-Z])/g, ' $1').trim()}</span>
                                            <span className="text-purple">{value || "-"}</span>
                                        </li>
                                    ))}
                        </ul>
                    </div>
                </div>

                <div className="mt-4 flex gap-2 flex-col lg:flex-row">
                    <div className="border border-gray-200 bg-white flex-1">
                        {historyData.length > 0 ?
                            <table className='w-full '>
                                <thead>
                                    <tr className=''>
                                        <th className={`text-left text-dark font-bold text-sm p-3`}>
                                            <h4>Email History</h4>
                                        </th>
                                        <th className='text-left text-dark font-bold text-sm p-3'>
                                            {status && "Campaign"}
                                        </th>
                                        <th className=' text-left text-dark font-bold text-sm p-3'>
                                            {status && "Step"}
                                        </th>
                                        <th className='text-left text-dark font-bold text-sm p-3'>
                                            {!status && "Clicked"}
                                        </th>
                                        <th className='text-left text-dark font-bold text-sm p-3'>
                                            {!status && "Open"}
                                        </th>
                                        <th className='text-left text-dark font-bold text-sm p-3'>
                                            Status
                                        </th>
                                        <th className=' text-dark text-start font-bold text-sm p-3'>
                                            {status ? "Created At " : "Last Open At"}
                                        </th>
                                    </tr>
                                </thead>

                                {historyData && historyData.map((list, index) => {
                                    console.log(list, "list")
                                    const parser = new DOMParser();
                                    const htmlDoc = parser.parseFromString(list?.body, 'text/html');
                                    const textContent = htmlDoc.body.textContent.trim();
                                    const textContentWithoutWhitespace = textContent.replace(/\s{2,}/g, ' ');
                                    const truncatedContent = textContentWithoutWhitespace.length > 70 ? textContentWithoutWhitespace.slice(0, 70) + '...' : textContentWithoutWhitespace;
                                    return (
                                        <tbody>
                                            <tr key={index} className="border-t border-gray-200 hover:bg-violet-100">
                                                <td className={`${status ? "w-[400px] xl:w-[600px]" : "w-[500px] xl:w-[800px]"} cursor-pointer p-3`}>
                                                    <h5 className="text-dark leading-normal text-sm">
                                                        {/* <span className="text-purple pr-1">{list.side}</span> */}
                                                        {list?.subject ?
                                                            <div onClick={() => {
                                                                setShowPreview(true)
                                                                setSubject(list?.subject)
                                                                setBody(list?.body)
                                                            }} title={list?.subject?.replace(/(<([^>]+)>)/gi, '')}
                                                                dangerouslySetInnerHTML={{ __html: list?.subject?.length > 80 ? list?.subject?.slice(0, 80) + '...' : list?.subject }}
                                                            ></div>
                                                            :
                                                            <div>Not Available</div>
                                                        }
                                                    </h5>
                                                    <div className="text-gray-6 text-xs">{truncatedContent === "undefined" ? "-" : truncatedContent}</div>
                                                </td>
                                                <td className="text-zinc-400  font-normal text-sm whitespace-nowrap  p-3">
                                                    <span>{status && list.campaignName}</span>
                                                </td>
                                                <td className="text-zinc-400  font-normal text-sm whitespace-nowrap  p-3">
                                                    <span>{status && list.stepName}</span>
                                                </td>
                                                <td className={`text-zinc-400  font-normal text-sm whitespace-nowrap  p-3`}>
                                                    <span>{!status && list.clickedCount}</span>
                                                </td>
                                                <td className={`text-zinc-400  font-normal text-sm whitespace-nowrap  p-3`}>
                                                    <span>{!status && list.openCount}</span>
                                                </td>
                                                <td className={`text-zinc-400  font-normal text-sm whitespace-nowrap  p-3 w-[180px]`}>
                                                    <span>{list.status}</span>
                                                </td>
                                                <td className="text-zinc-400 text-start font-normal text-sm whitespace-nowrap p-3 w-[180px]">
                                                    <span>{status ? convertDateFormat(list.createdAt) : list.lastOpenedAt ? convertDateFormat(list.lastOpenedAt) : "-"}</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    )
                                })}
                            </table>
                            :
                            <div className="text-purple bg-grey-4 text-center h-52 flex mt-2 justify-center items-center ">
                                <div>Data not found</div>
                            </div>
                        }

                    </div>
                </div>
                {pageViewsData?.length !== 0 &&
                    <div className='mt-4'>
                        <div className="bg-white p-3 mt-2 flex justify-between items-center gap-6 md:gap-8 lg:gap-12">
                            <h4 className="text-black-2 text-sm font-bold ">Page Views</h4>
                            <div className='flex justify-end gap-3'>
                                {action &&
                                    <button
                                        type="button"
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            setActionModel(!ActionModel);
                                        }}
                                        className="outline-none rounded-md bg-purple text-white appearance-none text-sm cursor-pointer lg:text-base py-1.5 px-3"
                                    >
                                        Action
                                    </button>
                                }
                            </div>
                        </div>
                        <div className="mb-4">
                            <PageViewsTable pageViewsData={pageViewsData} convertDateFormat={convertDateFormat} setActionModel={setActionModel} ActionModel={ActionModel} action={action} contactId={contactId} session={session} />
                        </div>
                    </div>
                }
            </>
        )
    );

};

function PageViewsTable({ pageViewsData, convertDateFormat, setActionModel, ActionModel, action, session, contactId }) {

    const [collapsedIndex, setCollapsedIndex] = useState(null);

    const handleToggleCollapse = (index) => {
        setCollapsedIndex(index === collapsedIndex ? null : index);
    };

    return (
        <>
            <ActionModal setActionModel={setActionModel} ActionModel={ActionModel} session={session} contactId={contactId} />
            {
                pageViewsData && pageViewsData.map((item, i) => {
                    return (
                        <table key={item._id} className="w-full bg-white [&_th]:text-dark text-left text-xs md:text-sm ">
                            {/* Table Header */}
                            <thead onClick={(event) => { event.stopPropagation(); handleToggleCollapse(i); }}
                                className="bg-[#ECECEC] border-b-8 border-white font-bold [&_th]:px-3 [&_th]:py-4 md:[&_th]:text-sm mt-4 cursor-pointer" >
                                <tr>
                                    <th >
                                        <div key={item._id} className="flex gap-1 items-center">
                                            <div className="flex gap-3 items-center justify-center">
                                                <div title={item?.website?.name} className="text-sm font-bold text-dark">
                                                    {item?.website?.name?.length > 25 ? item?.website?.name.slice(0, 25) + '...' : item?.website?.name}
                                                </div>
                                                <div className='text-gray-2'>
                                                    {item?.website?.link}
                                                </div>
                                            </div>
                                        </div>
                                    </th>
                                    <th ></th>
                                    <th className='flex justify-end mt-[2px]'>
                                        <img alt="arrow" src={Arrow} />
                                    </th>
                                </tr>
                                {collapsedIndex === i &&
                                    <tr className='!bg-white'>
                                        <th>
                                            <strong className="text-dark">URL</strong>
                                        </th>
                                        <th>
                                            <strong className="text-dark">Count</strong>
                                        </th>
                                        <th className=''>
                                            <strong className="text-dark ">Visited On</strong>
                                        </th>
                                    </tr>
                                }

                            </thead>
                            {/* Table Head */}
                            <tbody className="[&_tr]:border-b [&_tr]:border-[#ECECEC] [&_td]:px-4 [&_td]:py-3">
                                {
                                    item?.pages?.map((data, index) => {
                                        return (
                                            <tr key={data?._id} className={`${collapsedIndex === i ? 'transition-all duration-500 ease-in-out w-full' : 'hidden'}`}>
                                                <td className='w-[900px]'>
                                                    <span title={data?.referrer} className="text-gray-2 font-normal">
                                                        {data?.referrer ? data?.referrer.length > 70 ? data?.referrer.slice(0, 70) + ' ...' : data?.referrer : "-"}
                                                    </span>
                                                </td>
                                                <td className='w-[600px] '>
                                                    <span className="text-gray-2 font-normal">
                                                        {data?.count ? data?.count : "-"}
                                                    </span>
                                                </td>
                                                <td className='w-[300px]  '>
                                                    <span className="text-gray-2 font-normal">
                                                        {convertDateFormat(data?.latestVisitedDate)}
                                                    </span>
                                                </td>
                                            </tr>
                                        )

                                    })
                                }
                            </tbody>
                        </table>
                    )
                })
            }
        </>
    )
}



function PreviewSection({ showPreview, setShowPreview, subject, body }) {


    return (
        <div className={`bg-[#F5F5F5] w-[900px] h-screen p-6 ${!showPreview ? 'translate-x-[100%]' : 'translate-x-[0%]'} fixed top-0 right-0 z-[900] shadow-[0px_4px_9px_7px_rgba(0,0,0,0.05)] transition-all duration-500`}>
            <div className='w-full h-[7%]'>
                <div className="w-full flex text-sm justify-between items-center">
                    <h3>Preview Email</h3>
                    <button
                        type="button"
                        className="text-purple text-base font-medium"
                        onClick={() => {
                            setShowPreview(false)
                        }}
                    >
                        Close
                    </button>
                </div>
            </div>
            {
                subject !== '' && body !== '' &&
                <div className='w-full h-[85%] flex justify-center items-center'>
                    <div className='w-11/12 h-full overflow-y-auto p-6 bg-white'>
                        <div className='w-full flex flex-col gap-5'>
                            <div className='w-full flex flex-col gap-2'>
                                <p className="text-sm font-[600]">
                                    Subject
                                </p>
                                <div className='w-full text-sm'>{subject}</div>
                            </div>
                            <div className='w-full flex flex-col gap-2'>
                                <p className="text-sm font-[600]">
                                    Body
                                </p>
                                <div className='w-full' dangerouslySetInnerHTML={{ __html: body }}></div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};


export function ActionModal({ setActionModel, ActionModel, session, contactId }) {
    const [actionData, setActionData] = useState([]);
    const [isLoader, setIsLoader] = useState(false)
    const [loadingIndices, setLoadingIndices] = useState([]);
    const [otherLoader, setOtherLoader] = useState([]);
    const [otherText, setOtherText] = useState([]);
    const [text, setText] = useState([])

    const onAdd = async (data, index, state) => {
        if (state) { setLoadingIndices(prevLoadingIndices => [...prevLoadingIndices, index]); setText(prevLoadingIndices => [...prevLoadingIndices, index]) }
        else { setOtherLoader(prevLoadingIndices => [...prevLoadingIndices, index]); setOtherText(prevLoadingIndices => [...prevLoadingIndices, index]) };
        await axios.post(Path.MapRecords,
            {
                recordIds: [contactId],
                campaignId: data.campaignId,
            },
            {
                headers: {
                    Authorization: "Bearer " + session?.data?.token,
                }
            }).then((success) => {
                showSuccessMessage(success?.data?.message)
                setLoadingIndices(prevLoadingIndices => prevLoadingIndices.filter(idx => idx !== index));
                setOtherLoader(prevLoadingIndices => prevLoadingIndices.filter(idx => idx !== index));
            }).catch((error) => {
                console.log(error)
                showErrorMessage(error?.response?.data?.error || 'Something Went Wrong.')
                setLoadingIndices(prevLoadingIndices => prevLoadingIndices.filter(idx => idx !== index));
                setOtherLoader(prevLoadingIndices => prevLoadingIndices.filter(idx => idx !== index));
            })
    }


    async function GetVisitorCampaign() {
        setIsLoader(true);
        await axios.get(`${Path.GetVisitorCampaign}${contactId}`,
            {
                headers: {
                    Authorization: "Bearer " + session?.data?.token,
                },
            })
            .then((success) => {
                setIsLoader(false);
                setActionData(success?.data);
            })
            .catch((error) => {
                setIsLoader(false);
                console.log(error);
            });
    }

    useEffect(() => {
        GetVisitorCampaign();
    }, [session])



    return (
        <div className={`${ActionModel ? 'block' : 'hidden'} bg-black/40 absolute z-20 inset-0 flex items-center overflow-y-hidden`}>
            <div className="bg-white border border-gary-3 mx-auto max-w-[1200px] w-full p-6">
                <div className='max-h-[450px] overflow-auto'>
                    {
                        isLoader ?
                            <div className='flex justify-center items-center p-20 text-2xl text-green'>
                                <Icon icon={'svg-spinners:tadpole'} />
                            </div>
                            :
                            <>
                                <table className='w-full border'>
                                    <thead className=''>
                                        <tr className='bg-grey-4'>
                                            <th className={`text-left text-dark font-bold  text-sm p-3`}>
                                                <h4>Suggested</h4>
                                            </th>
                                            <th></th>
                                            <th></th>

                                        </tr>
                                    </thead>

                                    <tbody className='[&_td]:py-3'>
                                        {actionData?.suggestedCampaigns?.length !== 0
                                            ?
                                            actionData.suggestedCampaigns && actionData.suggestedCampaigns.map((data, index) => {
                                                const parser = new DOMParser();
                                                const htmlDoc = parser.parseFromString(data?.body, 'text/html');
                                                const textContent = htmlDoc.body.textContent.trim();
                                                const textContentWithoutWhitespace = textContent.replace(/\s{2,}/g, ' ');
                                                const truncatedContent = textContentWithoutWhitespace.length > 60 ? textContentWithoutWhitespace.slice(0, 60) + '...' : textContentWithoutWhitespace;
                                                return (
                                                    <tr key={index} className="border-t border-gray-200 hover:bg-violet-100">
                                                        <td className="text-zinc-400 w-[500px]  font-normal text-sm whitespace-nowrap  p-3">
                                                            <h5 className="text-dark leading-normal text-sm">
                                                                {data?.subject ?
                                                                    <div title={data?.subject?.replace(/(<([^>]+)>)/gi, '')}
                                                                        dangerouslySetInnerHTML={{ __html: data?.subject?.length > 60 ? data?.subject?.slice(0, 60) + '...' : data?.subject }}
                                                                    ></div>
                                                                    :
                                                                    <div>Not Available</div>
                                                                }
                                                            </h5>
                                                            <div className="text-gray-6 text-xs">{truncatedContent === "undefined" ? "-" : truncatedContent}</div>
                                                        </td>
                                                        <td className="text-zinc-400  w-[500px]  font-normal text-sm whitespace-nowrap  p-3">
                                                            <span>{data?.campaignName}</span>
                                                        </td>
                                                        <td className="w-[200px]">
                                                            {!text.includes(index) ?
                                                                <button
                                                                    onClick={() => onAdd(data, index, true)}
                                                                    type="button"
                                                                    className="flex items-center gap-2 outline-none rounded-md bg-purple text-white appearance-none text-sm cursor-pointer lg:text-base py-1 px-6 mr-4"
                                                                >
                                                                    <span>{"Add"}</span>
                                                                    {
                                                                        loadingIndices.includes(index) && <Icon icon={'svg-spinners:tadpole'} />
                                                                    }
                                                                </button>
                                                                :
                                                                <span className='pr-3 text-sm' >{"Added to campaign"}</span>}
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                            :
                                            <tr className="text-purple text-center h-10 flex mt-2  w-full text-sm justify-center items-center ">
                                                <div>Suggestion data not found</div>
                                            </tr>

                                        }
                                    </tbody>



                                </table>

                                <table className='w-full border mt-8'>
                                    <thead className=''>
                                        <tr className='bg-grey-4'>
                                            <th className={`text-left text-dark font-bold  text-sm p-3`}>
                                                <h4>Other Campaigns </h4>
                                            </th>
                                            <th></th>
                                            <th></th>

                                        </tr>
                                    </thead>
                                    <tbody className='[&_td]:py-3'>
                                        {actionData.otherCampaigns && actionData.otherCampaigns.map((data, index) => {
                                            const parser = new DOMParser();
                                            const htmlDoc = parser.parseFromString(data?.body, 'text/html');
                                            const textContent = htmlDoc.body.textContent.trim();
                                            const textContentWithoutWhitespace = textContent.replace(/\s{2,}/g, ' ');
                                            const truncatedContent = textContentWithoutWhitespace.length > 60 ? textContentWithoutWhitespace.slice(0, 60) + '...' : textContentWithoutWhitespace;
                                            return (
                                                <tr key={index} className="border-t border-gray-200 hover:bg-violet-100">
                                                    <td className="text-zinc-400 w-[500px]  font-normal text-sm whitespace-nowrap  p-3">
                                                        <h5 className="text-dark leading-normal text-sm">
                                                            {data?.subject ?
                                                                <div title={data?.subject?.replace(/(<([^>]+)>)/gi, '')}
                                                                    dangerouslySetInnerHTML={{ __html: data?.subject?.length > 60 ? data?.subject?.slice(0, 60) + '...' : data?.subject }}
                                                                ></div>
                                                                :
                                                                <div>Not Available</div>
                                                            }
                                                        </h5>
                                                        <div className="text-gray-6 text-xs">{truncatedContent === "undefined" ? "-" : truncatedContent}</div>
                                                    </td>
                                                    <td className="text-zinc-400 w-[500px] font-normal text-sm whitespace-nowrap  p-3">
                                                        <span>{data?.campaignName}</span>
                                                    </td>
                                                    <td className="w-[200px]">
                                                        {!otherText.includes(index) ?
                                                            <button
                                                                onClick={() => onAdd(data, index, false)}
                                                                type="button"
                                                                className="flex items-center gap-2 outline-none rounded-md bg-purple text-white appearance-none text-sm cursor-pointer lg:text-base py-1 px-6 mr-4"
                                                            >
                                                                <span>Add</span>
                                                                {
                                                                    otherLoader.includes(index) && <Icon icon={'svg-spinners:tadpole'} />
                                                                }
                                                            </button>
                                                            :
                                                            <span className='pr-3 text-sm' >{"Added to campaign"}</span>
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        })}

                                    </tbody>

                                </table>
                            </>
                    }
                </div>
                <div className="mt-8 flex justify-end items-center gap-6">
                    <button onClick={() => { setActionModel(!ActionModel) }} type="button" className="px-4 flex gap-1 items-center text-neutral-500 ">
                        <img src={backIcon} alt="" />
                        <span className="text-base font-bold">Back</span>
                    </button>
                </div>

            </div>
        </div>
    )
}