import React, { useContext, useEffect, useRef, useState } from "react";
import { Icon } from "@iconify/react";
import axios from "axios";
import { AuthContext } from "../../_providers/AuthProvider.jsx";
import { useLocation, useNavigate } from "react-router-dom";
import { Path } from "../../helper/path.js";
import { IfIconSVG, Logo } from "../../helper/SvgIcons.js";
import {
    showErrorMessage,
    showSuccessMessage,
} from "../../helper/showMessage.js";
import { GetBodyHtml, UploadImages, getBackgroundUrls, mjmlConvert, replaceBackgroundUrls } from "../../helper/Utility.js";
import { MultiSelect } from "react-multi-select-component";
import EditorLoader from "../common/EditorLoader.jsx";
import ayeAssistantIcon from "../../assets/images/common/Aye-Assistant-btn.svg";
import AssistantWidget from "./AssistantWidget.jsx";
import DynamicVariableModel from "../model/DynamicVariableModel.jsx";
import grapesjs from "grapesjs";
import GjsEditor from "@grapesjs/react";
import Select from "react-dropdown-select";
import ConditionModel from "../model/ConditionModel.jsx";

export default function EmailEditor() {
    const navigate = useNavigate();
    const location = useLocation();
    const { session } = useContext(AuthContext);
    const searchParams = new URLSearchParams(location.search);

    const campaignId = searchParams.get("id");
    const stepId = searchParams.get("stepId");
    const templateId = searchParams.get("templateId");
    const variationId = searchParams.get("variationId");
    const draftId = searchParams.get("draftId");

    const BodyEditor = useRef(null);
    const iframeRef = useRef(null);

    const [SubjectEditor, setSubjectEditor] = useState("");
    const [personaList, setPersonaList] = useState([]);
    const [selectedPersonaList, setSelectedPersonaList] = useState([]);
    const [dynamicVarList, setDynamicVarList] = useState([]);
    const [initData, setInitData] = useState({
        subject: "",
        body: `<mjml>
        <mj-body>
          <mj-section background-color="#f0f0f0">
            <mj-column>
              <mj-text font-size="20px" color="#626262">
                My Company
              </mj-text>
            </mj-column>
          </mj-section>
          <mj-section background-url="https://1.bp.blogspot.com/-TPrfhxbYpDY/Uh3Refzk02I/AAAAAAAALw8/5sUJ0UUGYuw/s1600/New+York+in+The+1960's+-+70's+(2).jpg" background-size="cover" background-repeat="no-repeat">
            <mj-column>
              <mj-text align="center" color="#fff" font-size="40px" font-family="Helvetica Neue">Slogan here
              </mj-text>
              <mj-button background-color="#F63A4D" href="#">
                Promotion
              </mj-button>
            </mj-column>
          </mj-section>
          <mj-section background-color="#fafafa">
            <mj-column width="400px">
              <mj-text font-size="20px" font-family="Helvetica Neue" color="#626262">My Awesome Text
              </mj-text>
              <mj-text color="#525252">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin rutrum enim eget magna efficitur, eu semper augue semper. Aliquam erat volutpat. Cras id dui lectus. Vestibulum sed finibus lectus, sit amet suscipit nibh. Proin nec commodo purus. Sed eget
                nulla elit. Nulla aliquet mollis faucibus.
              </mj-text>
              <mj-button background-color="#F45E43" href="#">
                Learn more
              </mj-button>
            </mj-column>
          </mj-section>
          <mj-section background-color="white">
            <mj-column>
              <mj-image width="200px" src="https://designspell.files.wordpress.com/2012/01/sciolino-paris-bw.jpg">
              </mj-image>
            </mj-column>
            <mj-column>
              <mj-text font-size="20px" font-family="Helvetica Neue" color="#626262">
                Find amazing places
              </mj-text>
              <mj-text color="#525252">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin rutrum enim eget magna efficitur, eu semper augue semper. Aliquam erat volutpat. Cras id dui lectus. Vestibulum sed finibus lectus.
              </mj-text>
            </mj-column>
          </mj-section>
          <mj-section background-color="#fbfbfb">
            <mj-column>
              <mj-image width="100px" src="https://191n.mj.am/img/191n/3s/x0l.png">
              </mj-image>
            </mj-column>
            <mj-column>
              <mj-image width="100px" src="https://191n.mj.am/img/191n/3s/x01.png">
              </mj-image>
            </mj-column>
            <mj-column>
              <mj-image width="100px" src="https://191n.mj.am/img/191n/3s/x0s.png">
              </mj-image>
            </mj-column>
          </mj-section>
          <mj-section background-color="#e7e7e7">
            <mj-column>
              <mj-social font-size="15px" icon-size="30px" mode="horizontal">
                <mj-social-element name="facebook" href="https://mjml.io/">
                  Facebook
                </mj-social-element>
                <mj-social-element name="google" href="https://mjml.io/">
                  Google
                </mj-social-element>
                <mj-social-element name="twitter" href="https://mjml.io/">
                  Twitter
                </mj-social-element>
              </mj-social>
            </mj-column>
          </mj-section>
        </mj-body>
      </mjml>`,
    });
    const [showVersionHistory, setShowVersionHistory] = useState(false);
    const [isRestored, setIsRestored] = useState(false);
    const [showPreview, setShowPreview] = useState(false);
    const [personaData, setPersonaData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [chatAssist, setChatAssist] = useState(false);
    const [dynamicModel, setDynamicModel] = useState({
        status: false,
        event: null,
    });
    const [conditionModel, setConditionModel] = useState({
        status: false,
        event: null,
    });
    const [editorState, setEditorState] = useState(false);
    const iframeSrc = `https://aye-campaign-editor.fair.dental?campaignId=${campaignId}`;
    const style = {
        width: "100%",
        height: "100dvh",
        border: "none",
    };

    async function EditorAPI() {
        setIsLoading(true);
        let subjectContent = SubjectEditor;
        let tempElement = document.createElement("div");
        tempElement.innerHTML = subjectContent;
        
        // const data = getBackgroundUrls(initData?.body)
        // const uploadedImages = await Promise.all(data.map(async (items) => {
        //     if(items.includes('data:image/')){
        //         const uploadedImage = await UploadImages(items, session?.data?.token)
        //         return uploadedImage.data
        //     }else return items
        // }))
        
        // const update = replaceBackgroundUrls(initData?.body, uploadedImages)

        if (templateId == null) {
            await axios
                .post(
                    Path.EmailSave,
                    {
                        subject: SubjectEditor,
                        body: initData?.body,
                        campaignId: campaignId,
                        stepId: stepId,
                        htmlBody: mjmlConvert(initData?.body)?.html,
                    },
                    {
                        headers: {
                            Authorization: "Bearer " + session?.data?.token,
                        },
                    }
                )
                .then((success) => {
                    showSuccessMessage(success?.data?.message);
                    setIsLoading(false);
                    navigate(`/campaigns/onboarding-campaign?id=${campaignId}`);
                })
                .catch((error) => {
                    console.log(error);
                    setIsLoading(false);
                    showErrorMessage(error?.response?.data?.error);
                });
        } else {
            await axios
                .post(
                    Path.EmailEdit,
                    {
                        subject: SubjectEditor,
                        body: initData?.body,
                        templateId: templateId,
                        htmlBody: mjmlConvert(initData?.body)?.html,
                    },
                    {
                        headers: {
                            Authorization: "Bearer " + session?.data?.token,
                        },
                    }
                )
                .then((success) => {
                    showSuccessMessage(success?.data?.message);
                    setIsLoading(false);
                    navigate(`/campaigns/onboarding-campaign?id=${campaignId}`);
                })
                .catch((error) => {
                    console.log(error);
                    setIsLoading(false);
                    showErrorMessage(error?.response?.data?.error);
                });
        }
    }

    async function VariationAPI() {
        setIsLoading(true);
        await axios
            .post(
                Path.EmailVariation,
                {
                    subject: SubjectEditor,
                    body: initData?.body,
                    campaignId: campaignId,
                    stepId: stepId,
                    variationFrom: variationId,
                    htmlBody: mjmlConvert(initData?.body)?.html,
                },
                {
                    headers: {
                        Authorization: "Bearer " + session?.data?.token,
                    },
                }
            )
            .then((success) => {
                showSuccessMessage(success?.data?.message);
                setIsLoading(false);
                navigate(`/campaigns/onboarding-campaign?id=${campaignId}`);
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false);
                showErrorMessage(error?.response?.data?.error);
            });
    }

    async function DraftAPI() {
        setIsLoading(true);
        await axios
            .put(
                `${Path.UpdateDraftDetails}/${draftId}`,
                {
                    htmlBody: mjmlConvert(initData?.body)?.html,
                    emailSubject: SubjectEditor,
                    emailBody: initData?.body,
                    status: "REVIEWED",
                },
                {
                    headers: {
                        Authorization: "Bearer " + session?.data?.token,
                    },
                }
            )
            .then((success) => {
                showSuccessMessage(success?.data?.message);
                navigate(-1);
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    async function SendMail() {
        setIsLoading(true);
        await axios
            .post(
                Path.SendMail,
                {
                    campaignId: campaignId,
                    stepId: stepId,
                    templateId: templateId,
                    contactId: selectedPersonaList?.map((item) => item?._id),
                },
                {
                    headers: {
                        Authorization: "Bearer " + session?.data?.token,
                    },
                }
            )
            .then((success) => {
                setIsLoading(false);
                showSuccessMessage(success?.data?.message);
            })
            .catch((error) => {
                setIsLoading(false);
                showErrorMessage("Email Sent Failed");
            });
    }

    async function reviewAndSend() {
        setIsLoading(true);
        await axios
            .post(
                `${Path.reviewAndSend}?draftId=${draftId}`,
                {
                    htmlBody: mjmlConvert(initData?.body)?.html,
                    emailSubject: SubjectEditor,
                    emailBody: initData?.body,
                    status: "REVIEWED",
                },
                {
                    headers: {
                        Authorization: "Bearer " + session?.data?.token,
                    },
                }
            )
            .then((success) => {
                showSuccessMessage(success?.data?.message);
                navigate(-1);
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    useEffect(() => {
        let list = [];
        let newArr = selectedPersonaList?.map((item) => item?._id);
        personaList.map((data) => {
            if (newArr.includes(data._id)) list.push(data);
        });
        setPersonaData(list);
    }, [selectedPersonaList]);

    useEffect(() => {
        async function GetInitialData(id) {
            await axios
                .get(`${Path.EmailSave}/${id}`, {
                    headers: {
                        Authorization: "Bearer " + session?.data?.token,
                    },
                })
                .then((success) => {
                    setInitData({
                        subject: success?.data?.emailSubject,
                        body: success?.data?.emailBody,
                    });
                    setSubjectEditor(success?.data?.emailSubject);
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.log(error?.response?.data?.error);
                    setIsLoading(false);
                });
        }

        templateId != null && session.data.token && GetInitialData(templateId);
        variationId != null && session.data.token && GetInitialData(variationId);
    }, [session, templateId, variationId, isRestored]);

    // For Drafts
    useEffect(() => {
        async function GetInitialData(id) {
            await axios
                .get(`${Path.DraftDetails}/${id}`, {
                    headers: {
                        Authorization: "Bearer " + session?.data?.token,
                    },
                })
                .then(async (success) => {
                    setInitData({
                        subject: success?.data?.data?.emailSubject,
                        body: success?.data?.data?.emailBody,
                    });
                    setSubjectEditor(success?.data?.data?.emailSubject);
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                    setIsLoading(false);
                });
        }

        draftId != null && session.data.token && GetInitialData(draftId);
    }, [draftId, session]);

    useEffect(() => {
        async function GetDynamicVarList() {
            await axios
                .get(Path.GetDynamicVarList, {
                    headers: {
                        Authorization: "Bearer " + session?.data?.token,
                    },
                })
                .then(async (success) => {
                    setDynamicVarList(success?.data);
                })
                .catch((error) => {
                    console.log(error?.response?.data?.error);
                });
        }
        async function GetPersonaList() {
            await axios
                .get(Path.GetContactListByCampaignId + campaignId, {
                    headers: {
                        Authorization: "Bearer " + session?.data?.token,
                    },
                })
                .then((success) => {
                    const _data = success?.data.map((d) => ({
                        ...d,
                        value: d._id,
                        label: d.firstName + " " + d.lastName,
                    }));
                    setPersonaList(_data);
                    setSelectedPersonaList([_data[0]]);
                })
                .catch((error) => {
                    console.log(error?.response?.data);
                });
        }
        session.data.token && GetDynamicVarList();
        session.data.token && GetPersonaList();
    }, [session]);

    useEffect(() => {
        if (templateId != null || variationId != null) setIsLoading(true);
    }, []);

    useEffect(() => {
        const iframe = iframeRef.current;
        if (iframe !== null) {
            // setIsLoading(true)
            iframe.addEventListener("load", () => {
                const win = iframe.contentWindow;
                win.postMessage({ mjml: initData?.body }, iframeSrc);
            });
        }
    }, [iframeRef, editorState]);

    useEffect(() => {
        const handler = (ev) => {
            if (typeof ev.data !== "object") return;
            if (!ev.data.type) return;
            if (ev.data.type === "loading-complete") setIsLoading(false);

            if (ev.data.type === "cancel-click") {
                setEditorState(false);
                return;
            }
            if (ev.data.type !== "save-click") return;
            if (!ev.data.message) return;

            if (ev.data.type === "save-click") {
                setInitData({ ...initData, body: ev.data.message });
                setEditorState(false);
                return;
            }
        };

        window.addEventListener("message", handler);

        return () => window.removeEventListener("message", handler);
    }, []);
    return (
        <div className="w-full min-h-screen flex flex-col bg-grey-5 !overflow-hidden">
            {isLoading && <EditorLoader />}
            {editorState ? (
                <>
                    <div>
                        <iframe
                            ref={iframeRef}
                            src={iframeSrc}
                            title="Iframe Page"
                            style={style}
                        />
                    </div>
                </>
            ) : (
                <>
                    <AssistantWidget
                        chatAssist={chatAssist}
                        setChatAssist={setChatAssist}
                        campaignId={campaignId}
                    />

                    <Header
                        navigate={navigate}
                        EditorAPI={EditorAPI}
                        templateId={templateId}
                        variationId={variationId}
                        campaignId={campaignId}
                        VariationAPI={VariationAPI}
                        initData={initData}
                        SubjectEditor={SubjectEditor}
                        BodyEditor={BodyEditor}
                        SendMail={SendMail}
                        chatAssist={chatAssist}
                        setChatAssist={setChatAssist}
                        draftId={draftId}
                        DraftAPI={DraftAPI}
                        reviewAndSend={reviewAndSend}
                    />
                    <VersionHistory
                        setShowVersionHistory={setShowVersionHistory}
                        showVersionHistory={showVersionHistory}
                        templateId={templateId}
                        session={session}
                        isRestored={isRestored}
                        setIsRestored={setIsRestored}
                    />
                    <PreviewSection
                        showPreview={showPreview}
                        setShowPreview={setShowPreview}
                        personaData={personaData}
                        campaignId={campaignId}
                        stepId={stepId}
                        templateId={templateId}
                        session={session}
                        setIsLoading={setIsLoading}
                        personaList={personaList}
                        selectedPersonaList={selectedPersonaList}
                        setSelectedPersonaList={setSelectedPersonaList}
                        subject={SubjectEditor || ""}
                        body={
                            mjmlConvert(initData?.body)?.html || ""
                        }
                    />
                    {dynamicModel.status && (
                        <DynamicVariableModel
                            dynamicModel={dynamicModel}
                            setDynamicModel={setDynamicModel}
                            session={session}
                        />
                    )}
                    {conditionModel.status && (
                        <ConditionModel
                            conditionModel={conditionModel}
                            setConditionModel={setConditionModel}
                            session={session}
                        />
                    )}
                    <div className="w-full flex flex-grow p-6">
                        <EditorArea
                            setShowPreview={setShowPreview}
                            initData={initData}
                            setShowVersionHistory={setShowVersionHistory}
                            SubjectEditor={SubjectEditor}
                            setSubjectEditor={setSubjectEditor}
                            BodyEditor={BodyEditor}
                            setIsLoading={setIsLoading}
                            setDynamicModel={setDynamicModel}
                            session={session}
                            setConditionModel={setConditionModel}
                            setEditorState={setEditorState}
                            draftId={draftId}
                        />
                    </div>
                </>
            )}
        </div>
    );
}

function Header({
    navigate,
    EditorAPI,
    variationId,
    campaignId,
    templateId,
    VariationAPI,
    initData,
    SubjectEditor,
    BodyEditor,
    SendMail,
    chatAssist,
    setChatAssist,
    draftId,
    DraftAPI,
    reviewAndSend,
}) {
    const [toggle, setToggle] = useState(false);
    const dropdownRef = useRef(null);

    function ClickHandler() {
        if (initData?.subject !== SubjectEditor || initData?.body !== BodyEditor)
            showErrorMessage("Please Save the Template First.");
        else SendMail();
    }

    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setToggle(false);
            }
        }
        document.addEventListener("click", handleClickOutside);

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [setToggle]);
    return (
        <div className="w-full min-h-[73px] flex justify-between items-center bg-white shadow-sm px-4 ">
            <div className="flex gap-4 items-center">
                <span className="cursor-pointer" onClick={() => navigate(`/`)}>
                    <Logo />
                </span>
                <div>
                    <div className="text-sm text-grey-6">
                        <CustomBreadcrumb navigate={navigate} campaignId={campaignId} />
                    </div>
                    <div className="flex items-center gap-3">
                        <p className="text-black text-xl font-bold">
                            {`Default: Manual Email`}
                        </p>
                    </div>
                </div>
            </div>
            <div className="flex items-center gap-3 text-base font-[600]">
                <button
                    type="button"
                    onClick={() => setChatAssist(!chatAssist)}
                    className="h-[40px] px-5 rounded-full flex items-center justify-center gap-2 bg-[linear-gradient(90deg,#5152C8_0%,#9D06FF_52%,#08FFA0_100%)]">
                    <img
                        className="w-[19.27px] h-[15px]"
                        width={20}
                        height={15}
                        src={ayeAssistantIcon}
                        alt=""
                    />
                    <span className="text-white text-base font-semibold">
                        {`Aye Assistant`}
                    </span>
                </button>

                <div className="relative" ref={dropdownRef}>
                    <div className="flex items-center justify-center">
                        <button
                            className="h-10 px-12 text-sm rounded-full text-white bg-purple"
                            onClick={(event) => {
                                if (draftId != null) {
                                    DraftAPI();
                                    setToggle(!toggle);
                                } else {
                                    if (variationId == null) {
                                        EditorAPI();
                                        setToggle(!toggle);
                                    } else {
                                        VariationAPI();
                                        setToggle(!toggle);
                                    }
                                }
                            }}>
                            {draftId !== null ? `Review` : `Save`}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

function CustomBreadcrumb({ navigate, campaignId }) {
    const { pathname } = useLocation();
    const pathUrl = pathname.split("/").filter(Boolean);
    return (
        <div className="w-full flex select-none text-gray-2 text-sm">
            {pathUrl.map((item, index) => {
                return (
                    <div className="flex justify-start items-center gap-1" key={index}>
                        {index === pathUrl.length - 1 ? (
                            <span className={`text-purple capitalize`}>
                                {item.replace(/-/g, " ")}
                            </span>
                        ) : (
                            <span
                                onClick={() => {
                                    if (item === "campaigns") {
                                        navigate(`/${pathUrl.slice(0, index + 1).join("/")}`);
                                    } else if (item === "onboarding-campaign") {
                                        navigate(
                                            `/${pathUrl
                                                .slice(0, index + 1)
                                                .join("/")}?id=${campaignId}`
                                        );
                                    }
                                }}
                                className={`cursor-pointer capitalize`}>
                                {item.replace(/-/g, " ")}
                            </span>
                        )}
                        {index < pathUrl.length - 1 && (
                            <Icon
                                className="text-xl"
                                icon={"ic:twotone-keyboard-arrow-right"}
                            />
                        )}
                    </div>
                );
            })}
        </div>
    );
}

function EditorArea({
    initData,
    setShowVersionHistory,
    setShowPreview,
    SubjectEditor,
    setSubjectEditor,
    setDynamicModel,
    BodyEditor,
    setIsLoading,
    session,
    setConditionModel,
    setEditorState,
    draftId,
}) {
    const [imageState, setImageState] = useState([]);

    useEffect(() => {
        if (BodyEditor?.current !== null)
            BodyEditor.current.AssetManager.add(imageState);
    }, [imageState]);
    return (
        <div className="w-full min-h-full flex flex-col gap-8 p-5 rounded-lg bg-white text-black shadow-lg">
            <div className="w-full flex justify-between items-center">
                <div className="font-bold text-sm select-none">{`Email Editor`}</div>
                {draftId !== null ? (
                    ""
                ) : (
                    <div className="flex items-center gap-3">
                        <button
                            onClick={() => {
                                setShowPreview(false);
                                setShowVersionHistory(true);
                            }}
                            className="bg-green text-white text-sm  rounded-lg font-semibold px-5 p-2">
                            {`Version History`}
                        </button>
                        <button
                            onClick={() => {
                                setShowVersionHistory(false);
                                setShowPreview(true);
                            }}
                            className="bg-purple text-white text-sm  rounded-lg font-semibold px-5 p-2">
                            {`Preview`}
                        </button>
                    </div>
                )}
            </div>

            <div className="w-full flex flex-col gap-2">
                <span className="text-xs font-[500] select-none">{`Subject`}</span>
                {initData?.subject !== null && (
                    <textarea
                        onChange={(e) => setSubjectEditor(e.target.value)}
                        defaultValue={SubjectEditor}
                        className="w-full text-sm bg-white text-black outline-none border border-gray-300 rounded-lg resize-none p-2"></textarea>
                )}
            </div>
            {/* Body Editor */}
            <div className="w-full flex-grow flex flex-col gap-2">
                <span className="text-xs font-[500] select-none">{`Body`}</span>
                <div className="w-full min-h-screen">
                    <NewPreviewSection
                        initData={initData}
                        setEditorState={setEditorState}
                        setIsLoading={setIsLoading}
                    />
                </div>
            </div>
            {/* Body Editor */}
        </div>
    );
}

const NewPreviewSection = ({ initData, setEditorState, setIsLoading }) => {
    return (
        <div className="border-[1px]  border-dashed min-h-screen bg-gray-200 border-black h-max flex justify-center overflow-y-auto">
            <div className="w-[600px]  relative group cursor-pointer overflow-auto">
                <div
                    title="MJML Preview"
                    dangerouslySetInnerHTML={{ __html: mjmlConvert(initData.body).html }}
                    className="w-[600px] min-h-screen bg-white group-hover:blur-[2px] transition-all duration-75"
                    frameBorder="0"></div>

                <div className="absolute w-full h-full top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 justify-center pt-[200px] hidden group-hover:flex transition-all duration-150">
                    <button
                        className=" bg-purple text-white py-2 px-4 rounded-lg shadow-lg h-[50px] "
                        onClick={() => {
                            setEditorState(true);
                            setIsLoading(true);
                        }}>
                        Edit Template
                    </button>
                </div>
            </div>
        </div>
    );
};

function VersionHistory({
    showVersionHistory,
    setShowVersionHistory,
    templateId,
    session,
    isRestored,
    setIsRestored,
}) {
    const [data, setData] = useState([]);
    const [isLoader, setIsLoader] = useState(false);
    const [active, setActive] = useState(0);

    function formatDate(d) {
        const date = new Date(d);
        const months = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ];
        let day = date.getDate();
        let month = months[date.getMonth()];
        let year = date.getFullYear().toString().substr(-2);
        let hour = date.getHours();
        let minute = date.getMinutes();
        let ampm = hour >= 12 ? "pm" : "am";
        hour = hour % 12;
        hour = hour ? hour : 12; // the hour '0' should be '12'
        minute = minute < 10 ? "0" + minute : minute;

        return `${day} ${month} ${year}, ${hour}:${minute} ${ampm}`;
    }

    function timeAgo(d) {
        const now = new Date();
        const date = new Date(d);
        const diffInMilliseconds = now - date;
        const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
        const secondsInMinute = 60;
        const secondsInHour = secondsInMinute * 60;
        const secondsInDay = secondsInHour * 24;

        const days = Math.floor(diffInSeconds / secondsInDay);
        const hours = Math.floor((diffInSeconds % secondsInDay) / secondsInHour);

        if (days > 0) {
            if (days === 1) {
                if (hours === 0) {
                    return "1 day ago";
                } else {
                    return `1 day, ${hours} hrs ago`;
                }
            } else {
                if (hours === 0) {
                    return `${days} days ago`;
                } else {
                    return `${days} days, ${hours} hrs ago`;
                }
            }
        } else if (hours > 0) {
            return `${hours} hrs ago`;
        } else {
            const minutes = Math.floor(
                (diffInSeconds % secondsInHour) / secondsInMinute
            );
            if (minutes > 0) {
                return `${minutes}m ago`;
            } else {
                return `${diffInSeconds}s ago`;
            }
        }
    }

    async function RestoreVersion(versionId) {
        await axios
            .post(
                Path.RestoreVersion,
                {
                    templateId: templateId,
                    versionId: versionId,
                },
                {
                    headers: {
                        Authorization: "Bearer " + session?.data?.token,
                    },
                }
            )
            .then((success) => {
                setShowVersionHistory(!showVersionHistory);
                setIsRestored(!isRestored);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    useEffect(() => {
        async function GetVersionHistory() {
            setIsLoader(true);
            await axios
                .get(`${Path.EmailEdit}/${templateId}`, {
                    headers: {
                        Authorization: "Bearer " + session?.data?.token,
                    },
                })
                .then((success) => {
                    setData(success?.data);
                    setIsLoader(false);
                })
                .catch((error) => {
                    console.log(error);
                    setIsLoader(false);
                });
        }
        showVersionHistory === true && GetVersionHistory();
    }, [showVersionHistory]);
    return (
        <>
            <div
                className={`bg-[#F5F5F5] min-w-[900px] max-w-[60vw] h-screen p-6 ${!showVersionHistory ? "translate-x-[100%]" : "translate-x-[0%]"} fixed right-0 z-[999] shadow-[0px_4px_9px_7px_rgba(0,0,0,0.05)] transition-all duration-500`}>
                <div className="flex justify-between text-sm items-center mb-10">
                    <h4>Version History</h4>
                    <button
                        type="button"
                        className="text-purple font-medium"
                        onClick={() => {
                            setShowVersionHistory(false);
                        }}>
                        Close
                    </button>
                </div>
                {isLoader ? (
                    <div className="text-purple flex h-48 items-center justify-center">
                        <Icon width={50} icon={"svg-spinners:90-ring-with-bg"} />{" "}
                    </div>
                ) : (
                    <div className="flex">
                        <div className="w-auto border-r text-sm border-[#D6D6D6]">
                            <div className="w-[90%] h-[85vh] overflow-y-auto pr-4">
                                {data.length !== 0 && (
                                    <>
                                        <div className="flex font-bold gap-2 mb-3">
                                            Subject:{" "}
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html: data[active]?.subject,
                                                }}></span>
                                        </div>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: data[active]?.htmlBody,
                                            }}
                                            className="leading-loose text-dark text-sm font-normal mb-5"></div>
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="min-w-[360px] px-5 text-sm">
                            <h4 className="text-right mb-3">History</h4>
                            <ul className="">
                                {data.length !== 0 &&
                                    data?.map((item, index) => {
                                        return (
                                            <li
                                                key={item?._id}
                                                onClick={() => setActive(index)}
                                                className={`${active === index ? "bg-violet-100" : ""
                                                    } text-zinc-800 flex items-center justify-between gap-2 px-2 py-3 hover:bg-violet-100 select-none cursor-pointer`}>
                                                <span className="text-dark">
                                                    {formatDate(item?.createdAt)}
                                                </span>
                                                <span className="text-neutral-500">
                                                    {timeAgo(item?.createdAt)}
                                                </span>
                                            </li>
                                        );
                                    })}
                            </ul>
                        </div>
                    </div>
                )}

                <button
                    onClick={() => RestoreVersion(data[active]?._id)}
                    className="absolute bottom-[70px] right-[50px] text-zinc-800 text-sm font-semibold py-2 px-4 bg-white border border-[#D6D6D6] rounded-md">
                    Restore Version
                </button>
            </div>
        </>
    );
}

function PreviewSection({
    showPreview,
    setShowPreview,
    subject,
    body,
    personaData,
    campaignId,
    stepId,
    session,
    setIsLoading,
    selectedPersonaList,
    setSelectedPersonaList,
    personaList,
}) {
    const [test, setTest] = useState({ body: "", subject: "" });

    async function GetGeneratedContent() {
        setIsLoading(true);
        // console.log(body)
        await axios
            .get(`${Path.GetCampaignByID}${campaignId}`, {
                headers: {
                    Authorization: "Bearer " + session?.data?.token,
                },
            })
            .then(async (success) => {
                await axios
                    .post(
                        Path.generateContent,
                        {
                            campaignId: campaignId,
                            brandId: success?.data?.brandId?._id,
                            personaId: personaData[0]?._id,
                            stepId: stepId,
                            subject: subject,
                            body: body,
                        },
                        {
                            headers: {
                                Authorization: "Bearer " + session?.data?.token,
                            },
                        }
                    )
                    .then((success) => {
                        setTest({
                            body: success?.data?.contentBody,
                            subject: success?.data?.contentSubject,
                        });
                        setIsLoading(false);
                    })
                    .catch((error) => {
                        console.log(error?.response);
                        showErrorMessage(
                            error?.response?.data?.error || "Something Went Wrond"
                        );
                        setIsLoading(false);
                    });
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false);
            });
    }
    return (
        <div
            className={`bg-[#F5F5F5] w-[900px] h-screen p-6 ${!showPreview ? "translate-x-[100%]" : "translate-x-[0%]"
                } fixed right-0 z-[900] shadow-[0px_4px_9px_7px_rgba(0,0,0,0.05)] transition-all duration-500`}>
            <div className="w-full h-[7%] text-sm ">
                <div className="w-full flex justify-between items-center">
                    <h3>Preview Email</h3>
                    <button
                        type="button"
                        className="text-purple font-medium"
                        onClick={() => {
                            setShowPreview(false);
                            setTest({ body: "", subject: "" });
                        }}>
                        Close
                    </button>
                </div>
            </div>
            <div className="w-full h-[7%] flex items-center gap-3">
                <div className="flex items-center gap-2">
                    <span className="font-semibold text-sm ">{`Select Contact:`}</span>
                    {/* <MultiSelect
                        className='w-[200px] lg:w-[250px] h-[40px] border border-gray-300 rounded-lg bg-white text-sm lg:text-base select-none'
                        options={personaList}
                        value={selectedPersonaList}
                        onChange={setSelectedPersonaList}
                        labelledBy="Select"
                        hasSelectAll={false} // Disable select all option
                    /> */}
                    <Select
                        className="w-[200px] lg:w-[250px] h-[40px] border border-gray-300 rounded-lg bg-white text-sm  select-none"
                        style={{ width: "250px", borderRadius: "8px", background: "white" }}
                        options={personaList}
                        value={selectedPersonaList}
                        onChange={setSelectedPersonaList}
                        labelledBy="Select"
                    />
                </div>
                <button
                    onClick={() =>
                        showPreview && personaData.length !== 0 && GetGeneratedContent()
                    }
                    className="h-10 px-10 rounded-lg bg-purple text-white font-semibold text-sm ">
                    {`Generate`}
                </button>
            </div>
            {test?.subject !== "" && test?.body !== "" && (
                <div className="w-full h-[85%] flex justify-center items-center pr-4">
                    <div className="w-11/12 h-full overflow-y-auto p-6 bg-white">
                        <div className="w-full flex flex-col gap-5">
                            <div className="w-full flex flex-col gap-2">
                                <p className="text-sm font-[600]">Subject</p>
                                <div className="w-full text-sm">{test?.subject}</div>
                            </div>
                            <div className="w-full flex flex-col gap-2">
                                <p className="text-sm font-[600]">Body</p>
                                <div
                                    className="w-full"
                                    dangerouslySetInnerHTML={{ __html: test?.body.trim() }}></div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}


<mjml><mj-body><mj-section background-color="#f0f0f0"><mj-column><mj-text font-size="20px" color="#626262">Vizy Print</mj-text></mj-column></mj-section><mj-section background-url="something" background-size="cover" background-repeat="no-repeat"><mj-column><mj-text align="center" color="#fff" font-size="40px" font-family="Helvetica Neue">Welcome</mj-text><mj-button background-color="#F63A4D">Know More</mj-button></mj-column></mj-section><mj-section background-color="#fafafa" background-url="https://ayecampaigns.s3.ap-south-1.amazonaws.com/1725886668341.jpeg" __p="true"><mj-column width="400px"><mj-text font-size="20px" font-family="Helvetica Neue" color="#626262">My Awesome Text
</mj-text><mj-text color="#525252">[#]Introduct VizyPrint within 50 words.[/#]</mj-text><mj-button background-color="#F45E43">Learn more
    </mj-button></mj-column></mj-section><mj-section background-color="white"><mj-column><mj-image width="200px" src="https://designspell.files.wordpress.com/2012/01/sciolino-paris-bw.jpg">
    </mj-image></mj-column><mj-column><mj-text font-size="20px" font-family="Helvetica Neue" color="#626262">
        Find amazing places
    </mj-text><mj-text color="#525252">[#]Display list of 5 services that vizyprint can provide. List all 5 services as bullet points with numbers, having short description for each.[/#]</mj-text></mj-column></mj-section><mj-section background-color="#fbfbfb"><mj-column><mj-image width="100px" src="//191n.mj.am/img/191n/3s/x0l.png">
    </mj-image></mj-column><mj-column><mj-image width="100px" src="//191n.mj.am/img/191n/3s/x01.png">
    </mj-image></mj-column><mj-column><mj-image width="100px" src="//191n.mj.am/img/191n/3s/x0s.png">
    </mj-image></mj-column></mj-section><mj-section background-color="#e7e7e7"><mj-column><mj-social font-size="15px" icon-size="30px" mode="horizontal"><mj-social-element name="facebook" href="https://mjml.io/">
        Facebook
    </mj-social-element><mj-social-element name="google" href="https://mjml.io/">
            Google
        </mj-social-element><mj-social-element name="twitter" href="https://mjml.io/">
            Twitter
        </mj-social-element></mj-social></mj-column></mj-section></mj-body></mjml>