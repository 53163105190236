import { Icon } from "@iconify/react";
import React, { useContext, useEffect, useState } from "react";
import Breadcrumb from "../common/Breadcrumbs";
import microsoftIcon from "../../assets/images/campaigns/microsoft-excel.png";
import listViewIcon from "../../assets/images/campaigns/list-view.svg";
import importContactIcon from "../../assets/images/campaigns/importContactIcon.svg";
import NewSelectListModal from "../model/NewSelectListModal";
import AddNewContact from "../model/AddNewContact";
import ImportIcon from "../../assets/images/dashboard/import.svg";
import CSVReader from "react-csv-reader";
import { showErrorMessage, showSuccessMessage } from "../../helper/showMessage";
import axios from "axios";
import { AuthContext } from "../../_providers/AuthProvider";
import { Path } from '../../helper/path';
import { useLocation, useNavigate } from "react-router";
import { DownloadIcon } from "../../helper/SvgIcons";


export default function ContactNewCsvImport() {
    const { session } = useContext(AuthContext)
    const [openList, setOpenList] = useState(false);
    const [visibility, setVisibility] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [basicFieldError, setBasicFieldError] = useState([]);
    const [contactData, setContactData] = useState([]);
    const [data, setData] = useState(null)
    const location = useLocation();
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState("")
    const searchParams = new URLSearchParams(location.search);
    const [dynamicVarList, setDynamicVarList] = useState([])
    const [mapOptions, setMapOptions] = useState([])
    const [openImportModal, setOpenImportModal] = useState(false)
    const [fileKey, setFileKey] = useState(0);
    const [fileName, setFileName] = useState(0);



    const id = searchParams.get('id');

    const BasicFields = [
        // { key: "contactStage", type: "string", required: true, regex: null },
        // {
        //     key: "firstName",
        //     type: "string",
        //     required: true,
        //     regex: /^[a-zA-Z\s']+$/,
        // },
        // {
        //     key: "lastName",
        //     type: "string",
        //     required: true,
        //     regex: /^[a-zA-Z\s']+$/,
        // },
        // {
        //     key: "dateOfBirth",
        //     type: "string",
        //     required: true,
        //     regex: /^\d{4}-\d{2}-\d{2}$/,
        //     format: "Date format YYYY-MM-DD",
        // }, // Assuming date format YYYY-MM-DD
        {
            key: "email",
            type: "string",
            required: true,
            regex: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        },
        // {
        //     key: "phone",
        //     type: "string",
        //     required: true,
        //     regex: /^\+?[0-9]{7,15}$/,
        //     format: "International phone format with optional '+' (7 to 15 digits)",
        // },
        // { key: "companyName", type: "string", required: false, regex: null },
        // {
        //     key: "domainName",
        //     type: "string",
        //     required: false,
        //     regex: /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/,
        // },
        // { key: "status", type: "string", required: true, regex: null },
        // {
        //     key: "additionalNotes",
        //     type: "string",
        //     required: false,
        //     regex: null,
        // },
        // {
        //     key: "linkedinUrl",
        //     type: "string",
        //     required: false,
        //     regex: /^(https?:\/\/)?(www\.)?linkedin\.com\/.*$/,
        // },
        // { key: "personas", type: "string", required: false, regex: null },
        // { key: "jobTitle", type: "string", required: false, regex: null },
    ];

    function CheckBasicFields(array, fields) {
        let errors = [];

        array.forEach((obj, index) => {
            fields.forEach(({ key, type, required, regex, format }) => {
                const value = obj[key];
                const isValueNull = value === null;

                if (required && !obj.hasOwnProperty(key)) {
                    errors.push(
                        `Row ${index + 1} is missing required key '${key}'`
                    );
                } else if (required && obj.hasOwnProperty(key) && isValueNull) {
                    errors.push(
                        `Row ${index + 1
                        } has required key '${key}' but the value is missing`
                    );
                } else if (required && !isValueNull && typeof value !== type) {
                    errors.push(
                        `Row ${index + 1
                        } has key '${key}' with incorrect type. Expected ${type}, found ${typeof value}`
                    );
                } else if (
                    regex &&
                    !isValueNull &&
                    value &&
                    !regex.test(value)
                ) {
                    let formatError = format
                        ? ` Expected format: ${format}`
                        : " Expected value to match provided pattern";
                    errors.push(
                        `Row ${index + 1
                        } has key '${key}' with value not matching the required format.${formatError}`
                    );
                }
            });
        });

        if (errors.length > 0) {
            console.log(errors)
            setBasicFieldError(errors);
            return false;
        }

        return true;
    }

    function AppendNewFields(array, newFields) {
        array.forEach((obj) => {
            for (const [key, value] of Object.entries(newFields)) {
                obj[key] = value;
            }
        });
        return array;
    }

    const handleForce = async (data, fileInfo) => {
        setContactData([]);
        setErrorMessage("")
        setBasicFieldError([])
        if (CheckBasicFields(data, BasicFields)) {
            const newListFields = {
                // campaignId: id, // Get List Id from previous page || Change this to campaignId when using this component in campaign
                // addedBy: session?.data?.userInfo?._id,
            };
            const _data = AppendNewFields(data, newListFields);
            setContactData(_data);
            setOpenImportModal(!openImportModal)
            setFileKey((prevKey) => prevKey + 1);
            setFileName(fileInfo.name)
        } else {
            // Show Error Model
            showErrorMessage(basicFieldError);
        }
    };


    function convertArray(inputArray, mapOptions) {
        let destructuredField = ['email']
        let outputArray = [];

        // Filter out the values and then map them back to an object with new indices
        const filteredValues = Object.values(mapOptions).filter(value => !destructuredField.includes(value));

        // Reconstruct the object with new sequential indices
        const filteredMapOptions = Object.fromEntries(filteredValues.map((value, index) => [index.toString(), value]));

        inputArray.forEach((obj) => {
            const { email, ...rest } = obj;
            const userInfo = {};
            Object.entries(rest).forEach(([key, value], index) => {
                const selectedKey = filteredMapOptions[index] || key;
                const finalKey = selectedKey.startsWith("data.") ? selectedKey.slice(5) : selectedKey;
                userInfo[finalKey] = value;
            });
            outputArray.push({ userInfo, email });
        });
        return outputArray;
    }

    async function UploadHandleClick() {
        if (contactData == null) {

            showErrorMessage("Please check the contact the data");
        } else {
            const updatedData = convertArray(contactData, mapOptions);
            if (Array.isArray(updatedData)) {
                updatedData.forEach((obj) => {
                    obj.listId = id;  // Replace listId with your actual list ID
                });
            }
            await axios
                .post(`${Path.BulkUploadByDirectory}`, updatedData, {
                    headers: {
                        Authorization: "Bearer " + session?.data?.token,
                    },
                })
                .then((success) => {
                    showSuccessMessage(success?.data?.message)
                    setOpenList(false);
                    setOpenImportModal(!openImportModal)
                    navigate("/administration/contact");
                    setBasicFieldError([]);
                    // setContactData([]);

                })
                .catch((error) => {
                    // showErrorMessage(error?.response?.data?.error || 'Something Went Wrong.')
                    setErrorMessage(error?.response?.data?.error)
                });
        }
    }

    const papaparseOptions = {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
        transformHeader: (header) => header.replace(/\W/g, "_"),
    };

    const handleDownload = () => {
        const fileUrl = "/ContactImportTemplate.csv";
        const link = document.createElement("a");
        link.href = fileUrl;
        link.setAttribute("download", "ContactImportTemplate.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    const hiddenFields = ['_id', '__v', 'createdAt', 'updatedAt']

    async function GetDynamicVarList() {
        await axios.get(Path.GetDynamicVarList,
            {
                headers: {
                    Authorization: "Bearer " + session?.data?.token,
                }
            }
        ).then(async (success) => {
            setDynamicVarList(success?.data)
        }).catch((error) => {
            console.log(error?.response?.data?.error)
        })
    }

    useEffect(() => {
        GetDynamicVarList()
        setContactData([]);
        setBasicFieldError([]);
    }, [])



    useEffect(() => {
        async function GetOverviewSetp() {
            await axios.get(`${Path.getByIdNewList}${id}`, {
                headers: {
                    Authorization: "Bearer " + session?.data?.token,
                },
            }).then((success) => {
                setData(success?.data)
            }).catch((error) => {
                console.log(error)
            })
        }
        GetOverviewSetp()
    }, [session?.data?.token])



    return (
        <>
            {/* <NewSelectListModal visibility={visibility} event={setVisibility} /> */}
            <AddNewContact openModal={openModal} event={setOpenModal} session={session} navigate={navigate} listId={data?._id} />
            <div className="flex flex-col justify-center items-start">
                <CustomBreadcrumb data={data} />
                <span className="large-title">{data?.title}</span>
            </div>
            {openList === true ? (

                //for UploadCSV--------------------------------------------
                <div className="flex flex-col justify-center items-center gap-5 min-h-[65dvh] mt-5 py-10 bg-white">
                    <div className="flex flex-col gap-6 w-full md:max-w-3xl p-12 md:p-0 items-start">
                        <img
                            width={53}
                            height={38}
                            src={importContactIcon}
                            alt=""
                        />
                        <div className="flex flex-col w-full">
                            <div className="justify-between flex gap-2 w-full">
                                <h6 className="text-2xl font-bold">
                                    Upload Contact List
                                </h6>
                                <a href="https://docs.google.com/spreadsheets/d/1B5BHwqME0sRP05xE7iP-pgVFI6vrUkcvmOPhlPbZKFU/edit#gid=1622148469"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="flex justify-center items-center gap-2 rounded-full  hover:bg-grey-8 hover:border-transparent border border-purple bg-white text-zinc-500 p-2 px-5 duration-300"
                                >
                                    <DownloadIcon />
                                    <span className="text-base">
                                        {`View Template`}
                                    </span>
                                </a>
                            </div>
                            <span className="text-base font-normal text-neutral-500">
                                You can import up to 100,000 rows at a
                                time. Supported file format : CSV
                            </span>
                        </div>
                        <p className="text-neutral-500 text-base font-normal mt-3">
                            For accurate mapping, please include at
                            least one of these fields : Company Name,
                            Company Website, LinkedIn URL, and/or
                            Contact Email. For more information, please
                            visit our help center
                        </p>
                        <div className="w-full flex gap-4 justify-between items-center flex-col md:flex-row">
                            <CSVReader
                                cssClass="flex gap-4 border-[2px] border-green justify-center items-center py-3 px-8 rounded-md w-8/12 md:w-7/12"
                                // label="Upload CSV File"
                                key={fileKey}
                                onFileLoaded={handleForce}
                                parserOptions={papaparseOptions}
                            />
                            <div className="flex gap-2">
                                {/* <button
                                    onClick={UploadHandleClick}
                                    type="button"
                                    className="relative btn !bg-green px-10"
                                >
                                    Upload Users List
                                </button> */}
                                <button
                                    onClick={() => setOpenList(false)}
                                    type="button"
                                    className="relative btn text-base font-bold px-4"
                                >
                                    Cancel
                                </button>
                            </div>

                        </div>
                        {basicFieldError.length !== 0 && (
                            <div className="flex flex-col overflow-y-auto max-h-[300px] w-full">
                                <h3 className="my-4">
                                    File Upload Errors{" "}
                                </h3>
                                {basicFieldError.map((e, i) => {
                                    return (
                                        <p className="text-red-color py-1">
                                            {e}
                                        </p>
                                    );
                                })}
                            </div>
                        )}
                        {
                            contactData.length !== 0 &&
                            // <ContactTable contactData={contactData} errorMessage={errorMessage} />
                            <NewSelectListModal errorMessage={errorMessage} contactData={contactData} setOpenImportModal={setOpenImportModal} openImportModal={openImportModal} fileName={fileName} session={session} dynamicVarList={dynamicVarList} setDynamicVarList={setDynamicVarList} UploadHandleClick={UploadHandleClick} setMapOptions={setMapOptions} />
                        }
                    </div>
                </div>
            )
                :
                <UploadOptions setOpenList={setOpenList} setOpenModal={setOpenModal} openModal={openModal} />
            }
        </>
    );
}

function UploadOptions({ setOpenList, setOpenModal, openModal }) {
    return (
        <div className="flex flex-col justify-center items-center bg-white gap-3 h-[65dvh] mt-5">
            <div className="bg-[#DAD6FF] flex gap-8 justify-center items-center h-[394px] w-[750px]  md:w-[900px] lg:w-[1064px]">
                <div className="bg-white rounded-[23px] border border-stone-300 flex flex-col gap-5 justify-center items-center w-[310px] h-[262px]">
                    <span className="w-[72px] h-[72px] flex justify-center items-center bg-green rounded-full">
                        <img
                            width={40}
                            height={40}
                            src={microsoftIcon}
                            alt=""
                        />
                    </span>
                    <p className="w-[80%] mx-auto text-center text-black-1 text-sm">
                        Upload a CSV file of<br /> contacts.
                    </p>
                    <button
                        onClick={() => setOpenList(true)}
                        type="button"
                        className="flex gap-3 justify-center items-center bg-purple w-[173px] h-[34px] rounded-[9px] font-bold text-sm text-white"
                    >
                        Upload CSV
                        <Icon
                            className="text-2xl"
                            icon="ph:upload-simple"
                        />
                    </button>
                </div>
                <div className="bg-white rounded-[23px] border border-stone-300 flex flex-col gap-5 justify-center items-center w-[310px] h-[262px]">
                    <span className="size-[72px] flex justify-center items-center bg-green rounded-full">
                        <img
                            width={40}
                            height={40}
                            src={listViewIcon}
                            alt=""
                        />
                    </span>
                    <p className="w-[80%] mx-auto text-center text-black-1 text-sm">
                        Add a single new contact into the current list
                    </p>
                    <button
                        onClick={() => setOpenModal(!openModal)}
                        type="button"
                        className="flex gap-3 justify-center items-center bg-purple w-[173px] h-[34px] rounded-[9px] font-bold text-sm text-white"
                    >
                        Add New Contact
                    </button>
                </div>
            </div>
        </div>
    )

}

function CustomBreadcrumb({ data }) {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    let pathUrl = pathname.split('/').filter(Boolean);

    if (pathUrl.length > 1) {
        pathUrl[pathUrl.length - 1] = data?.title || '';
    }

    return (
        <div className="w-full flex select-none text-gray-2 text-sm">
            {pathUrl.map((item, index) => (
                <div className='flex justify-start items-center gap-1' key={index}>
                    <span
                        onClick={() => {
                            // Check if it's not the last index and not the last element and not "administration"
                            if (index < pathUrl.length - 1 && item !== "administration") {
                                navigate(`/${pathUrl.slice(0, index + 1).join('/')}`);
                            }
                        }}
                        className={`${index === pathUrl.length - 1 || item === "administration" ? '' : 'cursor-pointer'} capitalize ${index === pathUrl.length - 1 ? 'text-purple' : ''}`}
                    >
                        {item.replace(/-/g, ' ')}
                    </span>
                    {index < pathUrl.length - 1 && (
                        <Icon className='text-xl' icon={"ic:twotone-keyboard-arrow-right"} />
                    )}
                </div>
            ))}
        </div>
    );
}


const ContactTable = ({ contactData, errorMessage }) => {
    if (contactData.length === 0) return null;
    // Extracting the keys from the first object in your array
    const headers = Object.keys(contactData[0]);

    return (
        <div className="flex flex-col overflow-y-auto max-h-[500px] w-full">
            <h3 className="my-4">Contact Data</h3>
            <div className="text-sm text-red-color py-4">{errorMessage}</div>
            <table className="w-full">
                <thead>
                    <tr className="border-grey-1 border">
                        <th className="p-2 px-4 text-left border" key={0}>#</th>
                        {headers.map(header => {
                            if (header === "listId" || header === "addedBy") return ""
                            return (<th className="p-2 px-4 text-left border" key={header}>{header}</th>)
                        })}
                    </tr>
                </thead>
                <tbody>
                    {contactData.map((data, index) => {
                        return (<tr key={index} className="border-grey-1 border">
                            <td className="p-2 px-4 border" key={0}>{index + 1}</td>
                            {headers.map(header => {
                                // Skip rendering the cell if the header is 'addedBy'
                                if (header === "listId" || header === "addedBy") return null;
                                return (
                                    <td className="p-2 px-4 border" key={header}>{String(data[header])}</td>
                                );
                            })}
                        </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    );
};