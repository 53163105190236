import React from 'react';
import crossIcon from '../../assets/images/common/crossicons.svg';
import emailIcon from '../../assets/images/campaigns/email.svg'
import phoneIcon from '../../assets/images/campaigns/phone.svg'
import linkedInIcon from '../../assets/images/campaigns/linkedIn.svg'
export default function PersonDetailModel({ hide, session, personData }) {
    return (
        <>
            <div className={`bg-black/40 absolute z-20 inset-0 flex items-center`}>
                <div className="bg-white border border-gary-3 mx-auto max-w-[800px] w-full p-8">
                    {/* Popup Header */}
                    <div className="ml-auto w-fit cursor-pointer" onClick={() => hide()}>
                        <img width={25} height={25} src={crossIcon} alt="" />
                    </div>
                    {personData?.photo_url ? <div className='flex justify-center items-center w-full'>
                        <img src={personData?.photo_url} alt="" srcset="" className='h-[100px] w-[100px] object-fill' />
                    </div> : <></>}
                    <div className="text-center h-16 ">
                        <h4 className="large-title mb-2">{`${personData?.name}`}</h4>
                    </div>
                    <div className="bg-white p-3 border border-gray-200 flex gap-8">
                        <div className="flex flex-col items-start gap-2 basis-[45%]">
                            <h5 className="space-x-2 text-xs font-medium">
                                <span className="text-zinc-500">{personData?.title}</span>
                                <span className="text-purple">{personData?.organization?.name}</span>
                            </h5>

                            <div>
                                <h4 className="text-dark text-sm font-bold ">Personal Info</h4>
                                <ul className="mt-4 text-[#888888] flex flex-col gap-2 font-medium text-xs  ">
                                    <li className="flex gap-2 items-center">
                                        <span>
                                            <img className="w-5 h-5" width={20} height={20} src={emailIcon} alt="" />
                                        </span>
                                        <span className={`${personData?.email ? 'cursor-pointer' : ''}`}
                                            onClick={() => {
                                                if (personData?.email) {
                                                    const mailtoLink = `mailto:${personData.email}`;
                                                    window.location.href = mailtoLink;
                                                }
                                            }}> {personData?.email ? personData?.email : "-"}</span>
                                    </li>
                                    <li className="flex gap-2 items-center">
                                        <span>
                                            <img className="w-5 h-5" width={20} height={20} src={phoneIcon} alt="" />
                                        </span>
                                        <span>{personData?.phone_numbers?.length ? personData?.phone_numbers[0]?.sanitized_number : "-"}</span>
                                    </li>
                                    <li className="flex gap-2 items-center">
                                        <span>
                                            <img className="w-5 h-5" width={20} height={20} src={linkedInIcon} alt="" />
                                        </span>
                                        <span className={`${personData?.linkedin_url ? 'cursor-pointer' : ''}`}
                                            onClick={() => {
                                                try {
                                                    if (!personData?.linkedin_url) {
                                                        return;
                                                    }
                                                    const normalizedUrl = new URL(personData?.linkedin_url.startsWith('http') ? personData?.linkedin_url : `https://${personData?.linkedin_url}`);
                                                    window.open(normalizedUrl.href, '_blank');
                                                } catch (e) {
                                                    console.error('Invalid URL:', personData?.linkedin_url);
                                                }
                                            }}>{personData?.linkedin_url ? personData?.linkedin_url : "-"}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div>
                            <h4 className="text-dark text-sm font-bold ">Other Info</h4>
                            <ul className="mt-4 text-[#888888] flex flex-col gap-2 font-medium text-xs ">
                                <li className="flex gap-2 items-center">
                                    <span className='w-[130px]'>
                                        Location
                                    </span>
                                    <span className='text-purple'>{`${personData?.city}, ${personData?.country}`}</span>
                                </li>
                                <li className="flex gap-2 items-center">
                                    <span className='w-[130px]'>
                                        Industry
                                    </span>
                                    <span className='text-purple capitalize'>{personData?.data?.industry ? personData?.data?.industry : "-"}</span>
                                </li>
                                <li className="flex gap-2 items-center">
                                    <span className='w-[130px]'>
                                        Domain
                                    </span>
                                    <span className={`text-purple ${personData?.organization?.primary_domain ? 'cursor-pointer' : ''}`}
                                        onClick={() => {
                                            try {
                                                if (!personData?.organization?.primary_domain) {
                                                    return;
                                                }
                                                const normalizedUrl = new URL(personData?.organization?.primary_domain.startsWith('http') ? personData?.organization?.primary_domain : `https://${personData?.organization?.primary_domain}`);
                                                window.open(normalizedUrl.href, '_blank');
                                            } catch (e) {
                                                console.error('Invalid URL:', personData?.organization?.primary_domain);
                                            }
                                        }}
                                    >{personData?.organization?.primary_domain ? personData?.organization?.primary_domain : "-"}</span>
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
