import React, { createContext, useEffect, useState } from 'react';

export const AuthContext = createContext();
const AuthProvider = ({ children }) => {
    const [data, setData] = useState({
        userInfo: "",
        token: "",
        stepId: "",
    });
    const [loggedIn, setLoggedIn] = useState(false);
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const userInfo = JSON.parse(localStorage.getItem("UserInfo"));
        const token = localStorage.getItem("Token")
        if (userInfo != null && token != null) {
            setLoggedIn(true)
            setData({
                userInfo: JSON.parse(localStorage.getItem("UserInfo")),
                token: localStorage.getItem("Token"),
                stepId: localStorage.getItem("stepId"),
            })
            setLoading(false)
        }
        else setLoading(false)
    }, [])


    const DataStorage = (type, item) => {
        if (type === 'POST') {
            localStorage.setItem(item?.name, item?.value)
        } else if (type === 'GET') {
            return localStorage.getItem(item)
        } else if (type === 'DELETE') {
            localStorage.setItem(item, 'All')
        }
    }

    const login = (userInfo, token) => {
        localStorage.setItem("UserInfo", userInfo)
        localStorage.setItem("Token", token)
        
        setData({
            userInfo: JSON.parse(localStorage.getItem("UserInfo")),
            token: localStorage.getItem("Token"),
        });
        setLoggedIn(true);
    }
    const logout = () => {
        localStorage.clear()
        setLoggedIn(false);
        setData({
            userInfo: "",
            token: "",
        });
    }
    const setStepId = (stepId) => {
        localStorage.setItem("stepId", stepId)
        setData({
            userInfo: JSON.parse(localStorage.getItem("UserInfo")),
            token: localStorage.getItem("Token"),
            stepId: localStorage.getItem("stepId")
        });
    }

    const contextValue = {
        status: {
            loggedIn,
            login,
            logout,
            loading,
        },
        session: {
            data,
            setData,
            setStepId,
            DataStorage
        }

    }


    return (
        <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;