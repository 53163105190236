import { Icon } from '@iconify/react';
import React, { useContext, useEffect, useState } from 'react';
import InviteUserModel from '../model/InviteUserModel';
import { useModal } from 'react-modal-hook';
import { AuthContext } from '../../_providers/AuthProvider';
import AddNewCampaignModel from '../model/AddNewCampaignModel';
import ReactApexChart from 'react-apexcharts';
import { Path } from '../../helper/path';
import axios from 'axios';
import { FullScreenLoader } from '../common/FullScreenLoader';
import Loader from '../common/Loader';
export default function Dashboard() {
    const { session } = useContext(AuthContext)
    const [brandFetchLoader, setBrandFetchLoader] = useState(false)
    const [lineDataLoader, setLineDataLoader] = useState(false)
    const [cardDataLoader, setCardDataLoader] = useState(false)
    const [refresh, setRefresh] = useState(false);
    const [brandData, setBrandData] = useState([]);
    const [campaignList, setCampaignList] = useState([]);
    const [triggerAnalytics, setTriggerAnalytics] = useState(false);
    const [cardData, setCardData] = useState([])
    const [hide, setHide] = useState({
        status: true,
        id: ''
    })
    const [selectedBrand, setSelectedBrand] = useState('All');
    const [selectedCampaign, setSelectedCampaign] = useState('All');

    const [series, setSeries] = useState([
        {
            name: 'Sends',
            data: [],
            show: false
        }, {
            name: 'Clicks',
            data: [],
            show: false
        },
    ])
    const [categories, setCategories] = useState([])

    async function GetCardData(campaignId, brandId) {
        setCardDataLoader(true)
        await axios.post(Path.GetDashboardAnalytics,
            {
                campaignId: campaignId,
                brandId: brandId
            },
            {
                headers: {
                    Authorization: "Bearer " + session?.data?.token
                }
            }
        ).then((success) => {
            setCardDataLoader(false)
            setCardData(success?.data || [])
        }).catch((error) => {
            setCardDataLoader(false)
            console.log(error?.response)
        })
    }
    async function LineChartData(campaignId, brandId) {
        setLineDataLoader(true)
        await axios.post(Path.GetDashboardLineChartAnalytics,
            {
                campaignId: campaignId,
                brandId: brandId
            },
            {
                headers: {
                    Authorization: "Bearer " + session?.data?.token
                }
            }
        ).then((success) => {
            setLineDataLoader(false)
            setSeries([
                {
                    name: success?.data?.datasets[0]?.label,
                    data: success?.data?.datasets[0]?.data,
                    show: false
                },
                {
                    name: success?.data?.datasets[1]?.label,
                    data: success?.data?.datasets[1]?.data,
                    show: false
                }
            ])
            setCategories(success?.data?.labels)
        }).catch((error) => {
            setLineDataLoader(false)
            console.log(error?.response)
        })
    }

    async function GetCampaignListByBrandId(brandId) {
        await axios.get(`${Path.GetCampaignsByBrandId}${brandId}`,
            {
                headers: {
                    Authorization: "Bearer " + session?.data?.token
                }
            }
        ).then((success) => {
            setCampaignList(success?.data)
        }).catch((error) => {
            console.log(error)
        })
    }

    useEffect(() => {
        const token = session?.data?.token;
        // Check if the token is available before making the API call
        if (token) {
            async function BrandVoiceData() {
                setBrandFetchLoader(true)
                await axios.get(Path.BrandVoiceTextBase, {
                    headers: {
                        Authorization: "Bearer " + token,
                    },
                }).then((success) => {
                    setBrandData(success?.data)
                    setBrandFetchLoader(false)
                }).catch((error) => {
                    console.log(error)
                    setBrandFetchLoader(false)
                })
            }
            BrandVoiceData()
        }
    }, [session?.data?.token])

    useEffect(() => {
        const brandId = session?.DataStorage('GET', 'Brand')
        const campaignId = session?.DataStorage('GET', 'Campaign')
        if (brandId) {
            setSelectedBrand(brandId)
            GetCampaignListByBrandId(brandId)
        }
        if (campaignId != undefined && campaignId != null) {
            setSelectedCampaign(campaignId)
        }
    }, [session, brandData])

    useEffect(() => {
        const brandId = session?.DataStorage('GET', 'Brand')
        const campaignId = session?.DataStorage('GET', 'Campaign')
        if (brandId && campaignId) {
            session?.data?.token && GetCardData(campaignId, brandId)
            session?.data?.token && LineChartData(campaignId, brandId)
        }
    }, [triggerAnalytics])
    useEffect(() => {
        const brandId = session?.DataStorage('GET', 'Brand')
        const campaignId = session?.DataStorage('GET', 'Campaign')
        if (!(brandId && campaignId)) {
            session?.data?.token && GetCardData('All', 'All')
            session?.data?.token && LineChartData('All', 'All')
        }
    }, [])
    return (
        <div className='w-full min-h-full flex flex-col gap-4 select-none'>
            {brandFetchLoader ? <FullScreenLoader /> : " "
            }
            <DashboardSubHeader session={session.data} openModel={setHide} sessionCamp={session.data} />
            <AddNewCampaignModel hide={hide} setHide={setHide} refresh={refresh} setRefresh={setRefresh} session={session?.data?.token} />

            <div className='w-full flex flex-col gap-4 bg-white p-4 md:px-6'>
                <div className='w-full flex justify-start items-center gap-4 text-sm font-semibold'>
                    <div className='relative w-[200px] h-[35px]'>
                        <select
                            onChange={(e) => {
                                const selectedBrand = e.target.value;
                                setSelectedBrand(selectedBrand);
                                session?.DataStorage('POST', { name: 'Brand', value: selectedBrand });
                                if (selectedBrand !== 'All') {
                                    GetCampaignListByBrandId(selectedBrand);
                                } else {
                                    setCampaignList([]);
                                }
                                session?.DataStorage('DELETE', 'Campaign');
                                setSelectedCampaign('All');
                                setTriggerAnalytics(!triggerAnalytics);
                            }}
                            value={selectedBrand}
                            id='brandSelect' name="brand"
                            className="w-full h-full pl-4 pr-9 bg-[url('/src/assets/images/dashboard/select-down-icon.svg')] bg-no-repeat
                            bg-[95%] bg-[length:1.2rem] bg-white border border-gray-200 rounded-md appearance-none cursor-pointer outline-none"
                        >
                            <option value="All">Brands</option>
                            {brandData.length !== 0 && brandData.map((item) => (
                                <option className="font-[Inter]" key={item._id} value={item._id}>{item.name}</option>
                            ))}
                        </select>
                    </div>
                    <div className='relative w-[200px] h-[35px]'>
                        <select
                            onChange={(e) => {
                                const selectedCampaign = e.target.value;
                                setSelectedCampaign(selectedCampaign);
                                session?.DataStorage('POST', { name: 'Campaign', value: selectedCampaign });
                                setTriggerAnalytics(!triggerAnalytics);
                            }}
                            value={selectedCampaign}
                            id='campaignSelect' name="campaign"
                            className="w-full h-full pl-4 pr-9 bg-[url('/src/assets/images/dashboard/select-down-icon.svg')] bg-no-repeat
                            bg-[95%] bg-[length:1.2rem] bg-white border border-gray-200 rounded-md appearance-none cursor-pointer outline-none"
                        >
                            <option value="All">Campaigns</option>
                            {campaignList?.length !== 0 && campaignList?.map((item) => (
                                <option className="font-[Inter]" key={item._id} value={item._id}>{item.name}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className='w-full h-max flex flex-wrap gap-4'>
                    <div className='w-full lg:w-[64%] flex flex-grow flex-col gap-4'>
                        <div className='w-full flex flex-wrap items-center gap-8 border border-gray-200 rounded-md px-6 py-4'>
                            {cardDataLoader ? <div className='flex justify-center align-center w-full'> <Loader /> </div> : <div className='flex w-full justify-between'>
                                <div className='flex basis-[35%] flex-col gap-2'>
                                    <span className='text-grey-6 text-[15px] font-medium'>
                                        {`Total email sent`}
                                    </span>
                                    <span className='text-[28px]/none text-black font-bold mt-2'>
                                        {cardData[0]?.totalCount}
                                    </span>

                                </div>
                                <div className='flex flex-1 gap-3 justify-between'>
                                    {
                                        cardData && cardData?.map((data, index) => {
                                            return (
                                                <>
                                                    <div key={index} className='flex flex-col gap-2 justify-between'>
                                                        <span className='text-grey-6 text-[15px] font-medium'>
                                                            {'Open'}
                                                        </span>
                                                        <span className='text-[28px]/none text-black font-bold mt-2'>
                                                            {data?.totalOpens}
                                                        </span>
                                                        <span className='text-[14px] text-green'>
                                                            +{parseFloat(data?.percentageOpens).toFixed(2)}%
                                                        </span>
                                                    </div>
                                                    <div className='flex flex-col gap-2 justify-between'>
                                                        <span className='text-grey-6 text-[15px] font-medium'>
                                                            {'Click'}
                                                        </span>
                                                        <span className='text-[28px]/none text-black font-bold mt-2'>
                                                            {data?.totalClicks}
                                                        </span>
                                                        <span className='text-[14px] text-green'>
                                                            +{parseFloat(data?.percentageClicks).toFixed(2)}%
                                                        </span>
                                                    </div>
                                                    <div className='flex flex-col gap-2 justify-between'>
                                                        <span className='text-grey-6 text-[15px] font-medium'>
                                                            {'Delivered'}
                                                        </span>
                                                        <span className='text-[28px]/none text-black font-bold mt-2'>
                                                            {data?.totalDelivered}
                                                        </span>
                                                        <span className='text-[14px] text-green'>
                                                            +{parseFloat(data?.percentageDelivered).toFixed(2)}%
                                                        </span>
                                                    </div>
                                                </>
                                            )
                                        })
                                    }
                                </div>
                            </div>}
                        </div>
                        <div className='w-full flex flex-col gap-4 border border-gray-200 text-[#7B7B7B] rounded-md p-4 md:p-6 [&_div:last-child]:pb-0'>
                            <div className="flex justify-between text-[15px] font-normal px-5 ">
                                <span>
                                    {`You’re looking at your Email performance`}
                                </span>
                                <div className='flex gap-6'>
                                    <div className='flex items-center gap-2'>
                                        <div className='w-4 h-4 bg-[#D6D6D6] rounded-sm'></div>
                                        <div className='text-grey-6 '>{`Emails sent`}</div>
                                    </div>
                                    <div className='flex items-center gap-2'>
                                        <div className='w-4 h-4 bg-[#7F5FEE] rounded-sm'></div>
                                        <div className='text-grey-6 '>{`Opened`}</div>
                                    </div>
                                </div>
                            </div>
                            {lineDataLoader ? <div className='flex justify-center align-center w-full h-[400px]'> <Loader /> </div> :
                                categories?.length !== 0
                                    ?
                                    <DetailReportChart series={series} categories={categories} />
                                    :
                                    <div className='w-full h-[300px] flex justify-center items-center'>
                                        {`No Data Found`}
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

function DashboardSubHeader({ session, openModel, sessionCamp }) {
    const [openSuccesPopUp, hideSuccesPopUp] = useModal(() => (
        <InviteUserModel sessionData={session} hide={hideSuccesPopUp} />
    ));


    return (
        <>
            <div className='w-full flex justify-between items-start'>
                <div className='flex flex-col justify-center items-start'>
                    <span className='large-title '>
                        {`Dashboard`}
                    </span>
                    <span className='sub-title '>
                        {`Design your first email campaign`}
                    </span>
                </div>
                <div className='flex gap-2 '>
                    <button onClick={openSuccesPopUp} className='btn  !bg-white border !border-grey-6 text-grey-6'>
                        {`Invite User`}
                    </button>
                    <button className='btn !text-sm'>
                        <Icon
                            icon="bx:plus-medical"
                            width={12}
                            height={12}
                        />
                        <span className='' onClick={() => openModel({ status: false, id: '' })}>
                            {`New Campaign`}
                        </span>
                    </button>
                </div>
            </div>
        </>

    );
};


function DetailReportChart({ series, categories }) {
    const [options, setOptions] = useState({
        chart: {
            toolbar: {
                show: false,
                tools: {
                    download: false
                }
            },
            type: 'bar',
            height: 300,
            foreColor: '#FFFUG7',
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '23px',
                endingShape: 'rounded',
                borderRadius: 3,
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        xaxis: {
            show: false,
            axisBorder: {
                show: false
            },
            categories: categories,
            labels: {
                style: {
                    fontSize: '15px',
                    colors: '#7B7B7B',
                    fontWeight: 500,
                },
            },
        },
        colors: ["#D6D6D6", "#7F5FEE"],
        yaxis:
        {
            show: true,
            min: 0,
            max: 100,
            labels: {
                style: {
                    colors: ['#7B7B7B'],
                    fontSize: '15px',
                    fontWeight: 500,
                },
                formatter: function (value) {
                    return value;
                },
            },
        },


        legend: {
            show: false,
        },
        grid: {
            show: true,
            borderColor: '#F5F5F5',
            yaxis: {
                lines: {
                    show: true,
                }
            },
            xaxis: {
                lines: {
                    show: false,
                }
            },
        },
        fill: {
            opacity: 1
        },
        tooltip: {
            shared: true,
            intersect: false,
            enabled: true,
            followCursor: true,
            style: {
                fontSize: '14px',
            },
            x: {
                show: true,
                format: "yyyy",
            },
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                var dateString = w.config.xaxis.categories[dataPointIndex];
                var dateObject = new Date(dateString);
                var formattedDate = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'long' }).format(dateObject);

                return (
                    "<div class='text-[16px] flex justify-between text-[#7B7B7B] py-[4px] p-3 items-center rounded-[10px] font-[Inter]'>" + formattedDate + "</div>" +
                    "<div class='space-x-4 flex justify-between  py-[4px] p-3  items-center rounded-[10px] font-[Inter]'>" +
                    "<div class='flex justify-between items-center space-x-2 '>" +
                    "<div class='w-2.5 h-2.5 bg-[#D6D6D6] rounded-sm'></div>" +
                    "<div class='text-[12px] flex items-center font-normal !text-black font-[Inter]'>Emails Sent</div>" +
                    "</div>" +
                    "<div class='text-[14px] font-medium text-black font-[Inter]'>" +
                    series[0][dataPointIndex] +
                    "</div>" +
                    "</div>" +
                    "<div class='space-x-4 flex justify-between py-[4px] p-3 mb-2 items-center font-[Inter]'>" +
                    "<div class='flex justify-between items-center space-x-2 '>" +
                    "<div class='w-2.5 h-2.5 bg-[#7F5FEE] rounded-sm'></div>"
                    +
                    "<div class='flex items-center text-[12px] font-normal !text-black font-[Inter]'>Opened</div>" +
                    "</div>" +
                    "<div class='text-[14px] font-medium text-black font-[Inter]'>" +
                    series[1][dataPointIndex] +
                    "</div>" +
                    "</div>"
                );
            },
        }

    })
    return (
        <>
            <div id="chart" className=' text-[#7B7B7B]'>
                <ReactApexChart
                    options={options}
                    series={series}
                    type="bar"
                    height={400}
                />
            </div>
        </>
    )
}