import { Overview, Contact, ActivityLog, Settings, Reports } from '../../helper/SvgIcons';
import React, { useContext, useEffect, useState } from 'react'
import CampaignOverview from './CampaignOverview'
import CampaignContact from './CampaignContact'
import CampaignActivityLog from './CampaignActivityLog'
import CampaignSettings from './CampaignSettings'
import CampaignReports from './CampaignReports'
import ReactSwitch from 'react-switch';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../_providers/AuthProvider'
import axios from 'axios';
import { Path } from '../../helper/path';
import { Icon } from '@iconify/react';
import { FullScreenLoader } from '../common/FullScreenLoader';


export default function OnboardingCampaign() {
    const [activeTab, setActiveTab] = useState(0)
    const [visible, setVisible] = useState(true)
    const [isActive, setIsActive] = useState(false);
    const { session } = useContext(AuthContext)
    const [data, setData] = useState()
    const [filterType, setFilterType] = useState(false);
    const [isLoader, setIsLoader] = useState(false)
    const [openList, setOpenList] = useState(false)


    const navigate = useNavigate()

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const _activeTab = searchParams.get('activeTab');


    useEffect(() => {
        if (_activeTab) {
            setActiveTab(parseInt(_activeTab))
        }
    }, [])

    const id = searchParams.get('id');
    useEffect(() => {
        setIsActive(data?.status === 'ACTIVE');
    }, [data?.status]);

    const StatusHandler = () => {
        setIsActive((prevIsActive) => !prevIsActive);
    };

    useEffect(() => {
        setIsLoader(true)
        async function GetData() {
            await axios.get(`${Path.GetCampaignByID}${id}`,
                {
                    headers: {
                        Authorization: "Bearer " + session?.data?.token,
                    }
                }
            ).then((success) => {
                setData(success?.data)
                setIsLoader(false)
            }).catch((error) => {
                console.log(error)
                setIsLoader(false)
            })
        }
        GetData();
    }, [id, session?.data?.token, ])
    return (
        <>
            {isLoader ? <FullScreenLoader /> : " "}
            <div className="w-full flex flex-col gap-6">
                <OnboardingCampaignSubHeader data={data} />
                <div className="">

                    <div className="bg-white gap-4 flex justify-between items-center border-b border-[#ECECEC] pr-4">
                        <ul className="flex items-center  gap-3 md:gap-2">
                            <li className="max-md:flex-auto hover:text-purple">
                                <button onClick={() => setActiveTab(0)} type="button"
                                    className={`${activeTab === 0 ? 'active' : ''} flex gap-3 items-center tab`}>
                                    <span className="hidden lg:block ">
                                        <Overview active={activeTab === 0} color={activeTab === 0 ? '#7F5FEE' : '#888888'} />
                                    </span>
                                    Overview
                                </button>
                            </li>
                            <li className="max-md:flex-auto hover:text-purple">
                                <button onClick={() => {
                                    setActiveTab(1);
                                    setOpenList(true);
                                }} type="button"
                                    className={`${activeTab === 1 ? 'active' : ''} flex gap-3 items-center tab`}
                                >
                                    <span className="hidden lg:block">
                                        <Contact active={activeTab === 1} color={activeTab === 1 ? '#7F5FEE' : '#888888'} />
                                    </span>
                                    Contact
                                </button>
                            </li>
                            <li className="max-md:flex-auto  hover:text-purple">
                                <button onClick={() => setActiveTab(2)} type="button"
                                    className={`${activeTab === 2 ? 'active' : ''} flex gap-3 items-center tab`}>
                                    <span className="hidden lg:block ">
                                        <ActivityLog active={activeTab === 2} color={activeTab === 2 ? '#7F5FEE' : '#6E6E6E'} />
                                    </span>
                                    Activity Log
                                </button>
                            </li>
                            <li className="max-md:flex-auto  hover:text-purple">
                                <button onClick={() => setActiveTab(3)} type="button"
                                    className={`${activeTab === 3 ? 'active' : ''} flex gap-3 items-center tab`}>
                                    <span className="hidden lg:block ">
                                        <Settings active={activeTab === 3} color={activeTab === 3 ? '#7F5FEE' : '#828282'} />
                                    </span>
                                    Settings
                                </button>
                            </li>
                            <li className="max-md:flex-auto hover:text-purple">
                                <button onClick={() => setActiveTab(4)} type="button"
                                    className={`${activeTab === 4 ? 'active' : ''} flex gap-3 items-center tab`}>
                                    <span className="hidden lg:block ">
                                        <Reports active={activeTab === 4} color={activeTab === 4 ? '#7F5FEE' : '#828282'} />
                                    </span>
                                    Reports
                                </button>
                            </li>
                        </ul>

                        {
                            activeTab !== null ?
                                <div className="flex gap-2 lg:gap-4 justify-center items-center text-base font-normal text-[#888]">
                                    {
                                        activeTab === 0
                                            ?
                                            <>
                                                <button
                                                    onClick={() => setFilterType(!filterType)}
                                                    type="button"
                                                    className="text-sm font-medium rounded-lg flex items-center gap-2 border text-purple border-purple
                                             hover:text-purple hover:border-current focus:border-purple focus:text-purple h-[33px] ml-auto px-4"
                                                >
                                                    {filterType ? `Active Steps` : `Archived Steps`}
                                                </button>
                                                {/* <div className='relative w-[124px] lg:w-[144px] h-[33px]'>
                                                    <Icon className='absolute top-[18%] right-1 text-white text-2xl pointer-events-none' icon={'material-symbols:keyboard-arrow-down-rounded'} />
                                                    <select
                                                        className="w-full h-full px-2 outline-none rounded-md bg-purple text-white appearance-none text-sm cursor-pointer lg:text-base"
                                                    >
                                                        <option selected defaultValue>
                                                            Add Contact
                                                        </option>
                                                    </select>
                                                </div> */}
                                            </>
                                            :
                                            null
                                    }
                                    {/* {
                                        activeTab !== 1
                                            ?
                                            <>
                                                <button className='flex justify-center items-center'>
                                                    <ReactSwitch
                                                        checked={isActive}
                                                        onChange={StatusHandler}
                                                        className={`absolute cursor-pointer top-0 left-0 right-0 bottom-0 transition-all duration-300 ease-in-out transform`}
                                                        uncheckedIcon={false}
                                                        checkedIcon={false}
                                                        boxShadow="0 0 2px 2px #ccc"
                                                        onColor="#53D0A4"
                                                        offColor="#FF0000"
                                                        width={39}
                                                        height={20}
                                                        handleDiameter={12}
                                                    />
                                                </button>
                                                <span className="hidden lg:block">
                                                    {isActive ? 'Active' : 'Inactive'}
                                                </span>
                                            </>
                                            :
                                            null
                                    } */}
                                </div>
                                :
                                null
                        }
                        {
                            activeTab === 1 ?
                                <div className="flex gap-2 lg:gap-4 justify-center items-center text-base font-normal text-[#888]">
                                    {
                                        visible
                                            ?
                                            null
                                            :
                                            <select
                                                className="px-2 h-[33px] outline-none border border-white rounded-md bg-purple text-white
                                                bg-no-repeat bg-[98%] bg-[length:1.5rem] bg-[url('/src/assets/images/common/whitedown-arrow.svg')] appearance-none text-sm w-[124px] lg:w-[144px] lg:text-base"
                                            >
                                                <option selected defaultValue>
                                                    Add Contact
                                                </option>
                                            </select>
                                    }
                                    {/* <button className='flex justify-center items-center'>
                                        <ReactSwitch
                                            checked={isActive}
                                            onChange={StatusHandler}
                                            className={`absolute cursor-pointer top-0 left-0 right-0 bottom-0 transition-all duration-300 ease-in-out transform`}
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            boxShadow="0 0 2px 2px #ccc"
                                            onColor="#53D0A4"
                                            offColor="#FF0000"
                                            width={39}
                                            height={20}
                                            handleDiameter={12}
                                        />
                                    </button>
                                    <span className="hidden lg:block">
                                        {
                                            isActive ? 'Active' : 'Inactive'
                                        }
                                    </span> */}
                                </div>
                                :
                                null
                        }
                    </div>
                   
                    {activeTab === 0 ? <CampaignOverview navigate={navigate} id={id} filterType={filterType} /> : null}
                    {activeTab === 1 ? <CampaignContact visible={visible} setVisible={setVisible} /> : null}
                    {activeTab === 2 ? <CampaignActivityLog id={id} /> : null}
                    {activeTab === 3 ? <CampaignSettings id={id} /> : null}
                    {activeTab === 4 ? <CampaignReports /> : null}
                </div>
            </div>
        </>
    )
}


function OnboardingCampaignSubHeader({ data }) {
    return (
        <div className='w-full flex justify-between items-start'>
            <div className='flex flex-col justify-center items-start'>
                <CustomBreadcrumb data={data} />
                <span className='large-title'>
                    {data?.name}
                </span>
            </div>
        </div>
    );
};


function CustomBreadcrumb({ data }) {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    let pathUrl = pathname.split('/').filter(Boolean);
    if (pathUrl.length > 1) {
        pathUrl[1] = data?.name || '';
    }
    return (
        <div className="w-full flex select-none text-gray-2 text-sm">
            {pathUrl.map((item, index) => (
                <div className='flex justify-start items-center' key={index}>
                    <span
                        onClick={() => index === pathUrl.length - 1 || pathUrl.slice(0, 1).join('/') === "administration" ? " " : navigate(`/${pathUrl.slice(0, index + 1).join('/')}`)}
                        className={`${index === pathUrl.length - 1 ? 'text-purple' : ''} capitalize cursor-pointer`}
                    >
                        {item.replace(/-/g, ' ')}
                    </span>
                    {index < pathUrl.length - 1 && (
                        <Icon className='text-xl' icon={"ic:twotone-keyboard-arrow-right"} />
                    )}
                </div>
            ))}
        </div>
    );
}