import { LogoutIcon, SearchIcon, SearchSidebar, Sidebar1, Sidebar2, Sidebar3, Sidebar5, Sidebar6, Sidebar7 } from '../../helper/SvgIcons';
import React, { useContext, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import AdministrationDropDown from './AdministrationDropDown';
import { SideBarArrow } from '../../helper/SvgIcons';
import { useNavigate } from 'react-router';
import { Icon } from '@iconify/react';
import { AuthContext } from '../../_providers/AuthProvider';
import VisitorsDropDown from './VisitorsDropDown';
import RulesEngineDropDown from './RulesEngineDropDown';

export default function Sidebar() {
    const { status } = useContext(AuthContext)
    const navigate = useNavigate()
    const Location = useLocation();
    const [toggle, setToggle] = useState(true)


    return (
        <div className={` flex flex-col overflow-y-auto duration-300 ease-linear w-14
           ${toggle && 'w-60 md:w-[241px]'}`}>
            {toggle === true ? (
                <button type="button" className="absolute right-[-15px] bottom-[8rem] z-10 text-white" onClick={() => setToggle(!toggle)}>
                    <span><SideBarArrow /></span>
                </button>
            ) : (
                <button type="button" className="absolute right-[-15px] bottom-[8rem] z-10 text-white rotate-180" onClick={() => setToggle(!toggle)}>
                    <span className=''><SideBarArrow /></span>
                </button>
            )
            }
            <ul className="">
                <li className="nav-item">
                    <NavLink to="/dashboard" onClick={() => setToggle(true)} className={`nav-link  ${Location.pathname === "/dashboard" ? 'active' : ''}`}>
                        <span className="menu-icon">
                            <Sidebar1 hover={Location.pathname === "/dashboard" ? true : false} color={Location.pathname.includes("dashboard") ? '#7F5FEE' : '#8B8B8B'} />
                        </span>
                        <span>Dashboard</span>
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to="/campaigns" onClick={() => setToggle(true)} className={`nav-link ${Location === "/campaigns" ? 'active' : ''}`}>
                        <span className="menu-icon">
                            <Sidebar2 hover={Location.pathname === "/campaigns" ? true : false} color={Location.pathname.includes("campaigns") ? '#7F5FEE' : '#8B8B8B'} />
                        </span>
                        <span>Campaigns</span>
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to="/people-search" onClick={() => setToggle(true)} className={`nav-link ${Location === "/people-search" ? 'active' : ''}`}>
                        <span className="menu-icon">
                            <SearchSidebar hover={Location.pathname === "/people-search" ? true : false} color={Location.pathname.includes("people") ? '#7F5FEE' : '#8B8B8B'} />
                        </span>
                        <span>People Search</span>
                    </NavLink>
                </li>
                <li className="nav-item">
                    {/* <NavLink to="/report-analytics" onClick={() => setToggle(true)} className={`nav-link ${Location === "/report-analytics" ? 'active' : ''}`}>
                        <span className="menu-icon">
                            <Sidebar3 hover={Location.pathname === "/report-analytics" ? true : false} color={Location.pathname.includes("report-analytics") ? '#7F5FEE' : '#8B8B8B'} />
                        </span>
                        <span>Visitors</span>
                    </NavLink> */}
                    <RulesEngineDropDown sidebarToggle={toggle} />
                </li>
                <li className="nav-item">
                    {/* <NavLink to="/report-analytics" onClick={() => setToggle(true)} className={`nav-link ${Location === "/report-analytics" ? 'active' : ''}`}>
                        <span className="menu-icon">
                            <Sidebar3 hover={Location.pathname === "/report-analytics" ? true : false} color={Location.pathname.includes("report-analytics") ? '#7F5FEE' : '#8B8B8B'} />
                        </span>
                        <span>Visitors</span>
                    </NavLink> */}
                    <VisitorsDropDown sidebarToggle={toggle} />
                </li>
                <li className="nav-item" onClick={() => setToggle(true)}>
                    {/* <NavLink to="/administration/user-management" className={`nav-link ${Location === "/administration/user-management" ? 'active' : ''}`}>
                        <span className="menu-icon">
                            <Sidebar4 hover={Location.pathname === "/administration/user-management" ? true : false} color={Location.pathname.includes("administration") ? '#7F5FEE' : '#8B8B8B'} />
                        </span>
                        <span>Administration</span>
                    </NavLink> */}
                    <AdministrationDropDown sidebarToggle={toggle} />
                </li>

                {/* <li className="nav-item">
                    <NavLink to="/vistors-list" className={`nav-link ${Location === "/vistors-list" ? 'active' : ''}`}>
                        <span className="menu-icon">
                            <Sidebar5 hover={Location.pathname === "/vistors-list" ? true : false} color={Location.pathname.includes("vistors-list") ? '#7F5FEE' : '#8B8B8B'} />
                        </span>
                        <span>Visitors List</span>
                    </NavLink>
                </li>

                <li className="nav-item">
                    <NavLink to="/chat" className={`nav-link ${Location === "/chat" ? 'active' : ''}`}>
                        <span className="menu-icon">
                            <Sidebar6 hover={Location.pathname === "/chat" ? true : false} color={Location.pathname.includes("chat") ? '#7F5FEE' : '#8B8B8B'} />
                        </span>
                        <span>Chat</span>
                    </NavLink>
                </li> */}
                {/* <li className="nav-item">
                    <NavLink to="/setting" onClick={() => setToggle(true)} className={`nav-link ${Location === "/setting" ? 'active' : ''}`}>
                        <span className="menu-icon">
                            <Sidebar7 hover={Location.pathname === "/setting" ? true : false} color={Location.pathname.includes("setting") ? '#7F5FEE' : '#8B8B8B'} />
                        </span>
                        <span>Settings</span>
                    </NavLink>
                </li> */}
            </ul>
        </div>
    );
};