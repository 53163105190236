import React, { useContext, useEffect, useState, useMemo, useRef } from "react";
import Breadcrumb from "../common/Breadcrumbs";
import axios from "axios";
import { Path } from "../../helper/path";
import { AuthContext } from "../../_providers/AuthProvider";
import InviteUserModel from "../model/InviteUserModel";
import UserManagementModel from "../../components/model/UserManagementModel";
import actionIcon from "../../assets/images/brand-management/action-Icon.svg";
import { showErrorMessage, showSuccessMessage } from "../../helper/showMessage";
import { FullScreenLoader } from "../common/FullScreenLoader";
import Swal from "sweetalert2";
import { useModal } from "react-modal-hook";
import ReactPaginate from 'react-paginate';
import { ArchiveIcon } from "../../helper/SvgIcons";

export default function UserManagement() {
    const [visibility, setVisibility] = useState(false);
    const [userListing, setUserListing] = useState([]);
    const { session } = useContext(AuthContext);
    const [refresh, setRefresh] = useState(false);
    const [isLoader, setIsLoader] = useState(false);
    const [sortConfig, setSortConfig] = useState({ key: 'createdAt', direction: 'asc' });
    const [pageCount, setPageCount] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [showSetting, setShowSetting] = useState(false);
    const [filter, setFilter] = useState({ name: '', email: '' })

    const fetchUserData = async (pageNumber) => {
        setIsLoader(true);
        try {
            const response = await axios.get(Path.UserManagementListingNew,
                {
                    params: {
                        page: pageNumber,
                        size: 10,
                        sortKey: sortConfig.key,
                        sortOrder: sortConfig.direction,
                        name: filter.name,
                        email: filter.email
                    },
                    headers: {
                        Authorization: "Bearer " + session?.data?.token,
                    },
                }
            );
            setPageCount(response.data.totalPages || 1)
            setUserListing(response?.data?.users);
        } catch (error) {
            setIsLoader(false);
        }
        setIsLoader(false);
    };

    const sortedUserListing = useMemo(() => {
        let sortableItems = [...userListing];
        if (sortConfig !== null) {
            sortableItems.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'asc' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'asc' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableItems;
    }, [userListing, sortConfig]);

    const requestSort = key => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    const getClassNamesFor = name => {
        if (!sortConfig) {
            return;
        }
        return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    const handlePageClick = (data) => {
        const selectedPage = data.selected + 1;
        setCurrentPage(selectedPage);
        fetchUserData(selectedPage);
    };

    useEffect(() => {
        session?.data?.token && fetchUserData(currentPage);
    }, [refresh, session?.data?.token]);

    useEffect(() => {
        if (session?.data?.token) {
            fetchUserData(currentPage);
        }
    }, [currentPage, filter.name, sortConfig]);
    return (
        <>
            {isLoader && <FullScreenLoader />}
            <div className="w-full flex flex-col gap-5">
                <UserManagementSubHeader
                    session={session?.data}
                    visibility={visibility}
                    event={setVisibility}
                    refresh={refresh}
                    setRefresh={setRefresh}
                />
                <div className='flex gap-2'>
                    <button
                        onClick={() => {
                            if (showSetting) {
                                setShowSetting(!showSetting)
                                setFilter({ name: '', email: '' })
                                setCurrentPage(1)
                                fetchUserData(1)
                                setRefresh((e) => !e)
                            } else setShowSetting(true)
                        }}
                        type="button" className="min-w-[127px] text-sm font-medium rounded-lg flex items-center gap-2 border text-gray-6 border-gray-8 hover:text-dark hover:border-dark  h-[33px] px-2"
                    >
                        <ArchiveIcon color={showSetting ? "#2D2D2D" : "#AEAEAE"} />
                        <span style={{ color: showSetting ? "#2D2D2D" : "#AEAEAE" }}>
                            {!showSetting ? `Show Filters` : `Hide Filters`}
                        </span>
                    </button>
                    {
                        showSetting &&
                        <div className="flex items-center gap-2">
                            <input
                                onChange={(event) => setFilter((e) => ({ ...e, name: event.target.value }))}
                                className={'w-60 h-[30px] text-dark text-xs border border-dark rounded-lg px-2'} type={'name'} placeholder={'Enter Name'}
                            />
                            <input
                                onChange={(event) => setFilter((e) => ({ ...e, email: event.target.value }))}
                                className={'w-60 h-[30px] text-dark text-xs border border-dark rounded-lg px-2'} type={'name'} placeholder={'Enter Email'}
                            />
                            <button
                                onClick={() => {
                                    setCurrentPage(1)
                                    fetchUserData(1)
                                }}
                                type={'button'} className={'w-24 h-[30px] text-dark text-sm font-medium rounded-lg border border-dark hover:bg-dark hover:text-white'}
                            >
                                {`Apply`}
                            </button>
                        </div>
                    }
                </div>
                <div className="flex bg-white mt-3 flex-col overflow-y-auto min-h-[500px] w-full">
                    <UserManagementTable
                        sortedUserListing={sortedUserListing} requestSort={requestSort} getClassNamesFor={getClassNamesFor}
                        session={session?.data} refresh={refresh} setRefresh={setRefresh}
                    />
                    <div className='flex justify-end'>
                        <ReactPaginate
                            previousLabel={"<"}
                            nextLabel={">"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={4}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}
                            forcePage={currentPage - 1}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

function UserManagementTable({ sortedUserListing, requestSort, getClassNamesFor, session, refresh, setRefresh }) {
     const [hovered, setHovered] = useState(null);
    return (
        <table className="w-full table-auto text-left mt-2 text-xs md:text-sm">
            <thead className="[&_th]:!border-t-0 [&_th]:!font-medium [&_th]:!p-3 [&_th]:text-[15px] [&_th]:!text-[#7B7B7B] ">
                <tr>
                    <th
                        onMouseEnter={() => setHovered('firstName')}
                        onMouseLeave={() => setHovered(null)}
                        onClick={() => requestSort('firstName')}
                        className="cursor-pointer"
                    >
                        User Name
                        <span>
                            {hovered === 'firstName' ? (
                                getClassNamesFor('firstName') === 'asc' ? <span className='text-[12px]'>▲</span> : <span className='text-[12px]'>▼</span>
                            ) : getClassNamesFor('firstName') === 'asc' ? (
                                <span className='text-[12px]'>▲</span>
                            ) : getClassNamesFor('firstName') === 'desc' ? (
                                <span className='text-[12px]'>▼</span>
                            ) : ''}
                        </span>
                    </th>
                    <th
                        onMouseEnter={() => setHovered('email')}
                        onMouseLeave={() => setHovered(null)}
                        onClick={() => requestSort('email')}
                        className="cursor-pointer"
                    >
                        Email
                        <span>
                            {hovered === 'email' ? (
                                getClassNamesFor('email') === 'asc' ? <span className='text-[12px]'>▲</span> : <span className='text-[12px]'>▼</span>
                            ) : getClassNamesFor('email') === 'asc' ? (
                                <span className='text-[12px]'>▲</span>
                            ) : getClassNamesFor('email') === 'desc' ? (
                                <span className='text-[12px]'>▼</span>
                            ) : ''}
                        </span>
                    </th>
                    <th
                        onMouseEnter={() => setHovered('roles')}
                        onMouseLeave={() => setHovered(null)}
                        onClick={() => requestSort('roles')}
                        className="cursor-pointer"
                    >
                        Role
                        <span>
                            {hovered === 'roles' ? (
                                getClassNamesFor('roles') === 'asc' ? <span className='text-[12px]'>▲</span> : <span className='text-[12px]'>▼</span>
                            ) : getClassNamesFor('roles') === 'asc' ? (
                                <span className='text-[12px]'>▲</span>
                            ) : getClassNamesFor('roles') === 'desc' ? (
                                <span className='text-[12px]'>▼</span>
                            ) : ''}
                        </span>
                    </th>
                    <th
                        onMouseEnter={() => setHovered('invitedBy')}
                        onMouseLeave={() => setHovered(null)}
                        onClick={() => requestSort('invitedBy')}
                        className="cursor-pointer"
                    >
                        Invited By
                        <span>
                            {hovered === 'invitedBy' ? (
                                getClassNamesFor('invitedBy') === 'asc' ? <span className='text-[12px]'>▲</span> : <span className='text-[12px]'>▼</span>
                            ) : getClassNamesFor('invitedBy') === 'asc' ? (
                                <span className='text-[12px]'>▲</span>
                            ) : getClassNamesFor('invitedBy') === 'desc' ? (
                                <span className='text-[12px]'>▼</span>
                            ) : ''}
                        </span>
                    </th>
                    <th
                        onMouseEnter={() => setHovered('status')}
                        onMouseLeave={() => setHovered(null)}
                        onClick={() => requestSort('status')}
                        className="cursor-pointer"
                    >
                        Status
                        <span>
                            {hovered === 'status' ? (
                                getClassNamesFor('status') === 'asc' ? <span className='text-[12px]'>▲</span> : <span className='text-[12px]'>▼</span>
                            ) : getClassNamesFor('status') === 'asc' ? (
                                <span className='text-[12px]'>▲</span>
                            ) : getClassNamesFor('status') === 'desc' ? (
                                <span className='text-[12px]'>▼</span>
                            ) : ''}
                        </span>
                    </th>
                </tr>

            </thead>
            <tbody className="[&_td]:py-4 [&_td]:border-b [&_td]:border-white [&_td]:font-medium [&_td]:px-2 md:[&_td]:px-3">
                {
                    sortedUserListing.map((data, index) => (
                        <Row
                            key={index}
                            data={data}
                            session={session}
                            refresh={refresh}
                            setRefresh={setRefresh}
                        />
                    ))
                }
            </tbody>
        </table>
    )
}

function UserManagementSubHeader({ session, refresh, setRefresh }) {
    const [openSuccesPopUp, hideSuccesPopUp] = useModal(() => (
        <InviteUserModel
            sessionData={session}
            hide={() => {
                setRefresh(!refresh);
                hideSuccesPopUp();
            }}
        />
    ), [refresh]);

    return (
        <div className="w-full flex justify-between items-start">
            <div className="flex flex-col justify-center items-start">
                <Breadcrumb />
                <span className="large-title">User Management</span>
            </div>
            <button
                onClick={openSuccesPopUp}
                className="flex justify-center items-center gap-3 rounded-full btn bg-purple text-white hover:scale-105 duration-300"
            >
                <span>Invite New User</span>
            </button>
        </div>
    );
}

function Row({ data, session, refresh, setRefresh }) {
    const [toggle, setToggle] = useState(false);
    const dropdownRef = useRef(null);

    const [openEditPopUp, hideEditPopUp] = useModal(() => (
        <UserManagementModel mode="edit" hide={() => {
            setRefresh(!refresh);
            hideEditPopUp();
        }} userId={data._id} sessionData={session} refresh={refresh} setRefresh={setRefresh} />
    ), [refresh]);

    const [openViewPopUp, hideViewPopUp] = useModal(() => (
        <UserManagementModel mode="view" hide={hideViewPopUp} userId={data._id} sessionData={session} refresh={refresh} setRefresh={setRefresh} />
    ));

    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setToggle(false);
            }
        }
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    async function Archive(id, type) {
        await axios.get(`${Path.DeleteUserManagementById}${id}?status=${type}`,
            {
                headers: {
                    Authorization: "Bearer " + session?.token,
                }
            }
        ).then((success) => {
            showSuccessMessage(success?.data?.message);
            setRefresh(!refresh);
            setToggle(null);
        }).catch((error) => {
            console.log(error?.response?.data);
            setToggle(null);
            showErrorMessage(error?.response?.data?.error || 'Something Went Wrong.');
        });
    }

    async function Delete(id) {
        await axios.get(`${Path.DeleteUserManagementById}${id}?status=DELETED`,
            {
                headers: {
                    Authorization: "Bearer " + session?.token,
                }
            }
        ).then((success) => {
            showSuccessMessage(success?.data?.message);
            setRefresh(!refresh);
            setToggle(null);
        }).catch((error) => {
            console.log(error?.response?.data);
            setToggle(null);
        });
    }

    async function ResendInvite(id) {
        await axios.put(`${Path.resendInviteUser}${id}`,
            {},
            {
                headers: {
                    Authorization: "Bearer " + session?.token,
                },
            }
        ).then((success) => {
            showSuccessMessage(success?.data?.message);
            setRefresh(!refresh);
            setToggle(null);
        }).catch((error) => {
            console.log(error?.response?.data);
            setToggle(null);
        });
    }

    return (
        <tr key={data?._id} className="bg-grey-4">
            <td>
                {data?.firstName}{" "}{data?.lastName}
            </td>
            <td className="">
                {data?.email}
            </td>
            <td className="">
                {data?.roles}
            </td>
            <td className="">
                {data?.invitedBy}
            </td>
            <td className={data?.status === "ACTIVE" ? "text-green" : data?.status === "ARCHIVE" ? "text-yellow" : "text-red-color"}>
                {data?.status}
            </td>
            <td className="text-center relative" ref={dropdownRef}>
                <div className="flex items-center justify-center">
                    <button className="text-center " onClick={() => setToggle(!toggle)}>
                        <img width={30} height={30} className="mx-auto" src={actionIcon} alt='' />
                    </button>
                </div>
                <div className={`bg-white absolute right-8 z-10 ${toggle ? 'block' : 'hidden'}`}>
                    <ul className="text-sm w-[188px] rounded text-left shadow-[2px_4px_7px_4px_rgba(0,0,0,0.07)]">
                        {data?.status !== "PENDING" &&
                            <li onClick={openEditPopUp} className=" font-medium hover:font-bold hover:text-dark hover:bg-[#EAE3FF] text-[#A1A1A1] transition-all py-2 px-4 cursor-pointer">
                                Edit
                            </li>
                        }
                        {data?.status === "ACTIVE" ?
                            <li
                                className="cursor-pointer font-medium hover:font-bold hover:text-dark hover:bg-[#EAE3FF] text-[#A1A1A1] transition-all py-2 px-4"
                                onClick={() => Archive(data?._id, "ARCHIVE")}
                            >
                                Archive
                            </li>
                            :
                            data?.status === "ARCHIVE" ?
                                <li
                                    className="cursor-pointer font-medium hover:font-bold hover:text-dark hover:bg-[#EAE3FF] text-[#A1A1A1] transition-all py-2 px-4"
                                    onClick={() => Archive(data?._id, "ACTIVE")}
                                >
                                    Active
                                </li>
                                : ""
                        }
                        {data?.status === "PENDING" &&
                            <li className=" font-medium hover:font-bold hover:text-dark hover:bg-[#EAE3FF] text-[#A1A1A1] transition-all py-2 px-4 cursor-pointer" onClick={() => ResendInvite(data?._id)}>
                                Resend
                            </li>
                        }
                        <li className=" font-medium hover:font-bold hover:text-dark hover:bg-[#EAE3FF] text-[#A1A1A1] transition-all py-2 px-4 cursor-pointer" onClick={() => {
                            if (session?.userInfo?.email === data?.email) {
                                showErrorMessage("You can't delete yourself!")
                            } else {
                                Swal.fire({
                                    title: `Are you sure you want to delete user?`,
                                    text: "You won't be able to revert this!",
                                    icon: "warning",
                                    showCancelButton: true,
                                    confirmButtonColor: "#7F5FEE",
                                    cancelButtonColor: "#F86E6E",
                                    confirmButtonText: "Yes"
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        Delete(data?._id);
                                    }
                                });
                            }
                        }}>
                            Delete
                        </li>
                        {data?.status !== "PENDING" &&
                            <li onClick={openViewPopUp} className="font-medium hover:font-bold hover:text-dark hover:bg-[#EAE3FF] text-[#A1A1A1] transition-all py-2 px-4 cursor-pointer">
                                View
                            </li>
                        }
                    </ul>
                </div>
            </td>
        </tr>
    );
}
