import { Icon } from '@iconify/react'
import React, { useContext, useEffect, useState } from 'react'
import { AddTagsIcon, SettingsPlusIcon } from '../../helper/SvgIcons';
import { object, string } from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import { BrandVoiceModelValidation, campaignSettingValidation } from '../../helper/Messages';
import axios from 'axios';
import { Path } from '../../helper/path';
import { showErrorMessage, showSuccessMessage } from '../../helper/showMessage';
import { AuthContext } from '../../_providers/AuthProvider';
import { useNavigate } from 'react-router-dom';

export default function CampaignSettings({ id }) {
    const [refresh, setRefresh] = useState(false);
    // const [tags, setTags] = useState([]);
    const [list, setList] = useState([])
    const { session } = useContext(AuthContext)
    const navigate = useNavigate()
    const [tagLoader, setTagLoader] = useState(false)
    // const [tagsError, setTagsError] = useState(null);

    const validationSchema = object().shape({
        campaignName: string().trim().required(campaignSettingValidation?.campaignNameRequired),
        ownerName: string().trim().required(campaignSettingValidation?.brandNameRequired),
        tags: string().optional(),
        associatName: string().trim().required(campaignSettingValidation?.associatNameRequried),
        steps: string().trim().required(campaignSettingValidation?.stepsRequried),

    });
    const { register: settingCredential, handleSubmit, setValue, formState: { errors } } = useForm({ resolver: yupResolver(validationSchema) });


    // const TagsCreater = () => {
    //     const inputElement = document.getElementById('tagsInput');
    //     const tagValue = inputElement.value.trim();
    //     if (tagValue !== "") {
    //         setTagLoader(true)
    //         let update = [...tags];
    //         if (!update.includes(tagValue)) {
    //             update.push(tagValue);
    //             setTags(update);
    //             inputElement.value = '';
    //             setTagLoader(false)
    //         } else {
    //             setTagLoader(false)
    //             showErrorMessage("Value already exists");
    //         }
    //     }
    // };

    // const TagsDelete = (index) => {
    //     let update = [...tags]
    //     update.splice(index, 1)
    //     setTags(update)
    // }

    const onSubmit = async (data, event) => {
        event.preventDefault();
        // if (tags.length === 0) {
        //     setTagsError(campaignSettingValidation?.tagsRequired);
        //     return; // Prevent form submission
        // }

        await axios.put(`${Path.GetCampaignByID}${id}`,
            {
                name: data?.campaignName,
                // tags: tags,
            },
            {
                headers: {
                    Authorization: "Bearer " + session?.data?.token,
                }
            }
        ).then((success) => {
            showSuccessMessage(success?.data?.message)
            navigate("/campaigns")
        }).catch((error) => {
            showErrorMessage(error?.response?.data?.error || 'Something Went Wrong.')
        })
    };

    useEffect(() => {
        async function GetData() {

            await axios.get(`${Path.GetCampaignByID}${id}`,
                {
                    headers: {
                        Authorization: "Bearer " + session?.data?.token,
                    }
                }
            ).then((success) => {
                setValue("campaignName", success?.data?.name)
                setValue("associatName", success?.data?.brandId?._id)
                // setTags(...tags, success?.data?.tags)
                setValue("ownerName", success?.data?.createdBy?.firstName)
            }).catch((error) => {
                console.log(error)
            })
        }
        async function GetListData() {
            await axios.get(Path.BrandVoiceActive,
                {
                    headers: {
                        Authorization: "Bearer " + session?.data?.token,
                    }
                }
            ).then((success) => {
                setList(success?.data)
            }).catch((error) => {
                console.log(error?.response?.data?.error)
            })
        }

        GetData();
        GetListData();
    }, [refresh, session?.data?.token])


    return (
        <form onSubmit={handleSubmit(onSubmit)} onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
            className="bg-white p-5 grid grid-cols-2 items-center gap-5">
            <div className="col-span-auto">
                <label className="custom-label">
                    {`Name of Campaign`}
                </label>
                <input
                    className={errors?.campaignName ? "custom-input !border !border-[#FF1D48] !rounded-lg" : "custom-input !rounded-lg "}
                    type="text" name="campaignName" placeholder="Welcome Campaign"
                    {...settingCredential("campaignName")}
                />
                {
                    errors.campaignName && <div className="error-css">{errors?.campaignName?.message}</div>
                }
            </div>
            <div className="col-span-auto">
                <label className="custom-label">
                    {`Owner`}
                </label>
                <input readOnly className={errors?.ownerName ? "custom-input !border !border-[#FF1D48] !rounded-lg" : "custom-input !rounded-lg "} type="text" name="owner" placeholder="username"
                    {...settingCredential("ownerName")}
                />
                {
                    errors.ownerName && <div className="error-css">{errors?.ownerName?.message}</div>
                }
            </div>
            {/* <div className="col-span-2">
                <label className="custom-label">
                    {`Tags`}
                </label>

                <div className={`w-full flex flex-wrap justify-start items-center gap-1 ${tagsError ? 'border border-red-color' : ''} 
        border border-light-grey-2 rounded-lg p-1`}>
                    {

                        <>
                            <div className=' flex flex-wrap items-center gap-3 p-1'>
                                {
                                    tags?.length !== 0 && tags?.map((data, index) => {
                                        return (
                                            <div key={index} className='flex items-center gap-2  rounded-md p-1 px-2 text-green font-[600] bg-light-green'>
                                                <span>
                                                    {data}
                                                </span>
                                                <Icon onClick={() => TagsDelete(index)} className='text-black text-md cursor-pointer' icon={'radix-icons:cross-2'} />
                                            </div>
                                        );
                                    })
                                }
                                <div className='flex items-center w-[150px] !h-[36px] gap-2  px-2 rounded-md'>
                                    <input id="tagsInput" className="w-full h-[full] outline-none" type="text" onChange={() => setTagsError(null)} placeholder="Type here..." />
                                    <span className="cursor-pointer" onClick={TagsCreater}>
                                        {
                                            tagLoader ? <Icon className="text-green" icon={'svg-spinners:tadpole'} /> : <AddTagsIcon />
                                        }
                                    </span>
                                </div>
                            </div>
                        </>
                    }
                </div>
                {
                    tagsError && <div className="error-css">{tagsError}</div>
                }
            </div> */}



            {list?.length ? <div className="col-span-2">
                <label className="custom-label">
                    {`Associated Brand`}
                </label>
                <select disabled {...settingCredential("associatName")} className={errors?.associatName ? "custom-input !rounded-lg bg-no-repeat bg-[99%] bg-[length:2rem] bg-[url('/src/assets/images/common/Arrow-Down.svg')] appearance-none !border  !border-[#FF1D48]" : "custom-input !rounded-lg bg-no-repeat bg-[99%] bg-[length:2rem] bg-[url('/src/assets/images/common/Arrow-Down.svg')] appearance-none "
                }>
                    <option disabled selected>Add Contact</option>
                    {
                        list?.length !== 0 && list?.map((data, index) => {
                            return (
                                <option key={index} value={data?._id}>
                                    {data?.name}
                                </option>
                            );
                        })
                    }
                </select>
                {
                    errors.associatName && <div className="error-css">{errors?.associatName?.message}</div>
                }
            </div> : <></>}
            <div className="col-span-2">
                <label className="custom-label">
                    {`Steps`}
                </label>
                <textarea {...settingCredential("steps")} cols={2} rows={2} value={"Email Sequences"} className="custom-input text-purple font-semibold !border-none h-full" placeholder="" />
            </div>
            <div className="col-span-2 mt-10 flex justify-end items-center gap-6">
                <button onClick={() => navigate("/campaigns")} type="button" className="px-4 flex gap-1 items-center border-r border-[#7B7B7B] text-neutral-500 ">
                    <span className="text-sm font-bold">Cancel</span>
                </button>
                <button type="submit" className="btn py-3 text-sm !bg-green">Save Settings</button>
            </div>
        </form>
    )
}