
export const Validation = {
    emailRequired: "Please enter email",
    emailInvalid: "Email is not valid",
    passwordRequired: "Please enter password",
    passwordInvalid: "Minimum 8 characters, must contain one number, one special character, one capital letter and one small letter",
    p_NotSame: "Password doesn't match",
    p_ReEnter: "Please enter password again",
    firstNameRequired: "Please enter first name",
    APIRequired: "Please enter api key",
    UserNameRequired: "Please enter Username or Email",
    WebhookSecretRequired: "Please enter webhook secret",
    SenderEmailRequired: "Please enter sender email",
    lastNameRequired: "Please enter last name",
    fullNameInvalid: "Contain only characters",
    resetPasswordSuccess: "Password reset successfully",
    role: "Select the role",
    urlInvalid: "Please enter valid URL",
    urlRequired: "Please enter URL link",
    selectedValue: "Please enter selected type value",
    nameRequired: "Please enter name",
    descriptionRequired: "Please enter description",
    typeRequired: "Please select type",
    campaignRequired: "Please select Campaign",
    ruleNameRequired: "Please enter name",
    fieldRequired: "Please select Filed",
    valueRequired: "Please enter Value",

};

export const UserManagementModalValidation = {
    firstNameRequired: "Please enter first name",
    firtsNameInvalid: "Contain only characters",
    lastNameRequired: "Please enter last name",
    lastNameInvalid: "Contain only characters",
    emailRequired: "Please enter email",
    emailInvalid: "Email is not valid",
    roleRequired: "Please enter role",
    associatedBrandRequired: "Please enter associatedBrand",
};

export const BrandKnowledgeBaseModelValidation = {
    nameRequired: 'Please enter name',
    contentRequired: 'Please enter background information',
    tagsRequired: 'Please add at least one tag',
    FileRequired: 'Please upload file',
    urlRequired: "Please enter URL link",
    urlInvalid: "Please enter valid URL"
}
export const BrandVoiceModelValidation = {
    nameRequired: 'Please enter name',
    brandRequired: 'Please enter brand',
    FileRequired: 'Please upload file',
    urlRequired: "Please enter URL link",
    urlInvalid: "Please enter valid URL in this format: http://www.ayecampaign.ai"

}
export const addNewCampaignModelValidation = {
    campaignNameRequired: 'Please enter campaign name',
    brandRequired: 'Please enter brand'
}
export const campaignSettingValidation = {
    campaignNameRequired: 'Please enter campaign name',
    brandNameRequired: 'Please enter barnd name',
    tagsRequired: 'Please add at least one tag',
    associatNameRequried: 'Please enter associate name',
    stepsRequried: 'Please enter the steps',
    domainRequried: 'Please enter the domain',
    domainInvalid: 'Domain is not valid',
}

export const regex = {
    passRegex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z\d\s]).{8,}$/,
    nameRegex: /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/,
    emailRegex: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
    urlRegex: /^(ftp|http|https):\/\/[^ "]+$/,
    domainRegex: /^(?:https?:\/\/)?(?:www\.)?((?:[a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.[a-zA-Z]{2,})\/?$/,
};

export const content = {
    tooltip: "You must accept the terms and conditions",
}