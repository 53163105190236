import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import inviteIcon from '../assets/images/common/InviteIcon.svg';

export function showSuccessMessage(message) {
    toast.success(message, {
        theme: "colored",
        style: {
            color: "#ffffff", // Set the text color for success messages
            background: "#7F5FEE", // Set the background color for success messages
            fontSize: "14px"
        },
    });
}

export function EmailToast(message) {
    toast.success(message, {
        theme: "colored",
        style: {
            color: "#ffffff", // Set the text color for success messages
            background: "#7F5FEE", // Set the background color for success messages
            width: "350px",
            right: "30px",
            fontSize: "14px"
        },
    });
}


export function showErrorMessage(message) {
    toast.error(message, {
        theme: "colored",
        fontSize: "14px"
    });
}

export function InviteSuccessMessage() {
    toast.success(
        <div className="flex gap-4 items-center absolute z-99 inset-0 p-2 bg-emerald-50 shadow w-full pl-4">
            <span>
                <img src={inviteIcon} alt="invite" />
            </span>
            <div className="">
                <h6 className="text-zinc-800 text-base font-bold">Invitation sent successfully!</h6>
            </div>
        </div>,
        {
            hideProgressBar: true,
            closeButton: false,
            position: toast.POSITION.TOP_CENTER,
            className: "invite-success-width",
        }
    )
}