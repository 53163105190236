import React, { useState } from 'react'
import backIcon from "../../assets/images/campaigns/backbutton.svg"
import emailIcon from '../../assets/images/campaigns/email-box.svg'
import textIcon from '../../assets/images/campaigns/text-sms.svg'
import linkedinIcon from '../../assets/images/campaigns/linked-in.svg'
import axios from 'axios'
import { Path } from '../../helper/path'
import { showErrorMessage, showSuccessMessage } from '../../helper/showMessage'
import { Icon } from '@iconify/react'


export default function OnboardingCampaignModel({ hide, id, session, navigate }) {
    const [selectedType, setSelectedType] = useState(null);
    const [isLoader, setIsLoader] = useState(false)

    const onSubmit = async () => {
        if (selectedType) {
            try {
                setIsLoader(true)
                await axios.post(
                    Path.addStepCampaign,
                    {
                        type: selectedType,
                        campaignId: id,
                    },
                    {
                        headers: {
                            Authorization: 'Bearer ' + session?.data?.token,
                        }
                    }
                ).then((success) => {
                    showSuccessMessage(success?.data?.message);
                    navigate(`/campaigns/onboarding-campaign/email-step?id=${id}&stepId=${success?.data?.stepDetails?._id}`)
                    setIsLoader(false)
                }).catch((error) => {
                    console.log(error)
                    setIsLoader(false)
                    showErrorMessage(error?.response?.data?.error || 'Something Went Wrong.')
                })


            } catch (error) {
                console.error(error);
            }
        } else {
            showErrorMessage('Please select at least one checkbox before submitting.');
        }
    };

    const handleCheckboxChange = (type) => {
        setSelectedType(type);
    };

    return (
        <div className="bg-dark/50 absolute inset-0 flex items-center justify-center px-4 z-10">
            <div className="bg-white border border-stone-300 p-8">
                <h2 className="text-center large-title">Add steps to your ‘Onboarding Campaign’</h2>
                {/* Popup Header */}
                <from className="flex justify-between h-[30dvh]">
                    <div className="flex items-center gap-5">
                        <label className="step-check">
                            <img className="w-[40px] h-[40px] mb-2" src={emailIcon} alt="" />
                            <h5 className="font-medium text-dark text-sm">Email</h5>
                            <p className="text-zinc-400 font-medium text-xs mt-1">To edit, create and send emails.</p>
                            <input className="absolute h-5 w-5 right-6 top-9 accent-purple" type="checkbox" name="email-step" id="email"
                                checked={selectedType === STEPTYPES.mailType}
                                onChange={() => handleCheckboxChange(STEPTYPES.mailType)}
                            />
                        </label>

                        <label className="step-check">
                            <img className="w-[40px] h-[40px] mb-2" src={textIcon} alt="" />
                            <h5 className="font-medium text-dark text-sm"> Text SMS
                                <span className="text-zinc-400 text-xs"> (Coming soon)</span>
                            </h5>
                            <p className="text-zinc-400 font-medium text-xs mt-1">To edit and send text message.</p>
                            <input disabled className="absolute h-5 w-5 right-6 top-9 accent-purple" type="checkbox" name="text-step"
                                id="text" checked={selectedType === STEPTYPES.textType}
                                onChange={() => handleCheckboxChange(STEPTYPES.textType)} />
                        </label>

                        <label className="step-check">
                            <img className="w-[40px] h-[40px] mb-2" src={linkedinIcon} alt="" />
                            <h5 className="font-medium text-dark text-sm"> LinkedIn Message
                                <span className="text-zinc-400 text-xs"> (Coming soon)</span>
                            </h5>
                            <p className="text-zinc-400 font-medium text-xs mt-1">To edit and send text message.</p>
                            <input disabled className="absolute h-5 w-5 right-6 top-9 accent-purple" type="checkbox" name="linkedin-step" id="linkedin" checked={selectedType === STEPTYPES.linkedType}
                                onChange={() => handleCheckboxChange(STEPTYPES.linkedType)} />
                        </label>
                    </div>
                </from>

                {/* Popup Footer */}
                <div className="flex justify-end items-center gap-6">
                    <button onClick={hide} type="button" className="px-4 flex gap-2 items-center border-r border-[#7B7B7B] text-neutral-500 text-sm font-bold">
                        <img className="w-[18x] h-[18px]" src={backIcon} alt="" />
                        <span>Back</span>
                    </button>
                    <button onClick={() => { onSubmit(); }} type="submit" className={`flex items-center gap-2 bg-green text-white rounded-full py-1 text-sm h-10 px-10 font-[600]`}>
                        {
                            <span>{`Continue`}</span>
                        }
                        {
                            isLoader && <Icon icon={'svg-spinners:tadpole'} />
                        }
                    </button>
                </div>
            </div>
        </div>
    )
}


const STEPTYPES = {
    mailType: "MANUAL-EMAIL",
    textType: "TEXT-MESSAGE",
    linkedType: "LINKED-IN-MESSAGE",
};