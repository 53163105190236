import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Path } from '../../helper/path';
import { Icon } from '@iconify/react';

function ConditionModel({ conditionModel, setConditionModel, session }) {
    const [dynamicVarList, setDynamicVarList] = useState([]);

    const OperatorList = [
        {
            name: "isEqual",
            isSingleOperand: false,
        },
        {
            name: "isNotEqual",
            isSingleOperand: false,
        },
        {
            name: "greaterThan",
            isSingleOperand: false,
        },
        {
            name: "isTruthy",
            isSingleOperand: true,
        },
        {
            name: "isFalsy",
            isSingleOperand: true,
        }
    ];

    function AddVariable(e) {
        e.preventDefault();
        const condition = e.target.type.value === "1"
        ? `{{#${e.target.operator.value} ${e.target.variable.value} "${e.target.var.value}"}} true Content here  {{else}} false content here {{/${e.target.operator.value}}}`
        : `{{#${e.target.operator.value} ${e.target.variable.value} "${e.target.var.value}"}} true Content here {{/${e.target.operator.value}}}`

        conditionModel.event.insertHTML(condition)
        setConditionModel({ status: false, event: null })
    }

    useEffect(() => {
        async function GetDynamicVarList() {
            await axios.get(Path.GetDynamicVarList,
                {
                    headers: {
                        Authorization: "Bearer " + session?.data?.token,
                    }
                }
            ).then(async (success) => {
                setDynamicVarList(success?.data)
            }).catch((error) => {
                console.log(error?.response?.data?.error)
            })
        }
        conditionModel.status && session && GetDynamicVarList()
    }, [conditionModel.status, session])
    return (
        <div className={`fixed top-0 left-0 z-[9999] w-screen h-screen flex bg-[rgba(255,255,255,0.2)] backdrop-blur-[2px] justify-center items-center p-10`}>
            <form onSubmit={AddVariable} className='w-[450px] flex flex-col gap-5 bg-white rounded-lg shadow-2xl p-5'>
                <div className='w-full flex justify-between items-center'>
                    <div className='text-xl font-semibold text-purple'>
                        {`Add Condition`}
                    </div>
                    <Icon onClick={() => setConditionModel({ status: false, event: null })} className='text-2xl text-black cursor-pointer' icon={'gridicons:cross-circle'} />
                </div>
                <div className='w-full flex flex-col gap-4'>
                    <div className='w-full flex flex-col gap-1'>
                        <span className='text-sm lg:text-base font-semibold'>
                            {`Type`}
                        </span>
                        <select name='type' className='w-full h-[40px] outline-none border border-gray-300 rounded-lg px-3 bg-white cursor-pointer' defaultValue={''}>
                            <option value="">Select Type</option>
                            <option value="0">if endif</option>
                            <option value="1">if else endif</option>
                        </select>
                    </div>
                    <div className='w-full flex flex-col gap-1'>
                        <span className='text-sm lg:text-base font-semibold'>
                            {`Operators`}
                        </span>
                        <select
                            onChange={async (e) => {
                                const data = await Promise.all(OperatorList.filter((items) => items.name === e.target.value))
                                const elem = document.getElementById('dy-value')
                                elem.disabled = data[0].isSingleOperand
                                elem.value = ''
                            }}
                            name='operator' className='w-full h-[40px] outline-none border border-gray-300 rounded-lg px-3 bg-white cursor-pointer' defaultValue={''}
                        >
                            <option value="">Select Operators</option>
                            {
                                OperatorList?.length !== 0 && OperatorList?.map((items, index) => {
                                    return <option key={index} value={items.name}>{items.name}</option>
                                })
                            }
                        </select>
                    </div>
                    <div className='w-full flex flex-col gap-1'>
                        <span className='text-sm lg:text-base font-semibold'>
                            {`Variable`}
                        </span>
                        <select name='variable' className='w-full h-[40px] outline-none border border-gray-300 rounded-lg px-3 bg-white cursor-pointer' defaultValue={''}>
                            <option value="">Select Variable</option>
                            {
                                dynamicVarList?.length !== 0 && dynamicVarList?.map((items, index) => {
                                    return <option key={index} value={items}>{items}</option>
                                })
                            }
                        </select>
                    </div>
                    <div className='w-full flex flex-col gap-1'>
                        <span className='text-sm lg:text-base font-semibold'>
                            {`Enter Value`}
                        </span>
                        <input
                            id='dy-value'
                            className='w-full h-[40px] outline-none border border-gray-300 rounded-lg px-3 bg-white'
                            name='var' type='text' placeholder='For Example: CEO, Marketing, 18, New York'
                        />
                    </div>
                    <div className='w-full flex items-center gap-2'>
                        <button onClick={() => setConditionModel({ status: false, event: null })} type='button' className='w-1/2 h-[40px] bg-red rounded-lg text-white font-semibold hover:bg-white hover:border-2 hover:border-red hover:text-red'>
                            {`Cancel`}
                        </button>
                        <button type='submit' className='w-1/2 h-[40px] bg-green rounded-lg text-white font-semibold hover:bg-white hover:border-2 hover:border-green hover:text-green'>
                            {`Add`}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default ConditionModel;