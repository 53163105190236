import NewLogo from '../../src/assets/images/common/campaign-logo.svg'
import SignUpForm from '../components/SignUpForm';


export default function SignUp() {
    return (
        <div className='w-full h-[100dvh] flex'>
            <div className='w-[161px] min-h-full flex flex-col justify-start items-center gap-1 bg-login !bg-cover !bg-center !bg-no-repeat pt-10'>
                <img width={67} height={52} src={NewLogo} alt='' />
                <span className="text-white text-lg font-normal font-['Baloo']">Aye Campaigns</span>
            </div>
            <SignUpForm />
        </div>
    );
};