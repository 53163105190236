import React, { useContext, useState } from 'react'
import { Icon } from '@iconify/react';
import backIcon from "../../assets/images/campaigns/backbutton.svg";
import { object, string } from 'yup';
import { set, useForm } from 'react-hook-form';
import { Validation } from '../../helper/Messages';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import { Path } from '../../helper/path';
import axios from 'axios';
import { AuthContext } from '../../_providers/AuthProvider';
import { showErrorMessage, showSuccessMessage } from '../../helper/showMessage';

export default function NewContactListModel({ visibility, event }) {
    const { session } = useContext(AuthContext)
    const [isLoader, setIsLoader] = useState(false)
    const navigate = useNavigate()
    const validationSchema = object().shape({
        name: string().trim().required(Validation?.nameRequired),
        description: string().trim().required(Validation?.descriptionRequired),
    });

    const { register: contactCredential, handleSubmit, formState: { errors } } = useForm({ resolver: yupResolver(validationSchema) });
    const onSubmit = async (data) => {
        setIsLoader(true)
        await axios.post(Path.createNewList,
            {
                title: data.name,
                description: data.description,
            },
            {
                headers: {
                    Authorization: "Bearer " + session?.data?.token,
                }
            }
        ).then((success) => {
            // showSuccessMessage(success?.data?.message)
            event(!visibility)
            navigate(`/administration/contact/new-list?id=${success?.data?._id}`)
            setIsLoader(false)
        }).catch((error) => {
            event(!visibility)
            setIsLoader(false)
            showErrorMessage(error?.message || 'Something Went Wrong.')
        })
    }
    return (
        <div className={`${visibility ? 'block' : 'hidden'} bg-black/40 absolute z-20 inset-0 flex items-center`}>
            <div className="bg-white border border-gary-3 mx-auto max-w-[750px] w-full p-6">
                {/* Popup Header */}
                <h4 className="font-bold text-center text-dark text-xl h-10 md:h-16">New Contact List</h4>
                <form onSubmit={handleSubmit(onSubmit)} className="text-left">
                    {/* Popup Main Section */}
                    <div className="col-span-2 mt-4">
                        <label className="custom-label">
                            {`Name of List`}
                        </label>
                        <input type="text" placeholder="Name of list"
                            className={
                                errors.name ? 'custom-input !rounded-lg !border !border-[#FF1D48]' :
                                    'custom-input !rounded-lg'}
                            {...contactCredential("name")} />
                        {
                            errors.name && <div className="error-css">{errors?.name?.message}</div>
                        }
                    </div>
                    <div className="col-span-2 mt-4">
                        <label className="custom-label">
                            {`Description`}
                        </label>
                        <textarea
                            cols={4}
                            rows={4}
                            placeholder="Description"
                            className={
                                errors.name ? 'custom-input !rounded-lg !border !border-[#FF1D48]' :
                                    'custom-input !rounded-lg'}
                            {...contactCredential("description")} />
                        {
                            errors.description && <div className="error-css">{errors?.description?.message}</div>
                        }
                    </div>
                    {/* Popup Footer */}
                    <div className="mt-10 flex justify-end items-center gap-6">
                        <button onClick={() => event(!visibility)} type="button" className="px-4 flex gap-1 items-center border-r border-[#7B7B7B] text-neutral-500 ">
                            <img src={backIcon} alt="" />
                            <span className="text-sm font-bold">Back</span>
                        </button>
                        <button type='submit' className={`flex gap-2 items-center bg-green text-white rounded-full py-1 text-sm h-10 px-16 font-[600]`}>
                            {
                                <span>
                                    {`Save`}
                                </span>
                            }
                            {
                                isLoader && <Icon icon={'svg-spinners:tadpole'} />
                            }
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}
