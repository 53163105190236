import React, { useEffect, useState } from 'react'
import { Validation } from '../../helper/Messages';
import { object, string } from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { Path } from '../../helper/path';
import { Icon } from '@iconify/react/dist/iconify.js';
import { showSuccessMessage } from '../../helper/showMessage';

export default function AddContactListModel({ hide, session, campaignId }) {
    const [listData, setListData] = useState(null);
    const [isLoader, setIsLoader] = useState(false);
    const validationSchema = object().shape({
        list: string().trim().required("Please select list"),
    });

    const {
        register: userModalCredential,
        handleSubmit,
        formState: { errors },
    } = useForm({ resolver: yupResolver(validationSchema) });

    function GetAllList() {
        axios
            .get(`${Path.GetAllList}`, {
                headers: {
                    Authorization: "Bearer " + session?.data?.token,
                },
            })
            .then((success) => {
                setListData(success?.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    useEffect(() => {
        GetAllList();
    }, [session?.data?.token]);

    const onSubmit = async (data, e) => {
        e.preventDefault();
        try {
            setIsLoader(true);
            await axios.post(`${Path.AddListToCampaign}`, {
                listId: data?.list,
                campaignId: campaignId
            }, {
                headers: {
                    Authorization: "Bearer " + session?.data?.token,
                },
            })
            showSuccessMessage("Contacts imported successfully!!");
            hide(true);
            setIsLoader(false);
        } catch (error) {
            console.log(error);
            setIsLoader(false);
        }
    };

    return (
        <div
            className={`bg-black/40 absolute z-20 inset-0 flex items-center overflow-y-hidden`}>
            <div className="bg-white border border-gary-3 mx-auto max-w-[764px] w-full p-6">
                <h4 className="font-bold text-center text-dark text-xl h-10 md:h-16">
                    Select List
                </h4>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="col-span-2">
                        <label className="custom-label">{`Select the List`}</label>
                        <select
                            {...userModalCredential("list")}
                            className={
                                errors?.list
                                    ? `custom-input !text-grey-3 bg-[url('/src/assets/images/dashboard/select-down-icon.svg')] bg-no-repeat bg-[92.5%] bg-[length:1.7rem] bg-transparent appearance-none !border !border-[#FF1D48]`
                                    : `custom-input !text-grey-3 bg-[url('/src/assets/images/dashboard/select-down-icon.svg')] bg-no-repeat bg-[97.5%] bg-[length:1.7rem] bg-transparent appearance-none`
                            }>
                            <option value="" disabled selected>
                                Select List
                            </option>
                            {listData &&
                                listData.map((item) => (
                                    <option key={item._id} value={item._id}>
                                        {item.title}
                                    </option>
                                ))}
                        </select>
                        {errors.list && (
                            <div className="error-css">{errors?.list?.message}</div>
                        )}
                    </div>
                    <div className="mt-8 flex justify-end items-center gap-6">
                        <button
                            onClick={() => {
                                hide(false)
                            }}
                            type="button"
                            className="px-4 flex gap-1 items-center border-r border-[#7B7B7B] text-neutral-500 ">
                            <span className="text-sm font-bold">Cancel</span>
                        </button>
                        <button
                            type="submit"
                            className={`flex  gap-2 items-center bg-green text-white rounded-full py-1 text-sm h-10 px-16 font-[600] font-base`}>
                            <span>{`Import`}</span>
                            {isLoader && <Icon icon={"svg-spinners:tadpole"} />}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}
