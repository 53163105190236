import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { Path } from '../../helper/path';
import axios from 'axios';
import { AuthContext } from '../../_providers/AuthProvider';
import { showSuccessMessage } from '../../helper/showMessage';
import { useModal } from 'react-modal-hook';

import BrandVoiceTextModel from './BrandVoiceTextModel';
import BrandVoiceLinkModel from './BrandVoiceLinkModel';
import BrandVoiceFileModel from './BrandVoiceFileModel';
import { Icon } from '@iconify/react/dist/iconify.js';

export default function EditBrandVoiceModel() {
    const [data, setData] = useState(null)
    const { session } = useContext(AuthContext);
    const [toggle, setToggle] = useState(null)
    const [refresh, setRefresh] = useState(false)
    const navigate = useNavigate();



    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const id = searchParams.get('id');

    async function GetData() {
        await axios.get(`${Path.GetBrandVoiceDataByID}${id}`,
            {
                headers: {
                    Authorization: "Bearer " + session?.data?.token,
                }
            }
        ).then((success) => {
            setData(success?.data)
            setRefresh(!refresh)
            setToggle(null)
        }).catch((error) => {
            console.log(error)
        })
    }

    const [openTextPopUp, hideTextPopUp] = useModal(() => (
        <BrandVoiceTextModel hide={hideTextPopUp} id={id || ''} refresh={refresh} setRefresh={setRefresh} setToggle={setToggle} session={session} navigate={navigate} EditGetData={GetData} />
    ), [id, refresh, setRefresh]);

    const [openLinkPopUp, hideLinkPopUp] = useModal(() => (
        <BrandVoiceLinkModel hide={hideLinkPopUp} id={id || ''} refresh={refresh} setRefresh={setRefresh} setToggle={setToggle} session={session} navigate={navigate} EditGetData={GetData} />
    ), [id, refresh, setRefresh]);

    const [openFilePopUp, hideFilePopUp] = useModal(() => (
        <BrandVoiceFileModel hide={hideFilePopUp} id={id || ''} refresh={refresh} setRefresh={setRefresh} setToggle={setToggle} session={session} navigate={navigate} EditGetData={GetData} />
    ), [id, refresh, setRefresh]);



    useEffect(() => {
        GetData();
    }, [session?.data?.token])

    return (<>
        <div className='py-2 text-purple flex items-center gap-1 cursor-pointer' onClick={() => window.history.back()}>
            <Icon
                icon="la:arrow-left"
                className="icon-color"
                width={16}
                height={16}
            />
            <span className='text-sm '>{"Back"}</span>
        </div>
        <div className="flex gap-4 justify-between items-center mb-4">
            <div>
                <h4 className="font-bold text-dark text-base md:text-[20px]">Brand Voice and Tone</h4>
            </div>
            <button className="btn !bg-purple" onClick={() => {
                setToggle(null)
                if (data?.type === 'TEXT') openTextPopUp()
                else if (data?.type === 'URL') openLinkPopUp()
                else if (data?.type === 'FILE') openFilePopUp()
            }}>Edit</button>
        </div>

        <div className="bg-white p-6">
            <div className="w-full flex justify-between gap-2">
                <div className="basis-1/4 font-medium">
                    <h4 className="text-dark text-base md:text-[17px]">{data?.name}</h4>
                    <p className="text-[#7B7B7B] text-sm md:text-[15px]">Name</p>
                </div>
                <div className="basis-1/4 font-medium">
                    <h4 className="text-dark text-base md:text-[17px]">{data?.type}</h4>
                    <p className="text-[#7B7B7B] text-sm md:text-[15px]">By</p>
                </div>
                {(data?.type === "URL" || data?.type === "FILE") && (
                    <div className="basis-1/2 font-medium">
                        <>
                            <a href={data?.content} target='_blank' rel="noopener noreferrer">
                                <h4 title={data?.content} className="text-dark text-base md:text-[17px]">
                                    {data?.content.length > 40 ? data?.content.slice(0, 40) + '...' : data?.content}
                                </h4>
                            </a>
                            <p className="text-[#7B7B7B] text-sm md:text-[15px]">Source</p>
                        </>
                    </div>
                )}

            </div>

            <div className="voice-content mt-9 space-y-3 ">
                {data?.type === "TEXT" &&
                    <div className=''>
                        <h4 className="text-dark text-base md:text-[17px]">Content</h4>
                        <div className='text-grey-6 text-[17px] '>
                            <div className='p-2 w-full resize-none outline-none whitespace-pre-line text-justify'>
                                {data?.content}
                            </div>
                        </div>
                    </div>
                }

                <div className=''>
                    <h4 className="text-dark text-base md:text-[17px]">Voice</h4>
                    <div className='text-grey-6 text-[17px]'>
                        <div className='p-2 w-full resize-none outline-none whitespace-pre-line'>
                            {data?.voice}
                        </div>
                    </div>
                </div>



            </div>
        </div>

    </>
    )
}
