import React, { useContext, useEffect, useState } from 'react'
import crossIcon from '../../assets/images/common/crossicons.svg';
import { AddBadgesIcon } from '../../helper/SvgIcons';
import { Icon } from '@iconify/react/dist/iconify.js';
import axios from 'axios';
import { Path } from '../../helper/path';
import { AuthContext } from '../../_providers/AuthProvider';


export default function AddRulesModel({ visible, setVisible }) {
    const [activeIndex, setActiveIndex] = useState(0);
    const { sesion } = useContext(AuthContext)
    const [errors, setErrors] = useState({});
    const [dynamicForm, setDynamicForm] = useState([
        {
            index: 0,
            rule: {
                field: '',
                value: []
            }
        }
    ])

    const selectRuleHandleSubmit = async (event) => {
        event.preventDefault()
        const selectedRule = event?.target?.selectRule?.value.trim();

        const newErrors = {};
        if (!selectedRule) newErrors.rule = 'Please select the rule'
        setErrors(newErrors);
        if (Object.keys(newErrors).length > 0) {
            return;
        }

        console.log("selected Rule ", selectedRule)
        // await axios.post(Path.peopleSearch,
        //     selectedRule,
        //     {
        //         headers: {
        //             Authorization: "Bearer" + sesion?.data?.token
        //         },
        //     }
        // ).then((success) => {
        //     console.log(success)
        // }).catch((error) => {
        //     console.log(error)
        // }).finally(() => {
        // })
    }

    const createRuleHandleSubmit = async (event) => {
        event.preventDefault();
        const formValue = {
            name: event?.target?.ruleName?.value,
            rule: dynamicForm
        };

        const newErrors = {};
        if (!formValue.name) newErrors.name = 'Rule Name is required';
        dynamicForm.forEach((item, index) => {
            if (!item.rule.field) {
                newErrors[`field_${index}`] = 'Rule Field is required';
            }
            if (item.rule.value.length === 0) {
                newErrors[`value_${index}`] = 'Rule Value is required';
            }
        });
        setErrors(newErrors);
        if (Object.keys(newErrors).length > 0) {
            return;
        }

        console.log(formValue)
        // await axios.post(Path.peopleSearch,
        //     formValue,
        //     {
        //         headers: {
        //             Authorization: "Bearer" + sesion?.data?.token
        //         },
        //     }
        // ).then((success) => {
        //     console.log(success)
        // }).catch((error) => {
        //     console.log(error)
        // }).finally(() => {
        // })
    }

    const handleAdd = () => {
        let updateData = [...dynamicForm];
        updateData.push({
            index: updateData.length,
            rule: {
                field: '',
                value: []
            }
        })

        setDynamicForm(updateData);
    }

    const UpdateValues = async (index, name, value) => {
        let updateData = [...dynamicForm];
        let updateValues;
        if (name === 'value') {
            let newData;
            if (updateData[index]?.rule?.value?.includes(value)) newData = await Promise.all(updateData[index]?.rule?.value?.filter((i) => i !== value))
            else newData = [...updateData[index]?.rule?.value, value]

            updateValues = {
                ...updateData[index].rule,
                [name]: newData
            }
        } else {
            updateValues = {
                ...updateData[index].rule,
                [name]: value
            }
        }
        updateData[index].rule = updateValues
        setDynamicForm(updateData)
    }

    const handleDelete = async (index) => {
        let updateData = [...dynamicForm];
        updateData = await Promise.all(updateData.filter((item) => item.index !== index))
        updateData = await Promise.all(updateData.map((item, index) => ({ index: index, rule: { ...item.rule } })))
        setDynamicForm(updateData)
    }

    return (
        <div className={`fixed top-0 left-0 z-50 min-w-full min-h-full ${visible ? 'flex' : 'hidden'} justify-center items-center bg-black/40`}>
            <div className="min-w-[300px] w-7/12 min-h-[50dvh] max-h-[80dvh] flex flex-col gap-3 bg-white shadow-lg p-6 ">
                <div className="flex items-center justify-between w-full">
                    <h4 className="text-xl font-bold text-dark">
                        {`Add Rules`}
                    </h4>
                    <div className="cursor-pointer w-fit" onClick={() => setVisible(false)}>
                        <img width={25} height={25} src={crossIcon} alt="" />
                    </div>
                </div>
                <div className="flex flex-col w-full gap-7">
                    <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
                        <ul className="flex flex-wrap -mb-px">
                            <li className="me-2" onClick={() => setActiveIndex(0)}>
                                <span className={` ${activeIndex === 0 ? 'border-b-2 text-purple border-purple' : 'hover:text-gray-600 hover:border-gray-300 border-transparent'} inline-block p-4 rounded-t-lg cursor-pointer`}>
                                    Select Rule
                                </span>
                            </li>
                            <li className="me-2" onClick={() => setActiveIndex(1)}>
                                <span className={`${activeIndex === 1 ? 'border-b-2 text-purple border-purple' : 'hover:text-gray-600 hover:border-gray-300 border-transparent'} inline-block p-4 rounded-t-lg cursor-pointer`}>
                                    Create Rule
                                </span>
                            </li>
                        </ul>
                    </div>
                    <div className={`w-full ${activeIndex === 0 ? 'block' : 'hidden'}`}>
                        <form onSubmit={(event) => selectRuleHandleSubmit(event)}
                            className="flex flex-col w-full gap-3">
                            <div className="flex w-full gap-4">
                                <div className="flex flex-col w-8/12 gap-1">
                                    <label className="custom-label !text-neutral-500 !font-normal">Rule Field</label>
                                    <select name='selectRule'
                                        className={`!h-[45px] custom-select bg-white font-[Inter] !rounded-md !text-neutral-500 !font-normal bg-[url('/src/assets/images/dashboard/select-down-icon.svg')] bg-no-repeat bg-[97.5%] bg-[length:1.3rem]  appearance-none ${errors?.rule ? 'border border-red-color' : ''}`}
                                    >
                                        <option className="font-[Inter]" disabled selected value=''>select rule</option>
                                        <option className="font-[Inter]" value='1'> rule 1</option>
                                        <option className="font-[Inter]" value='2'> rule 2</option>
                                        <option className="font-[Inter]" value='3'> rule 3</option>
                                    </select>
                                    {
                                        errors?.rule && <span className="error-css">{errors?.rule}</span>
                                    }
                                </div>
                                <div className="flex w-4/12 gap-4">
                                    <div className="flex flex-col w-1/2 gap-1">
                                        <span className="opacity-0">Button</span>
                                        <button type="button" onClick={() => setVisible(false)}
                                            className={`h-[45px] flex items-center justify-center gap-2 bg-purple text-white rounded-md text-sm font-[600] py-1 `}>
                                            {`Cancel`}
                                        </button>
                                    </div>
                                    <div className="flex flex-col w-1/2 gap-1">
                                        <span className="opacity-0">Button</span>
                                        <button type="submit"
                                            className={`h-[45px] flex items-center justify-center gap-2 bg-green text-white rounded-md text-sm font-[600] py-1 `}>
                                            {`Submit`}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>
                    <div className={`${activeIndex === 1 ? 'block' : 'hidden'} w-full`}>
                        <form onSubmit={(event) => createRuleHandleSubmit(event)} className="w-full max-h-[500px] flex flex-col gap-3 overflow-auto">
                            <div className="flex flex-col w-8/12 gap-1">
                                <label className="custom-label !text-neutral-500 !font-normal">
                                    {`Rule Name`}
                                </label>
                                <input
                                    className='!h-[45px] custom-input !rounded-md' type="text" name='ruleName' placeholder='Enter rule name' />
                                {errors.name && <span className="error-css">{errors.name}</span>}
                            </div>
                            {
                                dynamicForm.map((item) => {
                                    return (
                                        <div key={item?.index} className="flex items-start w-full gap-3">
                                            <div className="flex flex-col w-4/12 gap-1">
                                                <label className="custom-label !text-neutral-500 !font-normal">Rule Field</label>
                                                <select className="!h-[45px] custom-select bg-white font-[Inter] !rounded-md !text-neutral-500 !font-normal bg-[url('/src/assets/images/dashboard/select-down-icon.svg')] bg-no-repeat bg-[97.5%] bg-[length:1.3rem] appearance-none"
                                                    onChange={(e) => UpdateValues(item?.index, 'field', e.target.value)}
                                                >
                                                    <option className="font-[Inter]" disabled selected value=''>select rule</option>
                                                    <option className="font-[Inter]" value='1'> rule 1</option>
                                                    <option className="font-[Inter]" value='2'> rule 2</option>
                                                    <option className="font-[Inter]" value='3'> rule 3</option>
                                                </select>
                                                {errors[`field_${item?.index}`] && <span className="error-css">{errors[`field_${item?.index}`]}</span>}
                                            </div>
                                            <div className="flex flex-col w-4/12 gap-1">
                                                <label className="custom-label !text-neutral-500 !font-normal">Rule Value</label>
                                                <div className={`w-full flex flex-wrap justify-start items-center gap-1 border border-light-grey-2 rounded-lg p-3`}>
                                                    {
                                                        item?.rule?.value?.map((tag, index) => (
                                                            <div key={index} className='flex items-center gap-2 rounded-md p-1 px-2 text-purple text-sm font-[600] bg-light-purple'>
                                                                <span>
                                                                    {tag}
                                                                </span>
                                                                <Icon
                                                                    onClick={() => UpdateValues(item?.index, 'value', tag)}
                                                                    className='text-black cursor-pointer text-md' icon='radix-icons:cross-2'
                                                                />
                                                            </div>
                                                        ))
                                                    }
                                                    <div className='w-full h-[20.5px] flex items-center gap-2 px-2 rounded-md'>
                                                        <input
                                                            className='w-full h-full outline-none'
                                                            type='text'
                                                            id={`value${item?.index}`}
                                                        />
                                                        <span
                                                            className='cursor-pointer'
                                                            onClick={() => {
                                                                const data = document.getElementById(`value${item?.index}`)
                                                                if (data?.value !== '' && !(item?.rule?.value?.includes(data.value))) {
                                                                    UpdateValues(item?.index, 'value', data?.value)
                                                                    data.value = ''
                                                                }
                                                            }}
                                                        >
                                                            <AddBadgesIcon />
                                                        </span>
                                                    </div>
                                                </div>
                                                {errors[`value_${item?.index}`] && <span className="error-css">{errors[`value_${item?.index}`]}</span>}
                                            </div>
                                            <div className='flex items-center w-4/12 gap-3'>
                                                {
                                                    ((dynamicForm?.length - 1) === item?.index) && item?.index !== 0 &&
                                                    (
                                                        <div className='flex flex-col w-1/2 gap-1'>
                                                            <span className="opacity-0">Button</span>
                                                            <button
                                                                className={`h-[45px] flex items-center justify-center gap-2 bg-purple text-white rounded-md py-1 text-sm font-[600]`}
                                                                onClick={() => handleDelete(item?.index)}
                                                            >
                                                                {`Delete`}
                                                            </button>
                                                        </div>
                                                    )
                                                }
                                                {
                                                    ((dynamicForm?.length - 1) === item?.index) && item?.index < 3 &&
                                                    (
                                                        <div className='flex flex-col w-1/2 gap-1'>
                                                            <span className="opacity-0">Button</span>
                                                            <button
                                                                type="button"
                                                                className={`h-[45px] flex items-center justify-center gap-2 bg-green text-white rounded-md py-1 text-sm font-[600] `}
                                                                onClick={handleAdd}
                                                            >
                                                                {`Add`}
                                                            </button>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                            {/* <div className='flex items-center justify-start'>
                                                {
                                                    ((dynamicForm?.length - 1) === item?.index) && item?.index !== 0 &&
                                                    (
                                                        <div className='flex flex-col gap-1'>
                                                            <span className="opacity-0">Button</span>
                                                            <Icon icon="material-symbols:delete-outline"
                                                                className="text-red hover:cursor-pointer"
                                                                height={30} width={30}
                                                                onClick={() => handleDelete(item?.index)} />
                                                        </div>
                                                    )
                                                }
                                                {
                                                    ((dynamicForm?.length - 1) === item?.index) && item?.index < 3 &&
                                                    (
                                                        <div className='flex flex-col gap-1'>
                                                            <span className="opacity-0">Button</span>
                                                            <Icon
                                                                className="text-green hover:cursor-pointer"
                                                                onClick={handleAdd}
                                                                height={30} width={30} icon="material-symbols:add-box-rounded" />
                                                        </div>
                                                    )
                                                }
                                            </div> */}
                                        </div>
                                    )
                                })
                            }
                            <div className="flex justify-center w-full mb-4">
                                <div className="flex w-[32.6%] gap-3">
                                    <div className="flex flex-col w-1/2 gap-1">
                                        <span className="opacity-0">Button</span>
                                        <button type="button" onClick={() => setVisible(false)}
                                            className={`h-[45px] flex items-center justify-center gap-2 bg-purple text-white rounded-md text-sm font-[600] py-1 `}>
                                            {`Cancel`}
                                        </button>
                                    </div>
                                    <div className="flex flex-col w-1/2 gap-1">
                                        <span className="opacity-0">Button</span>
                                        <button type="submit"
                                            className={`h-[45px] flex items-center justify-center gap-2 bg-green text-white rounded-md text-sm font-[600] py-1 `}>
                                            {`Submit`}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div >
        </div >
    );
};