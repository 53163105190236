import { Icon } from '@iconify/react';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const Breadcrumb = () => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const pathUrl = pathname.split('/').filter(Boolean);

    return (
        <div className="w-full flex select-none text-gray-2 text-sm">
            {pathUrl.map((item, index) => (
                <div className='flex justify-start items-center gap-1' key={index}>
                    <span
                        onClick={() => {
                            if (index === pathUrl.length - 1 || index === 0) {
                                return; // Do nothing for first and last items
                            }

                            navigate(`/${pathUrl.slice(0, index + 1).join('/')}`);
                        }}
                        className={`${index === pathUrl.length - 1 ? 'text-purple' : 'text-gray-500'} capitalize cursor-pointer`}
                    >
                        {item.replace(/-/g, ' ')}
                    </span>
                    {index < pathUrl.length - 1 && (
                        <Icon className='text-xl ' icon={"ic:twotone-keyboard-arrow-right"} />
                    )}
                </div>
            ))}
        </div>
    );
};

export default Breadcrumb;
