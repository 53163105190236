import { useNavigate } from 'react-router-dom';
import NewLogo from '../../src/assets/images/common/campaign-logo.svg';
import LoginForm from '../components/LoginForm';
import { useContext, useEffect } from 'react';
import { AuthContext } from '../_providers/AuthProvider';
import { baseURL } from '../helper/path';

export default function Login() {
    const { status } = useContext(AuthContext);
    const navigate = useNavigate();
    useEffect(() => {
        // console.log(process.env.NODE_ENV)
        if (status.loggedIn) {
            navigate("/dashboard");
        }
    }, [status.loggedIn, navigate]);

    return (
        <div className='w-full h-[100dvh] flex'>
            <div className='w-[161px] min-h-full flex flex-col justify-start items-center gap-1 bg-login !bg-cover !bg-center !bg-no-repeat pt-10'>
                <img width={67} height={52} src={NewLogo} alt='' />
                <span className="text-white text-lg font-normal font-['Baloo']">Aye Campaigns</span>
            </div>
            <LoginForm />
        </div>
    );
}
