import React, { useContext, useEffect, useRef, useState } from "react";
import Breadcrumb from "../common/Breadcrumbs";
import axios from "axios";
import { Path } from "../../helper/path";
import { AuthContext } from "../../_providers/AuthProvider";
import { FullScreenLoader } from "../common/FullScreenLoader";
import { AddBadgesIcon, DeletIcon } from '../../helper/SvgIcons';
import { Icon } from '@iconify/react/dist/iconify.js';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { showErrorMessage, showSuccessMessage } from "../../helper/showMessage";
import { allIndustry } from "./allIndustry.constant";

export default function AddRules() {
    const dropdownRef = useRef(null);
    const { id } = useParams();
    const { session } = useContext(AuthContext);
    const [isLoader, setIsLoader] = useState(false);
    const navigate = useNavigate();
    const [errors, setErrors] = useState({});
    const [campaigns, setCampaigns] = useState([]);
    const [dropdownState, setDropdownState] = useState({});

    let allSuggestions = allIndustry

    const [dynamicForm, setDynamicForm] = useState({
        name: "",
        campaignId: "",
        conditions: [
            {
                key: "",
                operation: "=",
                value: [],
                conjunction: "",
                visible: false
            }
        ]
    });

    const selectOption = [
        { _id: 1, name: "Select Rule", value: "" },
        { _id: 2, name: "Company Name", value: "companyName" },
        { _id: 3, name: "Seniority", value: "seniority" },
        { _id: 4, name: "Domain", value: "domain" },
        { _id: 5, name: "Location", value: "location" },
        { _id: 6, name: "Industry", value: "industry" }
    ];

    const removeDynamicForm = async (index) => {
        let data = await Promise.all([...dynamicForm.conditions].filter((items, idx) => index !== idx))
        data = await Promise.all([...data]?.map((items, idx) => {
            if ((dynamicForm?.conditions?.length - 2) === idx) return { ...items, conjunction: "" }
            else return { ...items }
        }))
        setDynamicForm((e) => ({ ...e, conditions: data }))
    }

    const updateDynamicForm = async (name, value, index) => {
        if (index !== null) {
            let updatedData = await Promise.all([...dynamicForm.conditions].map((items, idx) => {
                if (index === idx) {
                    if (name === 'conjunction') return { ...items, [name]: value, visible: false }
                    return { ...items, [name]: value }
                }
                else return { ...items }
            }))
            if (name === 'conjunction' && dynamicForm.conditions[index].conjunction === '' && dynamicForm.conditions?.length < 5) updatedData = [...updatedData, { key: "", operation: "=", value: [], conjunction: "", visible: false }]
            setDynamicForm((e) => ({ ...e, conditions: updatedData }))
        } else {
            setDynamicForm((e) => ({ ...e, [name]: value }))
        }
    }

    const ErrorHandler = async (data) => {
        let errors = {};
        if (data.name === "") errors[`name`] = "Rule Name is Required"
        if (data.campaignId === "") errors[`campaignId`] = "Campaign Name is Required"
        data.conditions.forEach((items, index) => {
            if (items.key === "") errors[`key${index}`] = "Rule Field is Required"
            if (items.value.length === 0) errors[`value${index}`] = "Atleast 1 Rule Value is Required"
        })
        setErrors(errors)
        if (Object.entries(errors)?.length === 0) return { success: false }
        else return { success: true }
    }

    const createRuleHandleSubmit = async (event) => {
        event.preventDefault();

        const isErrorFound = (await ErrorHandler(dynamicForm))?.success
        if (isErrorFound) return null;

        const update = await Promise.all(dynamicForm?.conditions?.map((items) => ({ key: items.key, value: items.value, conjunction: items.conjunction, operation: items.operation })))
        const formData = { ...dynamicForm, conditions: update }
        setIsLoader(true);
        let response = (id ? axios.put(`${Path.rulesEngine}/${id}`, formData, {
            headers: { Authorization: "Bearer " + session?.data?.token },
        }) : axios.post(Path.rulesEngine, formData, {
            headers: { Authorization: "Bearer " + session?.data?.token },
        }));
        response.then((success) => {
            showSuccessMessage(success?.data?.message);
            navigate("/rules-engine/list");
        }).catch((error) => {
            showErrorMessage(error?.response?.data?.error);
            console.log(error);
        }).finally(() => {
            setIsLoader(false);
        });
    };

    useEffect(() => {
        async function GetInitialData(identity) {
            axios.get(Path.rulesEngine + `/${identity}`,
                {
                    headers: {
                        Authorization: "Bearer " + session?.data?.token
                    }
                }
            ).then((ruleData) => {
                const { name, campaignId, conditions } = ruleData?.data;
                setDynamicForm((e) => ({
                    ...e,
                    name: name,
                    campaignId: campaignId,
                    conditions: conditions.map((condition) => ({
                        key: condition.key,
                        operation: condition.operation,
                        value: condition.value || [],
                        conjunction: condition.conjunction,
                    })),
                }));
            }).catch((error) => {
                console.log(error);
            });
        }
        id !== undefined && GetInitialData(id)
    }, [id]);

    useEffect(() => {
        setIsLoader(true);
        async function GetData() {
            await axios.get(Path.CreateORGetCampaign,
                {
                    headers: { Authorization: "Bearer " + session?.data?.token },
                }
            ).then((success) => {
                setCampaigns(success?.data);
            }).catch((error) => {
                console.log(error);
            }).finally(() => {
                setIsLoader(false);
            });
        }
        GetData();
    }, [session?.data?.token]);

    // useEffect(() => {
    //     async function handleClickOutside(event) {
    //         if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
    //             const data = await Promise.all(dynamicForm?.conditions?.map((items) => ({...items , visible: false}))) 
    //             setDynamicForm((e) => ({...e , conditions: data}))
    //         }
    //     }
    //     document.addEventListener('click', handleClickOutside);
    //     return () => document.removeEventListener('click', handleClickOutside);
    // }, []);


    const UpdateValues = async (index, name, value) => {
        dropdownState[index] = ''; // Reset input after update
        let updateData = [...dynamicForm.conditions]; // Correctly copy the conditions array
        let updateValues;

        if (name === 'value') {
            let newData;
            // Check if the value is already included, then remove it; otherwise, add it
            if (updateData[index]?.value?.includes(value)) {
                newData = await Promise.all(updateData[index]?.value?.filter((i) => i !== value));
            } else {
                newData = [...(updateData[index]?.value || []), value];
            }
            updateValues = { ...updateData[index], [name]: newData }; // Corrected the update logic
        } else {
            updateValues = { ...updateData[index], [name]: value };
        }

        updateData[index] = updateValues; // Update the modified object back in the array
        setDynamicForm((prevState) => ({ ...prevState, conditions: updateData })); // Update the state with the new conditions array
    };



    const handleInputChange = (e, index) => {
        const inputValue = e.target.value;
        const newDropdownState = { ...dropdownState, [index]: inputValue };
        setDropdownState(newDropdownState);

        const filteredSuggestions = allSuggestions.filter(suggestion =>
            suggestion.toLowerCase().includes(inputValue.toLowerCase())
        );
        setDropdownState({
            ...newDropdownState,
            [`${index}_suggestions`]: filteredSuggestions,
            [`${index}_visible`]: true,
        });
    };

    const handleFocus = (index) => {
        const inputValue = dropdownState[index] || '';
        const filteredSuggestions = allSuggestions.filter(suggestion =>
            suggestion.toLowerCase().includes(inputValue.toLowerCase())
        );
        setDropdownState({
            ...dropdownState,
            [`${index}_visible`]: true,
            [`${index}_suggestions`]: filteredSuggestions,
        });
    };

    const handleBlur = (index) => {
        setTimeout(() => {
            setDropdownState((prevState) => ({
                ...prevState,
                [`${index}_visible`]: false,
            }));
        }, 200); // Delay hiding to allow selection
    };

    const handleSelectSuggestion = (suggestion, index) => {
        UpdateValues(index, 'value', suggestion);
        setDropdownState((prevState) => ({
            ...prevState,
            [index]: '',
            [`${index}_visible`]: false,
        }));
    };


    return (
        <>
            {isLoader ? <FullScreenLoader /> : " "}
            <div className="w-full flex flex-col gap-5">
                <RuleListingSubHeader id={id} />
                <div className="bg-white">
                    <div className={`min-w-full min-h-full flex justify-center items-center`}>
                        <div className="w-full flex flex-col gap-3 p-6">
                            <div className="flex flex-col w-full gap-7">
                                <div className={`w-full`}>
                                    <form onSubmit={(event) => createRuleHandleSubmit(event)} className="w-full flex flex-col gap-3">
                                        <div className="w-full flex-col sm:flex items-start gap-3">

                                            <div className="w-full sm:w-[66%] flex flex-col gap-1">
                                                <label className="custom-label !text-neutral-500 !font-normal">
                                                    {`Rule Name`}
                                                </label>
                                                <input
                                                    onChange={(e) => updateDynamicForm('name', e.target.value, null)} value={dynamicForm?.name}
                                                    className='!h-[45px] custom-input !rounded-md' type="text" name='ruleName' placeholder='Enter rule name'
                                                />
                                                {errors?.name && <span className="error-css">{errors?.name}</span>}
                                            </div>
                                        </div>
                                        {
                                            dynamicForm?.conditions?.map((item, index) => {
                                                return (
                                                    <div key={index} className="w-full sm:flex  gap-3 ">
                                                        <div className="w-full sm:w-[33%] flex flex-col gap-1">
                                                            <label className="custom-label !text-neutral-500 !font-normal">
                                                                Rule Field
                                                            </label>
                                                            <select
                                                                className="!h-[45px] custom-select bg-white font-[Inter] !rounded-md !text-neutral-500 !font-normal bg-[url('/src/assets/images/dashboard/select-down-icon.svg')] bg-no-repeat bg-[97.5%] bg-[length:1.3rem] appearance-none"
                                                                onChange={(e) => updateDynamicForm('key', e.target.value, index)} value={item?.key}
                                                            >
                                                                {
                                                                    selectOption.map((option, idx) => {
                                                                        let isIncluded = false;
                                                                        dynamicForm?.conditions?.map((i) => { if (i.key === option.value) isIncluded = true })
                                                                        return (
                                                                            <option
                                                                                key={option?._id} value={option?.value} className="font-[Inter]"
                                                                                disabled={isIncluded}
                                                                                style={{ color: idx === 0 || isIncluded ? 'gray' : 'black' }}
                                                                            >
                                                                                {option?.name}
                                                                            </option>
                                                                        );
                                                                    })
                                                                }
                                                            </select>
                                                            {errors[`key${index}`] && <span className="error-css">{errors[`key${index}`]}</span>}
                                                        </div>
                                                        <div className="w-full sm:w-[33%] flex flex-col gap-1">
                                                            <label className="custom-label !text-neutral-500 !font-normal">Rule Value</label>
                                                            <div className={`w-full flex !capitalize flex-wrap justify-start items-center gap-1 border border-light-grey-2 rounded-lg p-3 relative`}>
                                                                {item?.value?.map((tag, idx) => (
                                                                    <div key={idx} className='flex items-center gap-2 rounded-md p-1 px-2 text-purple text-sm font-[600] bg-light-purple'>
                                                                        <span>{tag}</span>
                                                                        <Icon
                                                                            onClick={async () => {
                                                                                // Filter out the tag that is being removed
                                                                                const updatedTags = item?.value.filter((_, b) => idx !== b);
                                                                                // Update the dynamic form's value for the current index
                                                                                updateDynamicForm('value', updatedTags, index);
                                                                            }}
                                                                            className='text-black cursor-pointer text-md' icon='radix-icons:cross-2'
                                                                        />
                                                                    </div>
                                                                ))}
                                                                <div className='w-full h-[20.5px] flex items-center gap-2 px-2 rounded-md relative'>
                                                                    <input
                                                                        autoComplete="off"
                                                                        className='w-full h-full capitalize outline-none'
                                                                        type='text'
                                                                        id={`value${index}`}
                                                                        value={dropdownState[index] || ""}
                                                                        onChange={(e) => handleInputChange(e, index)}
                                                                        onFocus={() => handleFocus(index)}
                                                                        onBlur={() => handleBlur(index)}
                                                                    />
                                                                    <span
                                                                        className='cursor-pointer'
                                                                        onClick={() => {
                                                                            const d = dropdownState[index];
                                                                            if (d !== "") {
                                                                                updateDynamicForm('value', [...item.value, d], index);
                                                                                // Clear the input field by resetting its state
                                                                                handleInputChange({ target: { value: "" } }, index);
                                                                            }
                                                                        }}
                                                                    >
                                                                        <AddBadgesIcon />
                                                                    </span>
                                                                    {/* Suggestions Dropdown */}
                                                                    {item.key === "industry" && dropdownState[`${index}_visible`] && (
                                                                        <div className="absolute top-7 w-full z-10 bg-white border border-light-grey-2 rounded-lg shadow-md max-h-40 overflow-y-auto mt-1">
                                                                            {dropdownState[`${index}_suggestions`]?.map((suggestion, idx) => (
                                                                                <div
                                                                                    key={idx}
                                                                                    className="p-2 capitalize cursor-pointer hover:bg-gray-200"
                                                                                    onMouseDown={() => handleSelectSuggestion(suggestion, index)}
                                                                                >
                                                                                    {suggestion}
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            {errors[`value${index}`] && <span className="error-css">{errors[`value${index}`]}</span>}
                                                        </div>

                                                        <div className='w-full sm:w-[33%] flex items-center justify-end sm:justify-start gap-3 h-[75px] my-auto'>

                                                            <div className='flex flex-col w-2/12 sm:w-[166px] gap-1 relative ' ref={dropdownRef}>
                                                                <span className="opacity-0">
                                                                    Button
                                                                </span>
                                                                <button
                                                                    onClick={() => updateDynamicForm('visible', !item.visible, index)}
                                                                    type="button"
                                                                    className={`h-[45px] flex items-center justify-center gap-2 bg-purple text-white rounded-md py-1 text-sm font-[600]`}
                                                                >
                                                                    {item?.conjunction === "" ? "Add Rule +" : item?.conjunction}
                                                                </button>
                                                                <div className={`bg-white absolute top-20 right-8 z-10 ${item.visible ? 'block' : 'hidden'}`}>
                                                                    <ul className="text-sm w-[188px] rounded text-left shadow-[2px_4px_7px_4px_rgba(0,0,0,0.07)]">
                                                                        <li
                                                                            className="font-medium hover:font-bold hover:text-dark hover:bg-[#EAE3FF] text-[#A1A1A1] transition-all py-2 px-4 cursor-pointer"
                                                                            onClick={() => updateDynamicForm('conjunction', 'AND', index)}
                                                                        >
                                                                            AND
                                                                        </li>
                                                                        <li
                                                                            className="font-medium hover:font-bold hover:text-dark hover:bg-[#EAE3FF] text-[#A1A1A1] transition-all py-2 px-4 cursor-pointer"
                                                                            onClick={() => updateDynamicForm('conjunction', 'OR', index)}
                                                                        >
                                                                            OR
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>

                                                            {
                                                                dynamicForm?.conditions?.length > 1 &&
                                                                <div className='flex flex-col items-center justify-center '>
                                                                    <span className="opacity-0">
                                                                        Button
                                                                    </span>
                                                                    <button
                                                                        type="button"
                                                                        onClick={() => removeDynamicForm(index)}
                                                                    >
                                                                        <DeletIcon />
                                                                    </button>
                                                                </div>
                                                            }

                                                        </div>
                                                    </div>
                                                );
                                            })
                                        }
                                        <div className="w-full dlex-col sm:flex items-start gap-3">
                                            <div className="w-full sm:w-[33%] flex flex-col gap-1">
                                                <label className="custom-label !text-neutral-500 !font-normal">
                                                    {`Campaign Name`}
                                                </label>
                                                <select
                                                    onChange={(e) => updateDynamicForm('campaignId', e.target.value, null)} value={dynamicForm?.campaignId}
                                                    className="!h-[45px] custom-select bg-white font-[Inter] !rounded-md !text-neutral-500 !font-normal bg-[url('/src/assets/images/dashboard/select-down-icon.svg')] bg-no-repeat bg-[97.5%] bg-[length:1.3rem] appearance-none" name="campaign"
                                                >
                                                    <option value="" disabled>Select Campaign</option>
                                                    {
                                                        campaigns?.map((option) => (
                                                            <option key={option?._id} value={option?._id}>
                                                                {option?.name}
                                                            </option>
                                                        ))
                                                    }
                                                </select>
                                                {errors.campaignId && <span className="error-css">{errors.campaignId}</span>}
                                            </div>
                                        </div>
                                        <div className="w-full border-t mt-4">
                                            <div className="w-full sm:w-8/12 flex">
                                                <div className="w-full flex justify-end gap-3">
                                                    <div className="flex flex-col w-3/12 sm:w-[166px] gap-1">
                                                        <span className="opacity-0">
                                                            Button
                                                        </span>
                                                        <button type="submit" className={`h-[45px] flex items-center justify-center gap-2 bg-purple text-white rounded-md text-sm font-[600] py-1 `}>
                                                            {`Submit`}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

// {
//     dynamicForm?.conditions?.map((item, index) => {
//         return (
//             <div key={index} className="w-full sm:flex gap-3 ">
//                 <div className="w-full sm:w-[33%] flex flex-col gap-1">
//                     <label className="custom-label !text-neutral-500 !font-normal">Rule Field</label>
//                     <select
//                         className="!h-[45px] custom-select bg-white font-[Inter] !rounded-md !text-neutral-500 !font-normal bg-[url('/src/assets/images/dashboard/select-down-icon.svg')] bg-no-repeat bg-[97.5%] bg-[length:1.3rem] appearance-none"
//                         onChange={(e) => updateDynamicForm('key', e.target.value, index)} value={item?.key}
//                     >
//                         {
//                             selectOption.map((option, idx) => {
//                                 let isIncluded = false;
//                                 dynamicForm?.conditions?.map((i) => { if (i.key === option.value) isIncluded = true });
//                                 return (
//                                     <option
//                                         key={option?._id} value={option?.value} className="font-[Inter]"
//                                         disabled={isIncluded}
//                                         style={{ color: idx === 0 || isIncluded ? 'gray' : 'black' }}
//                                     >
//                                         {option?.name}
//                                     </option>
//                                 );
//                             })
//                         }
//                     </select>
//                     {errors[`key${index}`] && <span className="error-css">{errors[`key${index}`]}</span>}
//                 </div>

//                 {/* Rule Value Section */}
//                 <div className="w-full sm:w-[33%] flex flex-col gap-1">
//                     <label className="custom-label !text-neutral-500 !font-normal">Rule Value</label>
//                     <div className={`w-full flex !capitalize flex-wrap justify-start items-center gap-1 border border-light-grey-2 rounded-lg p-3 relative`}>
//                         {item?.value?.map((tag, idx) => (
//                             <div key={idx} className='flex items-center gap-2 rounded-md p-1 px-2 text-purple text-sm font-[600] bg-light-purple'>
//                                 <span>{tag}</span>
//                                 <Icon
//                                     onClick={async () => {
//                                         // Filter out the tag that is being removed
//                                         const updatedTags = item?.value.filter((_, b) => idx !== b);
//                                         // Update the dynamic form's value for the current index
//                                         updateDynamicForm('value', updatedTags, index);
//                                     }}
//                                     className='text-black cursor-pointer text-md' icon='radix-icons:cross-2'
//                                 />
//                             </div>
//                         ))}
//                         <div className='w-full h-[20.5px] flex items-center gap-2 px-2 rounded-md relative'>
//                             <input
//                                 autoComplete="off"
//                                 className='w-full h-full capitalize outline-none'
//                                 type='text'
//                                 id={`value${index}`}
//                                 value={dropdownState[index] || ""}
//                                 onChange={(e) => handleInputChange(e, index)}
//                                 onFocus={() => handleFocus(index)}
//                                 onBlur={() => handleBlur(index)}
//                             />
//                             <span
//                                 className='cursor-pointer'
//                                 onClick={() => {
//                                     const d = dropdownState[index];
//                                     if (d !== "") {
//                                         updateDynamicForm('value', [...item.value, d], index);
//                                         // Clear the input field by resetting its state
//                                         handleInputChange({ target: { value: "" } }, index);
//                                     }
//                                 }}
//                             >
//                                 <AddBadgesIcon />
//                             </span>
//                             {/* Suggestions Dropdown */}
//                             {item.key === "industry" && dropdownState[`${index}_visible`] && (
//                                 <div className="absolute top-7 w-full z-10 bg-white border border-light-grey-2 rounded-lg shadow-md max-h-40 overflow-y-auto mt-1">
//                                     {dropdownState[`${index}_suggestions`]?.map((suggestion, idx) => (
//                                         <div
//                                             key={idx}
//                                             className="p-2 capitalize cursor-pointer hover:bg-gray-200"
//                                             onMouseDown={() => handleSelectSuggestion(suggestion, index)}
//                                         >
//                                             {suggestion}
//                                         </div>
//                                     ))}
//                                 </div>
//                             )}
//                         </div>
//                     </div>
//                     {errors[`value${index}`] && <span className="error-css">{errors[`value${index}`]}</span>}
//                 </div>


//                 {/* Rest of your code */}
//             </div>
//         );
//     })
// }

function RuleListingSubHeader({ id }) {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const pathUrl = pathname.split('/').filter(Boolean);
    return (
        <div className='w-full flex justify-between items-start'>
            <div className='flex flex-col justify-center items-start'>
                <div className="w-full flex select-none text-gray-2 text-sm">
                    {pathUrl.slice(0, -1).map((item, index) => ( // Slice to remove the last item
                        <div className="flex justify-start items-center gap-1" key={index}>
                            <span
                                // onClick={() => {
                                //     if (index === 0) return; // Do nothing for the first item

                                //     navigate(`/${pathUrl.slice(0, index + 1).join('/')}`);
                                // }}
                                className={`${index === pathUrl.length - 2 ? 'text-purple' : 'text-gray-500'} capitalize `}
                            >
                                {item.replace(/-/g, ' ')}
                            </span>
                            {index < pathUrl.length - 2 && ( // Adjust the condition to exclude the last separator
                                <Icon className="text-xl" icon={"ic:twotone-keyboard-arrow-right"} />
                            )}
                        </div>
                    ))}
                </div>




                <span className='large-title'>
                    {id ? `Edit Rule` : `Add Rule`}
                </span>
            </div>
        </div>
    );
};