import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import { ModalProvider } from "react-modal-hook";
import DashboardPage from "./pages/DashboardPage";
import Dashboard from "./components/dashboard/Dashboard";
import CampaignSummary from "./components/campaign/CampaignSummary";
import ReportAnalytics from "./components/report-analytics/ReportAnalytics";
import AuthProvider from "./_providers/AuthProvider";
import UserManagement from "./components/administration/UserManagement";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProtectedRoute from "./helper/PrivateRoute";
import BrandKnowledgeBase from "./components/administration/BrandKnowledgeBase";
import BrandVoice from "./components/administration/BrandVoice";
import OnboardingCampaign from "./components/campaign/OnboardingCampaign";
import EditBrandVoiceModel from "./components/model/EditBrandVoiceModel";
import EmailEditor from "./components/campaign/EmailEditor";
import CampaignContactDetails from "./components/campaign/CampaignContactDetails";
import ContactDirectory from "./components/administration/ContactDirectory";
import ContactNewCsvImport from "./components/administration/ContactNewCsvImport";
import IntegrationSettings from "./components/administration/IntegrationSettings";
import VisitorListing from "./components/report-analytics/VisitorListing";
import VisitorRules from "./components/report-analytics/VisitorRules";
import PeopleSearch from "./components/Search/PeopleSearch";
import TemplateDraftEditor from "./components/campaign/TemplateDraftEditor";

import RuleList from "./components/rule-engine/RuleList";
import RuleListing from "./components/rule-engine/RuleList";
import AddRules from "./components/rule-engine/AddRules";
import FullScreenEditor from "./components/campaign/FullScreenEditor";
import VistorDetail from "./components/report-analytics/VistorDetail";
import { useEffect } from "react";
import DesktopDeviceOnly from "./components/common/DesktopDeviceOnly";


function App() {

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const keepSignedIn = localStorage.getItem('keepSignedIn');
      if (keepSignedIn === 'false') {
        localStorage.clear();
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return (
    <div>
      <ModalProvider>
        <AuthProvider>
          <BrowserRouter>
            <ToastContainer />
            {/* <DesktopDeviceOnly/> */}
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/sign-up" element={<SignUp />} />
              <Route
                path="/campaigns/onboarding-campaign/email-step"
                element={<EmailEditor />}
              />
              <Route
                path="/campaigns/onboarding-campaign/email-step/template-editor"
                element={<FullScreenEditor />}
              />
              <Route
                path="/campaigns/template-draft-editor"
                element={<TemplateDraftEditor />}
              />
              <Route
                path="/"
                element={<ProtectedRoute element={DashboardPage} />}>
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="campaigns" element={<CampaignSummary />} />
                <Route
                  path="campaigns/onboarding-campaign/"
                  element={<OnboardingCampaign />}
                />
                <Route
                  path="campaigns/contact-details"
                  element={<CampaignContactDetails />}
                />
                <Route
                  path="administration/contact/contact-details"
                  element={<CampaignContactDetails />}
                />
                <Route
                  path="administration/user-management"
                  element={<UserManagement />}
                />
                <Route
                  path="administration/brand-knowledgebase"
                  element={<BrandKnowledgeBase />}
                />
                <Route
                  path="administration/contact"
                  element={<ContactDirectory />}
                />
                <Route
                  path="administration/integration-settings"
                  element={<IntegrationSettings />}
                />
                <Route
                  path="administration/contact/new-list/"
                  element={<ContactNewCsvImport />}
                />
                <Route
                  path="administration/brand-voice"
                  element={<BrandVoice />}
                />
                <Route
                  path="administration/edit-Voice/"
                  element={<EditBrandVoiceModel />}
                />
                <Route
                  path="rules-engine/list"
                  element={<RuleListing />}
                />
                <Route
                  path="rules-engine/add"
                  element={<AddRules />}
                />
                <Route
                  path="rules-engine/edit/:id"
                  element={<AddRules />}
                />
                <Route
                  path="visitors/dashboard"
                  element={<ReportAnalytics />}
                />
                <Route path="visitors/list" element={<VisitorListing />} />
                <Route path="visitors/rules" element={<VisitorRules />} />
                <Route
                  path="visitors/list/contact-details"
                  element={<VistorDetail />}
                />
                <Route path="people-search" element={<PeopleSearch />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </AuthProvider>
      </ModalProvider>
    </div>
  );
}

export default App;
