import { Icon } from '@iconify/react';
import axios from 'axios';
import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Validation, content, regex } from '../helper/Messages';
import { object, string } from 'yup';
import * as Yup from "yup";
import { useForm } from 'react-hook-form';
import { Path } from '../helper/path';
import { yupResolver } from '@hookform/resolvers/yup';
import { AuthContext } from '../_providers/AuthProvider';
import { FullScreenLoader } from './common/FullScreenLoader';
import { showErrorMessage } from '../helper/showMessage';


export default function LoginForm() {
    const { status } = useContext(AuthContext)
    const navigate = useNavigate();
    const [visible, setVisible] = useState(false);
    const [isLoader, setIsLoader] = useState(false)


    const toggleEye = () => {
        setVisible((prevVisible) => !prevVisible);
    };

    const validationSchema = object().shape({
        email: string().trim().required(Validation?.emailRequired).matches(regex?.emailRegex, Validation?.emailInvalid),
        password: string().trim().required(Validation?.passwordRequired),
        keepSignedIn: Yup.boolean().default(true),
    });

    const { register: loginCredential, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema), defaultValues: {
            keepSignedIn: true,  // Set default value to true
        }
    });

    const onSubmit = async (data, event) => {
        event.preventDefault();
        setIsLoader(true)
        await axios.post(Path.Login,
            {
                email: data.email,
                password: data.password
            }
        ).then((success) => {
            localStorage.setItem('keepSignedIn', data.keepSignedIn)
            setIsLoader(false)
            status.login(JSON.stringify(success?.data?.user), success?.data?.token)
            navigate("/dashboard");
        }).catch((error) => {
            setIsLoader(false)
            showErrorMessage(error?.response?.data?.error || 'Something Went Wrong.')
        })
    };
    return (
        <>
            {isLoader ? <FullScreenLoader /> : " "}
            <div className="w-[calc(100vw-161px)] min-h-full flex flex-col gap-5 justify-center items-center bg-offwhite select-none">
                <div className="w-[90%] sm:w-[700px] text-center text-xl xs:text-2xl  text-purple font-['Baloo']">
                    {`“Welcome to the First AI Email Sequence Tool”`}
                </div>
                <form onSubmit={handleSubmit(onSubmit)} className="w-[90%] sm:w-[700px] flex flex-col justify-start items-center gap-8 rounded-lg shadow-lg bg-white p-5 px-10">
                    <div className="w-full text-xl font-bold text-dark mt-3">
                        {`Log In`}
                    </div>
                    <div className="w-full flex flex-col justify-start items-center gap-5 text-grey text-sm">
                        <div className="w-full flex flex-col gap-1">
                            <label>
                                {`Email`}
                            </label>
                            <input
                                className={errors?.email ? "input-form !border !border-[#FF1D48]" : "input-form"}
                                type="text" name="email" placeholder="Enter Email" {...loginCredential("email")}
                            />
                            {
                                errors.email && <div className="error-css">{errors?.email?.message}</div>
                            }
                        </div>
                        <div className="w-full flex flex-col gap-1 relative">
                            <label>
                                {`Password`}
                            </label>
                            <input
                                type={visible ? "text" : "password"} name="password" placeholder="Enter Password"  {...loginCredential("password")}
                                className={errors?.password ? "input-form !border !border-[#FF1D48]" : "input-form"}
                            />
                            <span onClick={toggleEye} className="absolute top-[45px] right-4 sm:right-6 lg:right-4 cursor-pointer">
                                {
                                    visible
                                        ?
                                        <Icon
                                            icon="codicon:eye-closed"
                                            className="icon-color"
                                            width={20}
                                            height={20}
                                        />
                                        :
                                        <Icon
                                            icon="codicon:eye"
                                            className="icon-color"
                                            width={20}
                                            height={20}
                                        />
                                }
                            </span>
                            {
                                errors.password && <div className="error-css">{errors?.password?.message}</div>
                            }

                            <div className="w-full flex justify-between items-center pt-3">
                                <div className="flex items-center  gap-1">
                                    <span>
                                        <input className="w-5 h-5 cursor-pointer accent-purple border border-light-grey flex items-center" type="checkbox"  {...loginCredential("keepSignedIn")} name='keepSignedIn'
                                        />
                                    </span>
                                    <span className="text-light-grey text-sm">
                                        {`Keep me signed in`}
                                    </span>
                                </div>
                                <span onClick={() => navigate("/forgot-password")} className="text-purple text-sm cursor-pointer hover:underline hover:scale-105 duration-300">
                                    {`Forgot password?`}
                                </span>
                            </div>
                        </div>
                    </div>
                    <button className="w-full h-[54px] rounded-md bg-purple text-white text-xl font-[500] hover:scale-105 duration-300 mb-6" type="submit">
                        {`Login`}
                    </button>
                </form>
                <div className="text-center text-sm">
                    <span className='text-grey-2'>{`This page is protected by Google reCAPTCHA to ensure you're not a bot.`}</span>
                    {/* <span className='text-green'>{` Learn more`}</span> */}
                </div>
            </div>
        </>
    )
}