import React, { useContext, useEffect, useState } from 'react';
import { ArchiveIcon } from '../../helper/SvgIcons';
import { AuthContext } from '../../_providers/AuthProvider';
import axios from 'axios';
import { Path } from '../../helper/path';
import { useNavigate } from 'react-router-dom';
import { FullScreenLoader } from '../common/FullScreenLoader';
import ReactPaginate from 'react-paginate';
import { allIndustry } from '../rule-engine/allIndustry.constant';

export default function VisitorListing() {
	const { session } = useContext(AuthContext);
	const [visitorList, setVisitorList] = useState([]);
	const [isLoader, setIsLoader] = useState(false);
	const [showSetting, setShowSetting] = useState(false);
	const [website, setWebsite] = useState([]);
	const [selectedWebsiteId, setSelectedWebsiteId] = useState("");
	const [selectedIndustry, setSelectedIndustry] = useState("");
	const [selectedTimestamp, setSelectedTimestamp] = useState("");
	const [activeTab, setActiveTab] = useState(0);
	const [sortKey, setSortKey] = useState("createdAt");
	const [sortOrder, setSortOrder] = useState("desc");
	const [pageCount, setPageCount] = useState(0);
	const [currentPage, setCurrentPage] = useState(0);
	const navigate = useNavigate();

	const handleEmailButtonClick = (data) => {
		if (data?.email) {
			const mailtoLink = `mailto:${data.email}`;
			window.location.href = mailtoLink;
		}
	};

	const fetchVisitorList = async (selectedPage = 0) => {
		setIsLoader(true);
		const params = {
			websiteId: selectedWebsiteId,
			industry: selectedIndustry,
			from: selectedTimestamp ? new Date(Date.now() - parseTimestamp(selectedTimestamp)).toISOString() : undefined,
			to: selectedTimestamp ? new Date().toISOString() : undefined,
			sortKey,
			sortOrder,
			page: selectedPage + 1,
			size: 10,
		};

		await axios.get(`${Path.GetVisitorList}/all`,
			{
				headers: {
					Authorization: "Bearer " + session?.data?.token,
				},
				params
			}
		).then((success) => {
			setIsLoader(false);
			setVisitorList(success?.data.visitors);
			setPageCount(success?.data.totalPages);
		}).catch((error) => {
			setIsLoader(false);
			console.log(error);
		});
	};

	useEffect(() => {
		if (selectedWebsiteId) fetchVisitorList();
	}, [session, selectedWebsiteId, selectedIndustry, selectedTimestamp, sortKey, sortOrder]);

	useEffect(() => {
		async function getWebsite() {
			setIsLoader(true);
			await axios.get(`${Path.GetWebsites}`, {
				headers: {
					Authorization: "Bearer " + session?.data?.token,
				},
			}).then((success) => {
				setWebsite(success?.data)
				if (success?.data.length > 0) {
					setSelectedWebsiteId(success?.data[0]._id);
				}
			}).catch((error) => {
				console.log(error);
			});
		}
		getWebsite();
	}, [session]);

	const handleWebsiteChange = (e) => {
		setSelectedWebsiteId(e.target.value);
	};

	const handleSort = (key) => {
		if (sortKey === key) {
			setSortOrder(sortOrder === "asc" ? "desc" : "asc");
		} else {
			setSortKey(key);
			setSortOrder("asc");
		}
	};

	const parseTimestamp = (timestamp) => {
		switch (timestamp) {
			case "Last 1 Day":
				return 24 * 60 * 60 * 1000;
			case "Last 1 Week":
				return 7 * 24 * 60 * 60 * 1000;
			case "Last 1 Month":
				return 30 * 24 * 60 * 60 * 1000;
			default:
				return 0;
		}
	};

	const handlePageClick = (data) => {
		const selectedPage = data.selected;
		setCurrentPage(selectedPage);
		fetchVisitorList(selectedPage);
	};

	return (
		<>
			{isLoader ?
				(<FullScreenLoader />)
				:
				(
					<>
						<div className="flex gap-4 items-end flex-wrap  pt-6 justify-between">
							<h2 className="large-title">{`Visitors List`}</h2>
							<div className='relative w-[200px] h-[35px]'>
								<select onChange={handleWebsiteChange} value={selectedWebsiteId} className="w-full h-full pl-4 pr-9 bg-[url('/src/assets/images/dashboard/select-down-icon.svg')] bg-no-repeat
                            bg-[95%] bg-[length:1.2rem] bg-white border border-gray-200 rounded-md appearance-none cursor-pointer outline-none" >
									<option value="" disabled>Website</option>
									{
										website.length !== 0 && website.map((item) => (
											<option className="font-[Inter]" key={item._id} value={item._id}>{item.name}</option>
										))
									}
								</select>
							</div>
						</div>
						{/* Show Filter / Hide Filter  Button */}
						<div className='flex mt-2'>
							<button
								onClick={() => {
									setShowSetting(!showSetting);
									setSelectedIndustry("");
									setSelectedTimestamp("");
								}}
								type="button"
								className="min-w-[127px] text-sm font-medium rounded-lg flex items-center gap-2 border text-gray-6 border-gray-8
                                hover:text-dark hover:border-dark  h-[33px] px-2"
							>
								<ArchiveIcon color={showSetting ? "#2D2D2D" : "#AEAEAE"} />
								<span style={{ color: showSetting ? "#2D2D2D" : "#AEAEAE" }}>
									{!showSetting ? `Show Filters` : `Hide Filters`}
								</span>
							</button>
							{showSetting ? <>
								{/* <div className='relative w-[200px] h-[33px] ml-2'>
									<select
										value={selectedIndustry}
										onChange={(e) => setSelectedIndustry(e.target.value)}
										className="w-full h-full pl-4 pr-9 bg-[url('/src/assets/images/dashboard/select-down-icon.svg')] bg-no-repeat
                            bg-[95%] bg-[length:1.2rem] bg-white border border-gray-200 rounded-md appearance-none cursor-pointer outline-none capitalize" >
										<option value="" disabled>Select Industry</option>
										{
											allIndustry.length !== 0 && allIndustry.map((item) => (
												<option className="font-[Inter]" key={item} value={item}>{item}</option>
											))
										}
									</select>
								</div> */}
								<div className='relative w-[200px] h-[33px] ml-2'>
									<select
										value={selectedTimestamp}
										onChange={(e) => setSelectedTimestamp(e.target.value)}
										className="w-full h-full pl-4 pr-9 bg-[url('/src/assets/images/dashboard/select-down-icon.svg')] bg-no-repeat
                            			bg-[95%] bg-[length:1.2rem] bg-white border border-gray-200 rounded-md appearance-none cursor-pointer outline-none"
									>
										<option value="" disabled>Timestamp</option>
										{
											["Last 1 Day", "Last 1 Week", "Last 1 Month"].map((item) => (
												<option className="font-[Inter]" key={item} value={item}>{item}</option>
											))
										}
									</select>
								</div></> : <></>}

						</div>

						<div className="flex bg-white mt-3 flex-col overflow-y-auto min-h-[500px] w-full">
							<div className="bg-white my-3">
								{
									activeTab === 0 &&
									<div className="overflow-auto pb-8">
										{
											visitorList?.length > 0
												?
												<>
													<ContactTable visitorList={visitorList} navigate={navigate} handleEmailButtonClick={handleEmailButtonClick} handleSort={handleSort} sortKey={sortKey} sortOrder={sortOrder} />
													<div className='flex justify-end'>
														<ReactPaginate
															previousLabel={"<"}
															nextLabel={">"}
															breakLabel={"..."}
															breakClassName={"break-me"}
															pageCount={pageCount}
															marginPagesDisplayed={2}
															pageRangeDisplayed={4}
															onPageChange={handlePageClick}
															containerClassName={"pagination"}
															subContainerClassName={"pages pagination"}
															activeClassName={"active"}
															forcePage={currentPage}
														/>
													</div>
												</>
												:
												<div className="text-purple bg-grey-4 text-center h-52 flex mt-2 justify-center items-center bg">
													<div>Data not found</div>
												</div>
										}
									</div>
								}
							</div>
						</div>
					</>
				)}
		</>
	)
}

function ContactTable({ visitorList, navigate, handleEmailButtonClick, handleSort, sortKey, sortOrder }) {
	const [hovered, setHovered] = useState(null);
	return (
		<table className="w-full table-auto text-left mt-2 text-sm">
			<thead className="[&_th]:!border-t-0 [&_th]:!font-medium [&_th]:!p-3 [&_th]:text-[15px] [&_th]:!text-[#7B7B7B] ">
			<tr>
                    <th 
                        onMouseEnter={() => setHovered('ipAddress')} 
                        onMouseLeave={() => setHovered(null)} 
                        onClick={() => handleSort('ipAddress')} 
                        className="cursor-pointer"
                    >
                        IP Address 
                        <span className='ml-1'>
                            {hovered === 'ipAddress' || sortKey === 'ipAddress' ? (
                                sortOrder === 'asc' ? <span className='text-[12px]'>▲</span> : <span className='text-[12px]'>▼</span>
                            ) : ''}
                        </span>
                    </th>
                    <th 
                        onMouseEnter={() => setHovered('domain')} 
                        onMouseLeave={() => setHovered(null)} 
                        onClick={() => handleSort('domain')} 
                        className="cursor-pointer"
                    >
                        Domain 
                        <span className='ml-1'>
                            {hovered === 'domain' || sortKey === 'domain' ? (
                                sortOrder === 'asc' ? <span className='text-[12px]'>▲</span> : <span className='text-[12px]'>▼</span>
                            ) : ''}
                        </span>
                    </th>
                    <th 
                        onMouseEnter={() => setHovered('city')} 
                        onMouseLeave={() => setHovered(null)} 
                        onClick={() => handleSort('city')} 
                        className="cursor-pointer"
                    >
                        City 
                        <span className='ml-1'>
                            {hovered === 'city' || sortKey === 'city' ? (
                                sortOrder === 'asc' ? <span className='text-[12px]'>▲</span> : <span className='text-[12px]'>▼</span>
                            ) : ''}
                        </span>
                    </th>
                    <th 
                        onMouseEnter={() => setHovered('browser')} 
                        onMouseLeave={() => setHovered(null)} 
                        onClick={() => handleSort('browser')} 
                        className="cursor-pointer"
                    >
                        Browser 
                        <span className='ml-1'>
                            {hovered === 'browser' || sortKey === 'browser' ? (
                                sortOrder === 'asc' ? <span className='text-[12px]'>▲</span> : <span className='text-[12px]'>▼</span>
                            ) : ''}
                        </span>
                    </th>
                    <th 
                        onMouseEnter={() => setHovered('os')} 
                        onMouseLeave={() => setHovered(null)} 
                        onClick={() => handleSort('os')} 
                        className="cursor-pointer"
                    >
                        OS 
                        <span className='ml-1'>
                            {hovered === 'os' || sortKey === 'os' ? (
                                sortOrder === 'asc' ? <span className='text-[12px]'>▲</span> : <span className='text-[12px]'>▼</span>
                            ) : ''}
                        </span>
                    </th>
                    <th 
                        onMouseEnter={() => setHovered('createdAt')} 
                        onMouseLeave={() => setHovered(null)} 
                        onClick={() => handleSort('createdAt')} 
                        className="cursor-pointer"
                    >
                        Date 
                        <span className='ml-1'>
                            {hovered === 'createdAt' || sortKey === 'createdAt' ? (
                                sortOrder === 'asc' ? <span className='text-[12px]'>▲</span> : <span className='text-[12px]'>▼</span>
                            ) : ''}
                        </span>
                    </th>
                </tr>
			</thead>
			<tbody className="[&_td]:py-4 [&_td]:border-b [&_td]:border-white [&_td]:font-medium [&_td]:px-2 md:[&_td]:px-3">
				{
					visitorList && visitorList?.map((item, index) => {
						return (
							<tr key={item._id} className='cursor-pointer bg-grey-4' onClick={() => navigate(`/visitors/list/contact-details?websiteId=${item.websiteId}&visitorsId=${item?._id}&status=truedaction=true`)}>
								<td>{item?.ipAddress}</td>
								<td>{item?.domain || 'N/A'}</td>
								<td>{item?.city || 'N/A'}</td>
								<td>{item?.browser || 'N/A'}</td>
								<td>{item?.os || 'N/A'}</td>
								<td>
									{new Date(item?.createdAt).toLocaleDateString()} {new Date(item?.createdAt).toLocaleTimeString()}
								</td>
							</tr>
						)
					})
				}
			</tbody>
		</table >
	)
}
