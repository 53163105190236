import React, { useState } from 'react'
import importUserIcon from '../../assets/images/campaigns/import-user.svg'
import importContactIcon from '../../assets/images/campaigns/import-contact.svg'
import crossIcon from '../../assets/images/common/crossicons.svg'



export default function UploadCSVModel({ hide }) {
    return (
        <>
            <div className="bg-black/40 absolute z-20 inset-0 justify-center flex items-center">
                <div className="bg-white max-w-[950px] w-full p-8">
                    <div className="items-start flex cursor-pointer">
                        <img width={25} height={25} src={crossIcon} onClick={hide} alt="" />
                    </div>
                    {/* Main Content */}
                    <form className="max-w-[85%] mx-auto mt-[44px]">
                        <div className="flex justify-center gap-10 items-center">
                            <div className="bg-white rounded-[23px] border border-stone-300 w-[360px] h-[399px] flex justify-center items-center">
                                <div className="w-[85%] mx-auto">
                                    <img width={49} height={42} src={importUserIcon} alt="" />
                                    <h6 className="text-base font-bold mt-5">User Personas</h6>
                                    <span className="text-xs font-normal text-zinc-800">
                                        You can import up to 100,000 rows at a time.
                                    </span>
                                    <p className="text-neutral-500 text-xs/5 font-normal mt-8 text-balance">For accurate mapping, please include at least one of these fields: Company Name, Company Website, LinkedIn URL, and/or Contact Email. For more information, please visit our
                                        <a href="#" className="underline-offset-2 underline hover:text-purple hover:no-underline"> help center</a></p>
                                    <button type="button" className="btn !bg-green mt-[53px]">
                                        Select CSV
                                    </button>
                                </div>
                            </div>

                            <div className="bg-white rounded-[23px] border border-stone-300 w-[360px] h-[399px] flex justify-center items-center">
                                <div className=" w-[85%] mx-auto">
                                    <img width={49} height={42} src={importContactIcon} alt="" />
                                    <h6 className="text-base font-bold mt-5">Contact List</h6>
                                    <span className="text-xs font-normal text-zinc-800">
                                        You can import up to 100,000 rows at a time.
                                    </span>
                                    <p className="text-neutral-500 text-xs/5 font-normal mt-8 text-balance">For accurate mapping, please include at least one of these fields: Company Name, Company Website, LinkedIn URL, and/or Contact Email. For more information, please visit our
                                        <a href="#" className="underline-offset-2 underline hover:text-purple hover:no-underline"> help center</a></p>
                                    <button type="button" className="btn !bg-green mt-[53px]">
                                        Select CSV
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="text-center my-6">
                            <h2 className="text-dark font-bold !leading-normal text-2xl lg:text-[28px]">Upload CSV from one of the options above</h2>
                            <p className="text-base text-neutral-500">Try adding contacts through files or list</p>
                        </div>
                    </form>
                </div>
            </div>
        </>

    )
}
