import React, { useEffect, useState, useRef } from "react";
import crossIcon from '../../assets/images/common/crossicons.svg';
import { InviteSuccessMessage, showErrorMessage, showSuccessMessage } from "../../helper/showMessage";
import { date, object, string } from 'yup';
import { Validation, regex } from '../../helper/Messages';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Path } from "../../helper/path";
import axios from "axios";
import { FullScreenLoader } from "../common/FullScreenLoader";
import { Icon } from '@iconify/react';

const CircularPhotoWithIcon = ({ imageSrc, onImageChange, onRemoveImage }) => {
    const fileInputRef = useRef(null);

    const handleImageClick = () => {
        fileInputRef.current.click();
    };

    return (
        <div className="relative w-24 h-24 cursor-pointer">
            <img
                src={imageSrc || "https://via.placeholder.com/150"}
                alt="Circular"
                className="w-full h-full object-cover rounded-full"
                onClick={handleImageClick}
            />
            <div className="absolute bottom-0 right-0 w-6 h-6 bg-blue-500 rounded-full flex items-center justify-center" onClick={handleImageClick}>
                <Icon icon={'mdi:camera-outline'} className="text-white w-4 h-4" />
            </div>
            {imageSrc && (
                <div className="absolute top-0 right-0 w-6 h-6 bg-red-500 rounded-full flex items-center justify-center" onClick={onRemoveImage}>
                    <Icon icon={'carbon:close-filled'} className="text-red w-6 h-6" />
                </div>
            )}
            <input
                type="file"
                accept="image/*"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={onImageChange}
            />
        </div>
    );
};

export default function UpdateUserDetailModel({ hide, session }) {
    const [isLoader, setIsLoader] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [imageError, setImageError] = useState('');

    const validationSchema = object().shape({
        firstName: string().trim().required(Validation?.firstNameRequired).matches(regex?.nameRegex, Validation?.fullNameInvalid),
        lastName: string().trim().required(Validation?.lastNameRequired).matches(regex?.nameRegex, Validation?.fullNameInvalid),
        email: string(),
        profileUrl: string()
    });
    const { register: registerCredential, handleSubmit, formState: { errors }, setValue } = useForm({ resolver: yupResolver(validationSchema) });
    console.log(errors);
    const onSubmit = async (data, event) => {
        console.log(data);
        if (imageError) {
            showErrorMessage("Please fix the image error before submitting.");
            return;
        }
        try {
            event.preventDefault();
            setIsLoader(true);
            let profileUrl = selectedFile ? await uploadImage(selectedFile) : '';
            await axios.put(Path.updateUser + session?.data?.userInfo?._id,
                {
                    firstName: data.firstName,
                    lastName: data.lastName,
                    profileUrl: profileUrl || data.profileUrl || ''
                },
                {
                    headers: {
                        Authorization: "Bearer " + session?.data?.token
                    }
                })
            setIsLoader(false);
            let userInfo = session?.data?.userInfo;
            userInfo.firstName = data.firstName;
            userInfo.lastName = data.lastName;
            if (profileUrl) {
                userInfo.profileUrl = profileUrl;
            } else {
                userInfo.profileUrl = selectedImage
            }
            localStorage.setItem('UserInfo', JSON.stringify({
                ...session?.data?.userInfo,
                ...userInfo
            }));
            showSuccessMessage("User detail updated successfully");
            hide();
            setTimeout(() => {
                window.location.reload();
            }, 100);
        } catch (error) {
            console.log(error)
            setIsLoader(false);
            showErrorMessage("Something went wrong");
        }
    }

    const uploadImage = async (image) => {
        try {
            let formData = new FormData();
            formData.append("file", image);
            let response = await axios.post(Path.uploadImage, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: "Bearer " + session?.token
                }
            });
            return response.data?.publicUrl;
        } catch (error) {
            console.log(error)
            return '';
        }
    }

    useEffect(() => {
        if (session?.data?.userInfo) {
            setValue("firstName", session?.data?.userInfo?.firstName || '')
            setValue("lastName", session?.data?.userInfo?.lastName || '')
            setValue("email", session?.data?.userInfo?.email || '')
            setValue("profileUrl", session?.data?.userInfo?.profileUrl || '')
            setSelectedImage(session?.data?.userInfo?.profileUrl || '')
        }
    }, [session?.token]);

    const handleImageChange = (e) => {
        setImageError('');
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            if (file.size > 1048576) { // 1 MB = 1048576 bytes
                setImageError("Image size should be less than 1 MB.");
                setSelectedFile(null);
                setSelectedImage(null);
            } else {
                setSelectedFile(file);
                setSelectedImage(URL.createObjectURL(file));
            }
        }
    };

    const handleRemoveImage = () => {
        setSelectedFile(null);
        setSelectedImage(null);
    };

    return (
        <>
            {isLoader ? <FullScreenLoader /> : " "}
            <div className={` bg-black/40 absolute z-20 inset-0 flex items-center`}>
                <div className="bg-white p-[45px] rounded-[11px] shadow mx-auto max-w-[700px] w-full">
                    {/* Popup Header */}
                    <div className="h-10 md:h-14 flex justify-between">
                        <h4 className="text-left font-bold text-dark text-xl ">Update Detail</h4>
                        <div className="items-start flex cursor-pointer">
                            <img width={25} height={25} src={crossIcon} onClick={hide} alt="" />
                        </div>
                    </div>

                    {/* Circular Photo with Icon and File Input */}
                    <div className="flex flex-col items-center my-4">
                        <CircularPhotoWithIcon imageSrc={selectedImage} onImageChange={handleImageChange} onRemoveImage={handleRemoveImage} />
                        {imageError && <div className="error-css">{imageError}</div>}
                    </div>

                    <form onSubmit={handleSubmit(onSubmit)} className="text-left">
                        {/* Popup Main Section */}
                        <div className="">
                            <label className="custom-label !text-neutral-500 !font-normal">First Name</label>
                            <input
                                type="text" name="name" placeholder="Enter First Name" {...registerCredential("firstName")}
                                className="custom-input !rounded-md"
                            />
                            {
                                errors.firstName && <div className="error-css">{errors?.firstName?.message}</div>
                            }
                        </div>
                        <div className="mt-5">
                            <label className="custom-label !text-neutral-500 !font-normal">Last Name</label>
                            <input
                                type="text" name="name" placeholder="Enter Last Name" {...registerCredential("lastName")}
                                className="custom-input !rounded-md"
                            />
                            {
                                errors.lastName && <div className="error-css">{errors?.lastName?.message}</div>
                            }
                        </div>
                        <div className="mt-5">
                            <label className="custom-label !text-neutral-500 !font-normal">User Email Address</label>
                            <input className="custom-input !rounded-md" type="text" name="" placeholder="Enter a valid e-mail address" {...registerCredential("email")} disabled />
                            {
                                errors.email && <div className="error-css">{errors?.email?.message}</div>
                            }
                        </div>

                        {/* Popup Footer */}
                        <div className="mt-10 flex justify-end items-center gap-6">
                            <button type="submit" className="w-full h-[54px] rounded-md bg-green text-white text-xl font-[500] hover:scale-105 duration-300">Update</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}
