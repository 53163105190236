import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../_providers/AuthProvider'
import axios from 'axios'
import { Path } from '../../helper/path'
import { useLocation } from 'react-router-dom'
import { showErrorMessage } from '../../helper/showMessage'
import { FullScreenLoader } from '../common/FullScreenLoader'

export default function CampaignReports() {
  const { session } = useContext(AuthContext)
  const [isLoader, setIsLoader] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get('id');
  const [analyticsData, setAnalyticsData] = useState("")


  useEffect(() => {
    async function getCampaignAnalytics() {
      setIsLoader(true)
      await axios.get(`${Path.campaignAnalytics}${id}`,
        {
          headers: {
            Authorization: "Bearer " + session?.data?.token,
          }
        }
      ).then((success) => {
        setAnalyticsData(success?.data)
        setIsLoader(false)
      }).catch((error) => {
        console.log(error?.response?.data)
        setIsLoader(false)
        showErrorMessage(error?.response?.data?.error || 'Something Went Wrong.')

      })
    }
    session?.data?.token && getCampaignAnalytics();
  }, [session])


  return (
    <>
      <div>
        {isLoader ?
          <FullScreenLoader />
          :
          <div className="p-3 bg-white">
            {/* <div>
          <h4 className="text-dark text-base font-bold lg:text-lg">Sequence by Contact</h4>
          <div className="flex gap-4 mt-1">
            {sequencecontact.map((sequence, id) => (
              <div key={id} className="flex flex-col justify-center items-center border border-gray-200 rounded-md flex-[0_1_315px] h-[140px] lg:h-[187px] gap-1">
                <h4 className="font-semibold text-dark text-xl md:text-2xl lg:text-4xl">{sequence.value}</h4>
                <p className="font-medium text-gray-6 text-sm md:text-base">{sequence.title}</p>
                <span className="bg-[#CFFFEE] rounded-sm p-1 text-green text-sm">{sequence.addnumber}</span>
              </div>
            )
            )}
          </div>
        </div> */}
            <div className="mt-8">
              <h4 className="text-dark text-[15px] font-bold"> Sequence by Email</h4>
              <div className="flex gap-2 mt-1 ">
                {/* <div className="flex flex-col justify-center items-center border border-gray-200 rounded-md flex-[0_1_315px] h-[140px] lg:h-[187px] gap-1">
              <h4 className="font-semibold text-dark text-xl md:text-2xl lg:text-4xl">{analyticsData?.sent}</h4>
              <p className="font-medium text-gray-6 text-sm md:text-base">Sent</p>
            </div> */}
                <div className="flex flex-col justify-center items-center border border-gray-200 rounded-md flex-[0_1_315px] h-[140px] lg:h-[187px] gap-1">
                  <h4 className="font-semibold text-dark text-[22px]">{analyticsData?.delivered}</h4>
                  <p className="font-medium text-gray-6 text-sm">Delivered</p>
                  <span className="bg-[#CFFFEE] rounded-sm p-1 text-green text-sm">{parseFloat(analyticsData?.percentage?.successRate?.toFixed(2))}%</span>
                </div>
                <div className="flex flex-col justify-center items-center border border-gray-200 rounded-md flex-[0_1_315px] h-[140px] lg:h-[187px] gap-1">
                  <h4 className="font-semibold text-dark text-[22px]">{analyticsData?.click}</h4>
                  <p className="font-medium text-gray-6 text-sm">Clicked</p>
                  <span className="bg-[#CFFFEE] rounded-sm p-1 text-green text-sm">{parseFloat(analyticsData?.percentage?.clickRate?.toFixed(2))}%</span>
                </div>
                <div className="flex flex-col justify-center items-center border border-gray-200 rounded-md flex-[0_1_315px] h-[140px] lg:h-[187px] gap-1">
                  <h4 className="font-semibold text-dark text-[22px]">{analyticsData?.reply}</h4>
                  <p className="font-medium text-gray-6 text-sm">Reply</p>
                  <span className="bg-[#CFFFEE] rounded-sm p-1 text-green text-sm">{parseFloat(analyticsData?.percentage?.replyRate?.toFixed(2))}%</span>
                </div>
                <div className="flex flex-col justify-center items-center border border-gray-200 rounded-md flex-[0_1_315px] h-[140px] lg:h-[187px] gap-1">
                  <h4 className="font-semibold text-dark text-[22px]">{analyticsData?.spamBlocked}</h4>
                  <p className="font-medium text-gray-6 text-sm">Spam Blocked</p>
                  <span className="bg-[#CFFFEE] rounded-sm p-1 text-green text-sm">{parseFloat(analyticsData?.percentage?.spamBlockedRate?.toFixed(2))}%</span>
                </div>
                <div className="flex flex-col justify-center items-center border border-gray-200 rounded-md flex-[0_1_315px] h-[140px] lg:h-[187px] gap-1">
                  <h4 className="font-semibold text-dark text-[22px]">{analyticsData?.interested}</h4>
                  <p className="font-medium text-gray-6 text-sm">Interested</p>
                  <span className="bg-[#CFFFEE] rounded-sm p-1 text-green text-sm">{parseFloat(analyticsData?.percentage?.interestedRate?.toFixed(2))}%</span>
                </div>
                <div className="flex flex-col justify-center items-center border border-gray-200 rounded-md flex-[0_1_315px] h-[140px] lg:h-[187px] gap-1">
                  <h4 className="font-semibold text-dark text-[22px]">{analyticsData?.optOut}</h4>
                  <p className="font-medium text-gray-6 text-sm">optOut</p>
                  <span className="bg-[#CFFFEE] rounded-sm p-1 text-green text-sm">{parseFloat(analyticsData?.percentage?.optOutRate?.toFixed(2))}%</span>
                </div>
              </div>
            </div>
            {/* <div className="mt-5">
          <h4 className="text-black-2 text-base font-bold lg:text-lg">Most Engaged Audience</h4>
          <div className="mt-2">
            <ul className="flex [&_li]:w-[300px] [&_li]:cursor-pointer [&_li.active]:text-purple [&_li.active]:font-semibold gap-3 items-center text-gary-3 font-medium text-base border-b border-gary-3 px-4 py-1 lg:text-lg">
              <li className="active">Interested Rate</li>
              <li>Open Rate</li>
              <li>Reply Rate</li>
            </ul>
            <div className="flex gap-4 mt-4 ">
              <div className="w-80 rounded-md border border-gary-3">
                <h4 className="font-bold text-dark  p-2 text-sm lg:text-base">Person’s title</h4>
                <ul className="p-2 text-base md:text-lg text-dark">
                  <li className="flex gap-5">
                    <span className="mr-auto">ceo</span>
                    <span>0</span>
                    <span>0%</span>
                  </li>
                  <li className="flex gap-5">
                    <span className="mr-auto">ceo</span>
                    <span>0</span>
                    <span>0%</span>
                  </li>
                  <li className="flex gap-5">
                    <span className="mr-auto">ceo</span>
                    <span>0</span>
                    <span>0%</span>
                  </li>
                  <li className="flex gap-5">
                    <span className="mr-auto">ceo</span>
                    <span>0</span>
                    <span>0%</span>
                  </li>
                </ul>
              </div>
              <div className="w-80 rounded-md border border-gary-3">
                <h4 className="font-bold text-dark  p-2 text-sm lg:text-base">Person’s title</h4>
                <ul className="p-2 text-base md:text-lg text-dark">
                  <li className="flex gap-5">
                    <span className="mr-auto">ceo</span>
                    <span>0</span>
                    <span>0%</span>
                  </li>
                  <li className="flex gap-5">
                    <span className="mr-auto">ceo</span>
                    <span>0</span>
                    <span>0%</span>
                  </li>
                  <li className="flex gap-5">
                    <span className="mr-auto">ceo</span>
                    <span>0</span>
                    <span>0%</span>
                  </li>
                  <li className="flex gap-5">
                    <span className="mr-auto">ceo</span>
                    <span>0</span>
                    <span>0%</span>
                  </li>
                </ul>
              </div>
              <div className="w-80 rounded-md border border-gary-3">
                <h4 className="font-bold text-dark p-2 text-sm lg:text-base">Person’s title</h4>
                <ul className="p-2 text-base md:text-lg text-dark">
                  <li className="flex gap-5">
                    <span className="mr-auto">ceo</span>
                    <span>0</span>
                    <span>0%</span>
                  </li>
                  <li className="flex gap-5">
                    <span className="mr-auto">ceo</span>
                    <span>0</span>
                    <span>0%</span>
                  </li>
                  <li className="flex gap-5">
                    <span className="mr-auto">ceo</span>
                    <span>0</span>
                    <span>0%</span>
                  </li>
                  <li className="flex gap-5">
                    <span className="mr-auto">ceo</span>
                    <span>0</span>
                    <span>0%</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div> */}
          </div>}


      </div>
    </>

  )
}
