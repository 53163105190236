import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../_providers/AuthProvider';
import axios from 'axios';
import { Path } from '../../helper/path';
import { FullScreenLoader } from '../common/FullScreenLoader';

function formatDate(date) {
    return new Date(date).toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
    })
}
function convertActivityToReadableFormat(activities) {
    const _activities = activities || [];
    return _activities.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    ).map((activity) => {
        switch (`${activity.resource}_${activity.action}`) {
            case 'CAMPAIGN_CONTACT_CREATE':
                return `<b>${activity?.metadata?.name}</b> is added to the campaign <div style="font-size: 14px; color: #AEAEAE;">${formatDate(activity?.createdAt)}</div>`
            case 'CAMPAIGN_CONTACT_DELETE':
                return `<b>${activity?.metadata?.name}</b> is deleted from the campaign <div style="font-size: 14px; color: #AEAEAE;">${formatDate(activity?.createdAt)}</div>`
            case 'CAMPAIGN_SEND_EMAIL_CREATE':
                return `<b>${activity?.metadata?.name}(${activity?.metadata?.email})</b> is sent an email with title <b> ${activity?.metadata?.title}</b> <div style="font-size: 14px; color: #AEAEAE;">${formatDate(activity?.createdAt)}</div>`
            default:
                return formatCampaignAndTemplateRelatedLogs(activity);
        }
    });
}

function formatCampaignAndTemplateRelatedLogs(activity) {
    const actionMapping = {
        CREATE: 'created',
        'CREATE VARIATION': 'created a variation for',
        UPDATE: 'updated',
        'RESTORE VERSION': 'restored a version for',
    };

    const resourceMapping = {
        CAMPAIGN: 'campaign',
        STEP: 'step',
        TEMPLATE: 'template',
    };
    const user = `${activity?.userId?.firstName} ${activity?.userId?.lastName}`;
    const action = actionMapping[activity?.action] || activity?.action.toLowerCase();
    const resource = resourceMapping[activity?.resource] || activity?.resource.toLowerCase();

    const timestamp = formatDate(activity.createdAt);
    let message = `${user} ${action} ${resource}`;

    if (activity.metadata && activity.metadata.type) {
        message += ` (${activity.metadata.type})`;
    }

    message += `<div style="font-size: 14px; color: #AEAEAE;">${timestamp}</div>`;

    return message;
}



export default function CampaignActivityLog({ id }) {
    const { session } = useContext(AuthContext);
    const [activityLogs, setActivityLogs] = useState([])
    const [isLoader, setIsLoader] = useState(false);

    useEffect(() => {
        async function GetData() {
            setIsLoader(true)
            await axios.get(`${Path.GetCampaignActivity}${id}`,
                {
                    headers: {
                        Authorization: "Bearer " + session?.data?.token,
                    }
                }
            ).then((success) => {
                setActivityLogs(convertActivityToReadableFormat(success?.data))
                setIsLoader(false)
            }).catch((error) => {
                console.log(error)
                setIsLoader(false)
            })
        }
        session?.data?.token && id && GetData()
    }, [session, id])
    return (
        <>
            {isLoader
                ?
                <FullScreenLoader /> :
                <div className="bg-white">
                    <ul className="space-y-3">
                        {
                            activityLogs?.length !== 0 ? activityLogs?.map((list, index) => {
                                return (
                                    <li key={index} className="border border-gray-200 hover:bg-violet-100 p-3">
                                        <div className="text-zinc-800 text-sm font-normal leading-normal">
                                            <div dangerouslySetInnerHTML={{ __html: list }} />
                                        </div>
                                    </li>
                                )
                            })
                                :
                                <div className='flex justify-center text-purple gap-2 p-6 mt-6 rounded-[34px] bg-white h-[250px]'>
                                    <div className='flex items-center justify-center'>
                                        No Activity Found
                                    </div>
                                </div>
                        }
                    </ul>
                </div>
            }


        </>

    )
}
