import React, { useEffect, useState } from 'react'
import { Icon } from '@iconify/react';
import backIcon from "../../assets/images/campaigns/backbutton.svg"
import ErrorIcons from '../../assets/images/brand-management/errorIcon.svg'
import SuccessIcon from '../../assets/images/brand-management/successIcon.svg'
import MappingError from '../../assets/images/brand-management/mapError.svg'
import CheckMark from '../../assets/images/brand-management/Checkmark.svg'
import { Path } from '../../helper/path';
import axios from 'axios';
import { showSuccessMessage } from '../../helper/showMessage';


export default function NewSelectListModal({ setOpenImportModal, openImportModal, contactData, errorMessage, fileName, dynamicVarList, setDynamicVarList, UploadHandleClick, setMapOptions }) {

    const [isLoader, setIsLoader] = useState(false)
    const [customModal, setCustomModal] = useState(false)
    const [customFieldName, setCustomFieldName] = useState('');
    const [selectedOptions, setSelectedOptions] = useState([]);
    if (!contactData || contactData.length === 0) {
        return null; // Render nothing if contactData is undefined, null, or empty
    }
    // Extracting the keys from the first object in your array
    const headers = Object.keys(contactData[0]);

    const handleOptionSelect = (selectedValue, index) => {
        setSelectedOptions(prevOptions => ({
            ...prevOptions,
            [index]: selectedValue
        }));
        setMapOptions(prevOptions => ({
            ...prevOptions,
            [index]: selectedValue
        }));
    };



    const handleAddCustomField = () => {
        const transformedFieldName = `data.${customFieldName.replace(/\s+/g, '')}`;
        if (transformedFieldName) {
            setDynamicVarList(prevList => [...prevList, transformedFieldName]);
            setCustomFieldName('');
            showSuccessMessage("Custom Field Added");
        }
    };

    // Check if all options have been selected
    const isImportEnabled = Object.keys(selectedOptions).length === headers.length;

    return (
        <>
            <div className={`${openImportModal ? 'block' : 'hidden'} bg-black/40 absolute z-20 inset-0 flex items-center`}>
                <div className="bg-white border border-gary-3 mx-auto max-w-[95%] w-full p-6">
                    {/* Popup Header */}
                    <h4 className="font-bold text-center text-dark text-xl md:text-[26px] h-10 md:h-16">Import Contact</h4>

                    <div className="flex flex-col gap-5 border-b border-[#CFCFCF] py-3">
                        <div className="flex justify-between items-center">
                            <h6 className="text-dark text-xl font-medium">Column Mappings</h6>
                            <div className="text-right">
                                <h6 className="cursor-pointer text-purple text-base/4 font-medium">{fileName}</h6>
                                {/* <span className="text-neutral-500 text-xs font-medium">4 rows included</span> */}
                            </div>
                        </div>
                        <p className="text-neutral-500 font-normal text-sm md:text-base">Each column below must be mapped to the related field in Aye Campaigns. When possible, we will automatically detect and map related fields for you. For any that we’re unable to map, please select the Aye Campaign field that most accurately describes the type of data you're importing. This will ensure the information in your records is updated accurately.</p>
                        <p className=" text-neutral-500 font-normal text-sm md:text-base ">

                            Don't see the field you want to map to in the drop-down in Aye Campaign
                            <div className='flex items-center gap-2 mt-2'>
                                <div className=" text-purple cursor-pointer items-center" onClick={() => setCustomModal(!customModal)}>
                                    <label className='text-lg cursor-pointer'>Create a custom field</label>
                                </div>
                                {customModal && (
                                    <div className="flex items-center gap-2 ">
                                        <input
                                            className='p-2 outline-none border rounded-md'
                                            type="text"
                                            value={customFieldName}
                                            onChange={(e) => { e.stopPropagation();; setCustomFieldName(e.target.value) }}
                                            placeholder="Enter custom field name"
                                        />
                                        <button className='bg-purple px-4 py-2 text-white rounded-md cursor-pointer' onClick={handleAddCustomField}>Add</button>
                                    </div>
                                )}
                            </div>
                        </p>
                    </div>

                    <div className="mt-10">
                        <div className="flex gap-2 items-center">
                            <h5 className="text-base font-semibold text-dark">Note: </h5>
                            <ul className="flex gap-2 items-center flex-wrap">
                                <li className="flex items-center bg-green rounded gap-1 text-white h-[22px] text-xs font-medium ps-2 pr-3">
                                    <span>
                                        <img src={SuccessIcon} alt="" />
                                    </span>
                                    Mapped columns
                                </li>
                                <li className="flex items-center bg-rose-700 rounded gap-1 text-white h-[22px] text-xs font-medium ps-2 pr-3">
                                    <span>
                                        <img src={ErrorIcons} alt="" />
                                    </span>
                                    Unmapped columns
                                </li>
                            </ul>
                        </div>
                        <div className="h-[45dvh] overflow-y-auto my-5">
                            <div className="flex flex-col overflow-y-auto max-h-[500px] w-full">
                                <h3 className="my-4">Contact Data</h3>
                                <div className="text-sm text-red-color py-4">{errorMessage}</div>
                                <table className="w-full">
                                    <thead>
                                        <tr className="border-grey-1 border">
                                            <th className="p-2 px-4 text-left border" key={0}>
                                                #
                                            </th>
                                            {headers.map((header, index) => {
                                                if (header === "listId" || header === "addedBy" || header === "campaignId")
                                                    return "";
                                                return (
                                                    <th
                                                        className="p-2 px-4 text-left border"
                                                        key={header}
                                                    >
                                                        <div className="flex gap-2 items-center">
                                                            <h5 className="font-semibold text-dark text-sm lg:text-base">{header}</h5>
                                                            {selectedOptions[index] ? <span> <img src={CheckMark} alt="" /></span> : <span> <img src={MappingError} alt="" /></span>}
                                                        </div>
                                                        <select
                                                            className="mt-2 w-full min-w-max outline-none px-3 h-8 bg-white border border-stone-300 font-[Inter] !rounded-md !text-neutral-500 !font-normal bg-[url('/src/assets/images/dashboard/select-down-icon.svg')] bg-no-repeat bg-[97%] bg-[length:1.3rem] appearance-none !pr-8"
                                                            onChange={(e) => handleOptionSelect(e.target.value, index)}
                                                            value={selectedOptions[index] || ''}
                                                        >
                                                            <option value="" disabled>Select</option>
                                                            {dynamicVarList.map((item) => {
                                                                // Check if the current item is already selected in any other dropdown

                                                                const displayItem = item.startsWith("data.") ? item.slice(5) : item;
                                                                // Check if the current item is already selected in any other dropdown
                                                                const isOptionDisabled = Object.values(selectedOptions).includes(displayItem);

                                                                return (
                                                                    <option
                                                                        key={item}
                                                                        disabled={isOptionDisabled}
                                                                        value={displayItem}
                                                                        className={isOptionDisabled ? '!text-gray-400 capitalize' : '!text-black capitalize'}
                                                                    >
                                                                        {displayItem}
                                                                    </option>
                                                                );
                                                            })}
                                                        </select>
                                                    </th>
                                                );
                                            })}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {contactData.map((data, index) => {
                                            return (
                                                <tr key={index} className="border-grey-1 border">
                                                    <td className="p-2 px-4 border max-w-[240px]" key={0}>
                                                        {index + 1}
                                                    </td>
                                                    {headers.map((header) => {
                                                        // Skip rendering the cell if the header is 'addedBy'
                                                        if (
                                                            header === "listId" ||
                                                            header === "addedBy" ||
                                                            header === "campaignId"
                                                        )
                                                            return null;
                                                        return (
                                                            <td
                                                                className="p-2 px-4 border max-w-[240px] !break-words"
                                                                key={header}
                                                            >
                                                                {String(data[header])}
                                                            </td>
                                                        );
                                                    })}
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="mt-8 flex justify-end items-center gap-6">
                        <button onClick={() => { setOpenImportModal(!openImportModal); setSelectedOptions([]) }} type="button" className="px-4 flex gap-1 items-center border-r border-[#7B7B7B] text-neutral-500 ">
                            <img src={backIcon} alt="" />
                            <span className="text-base font-bold">Back</span>
                        </button>
                        <button
                            type='submit'
                            onClick={UploadHandleClick}
                            className={`flex items-center bg-green text-white rounded-full py-1 text-base h-10 px-16 font-[600] font-base`}
                            disabled={!isImportEnabled}
                        >
                            <span>{`Import`}</span>
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

