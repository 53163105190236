import React, { useContext, useEffect, useState } from 'react'
import { DownArrow, Filter, TrendDown, TrendUp, UpArrow } from '../../helper/SvgIcons';
import ReactApexChart from 'react-apexcharts';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import GeoMap from '../common/GeoMap';
import ReactTooltip from "react-tooltip";
import axios from 'axios';
import { AuthContext } from '../../_providers/AuthProvider';
import { Path } from '../../helper/path';


ChartJS.register(ArcElement, Tooltip, Legend);

export default function ReportAnalytics() {
    const { session } = useContext(AuthContext);
    const [website, setWebsite] = useState([]);
    const [selectedWebsiteId, setSelectedWebsiteId] = useState("");
    const [visitorCount, setVisitorCount] = useState([])
    const [countryCount, setCountryCount] = useState([])
    const [pageViewCount, setpageViewCount] = useState([])
    const [browserCount, setBrowserCount] = useState([])
    const [pageReport, setPageReport] = useState([])
    const [countryReport, setCountryReport] = useState([])
    const [series, setSeries] = useState([
        {
            name: '',
            data: [],
            show: false
        }, {
            name: '',
            data: [],
            show: false
        },
    ])
    const [categories, setCategories] = useState([])

    async function GetWebsite() {
        await axios.get(`${Path.GetWebsites}`, {
            headers: {
                Authorization: "Bearer " + session?.data?.token,
            },
        }).then((success) => {
            setWebsite(success?.data)
        }).catch((error) => {
            console.log(error)
        })
    }

    async function GetVisitorCount() {
        await axios.get(`${Path.GetVisitorCount}${selectedWebsiteId}`, {
            headers: {
                Authorization: "Bearer " + session?.data?.token,
            },
        }).then((success) => {
            setVisitorCount(success?.data)
        }).catch((error) => {
            console.log(error)
        })
    }

    async function GetCountryCount() {
        await axios.get(`${Path.GetCountryCount}${selectedWebsiteId}`, {
            headers: {
                Authorization: "Bearer " + session?.data?.token,
            },
        }).then((success) => {
            setCountryCount(success?.data)
        }).catch((error) => {
            console.log(error)
        })
    }

    async function GetPageViewCount() {
        await axios.get(`${Path.GetPageViewCount}${selectedWebsiteId}`, {
            headers: {
                Authorization: "Bearer " + session?.data?.token,
            },
        }).then((success) => {
            setpageViewCount(success?.data)
        }).catch((error) => {
            console.log(error)
        })
    }

    async function GetBrowserCount() {
        await axios.get(`${Path.GetBrowserCount}${selectedWebsiteId}`, {
            headers: {
                Authorization: "Bearer " + session?.data?.token,
            },
        }).then((success) => {
            setBrowserCount(success?.data)
        }).catch((error) => {
            console.log(error)
        })
    }

    async function GetPageReport() {
        await axios.get(`${Path.GetPageReport}${selectedWebsiteId}`, {
            headers: {
                Authorization: "Bearer " + session?.data?.token,
            },
        }).then((success) => {
            setPageReport(success?.data)
        }).catch((error) => {
            console.log(error)
        })
    }

    async function GetWeeklyPageReport() {
        await axios.get(`${Path.GetWeeklyPageReport}${selectedWebsiteId}`, {
            headers: {
                Authorization: "Bearer " + session?.data?.token,
            },
        }).then((success) => {
            setSeries([
                {
                    name: success?.data?.datasets[0]?.label,
                    data: success?.data?.datasets[0]?.data,
                    show: false
                },
                {
                    name: success?.data?.datasets[1]?.label,
                    data: success?.data?.datasets[1]?.data,
                    show: false
                }
            ])
            setCategories(success?.data?.labels)
        }).catch((error) => {
            console.log(error)
        })
    }

    async function GetCountryReport() {
        await axios.get(`${Path.GetCountryReport}${selectedWebsiteId}`, {
            headers: {
                Authorization: "Bearer " + session?.data?.token,
            },
        }).then((success) => {
            setCountryReport(success?.data)
        }).catch((error) => {
            console.log(error)
        })
    }




    useEffect(() => {
        GetWebsite();
    }, [session?.data?.token])

    useEffect(() => {
        if (selectedWebsiteId) {
            GetVisitorCount();
            GetCountryCount();
            GetPageViewCount();
            GetBrowserCount();
            GetPageReport();
            GetWeeklyPageReport();
            GetCountryReport();
        }
    }, [selectedWebsiteId]);


    return (
        <div className='w-full min-h-full flex flex-col gap-6 select-none'>
            <RealtimeDashboardSubHeader website={website} setSelectedWebsiteId={setSelectedWebsiteId} selectedWebsiteId={selectedWebsiteId} />

            <section className="flex flex-row xl:space-x-4 flex-wrap xl:flex-nowrap">
                <VistorStats name={"Visitors"} value={visitorCount?.total} show="false" />
                <VistorStats name={"Daily Visitors"} value={visitorCount?.daily} show="true" />
                <VistorStats name={"Weekly Visitors"} value={visitorCount?.weekly} show="false" />
                <VistorStats name={"Monthly Visitors"} value={visitorCount?.monthly} show="true" />
            </section>

            <section className="flex md:flex-row flex-col gap-6 ">
                <div className='basis-full sm:basis-4/12 bg-white rounded-3xl flex flex-col justify-between p-4'>
                    <RealTimeWidgets name={"Views"} value={pageViewCount[0]?.totalUniqueVisitorsAllUrls || "0"} />
                    <RealTimeWidgets name={"Visitors"} value={visitorCount?.total || "0"} />
                    <RealTimeWidgets name={"Events"} value={"0"} />
                    <RealTimeWidgets borderNone="border-none" name={"Countries"} value={countryCount?.country || "0"} />
                </div>
                <div className='basis-full sm:basis-8/12 rounded-3xl bg-white px-8 py-4 flex flex-col justify-between'>
                    <div className='flex justify-between '>
                        <span className='text-xl font-bold'>Analytics</span>
                    </div>
                    <div className=''>
                        <RealChart series={series} categories={categories} />
                    </div>
                </div>
            </section>
            <section className='w-full h-auto flex flex-col md:flex-row justify-around rounded-3xl p-6 bg-white'>
                <Map countries={countryReport} />
            </section>
            <section className='xl:flex pb-3'>
                <div className="w-full ">
                    <div className="flex flex-col xl:flex-row flex-wrap pt-4">
                        {/* <div className='xl:w-4/12 xl:pr-4 my-2'>
                            <ReportDoughnutChart details={CampaignDetails} heading={"Campaign Report"} />
                        </div> */}
                        <div className='xl:w-6/12 xl:pr-4 my-2'>
                            <PageDoughnutChart details={pageReport} heading={"Page Report"} />
                        </div>
                        <div className='xl:w-6/12 my-2'>
                            <BrowserDoughnutChart details={browserCount} heading={"Browser Report"} />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}


function RealtimeDashboardSubHeader({ website, setSelectedWebsiteId, selectedWebsiteId }) {
    const handleWebsiteChange = (event) => {
        const selectedId = event?.target?.value;
        setSelectedWebsiteId(selectedId);
        localStorage.setItem('selectedWebsiteId', selectedId);
    };

    useEffect(() => {
        const storedWebsiteId = localStorage.getItem('selectedWebsiteId');
        if (storedWebsiteId && storedWebsiteId !== selectedWebsiteId) {
            setSelectedWebsiteId(storedWebsiteId);
        } else {
            if (website?.length) {
                setSelectedWebsiteId(website[0]?._id);
            }
        }
    }, [setSelectedWebsiteId, selectedWebsiteId, website]);

    return (
        <>
            <div className='w-full flex justify-between items-start'>
                <div className='flex flex-col justify-center items-start'>
                    <span className='large-title'>
                        {`Website Visitors`}
                    </span>
                </div>
                <div className='relative w-[200px] h-[35px]'>
                    <select onChange={handleWebsiteChange} value={selectedWebsiteId} className="w-full h-full pl-4 pr-9 bg-[url('/src/assets/images/dashboard/select-down-icon.svg')] bg-no-repeat
                            bg-[95%] bg-[length:1.2rem] bg-white border border-gray-200 rounded-md appearance-none cursor-pointer outline-none" >
                        <option value="" disabled selected>Website</option>
                        {
                            website.length !== 0 && website.map((item) => (
                                <option className="font-[Inter]" key={item._id} value={item._id}>{item.name}</option>
                            ))
                        }
                    </select>
                </div>
            </div>
        </>
    );
};

function RealTimeWidgets({ name, value, percent, borderNone }) {
    let status = true

    percent = percent - 100
    if (percent <= 0) {
        status = false;
    }
    if (value === null) {
        value = 0;
    }

    return (
        <div className='flex p-2 rounded-xl px-4'>
            <div className={`flex justify-between w-full border-b-2 pb-3 ${borderNone}`} >
                <div className='flex flex-col'>
                    <span className='font-[400px] text-sm text-gray-6'>{name}</span>
                    <span className='sm:text-xl font-bold'>{value}</span>
                </div>
                {/* <div className=' flex justify-center items-center'>
                    {
                        (status)
                            ?
                            <div className=' bg-light-green flex justify-evenly items-center rounded-md p-1'>
                                <div className='flex items-center'>
                                    <TrendUp />
                                    <div className='ml-2 text-[12px] w-[80%] text-green'>{(percent) ? `${percent} %` : `${0} %`}</div>
                                </div>
                            </div>
                            :
                            <div className=' bg-light-red flex justify-evenly items-center rounded-md p-1'>
                                <div className='flex items-center'>
                                    <TrendDown />
                                    <div className='ml-2 text-[12px] w-[80%] text-[#FF6581]'>{(percent) ? `${percent} %` : `${0} %`}</div>
                                </div>
                            </div>
                    }
                </div> */}
            </div>
        </div>
    )
}

function RealChart({ series, categories }) {
    const [options, setOptions] = useState({
        chart: {

            toolbar: {
                show: false,
                tools: {
                    download: false
                }
            },
            type: 'bar',
            height: 250,
            foreColor: '#7f8c8d',
        },

        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '52%',
                endingShape: 'rounded',
                borderRadius: 3,

            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        colors: ["#D6D6D6", "#7F5FEE"],
        yaxis: {
            show: true,
            labels: {
                style: {
                    fontSize: '14px'
                }
            }
        },
        xaxis: {
            categories: categories,
            labels: {
                style: {
                    fontSize: '14px'
                }
            }
        },
        legend: {
            show: true,
            position: 'top',
            horizontalAlign: 'right',
            offsetX: -10,
            offsetY: 0,
            fontSize: '14px',
        },
        grid: {
            show: true,
            xaxis: {
                lines: {
                    show: false,
                }
            },
            yaxis: {
                opposite: true,
                lines: {
                    show: true
                },
            },
        },
        fill: {
            opacity: 1
        },
        tooltip: {
            shared: true,
            intersect: false,
            enabled: true,
            followCursor: true,
            style: {
                fontSize: "14px"
            },
            x: {
                show: false,
            },
            custom: function ({ series, seriesIndex, dataPointIndex }) {
                return (
                    "<div class='text-[20px] px-3 py-[3px] bg-white/50  backdrop-blur items-center '>" +
                    "<div class='flex justify-between  '>" +
                    "<div class='flex  items-center'>" +
                    "<div class='p-[5px] rounded-sm bg-light-red-3 mr-2'>" + "</div>" +
                    "<div class='text-sm font-bold text-slate-500 '>" + "Unique Visitors" + "</div>" +
                    "</div>" +
                    "<div class='ml-5  text-sm font-extrabold '>" + series[0][dataPointIndex] + "</div>" +
                    "</div>" +

                    "<div class=' flex justify-between '>" +
                    "<div class='flex  items-center'>" +
                    "<div class='p-[5px] rounded-sm bg-light-pink-color mr-2'>" + "</div>" +
                    "<div class='text-sm font-bold text-slate-500 '>" + "Page Views" + "</div>" +
                    "</div>" +
                    "<div class='ml-5 text-sm font-extrabold '>" + series[1][dataPointIndex] + "</div>" +
                    "</div>" +
                    "</div>")

            }
        }
    })


    return (
        <>

            <div id="chart" className="basis-full">
                <ReactApexChart
                    options={options}
                    series={series}
                    type="bar"
                    height={350}
                />
            </div>

        </>
    )
}

function BrowserDoughnutChart({ details, heading }) {

    const [data, setData] = useState(
        {
            labels: [],
            datasets: [{
                label: 'Bugs',
                data: [],
                backgroundColor: ['#7F5FEE', '#BAA9FF', '#D8D1FF'],
                borderWidth: 0,
                cutout: '65%',
                hoverOffset: 4,
            }],
        }
    )

    const [chartOptions] = useState({
        plugins: {
            title: {
                display: true,
                text: 'Doughnut Chart',
                color: 'blue',
                font: {
                    size: 34
                },
                padding: {
                    top: 30,
                    bottom: 30
                },
                responsive: true,
                animation: {
                    animateScale: true,
                }
            },
            legend: {
                display: false,
                position: "bottom",
                align: "center",
                fontFamily: "Allianz-Neo",
                textDirection: 'ltr',
                labels: {
                    usePointStyle: true,
                    fontColor: "#EE61C9",
                }
            }
        }
    })

    useEffect(() => {
        const totals = [];
        const name = []
        details && details.filter((value, index) => index < 3).map((item) => {
            return (
                <>
                    {totals.push(item.visitCount)}
                    {name.push(item.browser)}
                </>
            )
        })
        setData(
            {
                labels: name,
                datasets: [{
                    label: 'Visit Count',
                    data: totals,
                    backgroundColor: ['#7F5FEE', '#BAA9FF', '#D8D1FF'],
                    borderWidth: 0,
                    cutout: '65%',
                    hoverOffset: 4,
                }],
            }
        )

    }, [details])

    return (
        <div className='h-full rounded-[34px] bg-violet-100 p-7'>
            <div className='flex gap-2 justify-between'>
                <div className='basis-10/12'>
                    <div className='flex gap-1 font-semibold text-xl mx-2'>
                        {heading}
                    </div>
                    <div className='text-sm font-medium mx-2'>
                        Total traffic in a week
                    </div>
                </div>
                {/* <Filter /> */}
            </div>
            {details?.length == 0 ?
                <div className='flex justify-center gap-2 p-6 mt-6 rounded-[34px] bg-white h-[250px]'>
                    <div className='flex items-center justify-center'>
                        No Data Found
                    </div>
                </div>
                :
                <>
                    <div className='flex justify-between gap-2 p-6 mt-6 rounded-[34px] bg-white h-[250px]'>
                        <div className='flex flex-col w-6/12 justify-around pl-2'>
                            {details && details.filter((value, index) => index < 3).map((item, index) => {
                                return (
                                    <div key={index} className='py-3'>
                                        <div className='font-medium text-sm'>
                                            {item.browser}
                                        </div>
                                        <div className='text-black font-semibold text-sm'>
                                            {item.visitCount}
                                        </div>
                                    </div>
                                )
                            }
                            )}
                        </div>
                        <div className='flex items-center w-4/12'>
                            <Doughnut
                                data={data}
                                options={chartOptions}
                            />
                        </div>
                    </div>
                    {/* <div className='flex gap-2 flex-wrap justify-between mt-4 '>
                        <div className='font-medium mr-[10px]'>
                        See more statistic
                        </div>
                        <button type='button' className="flex gap-1 items-center font-semibold text-white px-4 py-1 bg-purple rounded-full">
                        <ArrowRight />
                        <span >
                            See Details
                        </span>
                        </button>
                    </div> */
                    }
                </>
            }

        </div>
    )
}

function PageDoughnutChart({ details, heading }) {

    const [data, setData] = useState(
        {
            labels: [],
            datasets: [{
                label: 'Bugs',
                data: [],
                backgroundColor: ['#7F5FEE', '#BAA9FF', '#D8D1FF'],
                borderWidth: 0,
                cutout: '65%',
                hoverOffset: 4,
            }],
        }
    )

    const [chartOptions] = useState({
        plugins: {
            title: {
                display: true,
                text: 'Doughnut Chart',
                color: 'blue',
                font: {
                    size: 34
                },
                padding: {
                    top: 30,
                    bottom: 30
                },
                responsive: true,
                animation: {
                    animateScale: true,
                }
            },
            legend: {
                display: false,
                position: "bottom",
                align: "center",
                fontFamily: "Allianz-Neo",
                textDirection: 'ltr',
                labels: {
                    usePointStyle: true,
                    fontColor: "#EE61C9",
                }
            }
        }
    })

    useEffect(() => {
        const totals = [];
        const name = []
        details && details.filter((value, index) => index < 3).map((item) => {
            return (
                <>
                    {totals.push(item.visitCount)}
                    {name.push(item.url)}
                </>
            )
        })
        setData(
            {
                labels: name,
                datasets: [{
                    label: 'Visit Count',
                    data: totals,
                    backgroundColor: ['#7F5FEE', '#BAA9FF', '#D8D1FF'],
                    borderWidth: 0,
                    cutout: '65%',
                    hoverOffset: 4,
                }],
            }
        )

    }, [details])

    return (
        <div className='h-full rounded-[34px] bg-violet-100 p-7'>
            <div className='flex gap-2 justify-between'>
                <div className='basis-10/12'>
                    <div className='flex gap-1 font-semibold text-xl mx-2'>
                        {heading}
                    </div>
                    <div className='text-sm font-medium mx-2'>
                        Total traffic in a week
                    </div>
                </div>
                {/* <Filter /> */}
            </div>
            {details?.length == 0 ?
                <div className='flex justify-center gap-2 p-6 mt-6 rounded-[34px] bg-white h-[250px]'>
                    <div className='flex items-center justify-center'>
                        No Data Found
                    </div>
                </div>
                :
                <>
                    <div className='flex justify-between gap-2 p-6 mt-6 rounded-[34px] bg-white h-[250px]'>
                        <div className='flex flex-col w-6/12 justify-around pl-2'>
                            {details && details.filter((value, index) => index < 3).map((item, index) => {
                                return (
                                    <div key={index} className='py-3'>
                                        <div title={item?.url} className='font-medium  text-sm'>
                                            {item?.url.length > 10 ? item?.url.slice(0, 10) + '...' : item?.url}
                                        </div>
                                        <div className='text-black font-semibold text-sm'>
                                            {item.visitCount}
                                        </div>
                                    </div>
                                )
                            }
                            )}
                        </div>
                        <div className='flex items-center w-4/12  '>
                            <Doughnut
                                data={data}
                                options={chartOptions}
                            />
                        </div>
                    </div>
                    {/* <div className='flex gap-2 flex-wrap justify-between mt-4 '>
            <div className='font-medium mr-[10px]'>
              See more statistic
            </div>
            <button type='button' className="flex gap-1 items-center font-semibold text-white px-4 py-1 bg-purple rounded-full">
              <ArrowRight />
              <span >
                See Details
              </span>
            </button>
          </div> */}
                </>
            }

        </div>
    )
}

function ReportDoughnutChart({ details, heading }) {

    const [data, setData] = useState(
        {
            labels: [],
            datasets: [{
                label: 'Bugs',
                data: [],
                backgroundColor: ['#7F5FEE', '#BAA9FF', '#D8D1FF'],
                borderWidth: 0,
                cutout: '65%',
                hoverOffset: 4,
            }],
        }
    )

    const [chartOptions] = useState({
        plugins: {
            title: {
                display: true,
                text: 'Doughnut Chart',
                color: 'blue',
                font: {
                    size: 34
                },
                padding: {
                    top: 30,
                    bottom: 30
                },
                responsive: true,
                animation: {
                    animateScale: true,
                }
            },
            legend: {
                display: false,
                position: "bottom",
                align: "center",
                fontFamily: "Allianz-Neo",
                textDirection: 'ltr',
                labels: {
                    usePointStyle: true,
                    fontColor: "#EE61C9",
                }
            }
        }
    })

    useEffect(() => {
        const totals = [];
        const name = []
        details && details.filter((value, index) => index < 3).map((item) => {
            return (
                <>
                    {totals.push(item.total)}
                    {name.push(item.browsers)}
                </>
            )
        })
        setData(
            {
                labels: name,
                datasets: [{
                    label: 'Total Traffic',
                    data: totals,
                    backgroundColor: ['#7F5FEE', '#BAA9FF', '#D8D1FF'],
                    borderWidth: 0,
                    cutout: '65%',
                    hoverOffset: 4,
                }],
            }
        )

    }, [details])

    return (
        <div className='h-full rounded-[34px] bg-violet-100 p-7'>
            <div className='flex gap-2 justify-between'>
                <div className='basis-10/12'>
                    <div className='flex gap-1 font-semibold text-xl mx-2'>
                        {heading}
                    </div>
                    <div className='text-sm font-medium mx-2'>
                        Total traffic in a week
                    </div>
                </div>
                {/* <Filter /> */}
            </div>
            {details?.length == 0 ?
                <div className='flex justify-center gap-2 p-6 mt-6 rounded-[34px] bg-white h-[250px]'>
                    <div className='flex items-center justify-center'>
                        No Data Found
                    </div>
                </div>
                :
                <>
                    <div className='flex justify-between gap-2 p-6 mt-6 rounded-[34px] bg-white h-[250px]'>
                        <div className='flex flex-col w-6/12 justify-around pl-2'>
                            {details && details.filter((value, index) => index < 3).map((item, index) => {
                                return (
                                    <div key={index} className='py-3 '>
                                        <div className='font-medium  text-sm'>
                                            {item.browsers}
                                        </div>
                                        <div className='text-black font-semibold text-sm'>
                                            {item.total}
                                        </div>
                                    </div>
                                )
                            }
                            )}
                        </div>
                        <div className='flex items-center w-4/12 xl:w-6/12 '>
                            <Doughnut
                                data={data}
                                options={chartOptions}
                            />
                        </div>
                    </div>
                    {/* <div className='flex gap-2 flex-wrap justify-between mt-4 '>
            <div className='font-medium mr-[10px]'>
              See more statistic
            </div>
            <button type='button' className="flex gap-1 items-center font-semibold text-white px-4 py-1 bg-purple rounded-full">
              <ArrowRight />
              <span >
                See Details
              </span>
            </button>
          </div> */}
                </>
            }

        </div>
    )
}

function VistorStats({ name, value, percentage, show, barChart, showDollar, showPercentage }) {
    const [color, setColor] = useState(true)
    // percentage = percentage - 100

    // useEffect(() => {
    //     if (percentage < 0) {
    //         setColor(false)
    //     } else {
    //         setColor(true)
    //     }
    // }, [percentage])


    return (
        <div className="p-2 xl:p-0 basis-full sm:basis-2/4 md:basis-3/12">
            <div className={`p-4 xl:p-6  ${(show === "true") ? "bg-violet-50 " : "bg-slate-100"} rounded-3xl`}>
                <div className=" w-full text-sm flex justify-between ">
                    <div className="font-normal text-gray-6 ">
                        {name}
                    </div>
                    {/* {
                        showPercentage !== false
                            ?
                            <div className={`flex font-bold items-center ${color ? "text-green" : "text-red"}  `}>
                                <span className="mr-[5px]">
                                    {color ? <UpArrow /> : <DownArrow />}
                                </span>
                                {(percentage) ? `${percentage} %` : `${0} %`}
                            </div>
                            :
                            <></>
                    } */}

                </div>
                <div className="sm:text-xl font-bold text-brown-text-color">
                    {showDollar && "$ "}{(value !== undefined) ? (value >= 1000) ? `${(value / 1000).toFixed(2)}K` : `${value}` : `${0}`}
                </div>
                {/* <div className="!p-0 w-[40%] sm:w-[70%] md:w-[50%] lg:w-[45%] xl:w-[65%] mid-xl:w-[60%] mb-[-20px] ml-[-20px]">
                    <StatsChart showColor={show} barData={barChart} />
                </div> */}
            </div>
        </div>
    );
}

function StatsChart({ showColor, barData }) {

    let [a, setA] = useState([])
    let [b, setB] = useState([])

    useEffect(() => {
        let arr1 = [];
        let arr2 = [];

        barData !== undefined && barData.forEach((key) => {
            arr1.push(key._date)
        })
        setB(arr1)
        barData !== undefined && barData.forEach((key) => {
            arr2.push(key.value)
        })
        setA(arr2)
    }, [barData])

    const series = [
        {
            name: 'Unique Visitors',
            data: a
        }
    ];
    const options = {
        chart: {
            toolbar: {
                show: false,
                tools: {
                    download: false
                }
            },
            type: 'bar',
            height: 150,
            foreColor: '#7f8c8d',
        },

        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded',
                borderRadius: 5,
                distributed: true

            }
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },

        colors: [function ({ value, seriesIndex, w }) {
            if (showColor === "true") {
                return '#D6D6D6'
            } else {
                return '#7F5FEE'
            }
        }, function ({ value, seriesIndex, w }) {
            if (showColor === "true") {
                return '#2D2D2D'
            } else {
                return '#2D2D2D'
            }
        }],
        xaxis: {
            categories: b,
            labels: {
                show: false,
            },
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            },
        },
        yaxis: {
            show: false,
        },
        legend: {
            show: false,
            position: 'left',
            offsetY: 120,
        },

        grid: {
            show: true,
            xaxis: {
                lines: {
                    show: false,
                }
            },
            yaxis: {
                opposite: true,
                lines: {
                    show: false
                },
            },
        },
        fill: {
            opacity: 1,
        },
        tooltip: {
            enabled: false
        }
    };

    return (
        <ReactApexChart
            options={options}
            series={series}
            type="bar"
            height={90}
        />
    )
}


function Map({ countries }) {
    const [content, setContent] = useState("")
    const [totalVisitors, setTotalVisitors] = useState(0)


    useEffect(() => {
        const total = countries.reduce((acc, country) => acc + country.visitorCount, 0);
        setTotalVisitors(total);
    }, [countries]);

    return (
        <>
            <div className='basis-full md:basis-7/12'>
                <GeoMap setTooltipContent={setContent} data={countries} />
                <ReactTooltip>{content}</ReactTooltip>
            </div>
            <div className='basis-full md:basis-4/12 max-h-[550px] overflow-auto dark:bg-semi-purple-color bg-semi-gray-color rounded-3xl px-4'>
                <div className='flex justify-between px-4 pt-2 items-center'>
                    <span className='text-[20px] font-bold  dark:text-white'>{totalVisitors}</span>
                    <Filter />
                </div>
                {
                    totalVisitors === 0
                        ?
                        <div className='flex items-center justify-center py-16 dark:text-white'>
                            There are no visitors for this website
                        </div>
                        :
                        countries && countries.filter((value, index) => index < 6).map((item, index) => {
                            return (
                                <>
                                    <Flag key={index} countries={item.country} images={item.image} percentages={item.percentage} />
                                </>
                            )
                        })}
            </div>
        </>
    )
}

function Flag({ countries, images, percentages }) {
    const percent = Math.floor(percentages)
    const image = images.toLowerCase();
    return (
        <div className=' flex py-1 px-4 items-center'>
            <img src={image} alt="flags" width={32} height={32} />
            <div className='w-full p-3 ml-2'>
                <span className='font-bold text-xs'>
                    {countries}
                </span>
                <div className="w-full bg-violet-200 rounded-full mt-1">
                    <div className="bg-purple h-2.5 rounded-full " style={{ width: `${percent}%` }}></div>
                </div>
            </div>
            <span className='font-bold mt-[26px] text-xs'>{percent}%</span>
        </div>
    )
}