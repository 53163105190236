import React, { useContext, useState } from 'react'
import axios from 'axios';
import { AddBadgesIcon, SearchIcon } from '../../helper/SvgIcons'
import { Icon } from '@iconify/react/dist/iconify.js'
import { showErrorMessage, showSuccessMessage } from '../../helper/showMessage';
import { Path } from '../../helper/path';
import { AuthContext } from '../../_providers/AuthProvider';
import { FullScreenLoader } from '../common/FullScreenLoader';
import ReactPaginate from "react-paginate";
import { useEffect } from 'react';
import { useRef } from 'react';
import AddRulesModel from '../model/AddRulesModel';
import { RuleEngineIcon } from '../../helper/SvgIcons';
import { useModal } from 'react-modal-hook';
import PersonDetailModel from '../model/PersonDetailModel';
import { set } from 'react-hook-form';


export default function PeopleSearch() {
    const keywordRef = useRef(null)
    const { session } = useContext(AuthContext);
    const [isLoader, setIsLoader] = useState(false)
    const [peopleData, setPeopleData] = useState([]);
    const [pagination, setPagination] = useState();
    const [tagsError, setTagsError] = useState(null);
    const [sectionTags, setSectionTags] = useState({});
    const [rangeValues, setRangeValues] = useState({ min: '', max: '' });
    const [activePage, setActivePage] = useState(1);
    const [emailAddresses, setEmailAddresses] = useState({})
    const [contactData, setContactData] = useState([])
    const [visible, setVisible] = useState(false)




    const itemsPerPage = 20
    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
        fetchData(pageNumber, itemsPerPage)
    };

    const handleTagsCreate = (key) => {
        const inputElement = document.getElementById(`${key}TagsInput`);
        const tagValue = inputElement.value.trim();
        if (tagValue !== "") {
            const updatedSectionTags = { ...sectionTags };
            const updatedTags = updatedSectionTags[key] || [];
            if (!updatedTags.includes(tagValue)) {
                updatedTags.push(tagValue);
                updatedSectionTags[key] = updatedTags;
                setSectionTags(updatedSectionTags);
                // setSectionErrors({ ...sectionErrors, [key]: null }); 
                inputElement.value = '';
            } else {
                showErrorMessage("Value already exists");
            }
        }
        // else {
        //     setSectionErrors({ ...sectionErrors, [key]: "Please enter a valid tag" });
        // }
    };

    const handleTagDelete = (key, index) => {
        const updatedSectionTags = { ...sectionTags };
        const updatedTags = updatedSectionTags[key] || [];
        updatedTags.splice(index, 1);
        updatedSectionTags[key] = updatedTags;
        setSectionTags(updatedSectionTags);
        // if (updatedTags.length === 0) {
        //     setSectionErrors({ ...sectionErrors, [key]: "Required" });
        // } else {
        //     setSectionErrors({ ...sectionErrors, [key]: null }); // Clear section error
        // }
    };

    const handleSubmit = async (event, activePage) => {
        event.preventDefault();
        const rangeString = rangeValues.min + "," + rangeValues.max;
        const domainString = Array.isArray(sectionTags['domain']) ? sectionTags['domain'].join("\n") : '';
        const formValue = {
            "page": activePage,
            "per_page": itemsPerPage,
        }
        if (event.target.search.value && event.target.search.value !== '') formValue.q_keywords = event.target.search.value
        if (Array.isArray(sectionTags['domain']) && sectionTags['domain'].length > 0) formValue.q_organization_domains = domainString
        if (Array.isArray(sectionTags['location']) && sectionTags['location'].length > 0) formValue.person_locations = sectionTags['location']
        if (rangeValues.min && rangeValues.max) formValue.organization_num_employees_ranges = [rangeString]
        if (Array.isArray(sectionTags['jobTitle']) && sectionTags['jobTitle'].length > 0) formValue.person_titles = sectionTags['jobTitle']
        if (Array.isArray(sectionTags['seniority']) && sectionTags['seniority'].length > 0) formValue.person_seniorities = sectionTags['seniority']

        setIsLoader(true)
        await axios.post(Path.peopleSearch,
            formValue,
            {
                headers: {
                    Authorization: "Bearer " + session?.data?.token,
                }
            }
        ).then((success) => {
            setPeopleData(success?.data?.allPeople)
            setPagination(success?.data?.pagination)
        }).catch((error) => {
            console.log(error)
            showErrorMessage(error?.response?.data?.error || 'Something Went Wrong.')
        }).finally(() => {
            setIsLoader(false)
        })
    };

    const tagSections = [
        { label: "Domain", key: 'domain', placeholder: 'Type domain...' },
        { label: "Location", key: 'location', placeholder: 'Type location...' },
    ];

    const JobTagSections = [
        { label: "Job Title", key: 'jobTitle', placeholder: 'Type job title...' },
        { label: "Seniority", key: 'seniority', placeholder: 'Type seniority...' },
    ];

    const textlimit = (text) => {
        if (text && (text.length > 30)) {
            return text.substring(0, 30) + '...'
        }
        else {
            return text
        }
    }

    const fetchData = async (pageNumber, itemsPerPage) => {
        const rangeString = rangeValues.min + "," + rangeValues.max;
        const domainString = Array.isArray(sectionTags['domain']) ? sectionTags['domain'].join("\n") : '';
        const formValue = {
            "page": pageNumber,
            "per_page": itemsPerPage,
        }
        if (keywordRef?.current?.value && keywordRef?.current?.value !== '') formValue.q_keywords = keywordRef?.current?.value
        if (Array.isArray(sectionTags['domain']) && sectionTags['domain'].length > 0) formValue.q_organization_domains = domainString
        if (Array.isArray(sectionTags['location']) && sectionTags['location'].length > 0) formValue.person_locations = sectionTags['location']
        if (rangeValues.min && rangeValues.max) formValue.organization_num_employees_ranges = [rangeString]
        if (Array.isArray(sectionTags['jobTitle']) && sectionTags['jobTitle'].length > 0) formValue.person_titles = sectionTags['jobTitle']
        if (Array.isArray(sectionTags['seniority']) && sectionTags['seniority'].length > 0) formValue.person_seniorities = sectionTags['seniority']

        setIsLoader(true)
        await axios.post(Path.peopleSearch,
            formValue,
            {
                headers: {
                    Authorization: "Bearer " + session?.data?.token,
                }
            }
        ).then((success) => {
            setPeopleData(success?.data?.allPeople)
            setPagination(success?.data?.pagination)
        }).catch((error) => {
            console.log(error)
            showErrorMessage(error?.response?.data?.error || 'Something Went Wrong.')
        }).finally(() => {
            setIsLoader(false)
        })
    }

    const saveEmail = async () => {
        setIsLoader(true)
        await axios.post(`${Path.BulkUploadByDirectory}`,
            contactData,
            {
                headers: {
                    Authorization: "Bearer " + session?.data?.token,
                },
            }).then((success) => {
                showSuccessMessage("Person is saved successfully to contact list.")
            }).catch((error) => {
                console.log(error)
                showErrorMessage(error?.response?.data?.error || 'Something Went Wrong.')
            }).finally(() => {
                setIsLoader(false)
            })
    }

    const handleEmailAccess = async (id) => {
        await axios.get(Path.emailAccess + "/" + id,
            {
                headers: {
                    Authorization: "Bearer " + session?.data?.token,
                }
            }
        ).then((success) => {
            const email = success?.data
            if (email) setEmailAddresses({ ...emailAddresses, [id]: email })
        }).catch((error) => {
            console.log(error)
            showErrorMessage(error?.response?.data?.error || 'Something Went Wrong.')
        })
    }
    useEffect(() => {
        console.log(isLoader)
    }, [isLoader])

    return (
        <>
            {isLoader ? <FullScreenLoader /> : " "}
            {visible ? <AddRulesModel visible={visible} setVisible={setVisible} /> : ""}
            <div className='py-3 large-title'>
                {`Search`}
            </div>
            <div className='flex w-full gap-4 '>
                <div className={`flex flex-col border overflow-y-auto min-w-[280px]  xl:w-[20%] bg-white`}>
                    <SidebarForm handleSubmit={handleSubmit} tagSections={tagSections} tagsError={tagsError} sectionTags={sectionTags} handleTagDelete={handleTagDelete} setTagsError={setTagsError} handleTagsCreate={handleTagsCreate} JobTagSections={JobTagSections} rangeValues={rangeValues} setRangeValues={setRangeValues} keywordRef={keywordRef} />
                </div>
                <div className='bg-white max-w-[78%] border w-full overflow-hidden'>
                    <PeopleTable peopleData={peopleData} pagination={pagination} setActivePage={setActivePage} itemsPerPage={itemsPerPage} handlePageChange={handlePageChange} textlimit={textlimit} handleEmailAccess={handleEmailAccess} emailAddresses={emailAddresses} setContactData={setContactData} saveEmail={saveEmail} setVisible={setVisible} setIsLoader={setIsLoader} />
                </div>
            </div>
        </>
    )
}


function SidebarForm({ handleSubmit, tagSections, tagsError, sectionTags, handleTagDelete, setTagsError, JobTagSections, handleTagsCreate, setRangeValues, rangeValues, keywordRef }) {
    return (
        <form onSubmit={handleSubmit} className="space-y-6 text-left">
            <div className='px-4 mt-4'>
                <div className="relative h-[33px]">
                    <span className='absolute top-[8px] left-[8px] w-[18px] h-[18px]'>
                        <SearchIcon color={"#2D2D2D"} />
                    </span>
                    <input
                        name='search' ref={keywordRef}
                        className='w-full py-1 pl-10 pr-4 border rounded-lg outline-none border-gray-8 hover:text-dark hover:border-dark text-gray-6' type='text' placeholder='Search People...' />
                </div>
            </div>

            <div className="max-h-[600px] overflow-y-auto">
                <div className="">
                    <div className={`flex text-gray-2  bg-gray-100 p-2 items-center font-medium gap-2 hover:text-dark text-[15px]`}>
                        <span className=" text-purple">
                            <Icon icon="mdi:office-building" width={30} height={30} />
                        </span>
                        <span>Organisation</span>
                    </div>
                    <div className='px-4'>
                        {tagSections.map((section, sectionIndex) => (
                            <div key={sectionIndex} className='mt-2'>
                                <label className='custom-label'>{section.label}</label>
                                <div className={`w-full flex flex-wrap justify-start items-center gap-1 ${tagsError ? 'border border-red-color' : ''} border border-light-grey-2 rounded-lg p-1`}>
                                    {(sectionTags[section.key] || []).map((tag, index) => (
                                        <div key={index} className='flex items-center gap-2 rounded-md p-1 px-2 text-purple text-sm font-[600] bg-light-purple'>
                                            <span>{tag}</span>
                                            <Icon onClick={() => handleTagDelete(section.key, index)} className='text-black cursor-pointer text-md' icon='radix-icons:cross-2' />
                                        </div>
                                    ))}
                                    <div className='flex items-center h-[28px] gap-2 px-2 rounded-md w-full'>
                                        <input id={`${section.key}TagsInput`} className='w-full h-full outline-none' type='text' onChange={() => setTagsError(null)} placeholder={section.placeholder} />
                                        <span className='cursor-pointer' onClick={() => handleTagsCreate(section.key)}>
                                            <AddBadgesIcon />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                </div>
                <div className="mt-4">
                    <div className={`flex  bg-gray-100 p-2 text-gray-2 items-center font-medium gap-2 hover:text-dark text-[15px]`}>
                        <span className=" text-purple">
                            <Icon icon="uil:award" width={30} height={30} />
                        </span>
                        <span>Job Info</span>
                    </div>
                    <div className='px-4'>
                        {JobTagSections.map((section, sectionIndex) => (
                            <div key={sectionIndex} className='mt-2'>
                                <label className='custom-label'>{section.label}</label>
                                <div className={`w-full flex flex-wrap justify-start items-center gap-1 ${tagsError ? 'border border-red-color' : ''} border border-light-grey-2 rounded-lg p-1`}>
                                    {(sectionTags[section.key] || []).map((tag, index) => (
                                        <div key={index} className='flex items-center gap-2 rounded-md p-1 px-2 text-purple text-sm font-[600] bg-light-purple'>
                                            <span>{tag}</span>
                                            <Icon onClick={() => handleTagDelete(section.key, index)} className='text-black cursor-pointer text-md' icon='radix-icons:cross-2' />
                                        </div>
                                    ))}
                                    <div className='flex items-center  h-[28px] gap-2 px-2 rounded-md w-full'>
                                        <input id={`${section.key}TagsInput`} className='w-full h-full outline-none' type='text' onChange={() => setTagsError(null)} placeholder={section.placeholder} />
                                        <span className='cursor-pointer' onClick={() => handleTagsCreate(section.key)}>
                                            <AddBadgesIcon />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className="px-4 pb-3">
                <button type="submit" className="w-full p-1 rounded-md bg-purple mt-4 text-white text-base font-[500] ">Search</button>
            </div>
        </form>
    )
}

function PeopleTable({ peopleData, pagination, itemsPerPage, handlePageChange, textlimit, handleEmailAccess, emailAddresses, setContactData, saveEmail, setVisible, setIsLoader }) {
    const [checkboxes, setCheckboxes] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [loadingButtonIndex, setLoadingButtonIndex] = useState(null)
    const [selectedPersonData, setSelectedPersonData] = useState(null)
    const [openPersonDatailPopup, hidePersonDatailPopup] = useModal(() => (
        <PersonDetailModel hide={() => { hidePersonDatailPopup(); }} personData={selectedPersonData} />
    ), [selectedPersonData]);
    const handleCheckboxClick = async (index) => {
        const updatedCheckboxes = [...checkboxes];
        updatedCheckboxes[index] = !updatedCheckboxes[index];
        setCheckboxes(updatedCheckboxes);

    };
    const handleSelectAllClick = async () => {
        if (selectAll) {
            const updatedArr = await Promise.all(peopleData?.map((items) => {
                if (Object.keys(emailAddresses).includes(items?.id)) return false
                else return false
            }))
            setCheckboxes(updatedArr)
            setSelectAll(!selectAll)
        } else {
            const updatedArr = await Promise.all(peopleData?.map((items) => {
                if (Object.keys(emailAddresses).includes(items?.id)) return true
                else return false
            }))
            setCheckboxes(updatedArr)
            setSelectAll(!selectAll)
        }
    };

    const handleButtonClick = async (id, index) => {
        setLoadingButtonIndex(index)
        try {
            await handleEmailAccess(id)
        } catch (error) {
            console.log(error)
        } finally {
            setLoadingButtonIndex(null);
        }
    };

    const removeHttpPrefix = (url) => {
        if (url !== null) return url.replace(/^https?:\/\/(www\.)?/, '')
        else return url
    }

    useEffect(() => {
        async function CreateArray() {
            const newArr = await Promise.all(peopleData.map((d, i) => {
                return false
            }))
            setCheckboxes(newArr)
        }
        peopleData?.length !== 0 && CreateArray()
    }, [peopleData])
    useEffect(() => {
        async function SelectAllCheck() {
            const testArray = await Promise.all(Object.keys(emailAddresses).map((items) => {
                let condition = true;
                peopleData.forEach((d, i) => {
                    if (d?.id === items) {
                        if (checkboxes[i] === false) condition = false
                    }
                })
                return condition
            }))

            const allChecked = testArray.every((checkbox) => checkbox);
            setSelectAll(allChecked);

            const allUnchecked = testArray.every((checkbox) => !checkbox);
            if (allUnchecked) setSelectAll(false)
        }
        SelectAllCheck()
    }, [checkboxes])
    useEffect(() => {
        async function GetData() {
            const data = await Promise.all(peopleData?.map((item, index) => {
                if (checkboxes[index]) {
                    let email = ''
                    const cleanedDomain = removeHttpPrefix(item?.organization?.website_url)
                    Object.entries(emailAddresses).forEach((items) => {
                        if (items[0] === item.id) email = items[1];
                    });
                    return {
                        userInfo: {
                            firstName: item?.first_name,
                            lastName: item?.last_name,
                            companyName: item?.organization?.name,
                            jobTitle: item?.title,
                            seniority: item?.seniority,
                            linkedinURL: item?.linkedin_url,
                            domain: cleanedDomain
                        },
                        email: email
                    };
                }
            }));
            const newData = await Promise.all(data?.filter((items) => items !== undefined))
            setContactData(newData)
        }
        GetData();
    }, [checkboxes]);



    return (
        <>
            <div className="w-full bg-white">
                <div className="w-full flex items-left md:items-center gap-3 border-[#ECECEC] px-3 pt-3 flex-col md:flex-row border-b">
                    <div className="flex items-center justify-between w-full">
                        <div className="flex items-center gap-4 pb-2">
                            {/* All Check Box Button */}
                            <button
                                type="button"
                                className="w-[60px] h-[33px] flex justify-center gap-1 items-center p-1 text-base font-medium border rounded-md border-gray-8 -ml-1
                                hover:border-dark">
                                <input
                                    onClick={handleSelectAllClick} checked={selectAll}
                                    type="checkbox" className="w-4 h-4 align-middle rounded-none cursor-pointer accent-purple bg-light-gray-3"
                                />
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 16 16"><path fill="#707070" fillRule="evenodd" d="m7.976 10.072l4.357-4.357l.62.618L8.284 11h-.618L3 6.333l.619-.618l4.357 4.357z" clipRule="evenodd" /></svg>
                                </span>
                            </button>
                            <button
                                type="button" onClick={saveEmail}
                                className="min-w-[100px] text-[15px] font-medium rounded-lg flex items-center gap-2 border text-gray-500 border-gray-6
                                hover:text-dark hover:border-dark  h-[33px] px-2 "
                            >
                                <Icon icon="iconamoon:sign-plus" width={24} height={24} />
                                <span>
                                    {'Save'}
                                </span>
                            </button>
                            {/* <button
                                type="button" onClick={() => setVisible(true)}
                                className="min-w-[100px] text-[15px] rounded-lg font-medium flex items-center gap-2 border text-gray-500 border-gray-6
                                hover:text-dark hover:border-dark h-[33px] px-2"
                            >
                                <RuleEngineIcon />
                                <span>
                                    {'Rules'}
                                </span>
                            </button> */}
                        </div>
                        {/* <div className="flex items-center gap-3">
                            <div className="flex items-center gap-1 text-[15px] font-medium text-purple
                                h-[33px]  px-2 pb-3">
                                <span>
                                    {`Total`}
                                </span>
                                <span>
                                    {`(${pagination?.total_entries})`}
                                </span>
                            </div>
                            <div className="text-[15px] font-medium flex items-center gap-2 text-purple h-[33px]  px-2 pb-3">
                                Save
                            </div>
                        </div> */}
                    </div>
                </div>

                <div className="overflow-x-auto max-w-[1440px] overflow-y-auto  max-h-[calc(100dvh-250px)] mt-2 ">
                    {peopleData?.length > 0 ? (
                        <table className=" text-xs text-left md:text-[13px]">
                            <thead className="[&_th]:!border-t-0 sticky top-0 bg-white z-10 [&_th]:!font-medium
                            [&_th]:!p-2 [&_th]:text-sm [&_th]:!text-[#7B7B7B] ">
                                <tr>
                                    <th className="w-8 text-center !px-2 sticky left-0 bg-white">
                                        <span className="sr-only">
                                            <input type="checkbox" className="w-4 h-4 rounded-none accent-purple" />
                                        </span>
                                    </th>
                                    <th className="sticky !px-1 left-[32px] bg-white">Name</th>
                                    <th>Title</th>
                                    <th>Company</th>
                                    <th>Quick Actions</th>
                                    {/* <th>Phone</th> */}
                                    <th>Current Location</th>
                                    <th>LinkedIn</th>
                                </tr>
                            </thead>
                            <tbody className="[&_td]:py-2 [&_td]:border-b [&_td]:whitespace-nowrap
                            [&_td]:border-white [&_td]:font-medium [&_td]:px-2 md:[&_td]:px-3">
                                {
                                    peopleData && peopleData.map((data, index) => {
                                        return (
                                            <tr key={data?.id} className="bg-grey-4 text-dark">
                                                <td className="w-8 !px-2 text-center sticky left-0 bg-grey-4 shadow-lg">
                                                    <input
                                                        disabled={!(Object.keys(emailAddresses).includes(data?.id))}
                                                        checked={checkboxes[index]}
                                                        onChange={() => handleCheckboxClick(index)}
                                                        type="checkbox"
                                                        className="w-4 h-4 rounded-none cursor-pointer accent-purple"
                                                    />
                                                </td>

                                                <td className="!font-bold sticky !px-1 left-[32px] bg-grey-4 shadow-[3px_0_3px_rgba(0,0,0,.07)] cursor-pointer" onClick={() => {
                                                    setSelectedPersonData({
                                                        ...data,
                                                        email: emailAddresses[data?.id] || data?.email,
                                                    })
                                                    openPersonDatailPopup()

                                                }}>{data?.name}</td>
                                                <td className="whitespace-nowrap">{textlimit(data?.title)}</td>
                                                <td className="!font-bold">
                                                    <div className="flex items-center space-x-2 min-w-40">
                                                        <span className="inline-block flex-[1_0_28px] max-w-[28px]">
                                                            <img src={data?.organization?.logo_url} width={28} height={28} alt={data?.organization?.name} />
                                                        </span>
                                                        <span className="!text-wrap">{textlimit(data?.organization?.name)}</span>
                                                    </div>
                                                </td>
                                                <td className="">
                                                    {
                                                        emailAddresses[data?.id] ?
                                                            (
                                                                <span>
                                                                    {emailAddresses[data?.id]}
                                                                </span>
                                                            )
                                                            :
                                                            (
                                                                <button
                                                                    onClick={() => handleButtonClick(data?.id, index)}
                                                                    type="button"
                                                                    className={`outline-none border border-[#efeaff] flex gap-2 rounded-[4px] bg-white text-purple
                                                                    appearance-none text-sm cursor-pointer py-[6px] px-3 hover:border-purple ${loadingButtonIndex !== null ? 'opacity-50 cursor-not-allowed' : ''}`}
                                                                    disabled={loadingButtonIndex !== null}

                                                                >
                                                                    {
                                                                        loadingButtonIndex === index ?
                                                                            (
                                                                                <Icon icon="svg-spinners:180-ring-with-bg" width={20} height={20} />
                                                                            )
                                                                            :
                                                                            (
                                                                                <Icon icon="tabler:mail-star" width={20} height={20} />
                                                                            )
                                                                    }
                                                                    <span> Access Email</span>
                                                                </button>
                                                            )
                                                    }
                                                </td>
                                                {/* <td className="">
                                                <button
                                                    type="button"
                                                    className="outline-none flex gap-2 rounded-md bg-white text-purple  appearance-none text-sm cursor-pointer py-1.5 px-3"
                                                >
                                                    <Icon icon="tabler:phone-incoming" width={20} height={20} />
                                                    <span> Access Mobile</span>
                                                </button>
                                            </td> */}
                                                <td className="">
                                                    {data?.city ? data?.city : '-'}
                                                </td>
                                                <td className="">
                                                    {data?.linkedin_url ? textlimit(data?.linkedin_url) : '-'}
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    ) : (
                        <div className="flex items-center justify-center text-center text-purple bg-grey-4 h-52 bg">
                            <div>Data not found</div>
                        </div>
                    )}
                </div>
            </div>
            <div>
                {
                    pagination &&
                    <ReactPaginate
                        previousLabel="<<"
                        nextLabel=">>"
                        breakLabel="..."
                        pageCount={Math.ceil(pagination?.total_entries / itemsPerPage)}
                        pageRangeDisplayed={3}
                        onPageChange={({ selected }) => handlePageChange(selected + 1)}
                        containerClassName="pagination"
                        activeClassName="active"
                    />
                }
            </div>
        </>
    )
}