import { Icon } from '@iconify/react';
import axios from 'axios';
import React, { useState } from 'react';
import { Path } from '../helper/path';
import { yupResolver } from '@hookform/resolvers/yup';
import { Validation, regex } from '../helper/Messages';
import * as Yup from "yup";
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { string } from 'yup';
import { showErrorMessage, showSuccessMessage } from '../helper/showMessage';
import { FullScreenLoader } from './common/FullScreenLoader';


export default function ResetPasswordForm() {
    const navigate = useNavigate();
    const [visible, setVisible] = useState(false);
    const [visible2, setVisible2] = useState(false);
    const [isLoader, setIsLoader] = useState(false)

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const tokenParams = searchParams.get('token');

    const toggleEye = () => {
        setVisible((prevVisible) => !prevVisible);
    };
    const toggleEye2 = () => {
        setVisible2((prevState) => !prevState);
    };

    const validationSchema = Yup.object().shape({
        password: string().trim().required(Validation?.passwordRequired).matches(regex?.passRegex, Validation?.passwordInvalid),
        cnfpass: Yup.string().trim().required(Validation?.p_ReEnter).oneOf([Yup.ref("password")], Validation?.p_NotSame),
    });

    const { register: resetPassword, handleSubmit, formState: { errors } } = useForm({ resolver: yupResolver(validationSchema) });

    const onSubmit = async (data, event) => {
        event.preventDefault();
        setIsLoader(true)
        await axios.post(Path.ChangePassword,
            {
                token: tokenParams,
                newPassword: data.password
            }
        ).then((success) => {
            setIsLoader(false)
            showSuccessMessage(success?.data?.message)
            navigate("/");
        }).catch((error) => {
            console.log(error)
            showErrorMessage(error?.response?.data?.error || 'Something Went Wrong.')
            setIsLoader(false)
        })
        navigate("/")

    };
    return (
        <>
            {isLoader ? <FullScreenLoader /> : " "}
            <div className="w-[calc(100vw-161px)] min-h-full flex flex-col gap-4 md:gap-5 justify-center items-center bg-offwhite select-none">
                {/* <div className="w-[90%] sm:w-[700px] text-center text-xl xs:text-2xl sm:text-3xl text-purple font-['Baloo']">
                    {`“Welcome to the First AI Email Sequence Tool”`}
                </div> */}
                <form onSubmit={handleSubmit(onSubmit)} className="w-[90%] sm:w-[700px] flex flex-col justify-start items-center gap-6 md:gap-8 rounded-lg shadow-lg bg-white p-6 md:p-8 px-10">
                    <div className="w-full text-xl font-bold text-dark">
                        {`Reset your password`}
                    </div>
                    <div className="w-full flex flex-col justify-start items-center gap-5 text-grey text-sm">
                        <div className="w-full flex flex-col gap-1 relative">
                            <label>
                                {`New Password`}
                            </label>
                            <input
                                type={visible ? "text" : "password"} name="password" placeholder="Enter New Password" {...resetPassword("password")}
                                className={errors?.password ? "input-form !border !border-[#FF1D48]" : "input-form"}
                            />
                            <span onClick={toggleEye} className={`absolute top-[45px] right-4 sm:right-6 lg:right-4 cursor-pointer`}>
                                {
                                    visible
                                        ?
                                        <Icon
                                            icon="codicon:eye-closed"
                                            className="icon-color"
                                            width={20}
                                            height={20}
                                        />
                                        :
                                        <Icon
                                            icon="codicon:eye"
                                            className="icon-color"
                                            width={20}
                                            height={20}
                                        />
                                }
                            </span>
                            {
                                errors.password && <div className="error-css">{errors?.password?.message}</div>
                            }
                        </div>
                        <div className="w-full flex flex-col gap-1 relative">
                            <label>
                                {`Confirm Password`}
                            </label>
                            <input
                                type={visible2 ? "text" : "password"} name="cnfpass" placeholder="Enter Confirm Password"  {...resetPassword("cnfpass")}
                                className={errors?.cnfpass ? "input-form !border !border-[#FF1D48]" : "input-form "}
                            />
                            <span onClick={toggleEye2} className={`absolute top-[45px] right-4 sm:right-6 lg:right-4 cursor-pointer`}>
                                {
                                    visible2
                                        ?
                                        <Icon
                                            icon="codicon:eye-closed"
                                            className="icon-color"
                                            width={20}
                                            height={20}
                                        />
                                        :
                                        <Icon
                                            icon="codicon:eye"
                                            className="icon-color"
                                            width={20}
                                            height={20}
                                        />
                                }
                            </span>
                            {
                                errors.cnfpass && <div className="error-css">{errors?.cnfpass?.message}</div>
                            }
                        </div>
                    </div>
                    <button className="w-full h-[54px] rounded-md bg-purple text-white text-xl font-[500] hover:scale-105 duration-300 " type="submit">
                        {`Done`}
                    </button>
                </form>
            </div>
        </>
    )
}
