import React, { useEffect, useState } from "react";
import crossIcon from '../../assets/images/common/crossicons.svg';
import { InviteSuccessMessage, showErrorMessage } from "../../helper/showMessage";
import { object, string } from 'yup';

import { Validation, regex } from '../../helper/Messages';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Path } from "../../helper/path";
import axios from "axios";
import { FullScreenLoader } from "../common/FullScreenLoader";

export default function InviteUserModel({ hide, sessionData }) {
    const [isLoader, setIsLoader] = useState(false)
    const [roles, setRoles] = useState([])
    const validationSchema = object().shape({
        firstName: string().trim().required(Validation?.firstNameRequired).matches(regex?.nameRegex, Validation?.fullNameInvalid),
        lastName: string().trim().required(Validation?.lastNameRequired).matches(regex?.nameRegex, Validation?.fullNameInvalid),
        email: string().trim().required(Validation?.emailRequired).matches(regex?.emailRegex, Validation?.emailInvalid),
        role: string().trim().required(Validation?.roleRequired),

    });

    const { register: registerCredential, handleSubmit, formState: { errors } } = useForm({ resolver: yupResolver(validationSchema) });

    async function getRoles() {
        await axios.get(Path.roles,
            {
                headers: {
                    Authorization: "Bearer " + sessionData?.token,
                },
            }).then((success) => {
                setRoles(success.data)
            }).catch((error) => {
                console.log(error)
            })
    }

    const onSubmit = async (data, event) => {
        event.preventDefault();
        setIsLoader(true)
        await axios.post(Path.inviteUser,
            {
                firstName: data.firstName,
                lastName: data.lastName,
                emailId: data.email,
                roleId: data.role
            },
            {
                headers: {
                    Authorization: "Bearer " + sessionData?.token
                }
            }).then((success) => {
                setIsLoader(false)
                InviteSuccessMessage()
                hide()

            }).catch((error) => {
                setIsLoader(false)
                showErrorMessage(error?.response?.data?.error || 'Something Went Wrong.')
            })
    }


    useEffect(() => {
        getRoles();
    }, [sessionData?.token])

    return (
        <>
            {isLoader ? <FullScreenLoader /> : " "}
            <div className={` bg-black/40 absolute z-20 inset-0 flex items-center`}>
                <div className="bg-white p-[45px] rounded-[11px] shadow mx-auto max-w-[700px] w-full">
                    {/* Popup Header */}
                    <div className="h-10 md:h-14 flex justify-between">
                        <h4 className="text-left font-bold text-dark text-xl ">Invite User</h4>
                        <div className="items-start flex cursor-pointer">
                            <img width={25} height={25} src={crossIcon} onClick={hide} alt="" />
                        </div>

                    </div>

                    <form onSubmit={handleSubmit(onSubmit)} className="text-left">
                        {/* Popup Main Section */}
                        <div className="">
                            <label className="custom-label !text-neutral-500 !font-normal">First Name</label>
                            <input
                                type="text" name="name" placeholder="Enter First Name"   {...registerCredential("firstName")}
                                className="custom-input !rounded-md"
                            />
                            {
                                errors.firstName && <div className="error-css">{errors?.firstName?.message}</div>
                            }
                        </div>
                        <div className="mt-5">
                            <label className="custom-label !text-neutral-500 !font-normal">Last Name</label>
                            <input
                                type="text" name="name" placeholder="Enter Last Name"   {...registerCredential("lastName")}
                                className="custom-input !rounded-md"
                            />
                            {
                                errors.lastName && <div className="error-css">{errors?.lastName?.message}</div>
                            }
                        </div>
                        <div className="mt-5">
                            <label className="custom-label !text-neutral-500 !font-normal">User Email Address</label>
                            <input className="custom-input !rounded-md" type="text" name="" placeholder="Enter a valid e-mail address" {...registerCredential("email")} />
                            {
                                errors.email && <div className="error-css">{errors?.email?.message}</div>
                            }
                        </div>
                        <div className="mt-5">
                            <label className="custom-label !text-neutral-500 !font-normal">User Role</label>
                            <select className="custom-input bg-white font-[Inter] !rounded-md !text-neutral-500 !font-normal bg-[url('/src/assets/images/dashboard/select-down-icon.svg')] bg-no-repeat bg-[97.5%] bg-[length:1.3rem]  appearance-none" {...registerCredential("role")}>
                                <option value="" disabled selected>Select user role</option>
                                {roles && roles.map((item) => (
                                    <option className="font-[Inter] " key={item._id} value={item._id}>{item.name}</option>
                                ))}

                            </select>
                            {
                                errors.role && <div className="error-css">{errors?.role?.message}</div>
                            }
                        </div>

                        {/* Popup Footer */}
                        <div className="mt-10 flex justify-end items-center gap-6">
                            <button type="submit" className="w-full h-[54px] rounded-md bg-purple text-white text-xl font-[500] hover:scale-105 duration-300 ">Send Invitation</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}