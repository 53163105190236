import axios from 'axios';
import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Path } from '../helper/path';
import { Validation, regex } from '../helper/Messages';
import { EmailToast, showErrorMessage } from '../helper/showMessage';
import { FullScreenLoader } from './common/FullScreenLoader';


export default function ForgotPasswordForm() {
    const [isLoader,  setIsLoader] = useState(false)
    const validationSchema = object().shape({
        email: string().trim().required(Validation?.emailRequired).matches(regex?.emailRegex,Validation?.emailInvalid),
    });

    const { register: loginCredential, handleSubmit, formState: { errors } } = useForm({ resolver: yupResolver(validationSchema) });

    const onSubmit = async (data, event) => {
        event.preventDefault();
        setIsLoader(true)
        await axios.post(Path.ForgotPassword,
            {
                email: data.email
            }
        ).then((success) => {
            setIsLoader(false)
            EmailToast(success?.data?.message)
        }).catch((error) => {
            setIsLoader(false)
            console.log(error)
            showErrorMessage(error?.response?.data?.error || 'Something Went Wrong.')
            
        })
    };
    return (
        <>
        {isLoader ? <FullScreenLoader /> : " "}
        <div className="w-[calc(100vw-161px)] min-h-full flex flex-col gap-4 md:gap-5 justify-center items-center bg-offwhite select-none">
            {/* <div className="w-[90%] sm:w-[700px] text-center text-xl xs:text-2xl sm:text-3xl text-purple font-['Baloo']">
                {`“Welcome to the First AI Email Sequence Tool”`}
            </div> */}
            <form onSubmit={handleSubmit(onSubmit)} className="w-[90%] sm:w-[700px] flex flex-col justify-start items-center gap-6 md:gap-8 rounded-lg shadow-lg bg-white p-6 md:p-8 px-10">
                <div className="w-full flex flex-col gap-1">
                    <span className='text-xl font-bold text-dark'>
                        {`Forgot your password?`}
                    </span>
                    <span className='text-sm text-grey-3'>
                        {`Reset password link will be sent to your email`}
                    </span>
                </div>
                <div className="w-full text-sm flex flex-col gap-1">
                    <input
                        className={errors?.email ? "input-form !border !border-[#FF1D48]" : "input-form"}
                        type="text" name="email" placeholder="Type your email" {...loginCredential("email")}
                    />
                    {
                        errors.email && <div className="error-css">{errors?.email?.message}</div>
                    }
                </div>

                <button className="w-full h-[54px] rounded-md bg-purple text-white text-xl font-[500] hover:scale-105 duration-300 " type="submit">
                    {`Send`}
                </button>
            </form>
        </div> 
        </>
    )
}
