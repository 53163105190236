import { Icon } from '@iconify/react';
import axios from 'axios';
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Path } from '../../helper/path';
import { AuthContext } from '../../_providers/AuthProvider';
import { FullScreenLoader } from '../common/FullScreenLoader';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';
import { Validation, regex } from '../../helper/Messages';
import { showErrorMessage, showSuccessMessage } from '../../helper/showMessage';

export default function VisitorRules() {
    const { session } = useContext(AuthContext)
    const [activeTab, setActiveTab] = useState(0)
    const [openModal, setOpenModal] = useState(false);
    const [ruleData, setRuleData] = useState([])
    const [isLoader, setIsLoader] = useState(false)

    async function GetRules() {
        setIsLoader(true)
        await axios.get(`${Path.GetRule}`,
            {
                headers: {
                    Authorization: "Bearer " + session?.data?.token,
                },
            }
        ).then((success) => {
            setRuleData(success?.data)
            setIsLoader(false)
        }).catch((error) => {
            console.log(error)
            setIsLoader(false)

        })
    }

    useEffect(() => {
        GetRules();
    }, [session])

    return (
        isLoader ? (
            <FullScreenLoader />
        ) : (
            <>
                <SelectListModal openModal={openModal} setOpenModal={setOpenModal} session={session} GetRules={GetRules} />
                <div className="flex gap-4 items-end flex-wrap pt-6 justify-between">
                    <h2 className="large-title">{`Visitors Rules`}</h2>
                </div>
                <div className="flex items-center border-b border-[#E9E9E9] mt-5">
                    <ul className="flex items-center gap-3 md:gap-4 ">
                        <li className=" hover:text-purple">
                            <button onClick={() => setActiveTab(0)} type="button"
                                className={`${activeTab === 0 ? 'active' : ''} flex py-4 items-center text-[#888888] !border-0`}>
                                Rules
                            </button>
                        </li>
                    </ul>
                    <div className="relative ml-auto">
                        <div className="flex items-center justify-center">
                            <button className=" bg-purple text-white rounded-md py-2 px-[14px] text-base"
                                onClick={(event) => {
                                    event.stopPropagation();
                                    setOpenModal(!openModal)
                                }}
                            >
                                Add Rules
                            </button>
                        </div>
                    </div>
                </div>
                <div className="flex bg-white mt-3 flex-col overflow-y-auto min-h-[500px] w-full">
                    <div className="bg-white mb-3">
                        {activeTab === 0 && (
                            <div className="overflow-auto pb-8">
                                {ruleData.length > 0 ? (
                                    <table className="w-full table-auto text-left text-xs md:text-sm ">
                                        <thead className="[&_th]:!border-t-0 [&_th]:!font-medium [&_th]:!p-3 [&_th]:text-sm [&_th]:!text-[#7B7B7B] [&_th]:lg:!text-base">
                                            <tr>
                                                <th>Rule Name</th>
                                                <th>Type</th>
                                                <th className='text-center'>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody className="[&_td]:py-5 [&_td]:font-medium [&_td]:px-2 md:[&_td]:px-3">
                                            {ruleData && ruleData.map((data, index) => (
                                                <tr key={index} className="bg-grey-4 border-b border-white">
                                                    <td className="text-dark ">{data?.name}</td>
                                                    <td className="text-dark ">{data?.type}</td>
                                                    <td className="text-dark text-center">{data?.status}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                ) : (
                                    <div className="text-purple bg-grey-4 text-center h-52 flex mt-2 justify-center items-center bg">
                                        <div>Data not found</div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </>
        )
    );

}


export function SelectListModal({ setOpenModal, openModal, session, GetRules }) {

    const [placeholder, setPlaceholder] = useState("Value")
    const [isLoader, setIsLoader] = useState(false)

    const validationSchema = object().shape({
        name: string().trim().required(Validation?.ruleNameRequired),
        field: string().trim().required(Validation?.fieldRequired),
        type: string().trim().required(Validation?.typeRequired),
        value: string().trim().required(Validation?.valueRequired),

    });

    const { register: registerCredential, handleSubmit, formState: { errors } } = useForm({ resolver: yupResolver(validationSchema) });


    const handleFieldChange = (event) => {
        setPlaceholder(event.target.value);
    };

    const onSubmit = async (data, event) => {
        event.preventDefault();
        setIsLoader(true)
        await axios.post(Path.GetRule,
            {
                name: data.name,
                field: data.field,
                type: data.type,
                value: data.value,

            },
            {
                headers: {
                    Authorization: "Bearer " + session?.data?.token,
                },
            }
        ).then((success) => {
            setIsLoader(false)
            setOpenModal(!openModal)
            GetRules();
            showSuccessMessage(success?.data?.message)
        }).catch((error) => {
            setIsLoader(false)
            console.log(error)
            showErrorMessage(error?.response?.data?.error || 'Something Went Wrong.')
        })
    }

    return (
        <div className={`${openModal ? 'block' : 'hidden'} bg-black/40 absolute z-20 inset-0 flex items-center`}>
            <div className="bg-white p-[45px] rounded-[11px] shadow mx-auto max-w-[700px] w-full">
                {/* Popup Header */}
                <div className="h-10 md:h-14 flex justify-center">
                    <h4 className="text-center font-bold text-dark text-xl ">Add Rule</h4>
                </div>

                <form onSubmit={handleSubmit(onSubmit)} className="text-left">
                    {/* Popup Main Section */}
                    <div className="">
                        <label className="custom-label !text-neutral-500 !font-normal">Name</label>
                        <input
                            type="text" name="name" placeholder="Enter Rule Name"   {...registerCredential("name")}
                            className="custom-input !rounded-md"
                        />
                        {
                            errors.name && <div className="error-css">{errors?.name?.message}</div>
                        }
                    </div>
                    <div className="mt-5">
                        <label className="custom-label !text-neutral-500 !font-normal">Field</label>
                        <select className="custom-input bg-white font-[Inter] !rounded-md !text-neutral-500 !font-normal bg-[url('/src/assets/images/dashboard/select-down-icon.svg')] bg-no-repeat bg-[97.5%] bg-[length:1.3rem]  appearance-none" {...registerCredential("field")} onChange={handleFieldChange}>
                            <option value="" disabled selected>Select Field</option>
                            <option value="Domain" >Domain</option>
                            <option value="Company Name" >Company Name</option>
                            <option value="Current Rule" >Current Rule</option>
                            <option value="Industry" >Industry</option>
                        </select>
                        {
                            errors.field && <div className="error-css">{errors?.field?.message}</div>
                        }
                    </div>
                    <div className="mt-5">
                        <label className="custom-label !text-neutral-500 !font-normal">Type</label>
                        <select className="custom-input bg-white font-[Inter] !rounded-md !text-neutral-500 !font-normal bg-[url('/src/assets/images/dashboard/select-down-icon.svg')] bg-no-repeat bg-[97.5%] bg-[length:1.3rem]  appearance-none" {...registerCredential("type")}>
                            <option value="" disabled selected>Select Type</option>
                            <option value="isEqual" >isEqual</option>
                            <option value="isNotEqual" >isNotEqual</option>
                            <option value="OR" >OR</option>
                            <option value="AND" >AND</option>
                        </select>
                        {
                            errors.type && <div className="error-css">{errors?.type?.message}</div>
                        }
                    </div>
                    <div className="mt-5">
                        <label className="custom-label !text-neutral-500 !font-normal">Value</label>
                        <input
                            type="text" name="name" placeholder={`Enter ${placeholder}`}   {...registerCredential("value")}
                            className="custom-input !rounded-md"
                        />
                        {
                            errors.value && <div className="error-css">{errors?.value?.message}</div>
                        }
                    </div>


                    {/* Popup Footer */}
                    <div className="mt-8 flex justify-end items-center gap-6">
                        <button onClick={() => { setOpenModal(!openModal); }} type="button" className="px-4 flex gap-1 items-center border-r border-[#7B7B7B] text-neutral-500 ">
                            <span className="text-sm font-bold">Cancel</span>
                        </button>
                        <button type='submit' className={`flex  gap-2 items-center bg-green text-white rounded-full py-1 text-sm h-10 px-10 font-[600] `}>
                            <span>{`Add`}</span>
                            {
                                isLoader && <Icon icon={'svg-spinners:tadpole'} />
                            }
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}