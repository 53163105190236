import React, { useRef, useState } from 'react';
import { Sidebar4 } from '../../helper/SvgIcons';
import { NavLink, useLocation } from 'react-router-dom';
import { useEffect } from 'react';

export default function VisitorsDropDown({ sidebarToggle }) {
    const Location = useLocation();
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        //   Function to handle outside click
        function handleClickOutside(event) {
            if (!Location.pathname.includes("/visitor")) {
                if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                    setIsOpen(false);
                }
            }
        }
        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [Location.pathname]);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);

    };

    return (
        <div ref={dropdownRef} className={`dropdown group hover:bg-[#EFEAFF] ${isOpen ? 'bg-[#EFEAFF]' : ''}`}>
            <button type="button" className='nav-link w-full' onClick={toggleDropdown}>
                <span className="menu-icon">
                    <Sidebar4 />
                </span>
                <span>{'Visitors'}</span>
                <span className={`group-hover:opacity-100 opacity-0 duration-[.6s] p-4 ml-auto ${isOpen ? 'opacity-100' : 'rotate-180 '}`} >
                    <svg width="12" height="6" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path id="Stroke 1" d="M1 7.77637L8 0.776367L15 7.77637" stroke="#2D2D2D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </span>
            </button>

            <ul style={{ maxHeight: isOpen ? '600px' : '0px' }} className={` ${isOpen ? 'pb-5' : ''} pl-[45px]   dropdown__content`}>
                <li>
                    <NavLink to="/visitors/dashboard" className={`nav-link   ${Location === "/visitor/dashboard" ? 'active' : ''}`}>
                        {' Visitor Activity '}
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/visitors/list" className={`nav-link   ${Location === "/visitor/listing" ? 'active' : ''}`}>
                        {'Visitors List'}
                    </NavLink>
                </li>
            </ul>
        </div>
    );
};