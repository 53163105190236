import { useContext } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { AuthContext } from '../_providers/AuthProvider';

const ProtectedRoute = ({ element: Element, ...rest }) => {
    const { session } = useContext(AuthContext)
    const navigate = useNavigate();
    try {
        if (!session?.data?.userInfo && !session?.data?.token) {
            navigate("/")
        }
        return <Element {...rest} />;
    } catch (error) {
        return <Navigate to="/" replace />;
    }
};

export default ProtectedRoute;