import React from 'react'
import NewLogo from '../../../src/assets/images/common/campaign-logo.svg'

import { SuccessNewUserIcon } from '../../helper/SvgIcons';

export default function SuccessModel({ navigate }) {

    return (
        <div className={`bg-black/40 absolute z-20 inset-0 flex items-center`}>
            <div className='w-[180px] min-h-full flex flex-col justify-start items-center gap-1 bg-login !bg-cover !bg-center !bg-no-repeat pt-10 p-2'>
                <img width={67} height={52} src={NewLogo} alt='' />
                <span className="text-white text-lg font-normal font-['Baloo']">Aye Campaign</span>
            </div>
            <div className="w-[calc(100vw-161px)] min-h-full flex flex-col gap-4 md:gap-5 justify-center items-center bg-offwhite">
                <div className="bg-white max-w-lg w-full p-10 text-center flex flex-col justify-center items-center gap-4 md:gap-8 md:max-w-4xl">
                    <SuccessNewUserIcon />
                    <p className="text-center text-zinc-800 text-xl font-bold py-4">
                        You&apos;ve Successfully created
                        <br /> account on Aye Campaigns
                    </p>
                    <button onClick={() => navigate("/dashboard")} className="max-w-[600px] w-full h-[54px] rounded-md bg-green text-white text-xl font-[500] hover:scale-105 duration-300">Proceed to Dashboard</button>
                </div>
            </div>
        </div>
    );
};