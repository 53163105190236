import { useEffect } from "react";
import mjml2html from 'mjml-browser'
import axios from "axios";
import { Path } from "./path";

export function replaceHandlebars(template, data) {
    const regex = /{{(.*?)}}/g;
    return template.replace(regex, (match, key) => {
        const trimmedKey = key.trim();
        const replacement = data[trimmedKey] !== undefined ? data[trimmedKey] : match;
        return replacement;
    });
};

export const useOutsideClick = (ref, callback) => {
    const handleClick = e => {
        if (ref.current && !ref.current.contains(e.target)) {
            callback();
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClick);
        return () => {
            document.removeEventListener("click", handleClick);
        };
    });
};

export function getBackgroundUrls(mjmlString) {
    const regex = /background-url="(.*?)"/g;
    let matches = [];
    let match;

    // Loop through all matches of background-url in the string
    while ((match = regex.exec(mjmlString)) !== null) {
        matches.push(match[1]); // Push the matched background-url value (group 1)
    }

    return matches;
}
export function replaceBackgroundUrls(mjmlString, newUrls) {
    const regex = /background-url="(.*?)"/g;
    let index = 0;

    // Replace each matched background-url with the corresponding value from newUrls array
    return mjmlString.replace(regex, function (match, p1) {
        let replacement = newUrls[index] ? `background-url="${newUrls[index]}"` : match;
        index++;
        return replacement;
    });
}

export function UploadImages(imageURL, token) {
    return new Promise(async (resolve, reject) => {
        try {
            const byteCharacters = atob(imageURL.split(',')[1]);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'image/jpeg' });
            const fileFromBlob = new File([blob], `${Date.now()}.jpeg`, { type: 'image/jpeg' });

            const formData = new FormData();

            formData.append('file', fileFromBlob)
            await axios.post(Path.FileUpload,
                formData,
                {
                    headers: {
                        Authorization: "Bearer " + token,
                    }
                }
            ).then((success) => {
                resolve({ success: true, data: success?.data?.publicUrl })
            }).catch((error) => {
                reject({ success: false, data: error })
            })
        } catch (err) {
            reject({ success: false, data: err })
        }
    })
}

export const GetBodyHtml = (subject, body) => {
    return `<!doctype html>
    <html lang="en">
    <head>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
        <!-- utf-8 works for most cases -->
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <meta name="x-apple-disable-message-reformatting">
        <!-- Forcing initial-scale shouldn't be necessary -->
        <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <!-- Use the latest (edge) version of IE rendering engine -->
        <title>${subject}</title>
        <!-- The title tag shows in email notifications, like Android 4.4. -->
        <!-- Please use an inliner tool to convert all CSS to inline as inpage or external CSS is removed by email clients -->
        <!-- important in CSS is used to prevent the styles of currently inline CSS from overriding the ones mentioned in media queries when corresponding screen sizes are encountered -->
    
        <!--[if gte mso 9]>
        <style>
            /* Target Outlook 2007 and 2010 */
            <style type="text/css">
            body, table, td, a {font-family: Arial, Helvetica, sans-serif !important;}
            sup { font-size: 100% !important; }
            </style>
        </style>
        <![endif]-->
    
        <!--[if mso]>
        <style type="text/css">
        body, table, td, a {font-family: Arial, Helvetica, sans-serif !important;}
        .note{font-size: 10px !important;}   
        </style>
        <![endif]-->
    
        <!--[if gt mso 15]>
        <style type="text/css" media="all">
        /* Outlook 2016 Height Fix */
        table, tr, td {border-collapse: collapse;}
        tr { font-size:0px; line-height:0px; border-collapse: collapse; }
        </style>
        <![endif]-->
    
        <!--[if (gte mso 9)|(IE)]>
        <style type="text/css">
        table {
        border-collapse: collapse;
        border-spacing: 0; 
        mso-line-height-rule: exactly; 
        mso-margin-bottom-alt: 0; 
        mso-margin-top-alt: 0; 
        mso-table-lspace: 0pt; mso-table-rspace: 0pt;}
        </style>
    <![endif]-->
    <style type="text/css">
        html,
        .body {
            margin: 0 !important;
            padding: 0 !important;
            height: 100% !important;
            width: 100% !important;
            font-family: Arial, Helvetica, sans-serif !important;
            font-weight: normal;
        }
        img {
            max-width: 100%;
            display: inline;
        }
        .body{
            max-width: 600px;
            width: 600px;
            margin: 0 auto !important;
        }

        /* Media Queries */
        /* @media screen and (max-width: 500px) */
        @media screen and (max-width: 480px) {
            .email-container {
                width: 90% !important;
                margin: 0 auto !important;
            }
            .email-wrapper {
                width: 100% !important;
            }
            .stack-column {
                display: block !important;
                width: 100% !important;
                max-width: 100% !important;
                direction: ltr !important;
            }
            .body{
                max-width: 100%;
                width: 100%;
                margin: 0 auto !important;
            }
        }
    </style>
    </head>
    
    <body class="body">
        ${body}
    </body>
    </html>`
}

export function mjmlConvert(mjml) {

    const options = {
        useMjmlConfigOptions: false,
        mjmlConfigPath: null,
        filePath: null
    };

    return mjml2html(mjml, options);

}