import { Icon } from '@iconify/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Path } from '../../helper/path';

function DynamicVariableModel({ dynamicModel, setDynamicModel, session }) {
    const [dynamicVarList, setDynamicVarList] = useState([]);

    function AddVariable(e){
        e.preventDefault();
        // console.log(dynamicModel.event.selection())   //Todo: Never Remove this // dont try this at home
        dynamicModel.event.insertHTML(e.target.variables.value)
        setDynamicModel({ status: false, event: null })
    }

    useEffect(() => {
        async function GetDynamicVarList() {
            await axios.get(Path.GetDynamicVarList,
                {
                    headers: {
                        Authorization: "Bearer " + session?.data?.token,
                    }
                }
            ).then(async (success) => {
                setDynamicVarList(success?.data)
            }).catch((error) => {
                console.log(error?.response?.data?.error)
            })
        }
        dynamicModel.status && session && GetDynamicVarList()
    }, [dynamicModel.status, session])
    return (
        <div className={`fixed top-0 left-0 z-[9999] w-screen h-screen flex bg-[rgba(255,255,255,0.2)] backdrop-blur-[2px] justify-center items-center p-10`}>
            <form onSubmit={AddVariable} className='w-[400px] flex flex-col gap-5 bg-white rounded-lg shadow-2xl p-5'>
                <div className='w-full flex justify-between items-center'>
                    <div className='text-xl font-semibold text-purple'>
                        {`Add Variable`}
                    </div>
                    <Icon onClick={() => setDynamicModel({ status: false, event: null })} className='text-2xl text-black cursor-pointer' icon={'gridicons:cross-circle'} />
                </div>
                <select name='variables' defaultValue={""} className='w-full h-[40px] px-4 border border-gray-300 hover:border-purple bg-white text-black text-sm rounded-lg'>
                    <option disabled value="">
                        Select Variable
                    </option>
                    {
                        dynamicVarList?.length !== 0 && dynamicVarList?.map((items, index) => {
                            return <option key={index} value={`{{${items}}}`}>{`{{${items}}}`}</option>
                        })
                    }
                </select>
                <div className='w-full flex items-center gap-2'>
                    <button onClick={() => setDynamicModel({ status: false, event: null })} type='button' className='w-1/2 h-[40px] bg-red rounded-lg text-white font-semibold hover:bg-white hover:border-2 hover:border-red hover:text-red'>
                        {`Cancel`}
                    </button>
                    <button type='submit' className='w-1/2 h-[40px] bg-green rounded-lg text-white font-semibold hover:bg-white hover:border-2 hover:border-green hover:text-green'>
                        {`Add`}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default DynamicVariableModel;