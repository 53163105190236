import React, { useContext, useEffect, useRef, useState } from 'react'
import { Icon } from '@iconify/react';
import { AuthContext } from '../../_providers/AuthProvider';
import axios from 'axios';
import { Path } from '../../helper/path';
import { showErrorMessage } from '../../helper/showMessage';
import { Logo, WhiteLogo } from '../../helper/SvgIcons';
import logo from "../../assets/images/common/logo.svg";

function AssistantWidget({ chatAssist, setChatAssist, campaignId }) {
    const { session } = useContext(AuthContext);
    const [data, setData] = useState([]);
    const [brandVoice, setBrandVoice] = useState('');
    const [collectionName, setCollectionName] = useState('');
    const [prompt, setPrompt] = useState('');
    const [brandList, setBrandList] = useState([])
    const [generate, setGenerate] = useState(false)
    const messagesEndRef = useRef(null);

    async function AyeGenerator(event) {
        event.preventDefault()
        if (brandVoice && collectionName) {
            setGenerate(true)
            await axios.post(Path.EmailGenerate,
                {
                    prompt: prompt,
                    email_part: 'email_body',
                    voice: brandVoice,
                    collectionName: collectionName
                },
                {
                    headers: {
                        Authorization: "Bearer " + session?.data?.token,
                    }
                }
            ).then((success) => {
                const newData = [
                    ...data,
                    {
                        data: success?.data?.replace(/\n/g, '<br/>'),
                        prompt: prompt
                    }
                ];
                setData(newData)
                setGenerate(false)
                setPrompt('');
            }).catch((error) => {
                console.log(error?.response?.data)
                setGenerate(false)
            })
        } else {
            showErrorMessage('Brand is not Selected')
        }
    }

    useEffect(() => {
        if (campaignId) {
            async function GetData() {
                await axios.get(`${Path.GetCampaignByID}${campaignId}`,
                    {
                        headers: {
                            Authorization: "Bearer " + session?.data?.token,
                        }
                    }
                ).then((success) => {
                    setBrandVoice(success?.data?.brandId?.voice)
                    setCollectionName(success?.data?.brandId?.collectionName || '')
                }).catch((error) => {
                    console.log(error)
                })
            }
            chatAssist && session?.data?.token && GetData()
        } else {
            async function BrandData() {
                await axios.get(Path.BrandVoiceTextBase, {
                    headers: {
                        Authorization: "Bearer " + session?.data?.token,
                    },
                }).then((success) => {
                    setBrandList(success?.data)
                }).catch((error) => {
                    console.log(error)
                })
            }
            BrandData()
        }
    }, [session, chatAssist])


    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [data]);
    return (
        <div className={`bg-[#F5F5F5] w-[900px] h-screen ${!chatAssist ? 'translate-x-[100%]' : 'translate-x-[0%]'} fixed top-0 bottom-0 right-0 z-[999] shadow-[0px_4px_9px_7px_rgba(0,0,0,0.05)] transition-all duration-500`}>
            <div className='w-full h-full flex flex-col text-white'>
                <div className='w-full h-[8%] flex justify-between items-center px-4 bg-purple select-none'>
                    <div className='flex items-center gap-2'>
                        <WhiteLogo />
                        <div className='text-lg lg:text-xl font-semibold'>
                            {`Aye Assistant`}
                        </div>
                    </div>
                    <Icon
                        onClick={() => {
                            setData([])
                            setPrompt('');
                            setCollectionName('')
                            setBrandVoice('')
                            setChatAssist(false)
                            document.getElementById("brandSelect").value = "";
                        }}
                        className='text-xl lg:text-2xl cursor-pointer' icon={'gridicons:cross-circle'}
                    />
                </div>
                {
                    campaignId === null &&
                    <div className='w-full h-[6%] flex items-center gap-2 bg-white text-black shadow-lg px-4'>
                        <span className='text-sm font-semibold'>
                            {`Brand:`}
                        </span>
                        <select
                            defaultValue={""}
                            id="brandSelect"
                            onChange={(e) => {
                                brandList?.map((i) => {
                                    if (e.target.value === i._id) {
                                        setCollectionName(i?.collectionName)
                                        setBrandVoice(i?.voice)
                                    }
                                })
                            }}
                            className='w-[200px] h-[30px] rounded-md px-2 outline-none border border-gray-300 focus:border-purple text-sm bg-white cursor-pointer select-none'
                        >
                            <option disabled value="">Select Brand</option>
                            {
                                brandList?.length !== 0 && brandList?.map((items) => {
                                    return <option key={items?._id} value={items?._id}>{items?.name}</option>
                                })
                            }
                        </select>
                    </div>
                }
                <div className={`w-full ${campaignId === null ? 'h-[78%]' : 'h-[84%]'} flex flex-col items-end gap-5 p-10 py-5 overflow-y-auto`}>
                    {
                        (data?.length !== 0)
                            ?
                            data?.map((items, index) => {
                                return (
                                    <div className='flex w-full flex-col gap-2'>
                                        <div className='w-11/12 text-black text-sm lg:text-base text-justify text-wrap pr-4'>You: {items.prompt}</div>
                                        <div ref={messagesEndRef} className=' w-full flex gap-2 items-center'>
                                            <div
                                                key={index} dangerouslySetInnerHTML={{ __html: items.data }}
                                                className='w-full bg-light-purple text-black text-sm lg:text-base rounded-lg p-5 shadow-lg text-justify'
                                            ></div>
                                            <div className='flex flex-col items-center'>
                                                <span className="size-12 flex justify-center items-center rounded-full bg-gray-200">
                                                    <img className='' width={30} height={30} src={logo} alt='' />
                                                </span>
                                                <div className='text-xs text-black text-center leading-4'>Aye Assistant</div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                            :
                            <div className='w-full h-full flex items-center justify-center'>
                                <div className='flex flex-col items-center justify-center gap-1'>
                                    {/* <Logo />*/}
                                    <img className='' width={55} height={55} src={logo} alt='' />
                                    <span className='text-black font-semibold text-sm mt-2 lg:text-base'>
                                        {`Hello ${session?.data?.userInfo?.firstName}`}
                                    </span>
                                    <span className='text-black font-semibold text-sm lg:text-base'>
                                        {`How can I help you today ?`}
                                    </span>
                                </div>

                            </div>
                    }
                </div>
                <form onSubmit={AyeGenerator} className='w-full h-[8%] flex justify-center items-center gap-2 p-3 px-0'>
                    <input name='prompt' placeholder='Enter prompt here ...' className='w-10/12 h-[40px] bg-white outline-none border border-gray-300 focus:border-purple rounded-lg px-3 text-black' type="text"
                        value={prompt} // Bind input value to state
                        onChange={(e) => setPrompt(e.target.value)} />
                    <button
                        type='submit' disabled={generate}
                        className='w-12 h-12 flex rounded-full justify-center items-center bg-purple text-white'
                    >

                        {
                            (generate)
                                ?
                                <Icon className='text-xl lg:text-2xl' icon={'svg-spinners:gooey-balls-1'} />
                                :
                                <Icon className='text-xl lg:text-2xl' icon={'iconoir:send-diagonal-solid'} />
                        }
                    </button>
                </form>
            </div>

        </div>
    );
};

export default AssistantWidget;