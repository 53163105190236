import { Icon } from '@iconify/react';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { object, string } from 'yup';
import { Validation, content, regex } from '../helper/Messages';
import * as Yup from "yup";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import SuccessModel from "../components/model/SuccessModel"
import { Path } from '../helper/path';
import { useModal } from 'react-modal-hook';
import { useLocation, useNavigate } from 'react-router-dom';
import { FullScreenLoader } from './common/FullScreenLoader';
import { AuthContext } from '../_providers/AuthProvider';
import { showErrorMessage } from '../helper/showMessage';


export default function SignUpForm() {
    const navigate = useNavigate();
    const [visible, setVisible] = useState(false);
    const [visible2, setVisible2] = useState(false);
    const [isLoader, setIsLoader] = useState(false)
    const [modal, setModal] = useState(false);
    const { status } = useContext(AuthContext)

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const tokenParams = searchParams.get('token');
    const email = searchParams.get('email');

    const toggleEye = () => {
        setVisible((prevVisible) => !prevVisible);
    };
    const toggleEye2 = () => {
        setVisible2((prevState) => !prevState);
    };

    const validationSchema = object().shape({
        firstName: string().trim().required(Validation?.firstNameRequired).matches(regex?.nameRegex, Validation?.fullNameInvalid),
        lastName: string().trim().required(Validation?.lastNameRequired).matches(regex?.nameRegex, Validation?.fullNameInvalid),
        email: string().trim().required(Validation?.emailRequired).matches(regex?.emailRegex, Validation?.emailInvalid),
        password: string().trim().required(Validation?.passwordRequired).matches(regex?.passRegex, Validation?.passwordInvalid),
        cnfpass: string().trim().required(Validation?.p_ReEnter).oneOf([Yup.ref("password")], Validation?.p_NotSame),
        checkBox: Yup.boolean().required().default(false).oneOf([true], 'You must accept the terms and conditions'),

    });

    const { register: registerCredential, handleSubmit, setValue, formState: { errors } } = useForm({ resolver: yupResolver(validationSchema) });


    useEffect(() => {
        setValue('email', email);
    })

    const onSubmit = async (data, event) => {
        event.preventDefault();
        setIsLoader(true)
        await axios.post(Path.SignUp,
            {
                firstName: data.firstName,
                lastName: data.lastName,
                email: email,
                password: data.password,
                token: tokenParams
            }
        ).then(async (success) => {
            await axios.post(Path.Login,
                {
                    email: email,
                    password: data?.password,

                }
            ).then((success) => {
                setIsLoader(false)
                status.login(JSON.stringify(success?.data?.user), success?.data?.token)
                setModal(true)
            }).catch((error) => {
                setIsLoader(false)
                showErrorMessage(error?.response?.data?.error || 'Something Went Wrong.')
            })
        }).catch((error) => {
            showErrorMessage(error?.response?.data?.error || 'Something Went Wrong.')
            setIsLoader(false)
        })
    };
    return (
        <>
            {isLoader ? <FullScreenLoader /> : " "}
            {
                modal ?
                    <SuccessModel navigate={navigate} />
                    :
                    <div className="w-[calc(100vw-161px)] min-h-full overflow-y-auto flex flex-col gap-4 md:gap-5 justify-center items-center bg-offwhite select-none">
                        {/* <SuccessModel visibility={visibility} event={setVisibility} /> */}
                        <div className="w-[90%] sm:w-[700px] text-center text-xl xs:text-2xl text-purple font-['Baloo']">
                            {`“Welcome to the First AI Email Sequence Tool”`}
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)} className="w-[90%] sm:w-[700px] flex flex-col justify-start items-center gap-6 md:gap-8 rounded-lg shadow-lg bg-white p-6 md:p-8 px-10">
                            <div className="w-full text-xl font-bold text-dark">
                                {`New User Signup`}
                            </div>
                            <div className="w-full grid grid-cols-2 justify-start items-center gap-5 text-grey text-sm">
                                <div className="w-full col-span-1 gap-1">
                                    <label>
                                        {`First Name`}
                                    </label>
                                    <input
                                        type="text" name="name" placeholder="Enter First Name"   {...registerCredential("firstName")}
                                        className={errors?.name ? "input-form !border !border-[#FF1D48]" : "input-form"}
                                    />
                                    {
                                        errors.firstName && <div className="error-css">{errors?.firstName?.message}</div>
                                    }
                                </div>
                                <div className="w-full col-span-1 gap-1">
                                    <label>
                                        {`Last Name`}
                                    </label>
                                    <input
                                        type="text" name="name" placeholder="Enter Last Name"   {...registerCredential("lastName")}
                                        className={errors?.name ? "input-form !border !border-[#FF1D48]" : "input-form"}
                                    />
                                    {
                                        errors.lastName && <div className="error-css">{errors?.lastName?.message}</div>
                                    }
                                </div>
                                <div className="w-full col-span-2 flex flex-col gap-1">
                                    <label>
                                        {`Email`}
                                    </label>
                                    <input
                                        type="text" name="email" placeholder="Enter Email" readOnly {...registerCredential("email")}
                                        className={errors?.email ? "input-form !border !border-[#FF1D48]" : "input-form"}
                                        autoComplete="off"

                                    />
                                    {
                                        errors.email && <div className="error-css">{errors?.email?.message}</div>
                                    }
                                </div>
                                <div className="w-full col-span-2 flex flex-col gap-1 relative">
                                    <label>
                                        {`Password`}
                                    </label>
                                    <input
                                        type={visible ? "text" : "password"} name="password" placeholder="Enter Password"   {...registerCredential("password")}
                                        className={errors?.password ? "input-form !border !border-[#FF1D48]" : "input-form"}
                                        autoComplete="off"
                                    />
                                    <span onClick={toggleEye} className={`absolute top-[45px] right-4 sm:right-6 lg:right-4 cursor-pointer`}>
                                        {visible ? (
                                            <Icon
                                                icon="codicon:eye-closed"
                                                className="icon-color"
                                                width={20}
                                                height={20}
                                            />
                                        ) : (
                                            <Icon
                                                icon="codicon:eye"
                                                className="icon-color"
                                                width={20}
                                                height={20}
                                            />
                                        )}
                                    </span>
                                    {
                                        errors.password && <div className="error-css">{errors?.password?.message}</div>
                                    }
                                </div>
                                <div className="w-full col-span-2 flex flex-col gap-1 relative">
                                    <label>
                                        {`Confirm Password`}
                                    </label>
                                    <input
                                        type={visible2 ? "text" : "password"} name="cnfpass" placeholder="Enter Confirm Password"   {...registerCredential("cnfpass")}
                                        className={errors?.cnfpass ? "input-form !border !border-[#FF1D48]" : "input-form"}
                                    />
                                    <span onClick={toggleEye2} className={`absolute top-[45px] right-4 sm:right-6 lg:right-4 cursor-pointer`} >
                                        {visible2 ? (
                                            <Icon
                                                icon="codicon:eye-closed"
                                                className="icon-color"
                                                width={20}
                                                height={20}
                                            />
                                        ) : (
                                            <Icon
                                                icon="codicon:eye"
                                                className="icon-color"
                                                width={20}
                                                height={20}
                                            />
                                        )}
                                    </span>
                                    {
                                        errors.cnfpass && <div className="error-css">{errors?.cnfpass?.message}</div>
                                    }
                                    <div className="w-full flex items-center gap-2 pt-2">
                                        <div className='flex flex-wrap items-center text-xs xs:text-sm  gap-1'>
                                            {errors.checkBox ? (
                                                <div
                                                    className={` absolute text-center w-20 sm:w-24 top-[160px] left-[-10px] lg:left-[-32px] mid-xl:top-[40px] 
                                              mid-xl:left-[-37px] z-50 rounded-lg bg-gray-200 shadow-lg text-xs text-black`}
                                                >
                                                    <div className="w-full h-full relative p-2 rounded-lg">
                                                        <span>{content.tooltip}</span>
                                                        <div
                                                            className={`w-3 h-3 bg-gray-200 rounded-[2px] rotate-45 absolute bottom-[86%] lg:bottom-[83%] lg:left-[40%]`}
                                                        ></div>
                                                    </div>
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                            <span className={errors.checkBox ? "border-2 border-red-color inline-flex" : ""}>
                                                <input className="w-5 h-5 cursor-pointer accent-purple flex items-center" type="checkbox" {...registerCredential("checkBox")} />
                                            </span>
                                            <span className="text-light-grey ">
                                                {`By signing up, I agree to Aye Campaigns`}
                                            </span>
                                            <span className="text-purple cursor-pointer hover:underline hover:scale-105 duration-300">
                                                {`Terms of Service.`}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button className="w-full h-[54px] rounded-md bg-purple text-white text-xl font-[500] hover:scale-105 duration-300 " type="submit">
                                {`Sign Up`}
                            </button>
                        </form>
                    </div>
            }

        </>
    )
}