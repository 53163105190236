import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Arrow from "../../assets/images/common/Arrow-Down.svg"
import axios from 'axios'
import { AuthContext } from '../../_providers/AuthProvider'
import { Path } from '../../helper/path'
import { Icon } from '@iconify/react'
import { FullScreenLoader } from '../common/FullScreenLoader'

export default function VistorDetail() {
    const { session } = useContext(AuthContext)
    const [isLoader, setIsLoader] = useState(false);
    const [pageViewsData, setPageViewsData] = useState([])
    const [ActionModel, setActionModel] = useState(false);
    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);

    const visitorsId = searchParams.get('visitorsId');
    const websiteId = searchParams.get('websiteId');
    const action = searchParams.get('action');
    async function getPageViews() {
        setIsLoader(true)
        await axios.get(`${Path.GetPageViewsDetail}?websiteId=${websiteId}&visitorsId=${visitorsId}`,
            {
                headers: {
                    Authorization: "Bearer " + session?.data?.token,
                },
            }
        ).then((success) => {
            setPageViewsData(success?.data)
            setIsLoader(false)
        }).catch((error) => {
            console.log(error)
            setIsLoader(false)

        })
    }


    useEffect(() => {
        getPageViews();

    }, [session?.data?.token])

    function convertDateFormat(dateString) {
        const originalDate = new Date(dateString);

        const day = originalDate.getDate();
        const month = originalDate.getMonth() + 1; // Months are zero-based
        const year = originalDate.getFullYear();
        const hours = originalDate.getHours();
        const minutes = originalDate.getMinutes();
        const period = hours >= 12 ? 'pm' : 'am';
        const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
        const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;

        // Format the date as "MM/DD/YYYY hh:mm tt"
        const formattedDate =
            <>
                {formattedHours}:{formattedMinutes} {period}
                <span className="w-[6px] h-[6px] bg-zinc-400 rounded-full inline-block mx-1"></span>
                {day}/{month}/{year}
            </>
        return formattedDate;
    }

    return (
        isLoader ? (
            <FullScreenLoader />
        ) : (
            <>
                <div className='py-2 text-purple flex items-center gap-1 cursor-pointer' onClick={() => window.history.back()}>
                    <Icon
                        icon="la:arrow-left"
                        className="icon-color"
                        width={16}
                        height={16}
                    />
                    <span className='text-sm '>{"Back"}</span>
                </div>
                {pageViewsData.length ?
                    <div className='mt-4'>
                        <h4 className="text-black-2 text-sm font-bold ">Page Views</h4>
                        <div className="bg-white p-3 mt-2 flex justify-between items-center gap-6 md:gap-8 lg:gap-12">
                            <div className='flex justify-end gap-3'>
                                {action &&
                                    <button
                                        type="button"
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            setActionModel(!ActionModel);
                                        }}
                                        className="outline-none rounded-md bg-purple text-white appearance-none text-sm cursor-pointer lg:text-base py-1.5 px-3"
                                    >
                                        Action
                                    </button>
                                }
                            </div>
                        </div>
                        <div className="mb-4">
                            <PageViewsTable pageViewsData={pageViewsData} convertDateFormat={convertDateFormat} setActionModel={setActionModel} ActionModel={ActionModel} action={action} contactId={visitorsId} session={session} />
                        </div>
                    </div> : <div className="text-purple bg-grey-4 text-center h-52 flex mt-2 justify-center items-center ">
                        <div>Data not found</div>
                    </div>
                }
            </>
        )
    );

};

function PageViewsTable({ pageViewsData, convertDateFormat, setActionModel, ActionModel, action, session, contactId }) {


    return (
        <>
            <table className="w-full bg-white [&_th]:text-dark text-left text-sm">
                {/* Table Header */}
                <thead
                    className="bg-[#ECECEC] border-b-8 border-white font-bold [&_th]:px-3 [&_th]:py-4 md:[&_th]:text-sm mt-4 cursor-pointer" >
                    <tr className='!bg-white'>
                        <th>
                            <strong className="text-dark">URL</strong>
                        </th>
                        <th>
                            <strong className="text-dark">Count</strong>
                        </th>
                        <th className=''>
                            <strong className="text-dark ">Visited On</strong>
                        </th>
                    </tr>

                </thead>
                {/* Table Head */}
                <tbody className="[&_tr]:border-b [&_tr]:border-[#ECECEC] [&_td]:px-4 [&_td]:py-3">
                    {
                        pageViewsData?.map((data, index) => {
                            return (
                                <tr key={data?._id} className={`${'transition-all duration-500 ease-in-out w-full bg-grey-4'}`} >
                                    <td className='w-[900px]' >
                                        <span title={data?.referrer} className="text-gray-2 font-normal">
                                            {data?.referrer ? data?.referrer.length > 70 ? data?.referrer.slice(0, 70) + ' ...' : data?.referrer : "-"}
                                        </span>
                                    </td>
                                    <td className='w-[600px] '>
                                        <span className="text-gray-2 font-normal">
                                            {data?.count ? data?.count : "-"}
                                        </span>
                                    </td>
                                    <td className='w-[300px]  '>
                                        <span className="text-gray-2 font-normal">
                                            {convertDateFormat(data?.latestVisitedDate)}
                                        </span>
                                    </td>
                                </tr>
                            )

                        })
                    }
                </tbody>
            </table>
        </>
    )
}



