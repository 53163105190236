import React, { useContext, useEffect, useRef, useState } from "react";
import { Icon } from "@iconify/react";
import axios from "axios";
import { AuthContext } from "../../_providers/AuthProvider";
import { useLocation, useNavigate } from "react-router-dom";
import { Path } from "../../helper/path";
import { IfIconSVG, Logo } from "../../helper/SvgIcons";
import { showErrorMessage, showSuccessMessage } from "../../helper/showMessage";
import { GetBodyHtml } from "../../helper/Utility";
import { MultiSelect } from "react-multi-select-component";
import EditorLoader from "../common/EditorLoader";
import ayeAssistantIcon from "../../assets/images/common/Aye-Assistant-btn.svg";
import AssistantWidget from "./AssistantWidget";
import DynamicVariableModel from "../model/DynamicVariableModel.jsx";
import grapesjs from "grapesjs";
import GjsEditor from "@grapesjs/react";
import Select from "react-dropdown-select";
import ConditionModel from "../model/ConditionModel.jsx";
import mjml2html from "mjml-browser";

export default function TemplateDraftEditor() {
  const navigate = useNavigate();
  const location = useLocation();
  const { session } = useContext(AuthContext);
  const searchParams = new URLSearchParams(location.search);

  const campaignId = searchParams.get("id");
  const stepId = searchParams.get("stepId");
  const templateId = searchParams.get("templateId");
  const variationId = searchParams.get("variationId");
  const draftId = searchParams.get("draftId");

  const BodyEditor = useRef(null);

  const [SubjectEditor, setSubjectEditor] = useState("");
  const [personaList, setPersonaList] = useState([]);
  const [selectedPersonaList, setSelectedPersonaList] = useState([]);
  const [dynamicVarList, setDynamicVarList] = useState([]);
  const [initData, setInitData] = useState({
    subject: "",
    body: `<mjml><mj-body></mjs-body></mjml>`,
  });
  const [showVersionHistory, setShowVersionHistory] = useState(false);
  const [isRestored, setIsRestored] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [personaData, setPersonaData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [chatAssist, setChatAssist] = useState(false);
  const [dynamicModel, setDynamicModel] = useState({
    status: false,
    event: null,
  });
  const [conditionModel, setConditionModel] = useState({
    status: false,
    event: null,
  });

  async function EditorAPI() {
    setIsLoading(true);
    let subjectContent = SubjectEditor;
    let tempElement = document.createElement("div");
    tempElement.innerHTML = subjectContent;
    console.log(BodyEditor?.current?.runCommand("mjml-code-to-html")?.html);
    // if (templateId == null) {
    //     await axios
    //         .post(
    //             Path.EmailSave,
    //             {
    //                 subject: SubjectEditor,
    //                 body: `${BodyEditor?.current?.getHtml()}`,
    //                 campaignId: campaignId,
    //                 stepId: stepId,
    //                 htmlBody:
    //                     BodyEditor?.current?.runCommand("mjml-code-to-html")?.html,
    //             },
    //             {
    //                 headers: {
    //                     Authorization: "Bearer " + session?.data?.token,
    //                 },
    //             }
    //         )
    //         .then((success) => {
    //             showSuccessMessage(success?.data?.message);
    //             setIsLoading(false);
    //             // navigate(`/campaigns/onboarding-campaign?id=${campaignId}`)
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //             setIsLoading(false);
    //             showErrorMessage(error?.response?.data?.error);
    //         });
    // } else {
    //     await axios
    //         .post(
    //             Path.EmailEdit,
    //             {
    //                 subject: SubjectEditor,
    //                 body: `${BodyEditor?.current?.getHtml()}`,
    //                 templateId: templateId,
    //                 htmlBody:
    //                     BodyEditor?.current?.runCommand("mjml-code-to-html")?.html,
    //             },
    //             {
    //                 headers: {
    //                     Authorization: "Bearer " + session?.data?.token,
    //                 },
    //             }
    //         )
    //         .then((success) => {
    //             showSuccessMessage(success?.data?.message);
    //             setIsLoading(false);
    //             // navigate(`/campaigns/onboarding-campaign?id=${campaignId}`)
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //             setIsLoading(false);
    //             showErrorMessage(error?.response?.data?.error);
    //         });
    // }
  }

  async function VariationAPI() {
    setIsLoading(true);
    await axios
      .post(
        Path.EmailVariation,
        {
          subject: SubjectEditor,
          body: `${BodyEditor?.current?.getHtml()}`,
          campaignId: campaignId,
          stepId: stepId,
          variationFrom: variationId,
          htmlBody: BodyEditor?.current?.runCommand("mjml-code-to-html")?.html,
        },
        {
          headers: {
            Authorization: "Bearer " + session?.data?.token,
          },
        }
      )
      .then((success) => {
        showSuccessMessage(success?.data?.message);
        setIsLoading(false);
        // navigate(`/campaigns/onboarding-campaign?id=${campaignId}`)
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        showErrorMessage(error?.response?.data?.error);
      });
  }

  async function DraftAPI() {
    setIsLoading(true);
    // console.log(
    //   mjml2html(success?.data?.emailBody, {
    //     useMjmlConfigOptions: false,
    //     mjmlConfigPath: null,
    //     keepComments: false,
    //     beautify: true,
    //     minify: true,
    //     filePath: null,
    //   })
    // );
    console.log("Coming here For Draft Update");
    setIsLoading(false);
  }

  async function SendDraftApi() {
    setIsLoading(true);
    console.log("Coming here For Sending Draft");
    setIsLoading(false);
  }

  async function SendMail() {
    setIsLoading(true);
    await axios
      .post(
        Path.SendMail,
        {
          campaignId: campaignId,
          stepId: stepId,
          templateId: templateId,
          contactId: selectedPersonaList?.map((item) => item?._id),
        },
        {
          headers: {
            Authorization: "Bearer " + session?.data?.token,
          },
        }
      )
      .then((success) => {
        setIsLoading(false);
        showSuccessMessage(success?.data?.message);
      })
      .catch((error) => {
        setIsLoading(false);
        showErrorMessage("Email Sent Failed");
      });
  }

  useEffect(() => {
    let list = [];
    let newArr = selectedPersonaList?.map((item) => item?._id);
    personaList.map((data) => {
      if (newArr.includes(data._id)) list.push(data);
    });
    setPersonaData(list);
  }, [selectedPersonaList]);

  // For Drafts
  useEffect(() => {
    async function GetInitialData(id) {
      await axios
        .get(`${Path.DraftDetails}/${id}`, {
          headers: {
            Authorization: "Bearer " + session?.data?.token,
          },
        })
        .then((success) => {
          setInitData({
            subject: success?.data?.data?.emailSubject,
            body: success?.data?.data?.emailBody,
          });
          setSubjectEditor(success?.data?.data?.emailSubject);
          // BodyEditor?.current?.setComponents(success?.data?.data?.emailBody);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    }

    draftId != null && session.data.token && GetInitialData(draftId);
  }, [draftId, session]);

  useEffect(() => {
    async function GetDynamicVarList() {
      await axios
        .get(Path.GetDynamicVarList, {
          headers: {
            Authorization: "Bearer " + session?.data?.token,
          },
        })
        .then(async (success) => {
          setDynamicVarList(success?.data);
        })
        .catch((error) => {
          console.log(error?.response?.data?.error);
        });
    }
    async function GetPersonaList() {
      await axios
        .get(Path.GetContactListByCampaignId + campaignId, {
          headers: {
            Authorization: "Bearer " + session?.data?.token,
          },
        })
        .then((success) => {
          const _data = success?.data.map((d) => ({
            ...d,
            value: d._id,
            label: d.firstName + " " + d.lastName,
          }));
          setPersonaList(_data);
          setSelectedPersonaList([_data[0]]);
        })
        .catch((error) => {
          console.log(error?.response?.data);
        });
    }
    session.data.token && GetDynamicVarList();
    session.data.token && GetPersonaList();
  }, [session]);

  useEffect(() => {
    if (templateId != null || variationId != null || draftId != null)
      setIsLoading(true);
  }, []);
  return (
    <div className="w-full min-h-screen flex flex-col bg-grey-5 !overflow-hidden">
      {isLoading && <EditorLoader />}
      <AssistantWidget
        chatAssist={chatAssist}
        setChatAssist={setChatAssist}
        campaignId={campaignId}
      />

      <Header
        navigate={navigate}
        EditorAPI={EditorAPI}
        templateId={templateId}
        variationId={variationId}
        campaignId={campaignId}
        VariationAPI={VariationAPI}
        initData={initData}
        SubjectEditor={SubjectEditor}
        BodyEditor={BodyEditor}
        SendMail={SendMail}
        chatAssist={chatAssist}
        setChatAssist={setChatAssist}
        draftId={draftId}
        DraftAPI={DraftAPI}
        SendDraftApi={SendDraftApi}
      />
      <VersionHistory
        setShowVersionHistory={setShowVersionHistory}
        showVersionHistory={showVersionHistory}
        templateId={templateId}
        session={session}
        isRestored={isRestored}
        setIsRestored={setIsRestored}
      />
      <PreviewSection
        showPreview={showPreview}
        setShowPreview={setShowPreview}
        personaData={personaData}
        campaignId={campaignId}
        stepId={stepId}
        templateId={templateId}
        session={session}
        setIsLoading={setIsLoading}
        personaList={personaList}
        selectedPersonaList={selectedPersonaList}
        setSelectedPersonaList={setSelectedPersonaList}
        subject={SubjectEditor || ""}
        body={BodyEditor?.current?.runCommand("mjml-code-to-html")?.html || ""}
      />
      {dynamicModel.status && (
        <DynamicVariableModel
          dynamicModel={dynamicModel}
          setDynamicModel={setDynamicModel}
          session={session}
        />
      )}
      {conditionModel.status && (
        <ConditionModel
          conditionModel={conditionModel}
          setConditionModel={setConditionModel}
          session={session}
        />
      )}
      <div className="w-full flex flex-grow p-6">
        <EditorArea
          setShowPreview={setShowPreview}
          initData={initData}
          setShowVersionHistory={setShowVersionHistory}
          SubjectEditor={SubjectEditor}
          setSubjectEditor={setSubjectEditor}
          BodyEditor={BodyEditor}
          setIsLoading={setIsLoading}
          setDynamicModel={setDynamicModel}
          session={session}
          setConditionModel={setConditionModel}
        />
      </div>
    </div>
  );
}

function Header({
  navigate,
  EditorAPI,
  variationId,
  campaignId,
  templateId,
  VariationAPI,
  initData,
  SubjectEditor,
  BodyEditor,
  SendMail,
  chatAssist,
  setChatAssist,
  draftId,
  DraftAPI,
  SendDraftApi,
}) {
  const [toggle, setToggle] = useState(false);
  const dropdownRef = useRef(null);

  function ClickHandler() {
    if (initData?.subject !== SubjectEditor || initData?.body !== BodyEditor)
      showErrorMessage("Please Save the Template First.");
    else SendMail();
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setToggle(false);
      }
    }
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [setToggle]);
  return (
    <div className="w-full min-h-[73px] flex justify-between items-center bg-white shadow-sm px-4 ">
      <div className="flex gap-4 items-center">
        <span className="cursor-pointer" onClick={() => navigate(`/`)}>
          <Logo />
        </span>
        <div>
          <div className="text-sm text-grey-6">
            <CustomBreadcrumb navigate={navigate} campaignId={campaignId} />
          </div>
          <div className="flex items-center gap-3">
            <p className="text-black text-xl font-bold">
              {`Default: Manual Email`}
            </p>
          </div>
        </div>
      </div>
      <div className="flex items-center gap-3 text-base font-[600]">
        <button
          type="button"
          onClick={() => setChatAssist(!chatAssist)}
          className="h-[40px] px-5 rounded-full flex items-center justify-center gap-2 bg-[linear-gradient(90deg,#5152C8_0%,#9D06FF_52%,#08FFA0_100%)]">
          <img
            className="w-[19.27px] h-[15px]"
            width={20}
            height={15}
            src={ayeAssistantIcon}
            alt=""
          />
          <span className="text-white text-base font-semibold">
            {`Aye Assistant`}
          </span>
        </button>

        <div className="relative" ref={dropdownRef}>
          <div className="flex items-center justify-center">
            <button
              className="h-10 px-12 text-sm rounded-full text-white bg-purple"
              onClick={(event) => {
                event.stopPropagation();
                setToggle(!toggle);
              }}>
              Save
            </button>
          </div>
          <div
            className={`${
              toggle === true ? "block" : "hidden"
            } bg-white absolute right-0 top-[110%] z-10`}>
            <ul className="text-xs min-w-[180px] shadow-[2px_4px_7px_4px_rgba(0,0,0,0.07)] text-center">
              {templateId && (
                <li
                  onClick={ClickHandler}
                  className="cursor-pointer font-medium hover:font-bold hover:text-dark hover:bg-[#EAE3FF] text-[#A1A1A1] transition-all py-2 px-4">
                  {`Send Only`}
                </li>
              )}
              <li
                onClick={() => {
                  if (draftId != null) {
                    DraftAPI();
                    setToggle(!toggle);
                  } else {
                    if (variationId == null) {
                      EditorAPI();
                      setToggle(!toggle);
                    } else {
                      VariationAPI();
                      setToggle(!toggle);
                    }
                  }
                }}
                className="cursor-pointer font-medium hover:font-bold hover:text-dark hover:bg-[#EAE3FF] text-[#A1A1A1] transition-all text-center py-2 px-4">
                {"Save Only"}
              </li>
              <li
                onClick={SendDraftApi}
                className="cursor-pointer font-medium hover:font-bold hover:text-dark hover:bg-[#EAE3FF] text-[#A1A1A1] transition-all py-2 px-4">
                {`Save & Send`}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

function CustomBreadcrumb({ navigate, campaignId }) {
  const { pathname } = useLocation();
  const pathUrl = pathname.split("/").filter(Boolean);
  return (
    <div className="w-full flex select-none text-gray-2 text-sm">
      {pathUrl.map((item, index) => {
        return (
          <div className="flex justify-start items-center gap-1" key={index}>
            {index === pathUrl.length - 1 ? (
              <span className={`text-purple capitalize`}>
                {item.replace(/-/g, " ")}
              </span>
            ) : (
              <span
                onClick={() => {
                  if (item === "campaigns") {
                    navigate(`/${pathUrl.slice(0, index + 1).join("/")}`);
                  } else if (item === "onboarding-campaign") {
                    navigate(
                      `/${pathUrl
                        .slice(0, index + 1)
                        .join("/")}?id=${campaignId}`
                    );
                  }
                }}
                className={`cursor-pointer capitalize`}>
                {item.replace(/-/g, " ")}
              </span>
            )}
            {index < pathUrl.length - 1 && (
              <Icon
                className="text-xl"
                icon={"ic:twotone-keyboard-arrow-right"}
              />
            )}
          </div>
        );
      })}
    </div>
  );
}

function EditorArea({
  initData,
  setShowVersionHistory,
  setShowPreview,
  SubjectEditor,
  setSubjectEditor,
  setDynamicModel,
  BodyEditor,
  setIsLoading,
  session,
  setConditionModel,
}) {
  const [imageState, setImageState] = useState([]);

  function TemplateInject(editor) {
    editor.Blocks.add("Add-Wrapper", {
      label: "Add Wrapper",
      content: `
            <mj-wrapper background="#000000">
            </mj-wrapper>
            `,
      media: `<svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 24 24"><circle cx="18" cy="4" r="2" fill="currentColor"/><path fill="currentColor" d="m17.836 12.014l-4.345.725l3.29-4.113a1 1 0 0 0-.227-1.457l-6-4a.999.999 0 0 0-1.262.125l-4 4l1.414 1.414l3.42-3.42l2.584 1.723l-2.681 3.352a5.913 5.913 0 0 0-5.5.752l1.451 1.451A3.972 3.972 0 0 1 8 12c2.206 0 4 1.794 4 4c0 .739-.216 1.425-.566 2.02l1.451 1.451A5.961 5.961 0 0 0 14 16c0-.445-.053-.878-.145-1.295L17 14.181V20h2v-7a.998.998 0 0 0-1.164-.986M8 20c-2.206 0-4-1.794-4-4c0-.739.216-1.425.566-2.02l-1.451-1.451A5.961 5.961 0 0 0 2 16c0 3.309 2.691 6 6 6c1.294 0 2.49-.416 3.471-1.115l-1.451-1.451A3.972 3.972 0 0 1 8 20"/></svg>`,
    });
  }

  function DynamicVariable(editor) {
    editor.RichTextEditor.add("dynamic-variable", {
      icon: '<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24"><path fill="currentColor" d="M11 6.5v2.83L8.33 12L11 14.67v2.83L5.5 12M13 6.43L18.57 12L13 17.57v-2.83L15.74 12L13 9.26M5 3a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2z"/></svg>',
      attributes: { title: "Add Dynamic Variable" },
      result: (rte) => {
        setDynamicModel((e) => ({ status: !e.status, event: rte }));
      },
    });
  }

  function AddCondition(editor) {
    editor.RichTextEditor.add("add-promptArea", {
      icon: '<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="#888888" d="m18 22l3-3l-.7-.7l-1.8 1.8V16h-1v4.1l-1.8-1.8l-.7.7zm-6-11.15L6.075 7.425L5 8.05V9.1l7 4.05l7-4.05V8.05l-1.075-.625zm-9 6.275V6.875L12 1.7l9 5.175v5.8q-.675-.325-1.437-.5T18 12q-2.9 0-4.95 2.05T11 19q0 .675.125 1.325t.375 1.25l.25.575zM18 24q-2.075 0-3.537-1.463T13 19q0-2.075 1.463-3.537T18 14q2.075 0 3.538 1.463T23 19q0 2.075-1.463 3.538T18 24"/></svg>',
      attributes: { title: "Add Prompt Area" },
      result: (rte) => rte.insertHTML(`[#]Write content here...[/#]`),
    });
  }

  function AddIfElseCondition(editor) {
    editor.RichTextEditor.add("ifElse-Condition", {
      icon: IfIconSVG,
      attributes: { title: "ifElse Condition" },
      result: (rte) =>
        setConditionModel((e) => ({ status: !e.status, event: rte })),
    });
  }

  useEffect(() => {
    if (BodyEditor?.current !== null)
      BodyEditor.current.AssetManager.add(imageState);
  }, [imageState]);
  return (
    <div className="w-full min-h-full flex flex-col gap-8 p-5 rounded-lg bg-white text-black shadow-lg">
      <div className="w-full flex justify-between items-center">
        <div className="font-bold text-sm select-none">{`Email Editor`}</div>
        <div className="flex items-center gap-3">
          {/* <button
            onClick={() => {
              setShowPreview(false);
              setShowVersionHistory(true);
            }}
            className="bg-green text-white text-sm  rounded-lg font-semibold px-5 p-2">
            {`Version History`}
          </button> */}
          <button
            onClick={() => {
              setShowVersionHistory(false);
              setShowPreview(true);
            }}
            className="bg-purple text-white text-sm  rounded-lg font-semibold px-5 p-2">
            {`Preview`}
          </button>
        </div>
      </div>

      <div className="w-full flex flex-col gap-2">
        <span className="text-xs font-[500] select-none">{`Subject`}</span>
        {initData?.subject !== null && (
          <textarea
            onChange={(e) => setSubjectEditor(e.target.value)}
            defaultValue={SubjectEditor}
            className="w-full text-sm bg-white text-black outline-none border border-gray-300 rounded-lg resize-none p-2"></textarea>
        )}
      </div>
      {/* Body Editor */}
      <div className="w-full flex-grow flex flex-col gap-2">
        <span className="text-xs font-[500] select-none">{`Body`}</span>
        <div className="w-full h-[100vh]">
          <GjsEditor
            grapesjs={grapesjs}
            grapesjsCss="https://unpkg.com/grapesjs/dist/css/grapes.min.css"
            options={{
              height: "100%",
              storageManager: false,
              components: initData?.body,
              assetManager: {
                assets: imageState,
                upload: false,
                uploadFile: async (ev) => {
                  setIsLoading(true);
                  const formData = new FormData();
                  formData.append("file", ev?.target?.files[0]);
                  await axios
                    .post(Path.FileUpload, formData, {
                      headers: {
                        Authorization: "Bearer " + session?.data?.token,
                      },
                    })
                    .then((success) => {
                      let arr = [...imageState];
                      arr.push(success?.data?.publicUrl);
                      setImageState(arr);
                      setIsLoading(false);
                    })
                    .catch((error) => {
                      console.log(error);
                      setIsLoading(false);
                    });
                },
              },
            }}
            plugins={[
              { id: "grapesjs-mjml", src: "https://unpkg.com/grapesjs-mjml" },
              TemplateInject,
            ]}
            onEditor={(editor) => {
              BodyEditor.current = editor;
              editor.on("component:styleUpdate", (component) => {
                if (component?.attributes?.type === "mj-column") {
                  let _styles = component?.getStyle();
                  if (_styles?.width?.includes("!important")) {
                    let newStyles = {
                      ..._styles,
                      width: _styles?.width?.replace(" !important", ""),
                    };
                    component?.setStyle(newStyles);
                  }
                }
              });
              TemplateInject(editor);
              DynamicVariable(editor);
              AddCondition(editor);
              AddIfElseCondition(editor);
            }}
          />
        </div>
      </div>
      {/* Body Editor */}
    </div>
  );
}

function VersionHistory({
  showVersionHistory,
  setShowVersionHistory,
  templateId,
  session,
  isRestored,
  setIsRestored,
}) {
  const [data, setData] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [active, setActive] = useState(0);

  function formatDate(d) {
    const date = new Date(d);
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let day = date.getDate();
    let month = months[date.getMonth()];
    let year = date.getFullYear().toString().substr(-2);
    let hour = date.getHours();
    let minute = date.getMinutes();
    let ampm = hour >= 12 ? "pm" : "am";
    hour = hour % 12;
    hour = hour ? hour : 12; // the hour '0' should be '12'
    minute = minute < 10 ? "0" + minute : minute;

    return `${day} ${month} ${year}, ${hour}:${minute} ${ampm}`;
  }

  function timeAgo(d) {
    const now = new Date();
    const date = new Date(d);
    const diffInMilliseconds = now - date;
    const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
    const secondsInMinute = 60;
    const secondsInHour = secondsInMinute * 60;
    const secondsInDay = secondsInHour * 24;

    const days = Math.floor(diffInSeconds / secondsInDay);
    const hours = Math.floor((diffInSeconds % secondsInDay) / secondsInHour);

    if (days > 0) {
      if (days === 1) {
        if (hours === 0) {
          return "1 day ago";
        } else {
          return `1 day, ${hours} hrs ago`;
        }
      } else {
        if (hours === 0) {
          return `${days} days ago`;
        } else {
          return `${days} days, ${hours} hrs ago`;
        }
      }
    } else if (hours > 0) {
      return `${hours} hrs ago`;
    } else {
      const minutes = Math.floor(
        (diffInSeconds % secondsInHour) / secondsInMinute
      );
      if (minutes > 0) {
        return `${minutes}m ago`;
      } else {
        return `${diffInSeconds}s ago`;
      }
    }
  }

  async function RestoreVersion(versionId) {
    await axios
      .post(
        Path.RestoreVersion,
        {
          templateId: templateId,
          versionId: versionId,
        },
        {
          headers: {
            Authorization: "Bearer " + session?.data?.token,
          },
        }
      )
      .then((success) => {
        setShowVersionHistory(!showVersionHistory);
        setIsRestored(!isRestored);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    async function GetVersionHistory() {
      setIsLoader(true);
      await axios
        .get(`${Path.EmailEdit}/${templateId}`, {
          headers: {
            Authorization: "Bearer " + session?.data?.token,
          },
        })
        .then((success) => {
          setData(success?.data);
          setIsLoader(false);
        })
        .catch((error) => {
          console.log(error);
          setIsLoader(false);
        });
    }
    showVersionHistory === true && GetVersionHistory();
  }, [showVersionHistory]);
  return (
    <>
      <div
        className={`bg-[#F5F5F5] min-w-[900px] max-w-[60vw] h-screen p-6 ${
          !showVersionHistory ? "translate-x-[100%]" : "translate-x-[0%]"
        } fixed right-0 z-[999] shadow-[0px_4px_9px_7px_rgba(0,0,0,0.05)] transition-all duration-500`}>
        <div className="flex justify-between text-sm items-center mb-10">
          <h4>Version History</h4>
          <button
            type="button"
            className="text-purple font-medium"
            onClick={() => {
              setShowVersionHistory(false);
            }}>
            Close
          </button>
        </div>
        {isLoader ? (
          <div className="text-purple flex h-48 items-center justify-center">
            <Icon width={50} icon={"svg-spinners:90-ring-with-bg"} />{" "}
          </div>
        ) : (
          <div className="flex">
            <div className="w-auto border-r text-sm border-[#D6D6D6]">
              <div className="w-[90%] h-[85vh] overflow-y-auto pr-4">
                {data.length !== 0 && (
                  <>
                    <div className="flex font-bold gap-2 mb-3">
                      Subject:{" "}
                      <span
                        dangerouslySetInnerHTML={{
                          __html: data[active]?.subject,
                        }}></span>
                    </div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: data[active]?.htmlBody,
                      }}
                      className="leading-loose text-dark text-sm font-normal mb-5"></div>
                  </>
                )}
              </div>
            </div>
            <div className="min-w-[360px] px-5 text-sm">
              <h4 className="text-right mb-3">History</h4>
              <ul className="">
                {data.length !== 0 &&
                  data?.map((item, index) => {
                    return (
                      <li
                        key={item?._id}
                        onClick={() => setActive(index)}
                        className={`${
                          active === index ? "bg-violet-100" : ""
                        } text-zinc-800 flex items-center justify-between gap-2 px-2 py-3 hover:bg-violet-100 select-none cursor-pointer`}>
                        <span className="text-dark">
                          {formatDate(item?.createdAt)}
                        </span>
                        <span className="text-neutral-500">
                          {timeAgo(item?.createdAt)}
                        </span>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        )}

        <button
          onClick={() => RestoreVersion(data[active]?._id)}
          className="absolute bottom-[70px] right-[50px] text-zinc-800 text-sm font-semibold py-2 px-4 bg-white border border-[#D6D6D6] rounded-md">
          Restore Version
        </button>
      </div>
    </>
  );
}

function PreviewSection({
  showPreview,
  setShowPreview,
  subject,
  body,
  personaData,
  campaignId,
  stepId,
  session,
  setIsLoading,
  selectedPersonaList,
  setSelectedPersonaList,
  personaList,
}) {
  const [test, setTest] = useState({ body: "", subject: "" });

  async function GetGeneratedContent() {
    setIsLoading(true);
    // console.log(body)
    await axios
      .get(`${Path.GetCampaignByID}${campaignId}`, {
        headers: {
          Authorization: "Bearer " + session?.data?.token,
        },
      })
      .then(async (success) => {
        await axios
          .post(
            Path.generateContent,
            {
              campaignId: campaignId,
              brandId: success?.data?.brandId?._id,
              personaId: personaData[0]?._id,
              stepId: stepId,
              subject: subject,
              body: body,
            },
            {
              headers: {
                Authorization: "Bearer " + session?.data?.token,
              },
            }
          )
          .then((success) => {
            setTest({
              body: success?.data?.contentBody,
              subject: success?.data?.contentSubject,
            });
            setIsLoading(false);
          })
          .catch((error) => {
            console.log(error?.response);
            showErrorMessage(
              error?.response?.data?.error || "Something Went Wrond"
            );
            setIsLoading(false);
          });
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }
  return (
    <div
      className={`bg-[#F5F5F5] w-[900px] h-screen p-6 ${
        !showPreview ? "translate-x-[100%]" : "translate-x-[0%]"
      } fixed right-0 z-[900] shadow-[0px_4px_9px_7px_rgba(0,0,0,0.05)] transition-all duration-500`}>
      <div className="w-full h-[7%] text-sm ">
        <div className="w-full flex justify-between items-center">
          <h3>Preview Email</h3>
          <button
            type="button"
            className="text-purple font-medium"
            onClick={() => {
              setShowPreview(false);
              setTest({ body: "", subject: "" });
            }}>
            Close
          </button>
        </div>
      </div>
      <div className="w-full h-[7%] flex items-center gap-3">
        <div className="flex items-center gap-2">
          <span className="font-semibold text-sm ">{`Select Contact:`}</span>
          {/* <MultiSelect
                        className='w-[200px] lg:w-[250px] h-[40px] border border-gray-300 rounded-lg bg-white text-sm lg:text-base select-none'
                        options={personaList}
                        value={selectedPersonaList}
                        onChange={setSelectedPersonaList}
                        labelledBy="Select"
                        hasSelectAll={false} // Disable select all option
                    /> */}
          <Select
            className="w-[200px] lg:w-[250px] h-[40px] border border-gray-300 rounded-lg bg-white text-sm  select-none"
            style={{ width: "250px", borderRadius: "8px", background: "white" }}
            options={personaList}
            value={selectedPersonaList}
            onChange={setSelectedPersonaList}
            labelledBy="Select"
          />
        </div>
        <button
          onClick={() =>
            showPreview && personaData.length !== 0 && GetGeneratedContent()
          }
          className="h-10 px-10 rounded-lg bg-purple text-white font-semibold text-sm ">
          {`Generate`}
        </button>
      </div>
      {test?.subject !== "" && test?.body !== "" && (
        <div className="w-full h-[85%] flex justify-center items-center pr-4">
          <div className="w-11/12 h-full overflow-y-auto p-6 bg-white">
            <div className="w-full flex flex-col gap-5">
              <div className="w-full flex flex-col gap-2">
                <p className="text-sm font-[600]">Subject</p>
                <div className="w-full text-sm">{test?.subject}</div>
              </div>
              <div className="w-full flex flex-col gap-2">
                <p className="text-sm font-[600]">Body</p>
                <div
                  className="w-full"
                  dangerouslySetInnerHTML={{ __html: test?.body }}></div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
