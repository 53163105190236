import React, { useEffect, useState } from 'react'
import backIcon from "../../assets/images/campaigns/backbutton.svg"
import axios from 'axios';
import { Path } from '../../helper/path';
import { showSuccessMessage, showErrorMessage } from '../../helper/showMessage';
import { Icon } from '@iconify/react';
import { AddTagsIcon } from '../../helper/SvgIcons';
import { BrandKnowledgeBaseModelValidation } from '../../helper/Messages';



export default function AddKnowledgeTextModel({ id, refresh, setRefresh, hide, setToggle, session }) {
    const [tags, setTags] = useState([]);
    const [isLoader, setIsLoader] = useState(false)
    const [tagLoader, setTagLoader] = useState(false)
    const [data, setData] = useState(null)
    const [inputError, setInputError] = useState({
        name: { error: false, message: '' },
        content: { error: false, message: '' },
        tags: { error: false, message: '' },
    });


    const TagsCreater = () => {
        const inputElement = document.getElementById('tagsInput');
        const tagValue = inputElement.value.trim();
        if (tagValue !== "") {
            setTagLoader(true)
            let update = [...tags];
            if (!update.includes(tagValue)) {
                update.push(tagValue);
                setTags(update);
                inputElement.value = '';
                setTagLoader(false)
            } else {
                setTagLoader(false)
                showErrorMessage("Value already exists");
            }
        }

    };

    const TagsDelete = (index) => {
        let update = [...tags]
        update.splice(index, 1)
        setTags(update)
    }

    const handleCloseTextPopUp = () => {
        hide()
        setToggle(false)
    }

    const onSubmit = async (event, id) => {
        event.preventDefault();
        const name = event?.target?.name?.value.trim();
        const content = event?.target?.content?.value.trim();

        setInputError({
            name: { error: !name, message: !name ? BrandKnowledgeBaseModelValidation?.nameRequired : '' },
            content: { error: !content, message: !content ? BrandKnowledgeBaseModelValidation?.contentRequired : '' },
            tags: { error: tags.length === 0, message: tags.length === 0 ? BrandKnowledgeBaseModelValidation?.tagsRequired : '' },
        });

          // Rest of the validation...
          if (!name || !content || tags.length === 0) {
            return;
        }

        if (id !== '') {
            if (tags?.length !== 0) {
                setIsLoader(true)
                await axios.put(`${Path.UpdateBrandKnowledgeByID}${id}`,
                    {
                        name: event?.target?.name?.value,
                        content: event?.target?.content?.value,
                        type: "TEXT",
                        tags: tags
                    },
                    {
                        headers: {
                            Authorization: "Bearer " + session?.data?.token,
                        },
                    }
                ).then((success) => {
                    setIsLoader(false)
                    setRefresh(!refresh)
                    showSuccessMessage(success?.data?.message)
                    hide()
                }).catch((error) => {
                    console.log(error)
                    setIsLoader(false)
                })
            }
        } else {
            if (tags?.length !== 0) {
                setIsLoader(true)
                await axios.post(Path.BrandKnowledgeTextBase,
                    {
                        name: event?.target?.name?.value,
                        content: event?.target?.content?.value,
                        type: "TEXT",
                        tags: tags
                    },
                    {
                        headers: {
                            Authorization: "Bearer " + session?.data?.token,
                        },
                    }
                ).then((success) => {
                    setIsLoader(false)
                    setRefresh(!refresh)
                    showSuccessMessage(success?.data?.message)
                    hide()
                }).catch((error) => {
                    console.log(error)
                    setIsLoader(false)
                })
            }
        }

    };
    const handleFormKeyDown = (e) => {
        if (e.key === 'Enter') e.preventDefault();
    };

    const handleInputChange = (field, value) => {
        setInputError((prevInputError) => ({
            ...prevInputError,
            [field]: { error: !value.trim(), message: !value.trim() ? BrandKnowledgeBaseModelValidation[field + 'Required'] : '' },
        }));
    }

    useEffect(() => {
        async function GetData() {
            await axios.get(`${Path.GetBrandKnowledgeDataByID}${id}`,
                {
                    headers: {
                        Authorization: "Bearer " + session?.data?.token,
                    }
                }
            ).then((success) => {
                setData(success?.data)
                setTags(success?.data?.tags)
            }).catch((error) => {
                console.log(error)
            })
        }
        id !== '' && GetData()
    }, [id, session?.data?.token])

    
    return (
        <div className={`bg-black/40 absolute z-20 inset-0 flex items-center`}>
            <div className="bg-white border border-gary-3 mx-auto max-w-[850px] w-full p-8">
                {/* Popup Header */}
                <div className="text-center">
                    <h4 className="font-bold text-dark text-xl md:text-[26px]">
                        {
                            id !== '' ? 'Edit Knowledge Base' : 'Add to Knowledge Base'
                        }
                    </h4>

                    <p className="text-neutral-500 text-base/9 ">
                        Give Aye Assistant facts to more accurately write about any topic.
                    </p>
                </div>
                <form onSubmit={(e) => onSubmit(e, id)} onKeyDown={(e) => handleFormKeyDown(e)} className="mx-auto w-[95%] mt-[44px]">
                    <div className="mt-6">
                        <label className="custom-label">
                            {`Name`}
                        </label>
                        <input
                            onChange={(e) => handleInputChange('name', e.target.value)}
                            type="name" name="name" placeholder="Enter a Name" defaultValue={data?.name}
                            className={`custom-input !h-[46px] !rounded-lg ${inputError.name.error ? 'border border-red-color' : ''}`}
                        />
                        {
                            inputError.name.error && <div className="error-css">{inputError?.name?.message}</div>
                        }

                    </div>

                    <div className="mt-6">
                        <label className="custom-label">
                            {`Background Information`}
                        </label>
                        <textarea
                            cols={8}
                            rows={8}
                            name='content'
                            defaultValue={data?.content}
                            className={`custom-input !rounded-lg ${inputError.content.error ? 'border border-red-color' : ''}`}
                            onChange={(e) => handleInputChange('content', e.target.value)}
                            placeholder="Write background information"></textarea>
                        {
                            inputError.content.error && <div className="error-css">{inputError?.content?.message}</div>
                        }

                    </div>
                    <div className="mt-6">
                        <label className="custom-label">
                            {`Tags`}
                        </label>
                        <div
                            className={`w-full flex flex-wrap justify-start items-center gap-1 border border-light-grey-2 rounded-lg p-1 ${inputError.tags.error ? 'border border-red-color' : ''}`}>
                            {
                                <>
                                    <div className=' flex flex-wrap items-center gap-3 p-1'>
                                        {
                                            tags?.length !== 0 && tags?.map((data, index) => {
                                                return (
                                                    <div key={index} className='flex items-center gap-2  rounded-md p-1 px-2 text-green font-[600] bg-light-green'>
                                                        <span>
                                                            {data}
                                                        </span>
                                                        <Icon onClick={() => TagsDelete(index)} className='text-black text-md cursor-pointer' icon={'radix-icons:cross-2'} />
                                                    </div>
                                                );
                                            })
                                        }
                                        <div className='flex items-center w-[150px] !h-[36px] gap-2  px-2 rounded-md'>
                                            <input id="tagsInput" className="w-full h-[full] outline-none" type="text"
                                                onChange={(e) => handleInputChange('tags', e.target.value)}
                                                placeholder="Type here..." />
                                            <span className="cursor-pointer" onClick={TagsCreater}>
                                                {
                                                    tagLoader ? <Icon className="text-green" icon={'svg-spinners:tadpole'} /> : <AddTagsIcon />
                                                }
                                            </span>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>

                        {
                            inputError.tags.error && <div className="error-css">{inputError?.tags?.message}</div>
                        }
                    </div>
                    {/* Popup Footer */}
                    <div className="mt-10 flex justify-end items-center gap-6">
                        <button onClick={handleCloseTextPopUp} type="button" className="px-4 flex gap-1 items-center border-r border-[#7B7B7B] text-neutral-500 ">
                            <img src={backIcon} alt='' />
                            <span className="text-base font-bold">Back</span>
                        </button>
                        <button onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }} type="submit"
                            className={`flex items-center gap-2 bg-green text-white rounded-full py-1 text-base h-10 px-10  font-[600] font-base`}>
                            {
                                id !== ''
                                    ?
                                    <>
                                        {<span>{`Save`}</span>}
                                        {
                                            isLoader && <Icon icon={'svg-spinners:tadpole'} />
                                        }
                                    </>
                                    :
                                    <>
                                        {<span>{`Add to Knowledge Base`}</span>}
                                        {
                                            isLoader && <Icon icon={'svg-spinners:tadpole'} />
                                        }
                                    </>
                            }
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}