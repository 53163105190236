import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Personas, ListTab, CampaignIcon, EmailIcon, CallIcon, LinkedInIcon, UserContactIcon, EllipsisIcon, ArchiveIcon } from './../../helper/SvgIcons';
import actionIcon from "../../assets/images/brand-management/action-Icon.svg"
import Breadcrumb from '../common/Breadcrumbs'
import NewContactListModel from '../model/NewContactListModel';
import AddNewContact from '../model/AddNewContact';
import { Icon } from '@iconify/react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../_providers/AuthProvider';
import axios from 'axios';
import { Path } from '../../helper/path';
import { FullScreenLoader } from '../common/FullScreenLoader';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { object, string } from 'yup';
import { Validation } from '../../helper/Messages';
import { showErrorMessage, showSuccessMessage } from '../../helper/showMessage';
import { SelectListModal, UploadOptions } from '../campaign/CampaignContact';
import ContactNewCsvImport from './ContactNewCsvImport';
import Swal from 'sweetalert2';
import ReactPaginate from 'react-paginate';



export default function ContactDirectory() {
    const { session } = useContext(AuthContext);
    const [activeTab, setActiveTab] = useState(0)
    const [isLoader, setIsLoader] = useState(false);
    const [visibility, setVisibility] = useState(false)
    const [hoverColor, setHoverColor] = useState(null)
    const [toggle, setToggle] = useState(false);
    const [listData, setListData] = useState([])
    const [listContactData, setListContactData] = useState([])
    const [showContactbyList, setShowContactbyList] = useState(false)
    const [openModal, setOpenModal] = useState(false);
    const [contactList, setContactList] = useState([])
    const [selectedId, setSelectedId] = useState([])
    const [selectCampaignModal, setSelectCampaignModal] = useState(false);
    const [selectListCampaignModal, setSelectListCampaignModal] = useState(false);
    const [listCampaignId, setListCampaignId] = useState(null)
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
    const [listCheckboxes, setListCheckboxes] = useState([]);
    const [selectListModal, setSelectListModal] = useState(false)
    const [listName, setListName] = useState("")
    const [listId, setListId] = useState(null)
    const navigate = useNavigate();
    const dropdownRef = useRef(null);

    const [showSetting, setShowSetting] = useState(false);
    const [filter, setFilter] = useState({ name: '', domain: '' })
    const [sortConfig, setSortConfig] = useState({ key: 'createdAt', direction: 'desc' });
    const [pageCount, setPageCount] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [refresh, setRefresh] = useState(false)
    console.log(listName, "data")


    async function GetAllList() {
        await axios.get(`${Path.GetAllList}`, {
            headers: {
                Authorization: "Bearer " + session?.data?.token,
            },
        }).then((success) => {

            setListData(success?.data)
            setListName("")
        }).catch((error) => {
            console.log(error)
        })
    }


    async function GetListContact(listContactId) {
        setIsLoader(true);
        await axios.get(`${Path.GetByListContact}${listContactId}`, {
            headers: {
                Authorization: "Bearer " + session?.data?.token,
            },
        }).then((success) => {

            setListContactData(success?.data)
            setIsLoader(false);
        }).catch((error) => {
            console.log(error)
            setIsLoader(false);
        })
    }

    async function GetAllContact(pageNumber) {
        setIsLoader(true);
        await axios.get(Path.GetAllContactsNew,
            {
                params: {
                    page: pageNumber,
                    size: 10,
                    name: filter.name,
                    domain: filter.domain,
                    sortKey: sortConfig.key,
                    sortOrder: sortConfig.direction,
                },
                headers: {
                    Authorization: "Bearer " + session?.data?.token,
                },
            })
            .then((success) => {
                setIsLoader(false);
                // setListName("")
                setPageCount(success?.data?.totalPages)
                setContactList(success?.data?.contacts);
            })
            .catch((error) => {
                setIsLoader(false);
                console.log(error);
            });
    }

    const handlePageClick = (data) => {
        const selectedPage = data.selected + 1;
        setCurrentPage(selectedPage);
        GetAllContact(selectedPage);

    };

    const requestSort = key => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    const sortedContactListing = useMemo(() => {
        let sortableItems = [...contactList];
        if (sortConfig !== null) {
            sortableItems.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'asc' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'asc' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableItems;
    }, [contactList, sortConfig]);


    useEffect(() => {
        session?.data?.token && GetAllList();
        session?.data?.token && GetAllContact();
    }, [refresh, session?.data?.token]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setToggle(false);
            }
        }
        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [setToggle]);


    return (
        <>
            {isLoader ? <FullScreenLoader /> : " "}
            <div>
                <SelectCampaignModal openModal={selectCampaignModal} event={setSelectCampaignModal} session={session} selectedId={selectedId} setSelectedCheckboxes={setSelectedCheckboxes} setListCheckboxes={setListCheckboxes} />
                <SelectListCampaignModal openModal={selectListCampaignModal} event={setSelectListCampaignModal} session={session} listId={listCampaignId} />
                <NewContactListModel visibility={visibility} event={setVisibility} />
                <AddNewContact openModal={openModal} event={setOpenModal} session={session} navigate={navigate} listId={listId} status={true} GetAllList={GetAllList} GetAllContact={GetAllContact} />
                <SelectListModal openModal={openModal} event={() => { }} setListId={(data) => {
                    setListId(data)
                    navigate(`/administration/contact/new-list?id=${data}`)
                }} selectListModal={selectListModal} setSelectListModal={setSelectListModal} session={session} />

                <div className='flex flex-col justify-center items-start gap-1'>
                    <Breadcrumb />
                    <div className='flex w-full justify-between' >
                        <span className='large-title'>
                            {activeTab === 0
                                ? `Your Contacts`
                                : showContactbyList
                                    ? `Your Contact Lists (${listName})`
                                    : `Your Contact Lists`}
                        </span>

                        <div>
                            <button className=" bg-purple  py-2  btn"
                                onClick={(event) => {
                                    event.stopPropagation();
                                    if (activeTab === 0) {
                                        setSelectListModal(!selectListModal)
                                    } else {
                                        setVisibility(!visibility)
                                    }
                                }
                                }>
                                {activeTab == 0 ? 'Add Contact' : 'Add List'}
                            </button>
                        </div>
                    </div>
                </div>
                <div className="flex items-center border-b border-[#E9E9E9] mt-5">
                    <ul className="flex items-center gap-3 md:gap-4 ">
                        <li className=" hover:text-purple">
                            <button onClick={() => {
                                setActiveTab(0)
                            }} type="button"
                                className={`${activeTab === 0 ? 'active' : ''} flex gap-3 items-center tab !min-w-[130px] !border-0`}>
                                <span>
                                    <Personas color={activeTab === 0 ? '#7F5FEE' : '#888888'} />
                                </span>
                                All Contacts
                            </button>
                        </li>
                        <li className=" hover:text-purple">

                            <button onClick={() => { setActiveTab(1); setShowContactbyList(false);; }} type="button"
                                className={`${activeTab === 1 ? 'active' : ''} flex gap-3 items-center tab !min-w-[130px] !border-0`}>
                                <span>
                                    <ListTab color={activeTab === 1 ? '#7F5FEE' : '#888888'} />
                                </span>
                                Lists
                            </button>
                        </li>
                    </ul>

                </div>
                <>
                    {activeTab === 0 &&
                        <ContactListTable setSelectedId={setSelectedId} contactList={contactList} toggle={toggle} setToggle={setToggle} navigate={navigate} selectedCheckboxes={selectedCheckboxes} setSelectedCheckboxes={setSelectedCheckboxes} setSelectCampaignModal={setSelectCampaignModal} selectCampaignModal={selectCampaignModal} session={session} showSetting={showSetting}
                            setShowSetting={setShowSetting} setFilter={setFilter} setCurrentPage={setCurrentPage} setRefresh={setRefresh}
                            GetAllContact={GetAllContact} pageCount={pageCount} handlePageClick={handlePageClick}
                            currentPage={currentPage} sortedContactListing={sortedContactListing} requestSort={requestSort} sortConfig={sortConfig}
                        />
                    }
                    {(activeTab === 1) &&
                        <div className="bg-white mt-6">
                            {activeTab === 1 &&
                                <div>
                                    {listData.length > 0
                                        ?
                                        !showContactbyList ?
                                            <table className="w-full table-auto text-left mt-2 text-xs md:text-sm ">
                                                <thead className="[&_th]:!border-t-0 [&_th]:!font-medium [&_th]:!p-3 [&_th]:text-sm [&_th]:!text-[#7B7B7B]">
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Last Modified</th>
                                                        <th>Owner</th>
                                                        <th className=''>Total Records</th>
                                                        <th><span className="sr-only">Actions</span></th>
                                                    </tr>
                                                </thead>
                                                <tbody className="[&_td]:py-5 [&_td]:font-medium [&_td]:px-2 md:[&_td]:px-3">
                                                    {
                                                        listData && listData?.map((data, index) => {
                                                            return <ContactDirectoryRow key={index} data={data} setShowContactbyList={setShowContactbyList} GetListContact={GetListContact} setListCampaignId={setListCampaignId} setSelectListCampaignModal={setSelectListCampaignModal} selectListCampaignModal={selectListCampaignModal} setListName={setListName}
                                                            />
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                            :
                                            <ContactListTable setSelectedId={setSelectedId} contactList={listContactData} toggle={toggle} setToggle={setToggle} navigate={navigate} selectedCheckboxes={listCheckboxes} setSelectedCheckboxes={setListCheckboxes} setSelectCampaignModal={setSelectCampaignModal} selectCampaignModal={selectCampaignModal} session={session} showSetting={showSetting} setShowSetting={setShowSetting} setFilter={setFilter} setCurrentPage={setCurrentPage} setRefresh={setRefresh} GetAllContact={GetAllContact} pageCount={pageCount} handlePageClick={handlePageClick} currentPage={currentPage} sortedContactListing={sortedContactListing} requestSort={requestSort} sortConfig={sortConfig}
                                            />
                                        :
                                        <div className="text-purple bg-grey-4 text-center h-52 flex mt-2 justify-center items-center ">
                                            <div>Data not found</div>
                                        </div>
                                    }
                                </div>
                            }

                        </div>
                    }
                </>
            </div>
        </>
    )
}

export function ContactDirectoryRow({ data, setShowContactbyList, GetListContact, setSelectListCampaignModal, selectListCampaignModal, setListCampaignId, setListName }) {
    function convertDateFormat(dateString) {
        const originalDate = new Date(dateString);

        const day = originalDate.getDate();
        const month = originalDate.getMonth() + 1; // Months are zero-based
        const year = originalDate.getFullYear();
        const hours = originalDate.getHours();
        const minutes = originalDate.getMinutes();
        const period = hours >= 12 ? 'pm' : 'am';
        const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
        const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;

        // Format the date as "MM/DD/YYYY hh:mm tt"
        const formattedDate = `${year}-${month}-${day} ${formattedHours}:${formattedMinutes} ${period}`;

        return formattedDate;
    }

    return (
        <>
            <tr className="bg-grey-4 border-b border-white" onClick={() => { setShowContactbyList(true); GetListContact(data._id); setListName(data.title) }}>
                <td className="text-dark cursor-pointer">{data?.title}</td>
                <td className="text-dark">{convertDateFormat(data?.updatedAt)}</td>
                <td className="text-dark">{`${data?.createdBy?.firstName} ${data?.createdBy?.lastName}`}</td>
                <td className="text-dark">{data?.totalRecords}</td>
                <td className="relative text-center">
                    <div className="flex gap-2 items-center justify-around">
                        <button onClick={(event) => { event.stopPropagation(); setSelectListCampaignModal(!selectListCampaignModal); setListCampaignId(data._id) }} type="button" className="flex justify-center items-center text-gray-6 gap-2 bg-white border border-gray-6 rounded h-[33px] px-3 hover:border-purple">
                            <CampaignIcon />
                            <span className="font-medium !text-xs ">Add to Campaign</span>
                        </button>
                        <button className="text-center">
                            <img width={30} height={30} className="mx-auto" src={actionIcon} alt='' />
                        </button>
                    </div>
                </td>
            </tr>
        </>
    )
}

export const Dropdown = ({ options, onSelect }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    const toggleDropdown = () => setIsOpen(!isOpen);

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <div className="relative" ref={dropdownRef}>
            <button onClick={toggleDropdown} className="text-center p-1 px-2">
                <EllipsisIcon />
            </button>
            {isOpen && (
                <ul className="absolute right-0 top-full bg-white shadow text-left text-xs w-[188px]">
                    {options.map((option, index) => (
                        <li key={index} className="py-2 px-4 hover:bg-gray-100 cursor-pointer" onClick={() => onSelect(option)}>
                            {option.label}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

const ContactListTable = ({ contactList, toggle, setToggle, navigate, setSelectedId, setSelectedCheckboxes, selectedCheckboxes, setSelectCampaignModal, selectCampaignModal, session, showSetting, setShowSetting, setFilter, setCurrentPage, setRefresh, GetAllContact,
    pageCount, handlePageClick, currentPage, sortedContactListing, requestSort, sortConfig }) => {
    const dropdownRef = useRef(null);

    let headers = []
    if (contactList.length) {
        headers = Object.keys(contactList[0]);
    }

    const handleEmailButtonClick = (data) => {
        if (data?.email) {
            const mailtoLink = `mailto:${data.email}`;
            window.location.href = mailtoLink;
        }
    };
    const handleDropdownSelect = (option, data) => {
        if (option.value === 'delete') {
            Swal.fire({
                title: `Are you sure you want to delete the contact?`,
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#7F5FEE",
                cancelButtonColor: "#F86E6E",
                confirmButtonText: "Yes"
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.delete(`${Path.DeleteContact}${data?._id}`, {
                        headers: {
                            Authorization: "Bearer " + session?.data?.token,
                        }
                    })
                        .then(() => {
                            window.location.reload();
                        });
                }
            });
        }
    };
    const dropdownOptions = [
        { label: 'Delete', value: 'delete' }
    ];
    const handleCheckboxChange = (index) => {
        if (index === -1) { // If the checkbox is the select-all checkbox
            // Check if all checkboxes are already selected
            const allSelected = selectedCheckboxes.length === contactList.length;

            // If all checkboxes are already selected, deselect them all
            if (allSelected) {
                setSelectedCheckboxes([]);
            } else { // If not all checkboxes are selected, select them all
                setSelectedCheckboxes(contactList.map((_, i) => i));
            }
        } else { // If a checkbox other than the select-all checkbox is clicked
            if (selectedCheckboxes.includes(index)) {
                setSelectedCheckboxes(selectedCheckboxes.filter(item => item !== index));
            } else {
                setSelectedCheckboxes([...selectedCheckboxes, index]);
            }
        }
    };

    const handleGenerateButtonClick = () => {
        // Logic to handle generate button click
        const selectedIds = selectedCheckboxes.map(index => contactList[index]?._id).filter(Boolean);
        setSelectedId(selectedIds)
        setSelectCampaignModal(!selectCampaignModal)
    };

    useEffect(() => {
        //   Function to handle outside click
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setToggle(null);
            }
        }
        //   Bind the event listener
        document.addEventListener('click', handleClickOutside);
        //   Cleanup the event listener on unmount
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <>
            <div className="flex gap-4 items-end flex-wrap  pt-6 justify-end">
                {/* {selectedCheckboxes.length > 0 && ( */}

                {/*  )} */}

            </div>
            <div className="flex bg-white mt-3 flex-col overflow-y-auto min-h-[500px] w-full">
                <div className="bg-white my-3">
                    <div className='flex justify-between border-b border-[#ECECEC]'>
                        {/* Checkbox Button */}
                        <div className="flex items-center gap-3  p-3">
                            <button
                                type="button"
                                className="flex justify-between items-center pl-2 text-base font-medium border rounded-md border-gray-7 -ml-2"
                            >
                                <input
                                    type="checkbox"
                                    className="h-4 w-4 accent-purple rounded-none bg-light-gray-3 align-middle"
                                    onClick={() => handleCheckboxChange(-1)}
                                    checked={contactList.length > 0 && selectedCheckboxes.length === contactList.length}
                                    readOnly // Make the checkbox read-only to avoid React warnings
                                />
                                <span className="text-4xl text-gray-6">
                                    <Icon icon="ri:arrow-drop-down-line" />
                                </span>

                            </button>

                            <div className='flex gap-2 '>
                                <button
                                    onClick={() => {
                                        if (showSetting) {
                                            setShowSetting(!showSetting)
                                            setFilter({ campaignname: '', ruleName: '' })
                                            setCurrentPage(1)
                                            GetAllContact(1)
                                            setRefresh((e) => !e)
                                        } else setShowSetting(true)
                                    }}
                                    type="button" className="min-w-[127px] text-sm font-medium rounded-lg flex items-center gap-2 border text-gray-6 border-gray-8 hover:text-dark hover:border-dark  h-[33px] px-2"
                                >
                                    <ArchiveIcon color={showSetting ? "#2D2D2D" : "#AEAEAE"} />
                                    <span style={{ color: showSetting ? "#2D2D2D" : "#AEAEAE" }}>
                                        {!showSetting ? `Show Filters` : `Hide Filters`}
                                    </span>
                                </button>

                                {
                                    showSetting &&
                                    <div className="flex items-center gap-2">
                                        <input
                                            onChange={(event) => setFilter((e) => ({ ...e, name: event.target.value }))}
                                            className={'w-60 h-[30px] text-dark text-xs border border-dark rounded-lg px-2'} type={'name'} placeholder={'Enter User Name'}
                                        />
                                        <input
                                            onChange={(event) => setFilter((e) => ({ ...e, domain: event.target.value }))}
                                            className={'w-60 h-[30px] text-dark text-xs border border-dark rounded-lg px-2'} type={'name'} placeholder={'Enter Domain Name..'}
                                        />
                                        <button
                                            onClick={() => {
                                                setCurrentPage(1)
                                                GetAllContact(1)
                                            }}
                                            type={'button'} className={'w-24 h-[30px] text-dark text-sm font-medium rounded-lg border border-dark hover:bg-dark hover:text-white'}
                                        >
                                            {`Apply`}
                                        </button>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className='flex items-center'>
                            <button
                                type="button"
                                className="outline-none rounded-md bg-purple text-white appearance-none text-sm cursor-pointer lg:text-base py-1.5 px-3"
                                onClick={() => { !selectedCheckboxes.length > 0 ? showErrorMessage("Select at least one contact") : handleGenerateButtonClick(); }}>
                                Add to Campaign
                            </button>
                        </div>
                    </div>

                    {/* Campaign List */}
                    <div className="overflow-auto pb-8">
                        {sortedContactListing.length > 0 ? (
                            <div className="flex bg-white flex-col overflow-y-auto min-h-[500px] w-full">
                                <table className="w-full table-auto text-left text-sm">
                                    <thead className="[&_th]:!border-t-0 [&_th]:!font-medium [&_th]:!px-2 [&_th]:!py-3 [&_th]:text-[15px] [&_th]:!text-[#7B7B7B] ">
                                        <tr>
                                            <th className='w-8 text-center'></th>

                                            <th onClick={() => requestSort('firstName')} className='w-[200px] cursor-pointer'>
                                                User Name  {sortConfig.key === 'firstName' && (sortConfig.direction === 'asc' ? <span className='text-[12px]'>▲</span> : <span className='text-[12px]'>▼</span>)}
                                            </th>
                                            <th onClick={() => requestSort('domain')} className='space-x-2 w-[130px] ml-auto cursor-pointer'>
                                                Domain {sortConfig.key === 'domain' && (sortConfig.direction === 'asc' ? <span className='text-[12px]'>▲</span> : <span className='text-[12px]'>▼</span>)}
                                            </th>
                                            <th onClick={() => requestSort('industry')} className='space-x-2 w-[130px] ml-auto cursor-pointer'
                                            >
                                                Industry {sortConfig.key === 'industry' && (sortConfig.direction === 'asc' ? <span className='text-[12px]'>▲</span> : <span className='text-[12px]'>▼</span>)}
                                            </th>
                                            <th onClick={() => requestSort('name')} className='space-x-2 w-[250px] ml-'>
                                                Company Name
                                            </th>
                                            <th className='space-x-2 w-[250px] ml-auto'>
                                                Actions
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="[&_td]:px-2 [&_td]:py-4 [&_td]:bg-grey-4">
                                        {sortedContactListing && sortedContactListing.map((data, index) => {
                                            return (
                                                <tr className='border-t border-[#fff]'>
                                                    <td className="w-8 text-center">
                                                        <input
                                                            type="checkbox"
                                                            className="h-4 w-4 accent-purple align-middle"
                                                            checked={selectedCheckboxes.includes(index)}
                                                            onChange={() => handleCheckboxChange(index)}
                                                        />
                                                    </td>
                                                    <td
                                                        className="cursor-pointer w-[200px]"
                                                        onClick={() =>
                                                            navigate(`/administration/contact/contact-details?contactId=${data._id}&status=true`)
                                                        }
                                                    >
                                                        <div className="text-purple flex gap-3 items-center">
                                                            <span className="size-10 flex justify-center items-center rounded-full bg-gray-200">
                                                                <span className="text-purple uppercase font-semibold text-lg">
                                                                    {`${data?.firstName[0]}${data?.lastName[0]}`}
                                                                </span>
                                                            </span>
                                                            <span>{`${data?.firstName} ${data?.lastName}`}</span>
                                                        </div>
                                                    </td>
                                                    <td className="space-x-2 w-[130px] ml-auto">
                                                        {data?.domain ?
                                                            <span className="bg-[#F9FAFC] text-gray-6 text-xs p-1 rounded-md px-2 inline-block text-center cursor-pointer" onClick={() => {
                                                                try {
                                                                    const normalizedUrl = new URL(data?.domain.startsWith('http') ? data?.domain : `https://${data?.domain}`);
                                                                    window.open(normalizedUrl.href, '_blank');
                                                                } catch (e) {
                                                                    console.error('Invalid URL:', data?.domain);
                                                                }
                                                            }}>
                                                                {data?.domain}
                                                            </span>
                                                            :
                                                            "-"
                                                        }
                                                    </td>
                                                    <td className="space-x-2 w-[250px] ml-auto">
                                                        {data?.industry ?
                                                            <span className="bg-[#F9FAFC] text-gray-6 text-xs p-1 rounded-md px-2 inline-block text-center capitalize">
                                                                {data?.industry}
                                                            </span>
                                                            :
                                                            "-"
                                                        }
                                                    </td>
                                                    <td className="text-gray-6 space-x-2">
                                                        <span>{data?.jobTitle ? data?.jobTitle : "-"}</span>
                                                        <span className="text-purple">
                                                            {data?.companyName ? data?.companyName : "-"}
                                                        </span>
                                                    </td>
                                                    <td className="z-10 w-[200px]">
                                                        <div className="w-fit border border-gray-6 flex bg-white rounded-[5px] ">
                                                            {
                                                                // data?.email &&
                                                                <button onClick={() => handleEmailButtonClick(data)} title={data?.email} className={`text-center p-1 px-2 border-r border-gray-6 ${data?.email ? "cursor-pointer" : "cursor-default"}`}>
                                                                    <EmailIcon color={data?.email ? "#7B7B7B" : "#d3d3d3"} />
                                                                </button>
                                                            }
                                                            {
                                                                // data?.phone &&
                                                                <button title={data?.phone} className={`text-center p-1 px-2 border-r border-gray-6 ${data?.phone ? "cursor-pointer" : "cursor-default"}`}>
                                                                    <CallIcon color={data?.phone ? "#7B7B7B" : "#d3d3d3"} />
                                                                </button>
                                                            }
                                                            {(data?.linkedinURL || data?.linkedinUrl) && (
                                                                <button
                                                                    onClick={() => {
                                                                        try {
                                                                            const url = data?.linkedinURL || data?.linkedinUrl; // Define url here
                                                                            if (!url) {
                                                                                return;
                                                                            }
                                                                            const normalizedUrl = new URL(url.startsWith('http') ? url : `https://${url}`);
                                                                            window.open(normalizedUrl.href, '_blank');
                                                                        } catch (e) {
                                                                            console.error('Invalid URL:', data?.linkedinURL || data?.linkedinUrl);
                                                                        }
                                                                    }}
                                                                    title={data?.linkedinURL || data?.linkedinUrl}
                                                                    className={`text-center p-1 px-2 border-r border-gray-6 ${data?.linkedinURL || data?.linkedinUrl ? "cursor-pointer" : "cursor-default"
                                                                        }`}>
                                                                    <LinkedInIcon
                                                                        color={data?.linkedinURL || data?.linkedinUrl ? "#7B7B7B" : "#d3d3d3"}
                                                                    />
                                                                </button>
                                                            )}


                                                            {/* <button className="flex items-center space-x-1 text-center text-gray-4 p-1 px-2 border-r border-gray-6">
                                                            <UserContactIcon />
                                                            <span>+1</span>
                                                        </button> */}
                                                            <div className="relative">
                                                                <Dropdown
                                                                    options={dropdownOptions}
                                                                    onSelect={(option) => handleDropdownSelect(option, data)}
                                                                />
                                                            </div>
                                                        </div>

                                                    </td>
                                                </tr>
                                            )
                                        })}

                                    </tbody>
                                </table>

                                <div className='flex justify-end'>
                                    <ReactPaginate
                                        previousLabel={"<"}
                                        nextLabel={">"}
                                        breakLabel={"..."}
                                        breakClassName={"break-me"}
                                        pageCount={pageCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={4}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination"}
                                        subContainerClassName={"pages pagination"}
                                        activeClassName={"active"}
                                        forcePage={currentPage - 1}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="text-purple bg-grey-4 text-center h-52 flex mt-2 justify-center items-center bg">
                                <div>Data not found</div>
                            </div>
                        )}
                    </div>
                </div>
            </div >
        </>
    );
};

const SelectCampaignModal = ({ openModal, event, session, selectedId, setSelectedCheckboxes, setListCheckboxes }) => {

    const [campaignData, setCampaignData] = useState(null)

    const [isLoader, setIsLoader] = useState(false);

    const validationSchema = object().shape({
        campaign: string().trim().required(Validation?.campaignRequired)
    });

    const { register: userModalCredential, handleSubmit, formState: { errors } } = useForm({ resolver: yupResolver(validationSchema) });

    async function GetData() {
        await axios.get(Path.CreateORGetCampaign,
            {
                headers: {
                    Authorization: "Bearer " + session?.data?.token,
                }
            }
        ).then((success) => {
            setIsLoader(false)
            const sortedData = success.data.sort((a, b) => {
                return new Date(b.updatedAt) - new Date(a.updatedAt);
            });
            setCampaignData(sortedData);
        }).catch((error) => {
            console.log(error)
            setIsLoader(false)
        })
    }

    useEffect(() => {
        GetData();
    }, [openModal, session?.data?.token])

    const onSubmit = async (data, e) => {
        e.preventDefault();
        setIsLoader(true)
        await axios.post(Path.MapRecords,
            {
                recordIds: selectedId,
                campaignId: data.campaign,
            },
            {
                headers: {
                    Authorization: "Bearer " + session?.data?.token,
                }
            }).then((success) => {
                setIsLoader(false)
                showSuccessMessage(success?.data?.message)
                event(!openModal)
                setSelectedCheckboxes([]);
                setListCheckboxes([]);
            }).catch((error) => {
                setIsLoader(false)
                console.log(error)
                showErrorMessage(error?.response?.data?.error || 'Something Went Wrong.')
            })

    }


    return (
        <div className={`${openModal ? 'block' : 'hidden'} bg-black/40 absolute z-20 inset-0 flex items-center overflow-y-hidden`}>
            <div className="bg-white border border-gary-3 mx-auto max-w-[764px] w-full p-6">
                <h4 className="font-bold text-center text-dark text-xl h-10 md:h-16">Select campaign</h4>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="col-span-2">
                        <label className="custom-label">
                            {`Select the campaign`}
                        </label>
                        <select  {...userModalCredential("campaign")} className={errors?.campaign ? `custom-input !text-grey-3 bg-[url('/src/assets/images/dashboard/select-down-icon.svg')] bg-no-repeat bg-[92.5%] bg-[length:1.7rem] bg-transparent appearance-none !border !border-[#FF1D48]`
                            :
                            `custom-input !text-grey-3 bg-[url('/src/assets/images/dashboard/select-down-icon.svg')] bg-no-repeat bg-[97.5%] bg-[length:1.7rem] bg-transparent appearance-none`} >
                            <option value="" disabled selected>Select campaign</option>
                            {campaignData && campaignData.map((item) => (
                                <option key={item._id} value={item._id}>{item.name}</option>
                            ))}
                        </select>
                        {
                            errors.campaign && <div className="error-css">{errors?.campaign?.message}</div>
                        }
                    </div>
                    <div className="mt-8 flex justify-end items-center gap-6">
                        <button onClick={() => { event(!openModal); setSelectedCheckboxes([]); setListCheckboxes([]); }} type="button" className="px-4 flex gap-1 items-center border-r border-[#7B7B7B] text-neutral-500 ">
                            <span className="text-sm font-bold">Cancel</span>
                        </button>
                        <button type='submit' className={`flex  gap-2 items-center bg-purple text-white rounded-full py-1 text-sm h-10 px-10 font-[600] `}>
                            <span>{`Add`}</span>
                            {
                                isLoader && <Icon icon={'svg-spinners:tadpole'} />
                            }
                        </button>
                    </div>
                </form>



            </div>
        </div>
    )
}


const SelectListCampaignModal = ({ openModal, event, session, listId }) => {

    const [campaignData, setCampaignData] = useState(null)

    const [isLoader, setIsLoader] = useState(false);

    const validationSchema = object().shape({
        campaign: string().trim().required(Validation?.campaignRequired)
    });

    const { register: userModalCredential, handleSubmit, formState: { errors } } = useForm({ resolver: yupResolver(validationSchema) });

    async function GetData() {
        await axios.get(Path.CreateORGetCampaign,
            {
                headers: {
                    Authorization: "Bearer " + session?.data?.token,
                }
            }
        ).then((success) => {
            setIsLoader(false)
            const sortedData = success.data.sort((a, b) => {
                return new Date(b.updatedAt) - new Date(a.updatedAt);
            });
            setCampaignData(sortedData);
        }).catch((error) => {
            console.log(error)
            setIsLoader(false)
        })
    }

    useEffect(() => {
        GetData();
    }, [openModal, session?.data?.token])

    const onSubmit = async (data, e) => {
        e.preventDefault();
        setIsLoader(true)
        await axios.post(Path.AddListToCampaign,
            {
                listId: listId,
                campaignId: data.campaign,
            },
            {
                headers: {
                    Authorization: "Bearer " + session?.data?.token,
                }
            }).then((success) => {
                setIsLoader(false)
                showSuccessMessage(success?.data?.message)
                event(!openModal)
            }).catch((error) => {
                setIsLoader(false)
                console.log(error)
                showErrorMessage(error?.response?.data?.error || 'Something Went Wrong.')
            })

    }


    return (
        <div className={`${openModal ? 'block' : 'hidden'} bg-black/40 absolute z-20 inset-0 flex items-center overflow-y-hidden`}>
            <div className="bg-white border border-gary-3 mx-auto max-w-[764px] w-full p-6">
                <h4 className="font-bold text-center text-dark text-xl h-10 md:h-16">Select campaign</h4>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="col-span-2">
                        <label className="custom-label">
                            {`Select the campaign`}
                        </label>
                        <select  {...userModalCredential("campaign")} className={errors?.campaign ? `custom-input !text-grey-3 bg-[url('/src/assets/images/dashboard/select-down-icon.svg')] bg-no-repeat bg-[92.5%] bg-[length:1.7rem] bg-transparent appearance-none !border !border-[#FF1D48]`
                            :
                            `custom-input !text-grey-3 bg-[url('/src/assets/images/dashboard/select-down-icon.svg')] bg-no-repeat bg-[97.5%] bg-[length:1.7rem] bg-transparent appearance-none`} >
                            <option value="" disabled selected>Select campaign</option>
                            {campaignData && campaignData.map((item) => (
                                <option key={item._id} value={item._id}>{item.name}</option>
                            ))}
                        </select>
                        {
                            errors.campaign && <div className="error-css">{errors?.campaign?.message}</div>
                        }
                    </div>
                    <div className="mt-8 flex justify-end items-center gap-6">
                        <button onClick={() => { event(!openModal); }} type="button" className="px-4 flex gap-1 items-center border-r border-[#7B7B7B] text-neutral-500 ">
                            <span className="text-sm font-bold">Cancel</span>
                        </button>
                        <button type='submit' className={`flex  gap-2 items-center bg-green text-white rounded-full py-1 text-sm h-10 px-10 font-[600] `}>
                            <span>{`Add`}</span>
                            {
                                isLoader && <Icon icon={'svg-spinners:tadpole'} />
                            }
                        </button>
                    </div>
                </form>



            </div>
        </div>
    )
}

