import { Icon } from '@iconify/react';
import React from 'react'
import { RiLoader4Line } from "react-icons/ri";

function EditorLoader() {
    return (
        <div className={`fixed top-0 left-0 z-[999] w-screen h-screen flex justify-center items-center backdrop-blur-[10px] bg-[rgba(0,0,0,0.4)]`}>
            <RiLoader4Line className="animate-spin" size={64} scale={10} color="white" />
        </div>
    );
};

export default EditorLoader;